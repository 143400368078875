import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import { useEffect, useState } from "react";
import InputValidationMiddleware from "../../../../validators/InputValidationMiddleware";
import pt from "date-fns/locale/pt";
import { AutoComplete } from "primereact/autocomplete";
import { AlertService } from "../../../../components/AlertService";
import { url } from "../../../../environments/environments-develop";
import api from '../../../../services/api';
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import BancoService from "../../Services/BancoService";
import { Button } from "primereact/button";
interface InformacaoBancariaFormProps {
    formBasico?: any | null;
    onInputChangeInformacaoBancaria: (field: string, value: any) => void;
    handleAddPessoa: () => void;
    emiteBoleto?: any | null;
}
function InformacaoBancariaForm(props: InformacaoBancariaFormProps,) {
    const [formData, setFormData] = useState<any>(props.formBasico || {});
    const [touchedFields, setTouchedFields] = useState<string[]>([]);
    const [FilteredBanco, setFilteredBanco] = useState<any>(null);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    const [listPessoa, setListPessoa] = useState([]);
    const [data_ultima_remessa, setdataUltimaRemessa] = useState<Date | null>(null);
    interface Consulta {
        order?: string;
        filtro?: any;
    }
    const consulta: Consulta = {
        order: "id;desc",
    };
    const handleChange = (field: string, value: any) => {
        if (field === 'cpf_cnpj') {
            if (/^[0-9.;\/\-]*$/.test(value) && value.length <= 18) {
                props.onInputChangeInformacaoBancaria(field, value);
            }
        } else if (field === 'nome') {
            if (!value || value.length <= 60) {
                props.onInputChangeInformacaoBancaria(field, value);
            }
        } else if (field === 'nome') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChangeInformacaoBancaria(field, value);
            }
        } else if (field === 'inscricao_municipal') {
            if (/^\d*$/.test(value) && value.length <= 25) {
                props.onInputChangeInformacaoBancaria(field, value);
            }
        } else if (field === 'inscricao_estadual') {
            if (/^\d*$/.test(value) && value.length <= 25) {
                props.onInputChangeInformacaoBancaria(field, value);
            }
        } else if (field === 'email') {
            if (!value || value.length <= 60) {
                props.onInputChangeInformacaoBancaria(field, value);
            }
        } else if (field === 'filiacao_mae') {
            if (!value || value.length <= 60) {
                props.onInputChangeInformacaoBancaria(field, value);
            }
        } else if (field === 'filiacao_pai') {
            if (!value || value.length <= 60) {
                props.onInputChangeInformacaoBancaria(field, value);
            }
        } else if (field === 'rg') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChangeInformacaoBancaria(field, value);
            }
        } else {
            if (field == 'pessoa_id') {
                props.onInputChangeInformacaoBancaria('pessoa_nome', value.nome);
                props.onInputChangeInformacaoBancaria(field, value.id);
            } else {
                props.onInputChangeInformacaoBancaria(field, value);
            }
        }
        if (!touchedFields.includes(field)) {
            setTouchedFields([...touchedFields, field]);
        }
    };
    useEffect(() => {
        setFormData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ...props.formBasico
            };
        });
    }, [props.formBasico]);


    const search = (event: any) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _FilteredPessoa: any;

            if (!event.query.trim().length) {
                _FilteredPessoa = [...listPessoa];
            }
            else {
                if (event.query) {
                    _FilteredPessoa = listPessoa.filter((item: any) => {
                        return item.nome && item.nome.toLowerCase().startsWith(event.query.toLowerCase());
                        // Verifica se item.nome não é nulo ou indefinido antes de acessar toLowerCase()
                    });
                }
            }
            setFilteredPessoa(_FilteredPessoa);
        }, 250);
    }

    const searchBanco = (
        event: any,
        setFilteredPessoa: (value: any) => void
    ) => {
        const query = event.query;
        const form = {
            busca: query,
        };

        const filtro = Object.fromEntries(
            Object.entries(form).filter(([_, value]) => value !== null)
        );

        if (consulta) {
            consulta.filtro = filtro;
        }
        setTimeout(() => {
            BancoService.encontrar_banco({
                consulta: JSON.stringify(consulta),
            }).subscribe(
                (response) => {
                    setFilteredBanco(response);
                },
                (error) => {
                    let mensagens: string[] = [];
                    mensagens.push(error?.response?.data?.error?.message);
                    AlertService.error("Erro!", String(mensagens));
                }
            );
        }, 600);
    };
    const handleChangeBanco = (field: string, value: any) => {
        props.onInputChangeInformacaoBancaria('banco_nome', value);
        props.onInputChangeInformacaoBancaria(field, value.value);
    };
    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                //listagem dos pessoas
                const response = await api.get(`${url}/api/v1/admin/core/pessoa`);
                setListPessoa(response.data.data);
            } catch (error) {
                AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <InputValidationMiddleware>
                <Form.Group className="imovel-form">
                    <Row className="mb-3">
                        <h3 className="label-form-create-imovel">Informações Bancária</h3>
                    </Row>
                    <Row className="mb-3">
                        <Col style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Bancos</Form.Label>
                            <AutoComplete
                                placeholder='Pesquisar Bancos'
                                field="label"
                                style={{ height: "37px", width: '100%' }}
                                value={formData.banco_nome ? formData.banco_nome : ''}
                                suggestions={FilteredBanco}
                                completeMethod={(e) => searchBanco(e, setFilteredPessoa)}
                                onChange={(e) => handleChangeBanco('banco', e.value)}
                                dropdown
                            />
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Titular</Form.Label>
                            <Form.Control value={formData.titular} onChange={(e) => handleChange('titular', e.target.value)} />
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Agência</Form.Label>
                            <Form.Control value={formData.agencia} onChange={(e) => handleChange('agencia', e.target.value)} />
                        </Col>
                        <Col md='2' style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Dígito Agência</Form.Label>
                            <Form.Control value={formData.digito_agencia} onChange={(e) => handleChange('digito_agencia', e.target.value)} />
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Conta</Form.Label>
                            <Form.Control value={formData.conta} onChange={(e) => handleChange('conta', e.target.value)} />
                        </Col>
                        <Col md='2' style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Dígito Conta</Form.Label>
                            <Form.Control value={formData.digito_conta} onChange={(e) => handleChange('digito_conta', e.target.value)} />
                        </Col>
                    </Row>
                    {props.emiteBoleto &&
                        <Row className="mb-3">
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Seq.dia da Remessa</Form.Label>
                                <InputNumber
                                    value={formData.remessa_seq_dia ? formData.remessa_seq_dia : null}
                                    onChange={(e) => handleChange("remessa_seq_dia", e.value)}
                                    useGrouping={false}
                                    min={0}
                                    max={99999}
                                />
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Local de Pgto</Form.Label>
                                <Form.Control value={formData.local_pgto} onChange={(e) => handleChange('local_pgto', e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Instr.Caixa</Form.Label>
                                <Form.Control value={formData.inst_caixa} onChange={(e) => handleChange('inst_caixa', e.target.value)} />
                            </Col>
                            <Form.Group as={Col} controlId="formGridState" style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Layout do Retorno</Form.Label>
                                <Form.Select value={formData.retorno_layout || ''} onChange={(e) => handleChange('retorno_layout', Number(e.target.value))}>
                                    <option>Selecione...</option>
                                    <option value={1}>CNAB240</option>
                                    <option value={2}>CNAB400</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridState" style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Layout da Remessa</Form.Label>
                                <Form.Select value={formData.remessa_layout || ''} onChange={(e) => handleChange('remessa_layout', Number(e.target.value))}>
                                    <option>Selecione...</option>
                                    <option value={1}>CNAB240</option>
                                    <option value={2}>CNAB400</option>
                                </Form.Select>
                            </Form.Group>
                            <Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Telefone de Contato</Form.Label>
                                <InputNumber
                                    value={formData.telefone_contato ? formData.telefone_contato : null}
                                    onChange={(e) => handleChange("telefone_contato", e.value)}
                                    useGrouping={false}
                                    min={0}
                                    max={999999999999}
                                />
                            </Col>
                        </Row>
                    }
                    {props.emiteBoleto &&
                        <Row className="mb-3">
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Seq.Remessa</Form.Label>
                                <InputNumber
                                    value={formData.seq_remessa ? formData.seq_remessa : null}
                                    onChange={(e) => handleChange("seq_remessa", e.value)}
                                    useGrouping={false}
                                    min={0}
                                    max={999999999999}
                                />
                            </Col>
                            <Col md="2" style={{ display: 'grid', flexDirection: 'column' }}>
                                <Form.Label>Data da Ult Remessa</Form.Label>
                                <Calendar
                                    value={data_ultima_remessa}
                                    onChange={(event: any) => {
                                        const selectedDates = event.value;
                                        setdataUltimaRemessa(selectedDates);
                                        handleChange('data_ultima_remessa', selectedDates ? selectedDates.toISOString() : null);
                                    }}
                                    showButtonBar
                                    selectionMode="single"
                                    dateFormat="dd/mm/yy"
                                    locale="pt"
                                />


                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Variação</Form.Label>
                                <Form.Control value={formData.variacao} onChange={(e) => handleChange('variacao', e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Cod.Cedente|Cliente|Convenio</Form.Label>
                                <Form.Control value={formData.cod_cendente} onChange={(e) => handleChange('cod_cendente', e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Cod. Carteira</Form.Label>
                                <Form.Control value={formData.cod_carteira} onChange={(e) => handleChange('cod_carteira', e.target.value)} />
                            </Col>
                        </Row>
                    }
                    <Row className="mb-3">
                        {props.emiteBoleto &&
                            <>
                                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Form.Label>Posto|Ranger</Form.Label>
                                    <Form.Control value={formData.config_01} onChange={(e) => handleChange('config_01', e.target.value)} />
                                </Col>
                                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Form.Label>Byte</Form.Label>
                                    <Form.Control value={formData.config_02} onChange={(e) => handleChange('config_02', e.target.value)} />
                                </Col>
                            </>
                        }
                        <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Nome do Gerente </Form.Label>
                            <Form.Control value={formData.nome_gerente} onChange={(e) => handleChange('nome_gerente', e.target.value)} />
                        </Col>
                        {props.emiteBoleto &&
                            <Col md="3">
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                                        <Form.Label>Beneficiário</Form.Label>
                                        <AutoComplete
                                            placeholder='Pesquisar nome'
                                            field="nome"
                                            style={{ height: "37px", width: '100%' }}
                                            value={formData.pessoa_nome ? formData.pessoa_nome : formData.search}
                                            suggestions={FilteredPessoa}
                                            completeMethod={search}
                                            onChange={(e) =>
                                                handleChange('search', e.value)}
                                            onSelect={(e) =>
                                                handleChange('pessoa_id', e.value)}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px', marginTop: '25px' }}>
                                        {formData.pessoa ? (
                                            <Button
                                                className='button-editar'
                                                icon="pi pi-pencil"
                                                severity="warning"
                                                onClick={() => props.handleAddPessoa()}
                                            ></Button>
                                        ) : (
                                            <Button
                                                className='button-create'
                                                icon="pi pi-plus"
                                                severity="success"
                                                onClick={() => props.handleAddPessoa()}
                                            ></Button>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        }
                    </Row>
                </Form.Group >
            </InputValidationMiddleware>
        </>
    );
}

export default InformacaoBancariaForm;
