import 'react-datepicker/dist/react-datepicker.css';
import { handleInputChange } from "../Service/ImovelService";
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

interface TextualFormProps {
    formData: any,
    setFormData: (value: any) => void,
}
function TextualForm({
    formData,
    setFormData,
}: TextualFormProps) {

    return (
        <fieldset className="w-full border p-4 rounded mb-4">
            <legend className="font-bold text-lg">Textual / Descritivo</legend>
            <div className="grid grid-cols-3 gap-4">
                <div className="flex flex-col">
                    <label htmlFor="titulo" className="mb-1 font-medium">Título</label>
                    <InputText
                        id="titulo"
                        value={formData?.titulo}
                        invalid={!formData?.titulo}
                        onChange={(e) => handleInputChange("titulo", e.target.value, setFormData)}
                        placeholder="Digite o título"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="subtitulo" className="mb-1 font-medium">Subtitulo</label>
                    <InputText
                        id="subtitulo"
                        value={formData?.subtitulo}
                        invalid={!formData?.subtitulo}
                        onChange={(e) => handleInputChange("subtitulo", e.target.value, setFormData)}
                        placeholder="Digite o subtitulo"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="observacao" className="mb-1 font-medium">Observação</label>
                    <InputText
                        id="observacao"
                        value={formData?.observacao}
                        onChange={(e) => handleInputChange("observacao", e.target.value, setFormData)}
                        placeholder="observacao do imóvel"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="descricao_detalhada" className="mb-1 font-medium">Descrição detalhada</label>
                    <InputTextarea
                        id="descricao_detalhada"
                        value={formData?.descricao_detalhada}
                        invalid={!formData?.descricao_detalhada}
                        onChange={(e) => handleInputChange("descricao_detalhada", e.target.value, setFormData)}
                        placeholder="Digite o ID de visibilidade"
                        rows={5} cols={30}
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="descricao_curta" className="mb-1 font-medium">Descrição Curta</label>
                    <InputTextarea
                        id="descricao_curta"
                        value={formData?.descricao_curta}
                        invalid={!formData?.descricao_curta}
                        onChange={(e) => handleInputChange("descricao_curta", e.target.value, setFormData)}
                        placeholder="Descrição detalhada do imóvel"
                        rows={5} cols={30}
                    />
                </div>

            </div>
        </fieldset>
    );
}

export default TextualForm;
