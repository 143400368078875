import axios from "../../../services/api";
import { Observable, from, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { url } from "../../../environments/environments-develop";

interface IApiResponse<T> {
  data: T;
  headers: {
    titles: string[];
  };
  meta: any;
}

class ImovelService {
  private resourceUrl = url + "/api/v1/admin/vendas/imoveis-captados";
  private resourceUrlPublic =
    url + "/api/v1/admin/vendas/imoveis-captados-public";

  list(
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<any> {
    return from(
      axios
        .get<IApiResponse<any[]>>(this.resourceUrl, {
          params,
          headers,
        })
        .then((response) => {
          updatePagination(response.data.meta.pagination.total_pages);
          return response.data.data;
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  get(id: number, params: any = {}, headers: any = null): Observable<any> {
    return from(
      axios
        .get<any>(`${this.resourceUrl}/${id}`, {
          params,
          headers,
        })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }

  show_public(
    id: number,
    params: any = {},
    headers: any = null
  ): Observable<any> {
    return from(
      axios
        .get<any>(`${this.resourceUrlPublic}/${id}`, {
          params,
          headers,
        })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }

  create(data: any, params: any = {}, headers: any = null): Observable<any> {
    return from(
      axios
        .post<any>(this.resourceUrl, data, {
          params,
          headers,
        })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }

  update(
    id: number,
    data: any,
    params: any = {},
    headers: any = null
  ): Observable<any> {
    return from(
      axios
        .put<any>(`${this.resourceUrl}/${id}`, data, {
          params,
          headers,
        })
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }

  excluir(id: any): Observable<any> {
    return from(
      axios
        .delete<any>(`${this.resourceUrl}/${id}`)
        .then((response) => response.data)
    ).pipe(catchError((error) => throwError(error)));
  }
}

export default new ImovelService();
