import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { AlertService } from '../../../../../../components/AlertService';
import { url } from '../../../../../../environments/environments-develop';
import api from '../../../../../../services/api';
import { AutoComplete } from 'primereact/autocomplete';
import { searchInumado } from '../Service/VendaService';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

interface ModalInputInumadoProps {
    showModal: boolean;
    handleClose: () => void;
    gavetaSelect: any;
}

const ModalInputInumado: React.FC<ModalInputInumadoProps> = ({ showModal, handleClose, gavetaSelect }) => {
    const [show, setShow] = useState(showModal);
    const [formBasico, setFormBasico] = useState<any | null>(null);
    const [data_sepultamento, setData_sepultamento] = useState<any | null>(null);
    const [filteredInumado, setFilteredInumado] = useState<any>(null);
    const urlRedirectCreated = "/inumados/store";

    useEffect(() => {
        if (gavetaSelect) {
            setFormBasico({
                "imovel_id": gavetaSelect.imovel_id,
                "identificador_gaveta": gavetaSelect.identificador_gaveta,
            });
        }
    }, [gavetaSelect]);

    useEffect(() => {
        setShow(showModal);
    }, [showModal]);

    const handleSaveButton = async (): Promise<void> => {
        try {
            const response = await api.put(`${url}/api/v1/admin/cemiterio/imovel-gaveta/inputar-inumado`, formBasico);
            if (response.status === 200) {
                AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
                    setShow(false);
                    window.location.reload();
                });
            }
        } catch (error: any) {
            AlertService.infomessage('Erro!', String(error?.response?.data?.error?.message?.inumado_id)).then(() => {
                setShow(false);
            });
        }
    };

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    const handleChange = (field: string, value: any) => {
        setFormBasico((prevForm: any) => ({
            ...prevForm,
            [field]: value
        }));
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            className='modal-input-inumado'
        >
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Inumado ao Jazigo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className='mb-3'>
                        <Col md="4">
                            <Form.Group>
                                <Form.Label>Inumado</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AutoComplete
                                        field="pessoa.nome"
                                        style={{ flex: 1 }}
                                        value={formBasico?.inumado || ''}
                                        suggestions={filteredInumado}
                                        completeMethod={(e) => searchInumado(e, 0, setFilteredInumado)}
                                        onChange={(e) => {
                                            handleChange('inumado', e.value);
                                        }}
                                        onSelect={(e) => {
                                            handleChange('inumado', e.value);
                                            handleChange('inumado_id', e.value.id);
                                        }}
                                        dropdown
                                    />
                                    <Button
                                        icon="pi pi-plus"
                                        className="p-button-rounded p-button-success"
                                        tooltip='Adicionar Inumado'
                                        style={{
                                            borderRadius: '25px',
                                            height: "25px",
                                            width: "25px",
                                            backgroundColor: "#10c117",
                                            borderColor: "#10c117",
                                            marginLeft: '8px'
                                        }}
                                        onClick={() => {
                                            window.location.href = urlRedirectCreated;
                                        }}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md="3">
                            <Form.Label>Data Sepultamento</Form.Label>
                            <Form.Group controlId="formGridState">
                                <Calendar
                                    value={data_sepultamento}
                                    onChange={(event) => {
                                        const selectedDate = event.value;
                                        setData_sepultamento(selectedDate);
                                        handleChange('data_sepultamento', selectedDate);
                                    }}
                                    placeholder="Data Sepultamento"
                                    showButtonBar
                                    selectionMode="single"
                                    dateFormat="dd/mm/yy"
                                    locale="pt"
                                    readOnlyInput
                                    style={{
                                        width: '100%',
                                        height: '38px',
                                        textAlign: 'center',
                                    }}
                                    panelClassName="custom-calendar-panel"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex' }}>
                    <Button
                        className='button-save-or-update'
                        style={{ marginRight: '27px', borderRadius: '5px' }}
                        onClick={handleSaveButton}
                    >
                        Confirmar
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalInputInumado;
