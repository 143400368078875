import { useState, useEffect } from 'react';
import iconFiltroAvancado from '../../../../../assets/images/icon-filtro-avancado.png';
import { Col, Row, Modal, Form, Container } from 'react-bootstrap';
import { AlertService } from '../../../../components/AlertService';
import { url } from '../../../../environments/environments-develop';
import api from '../../../../services/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { showToast } from '../../../../components/ToastComponent';
import FiltroAvancadoModal from './FiltroAvancado';
import ModalShowImportacao from './ModalShowImportacao';
import { actionTemplate } from './actionTemplate';
import Paginacao from './Paginacao';
import Legenda from './Legenda';
import TogglerStatus from './TogglerStatus';

function ModalListagem({ showModal, handleShowModal }: any) {
    const [isLoading, setLoading] = useState(false);
    const [titles, setTitles] = useState<any | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState<number>(8);
    const urlSearch = '/api/v1/admin/core/import-arquivo'
    const initialFilters = {
        modo_import: '',
        local_import: '',
        status: '',
        tipo_consulta: '',
        data_inicio: '',
        data_fim: '',
    };
    const [listFiltros, setListFiltros] = useState<any>(initialFilters);
    const [showModalFiltro, setShowModalFiltro] = useState(false);
    const [showModalDetalhes, setShowModalDetalhes] = useState(false);
    const [filtros, setFiltros] = useState<string>('');
    const [selectedRow, setSelectedRow] = useState<any | null>(null);

    useEffect(() => {
        if (showModal) {
            montarFiltro();
        }
    }, [showModal, currentPage, itemsPerPage, listFiltros]);

    useEffect(() => {
        if (JSON.stringify(listFiltros) === JSON.stringify(initialFilters)) {
            montarFiltro();
        }
    }, [listFiltros]);
    
    async function montarFiltro(): Promise<void> {
        try {
            const filtrosLimpos = Object.fromEntries(
                Object.entries(listFiltros).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
            );
            const consulta: any = {
                consulta: {
                    order: "id;asc",
                    filtro: {
                        ...filtrosLimpos,
                    },
                },
            };
            const queryParams: any = {};
            const camposEnviar = ['consulta'];
            camposEnviar.forEach((key: any) => {
                const value = consulta[key];
                if (value !== undefined && value !== null && value !== '') {
                    if (key === 'consulta') {
                        queryParams[key] = JSON.stringify(value);
                    } else {
                        queryParams[key] = value;
                    }
                }
            });
            const queryString = Object.entries(queryParams)
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
                .join('&');

            setFiltros(queryString);
            fetchImportsData();
        } catch (error: any) {
            showToast('error', 'Erro ao montar filtro: ' + String(error.response?.data?.message) || 'Erro desconhecido');
        }
    };

    const fetchImportsData = async () => {
        try {
            const response = await api.get(`${url}${urlSearch}?${filtros}&page=${currentPage + 1}&totalitems=${itemsPerPage}`);
            if (response.status === 200) {
                const titles = response.data.headers.titles;
                setTitles(titles)
                const data = response.data.data;
                const totalPages = response.data.meta.pagination.total_pages;
                setTotalPages(totalPages);
                setNodes(data);
                setLoading(false);
            }
        } catch (error) {
            const mensagens = [];
            const errorMessage = (error as any).response?.data?.error?.message || 'Erro ao buscar dados.';
            mensagens.push(errorMessage);
            AlertService.error('Erro!', mensagens.join('\n'));
        }
    };

    const handleFilter = () => {
        setCurrentPage(0);
        montarFiltro();
    };

    const handleClearFilters = () => {
        setListFiltros(initialFilters);
        setCurrentPage(0);
    };

    return (
        <Modal show={showModal} onHide={() => handleShowModal(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Listagem de Importações</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Modo de Importação</Form.Label>
                            <Form.Select
                                value={listFiltros?.modo_import}
                                onChange={(e) => setListFiltros({ ...listFiltros, modo_import: e.target.value })}
                            >
                                <option value={''}>Selecione...</option>
                                <option value={1}>Inserir</option>
                                <option value={2}>Update</option>
                            </Form.Select>
                        </Col>
                        <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Local da Importação</Form.Label>
                            <Form.Select
                                value={listFiltros?.local_import}
                                onChange={(e) => setListFiltros({ ...listFiltros, local_import: e.target.value })}
                            >
                                <option value={''}>Selecione...</option>
                                <option value="1">Comprador</option>
                                <option value="2">Corretor</option>
                                <option value="3">Classificação de Lote</option>
                                <option value="4">Imovel | Lote</option>
                                <option value="5">Parcelas | Contas a Receber</option>
                                <option value="6">Vendas | Contratos</option>
                                <option value="8">Vendas Canceladas | Distratos</option>
                            </Form.Select>
                        </Col>
                        <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                                value={listFiltros?.status}
                                onChange={(e) => setListFiltros({ ...listFiltros, status: e.target.value })}
                            >
                                <option value={''}>Selecione...</option>
                                <option value={1}>Aguardando</option>
                                <option value={2}>Processando</option>
                                <option value={3}>Concluido</option>
                            </Form.Select>
                        </Col>
                        <Col className='div-filtros-avancados' style={{ marginTop: '22px' }}>
                            <Button
                                style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                                type="button"
                                rounded outlined severity="secondary"
                                onClick={() => setShowModalFiltro(true)}
                            >
                                <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                            </Button>
                            <Button
                                style={{ position: 'relative', float: 'right', height: '30px', borderRadius: '7px', marginRight: '5px' }}
                                type="button"
                                icon="pi pi-search"
                                severity="success"
                                onClick={() => {
                                    setCurrentPage(0);
                                    montarFiltro();
                                }}
                                rounded>
                                Filtrar
                            </Button>
                            <Button
                                style={{ height: '30px', borderRadius: '7px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                                type="button"
                                severity="danger"
                                icon="pi pi-delete-left"
                                rounded
                                onClick={() => {
                                    handleClearFilters();
                                }}
                            >
                                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <div className="card">
                        <Legenda />
                        <DataTable
                            lazy
                            stripedRows
                            size="small"
                            value={nodes ? nodes : []}
                            tableStyle={{ minWidth: '50rem' }}
                            emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
                        >
                            <Column
                                style={{ width: '5px' }}
                                body={(e) => <TogglerStatus status={e.status} />}
                                headerClassName=""
                            />
                            <Column
                                style={{ textAlign: 'right' }}
                                key={'id'}
                                field={'id'}
                                headerClassName="righted-header"
                                header={<div className="righted-content">Id</div>}
                                body={isLoading && (<Skeleton />)}
                            />
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'empreendimento_name'}
                                field={'empreendimento_name'}
                                headerClassName="lefted-header"
                                header={<div className="lefted-content">Empreendimento</div>}
                                body={isLoading && (<Skeleton />)}
                            />
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'filial_name'}
                                field={'filial_name'}
                                headerClassName="lefted-header"
                                header={<div className="lefted-content">Filial</div>}
                                body={isLoading && (<Skeleton />)}
                            />
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'user_name'}
                                field={'user_name'}
                                headerClassName="lefted-header"
                                header={<div className="lefted-content">Usuario</div>}
                                body={isLoading && (<Skeleton />)}
                            />
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'status_name'}
                                field={'status_name'}
                                headerClassName="lefted-header"
                                header={<div className="lefted-content">Status</div>}
                                body={isLoading && (<Skeleton />)}
                            />
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'modo_import_name'}
                                field={'modo_import_name'}
                                headerClassName="lefted-header"
                                header={<div className="lefted-content">Modo de Importação</div>}
                                body={isLoading && (<Skeleton />)}
                            />
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'localImport_name'}
                                field={'localImport_name'}
                                headerClassName="lefted-header"
                                header={<div className="lefted-content">Local Import</div>}
                                body={isLoading && (<Skeleton />)}
                            />
                            <Column
                                style={{ textAlign: 'left' }}
                                key={'log_sucess'}
                                field={'log_sucess'}
                                headerClassName="lefted-header"
                                header={<div className="lefted-content">Nome</div>}
                                body={isLoading && (<Skeleton />)}
                            />
                            <Column
                                headerClassName="centered-header"
                                header={<div className="centered-content">Ações</div>}
                                body={(e) => actionTemplate(e
                                    , setShowModalDetalhes
                                    , setSelectedRow
                                )}
                            />
                        </DataTable>
                        <Paginacao
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            itemsPerPage={itemsPerPage}
                            setItemsPorPagina={setItemsPerPage}
                        />
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    severity="secondary"
                    style={{ height: '30px', borderRadius: '7px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    onClick={() => handleShowModal(false)}
                >
                    Fechar
                </Button>
            </Modal.Footer>
            <ModalShowImportacao
                data={selectedRow}
                showModal={showModalDetalhes}
                onHide={() => setShowModalDetalhes(false)}
                onInputChange={() => setCurrentPage(currentPage)}
            />
            <FiltroAvancadoModal
                showModal={showModalFiltro}
                onHide={() => setShowModalFiltro(false)}
                handleClose={() => setShowModalFiltro(false)}
                listFiltros={listFiltros}
                setListFiltros={setListFiltros}
                setShowModalFiltro={setShowModalFiltro}
                handleFilter={handleFilter}
                handleClearFilters={handleClearFilters}
            />
        </Modal>
    );
}

export default ModalListagem;
