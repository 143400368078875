import { AlertService } from "../../../../components/AlertService";
import TokenService from "../../../../services/Auth/token.service";
import CaracteristicaService from "../../../Revenda/Services/CaracteristicaService";
interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "descricao;asc",
};

export const loadData = (
  listFiltros: any,
  itensPorPagina: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: (value: any) => void,
  sortOptions: { field: string; order: number } | null = null
) => {
  setLoading(true);
  const form = {
    "vendas.imoveis.tipo_imovel_id": listFiltros?.tipo_imovel_id,
    "vendas.imoveis.sub_tipo_imovel_id": listFiltros?.sub_tipo_imovel_id,
    descricao: listFiltros?.descricao,
    tipo_dado: listFiltros?.tipo_dado,
    nome_campos: listFiltros?.nome_campos,
    empreendimento_id: TokenService.getEmpreendimentoId()
      ? TokenService.getEmpreendimentoId()?.id
      : null,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
    if (sortOptions) {
      const orderDirection = sortOptions.order === 1 ? "asc" : "desc";
      consulta.order = `${sortOptions.field};${orderDirection}`;
    }
  }

  CaracteristicaList(
    consulta,
    itensPorPagina,
    currentPage,
    setLoading,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro
  );
};

export const CaracteristicaList = (
  filtros: any,
  itensPorPagina: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: any | null
) => {
  CaracteristicaService.list(
    {
      include: "sub_tipo_imovel, tipo_imovel",
      totalitems: itensPorPagina ?? 8,
      consulta: JSON.stringify(filtros),
      page: currentPage + 1,
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response) => {
      setLoading(false);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const excluirCaracteristica = (id: any) => {
  AlertService.confirm({
    title: "Confirmar Exclusão!",
    text: "Deseja realmente excluir está Característica?",
    cancelButtonText: "Não",
    confirmButtonText: "Sim",
  }).then((e: any) => {
    if (e?.isConfirmed) { 
      CaracteristicaService.excluir(id).subscribe(
        (response) => {
          AlertService.sucess("Sucesso!", "Excluído com sucesso!").then(() => {
            window.location.reload();
          });
        },
        (error) => {
          let mensagens: string[] = [];
          Object.keys(error?.response?.data?.error?.message).forEach((key) => {
            mensagens.push("\n" + error?.response?.data?.error?.message[key][0]);
          });
          AlertService.error("Erro!", String(mensagens));
        }
      );
    }
  });
};

export const setSessionEditData = async (value: any, url_redirect_edit: any, urlShow: any) => {
  CaracteristicaService.get(value, {
    include: "sub_tipo_imovel, tipo_imovel",
  }).subscribe(
    (response) => {
      localStorage.setItem("dadosEdicao", JSON.stringify(response));
      if (urlShow) {
        window.location.href = urlShow;
      } else {
        window.location.href = url_redirect_edit;
      }
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

