import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";

interface FiltroAvancadoModalProps {
    showModal: boolean;
    onHide: () => void;
    listFiltros: any;
    setListFiltros: any | null;
    loadData: (filtros: any, itemsPerPage: any, currentPage: number, setLoading: (nodes: any) => void, setNodes: (nodes: any) => void, updateTitles: (titles: string[]) => void, updatePagination: (pages: number) => void, setShowModalFiltro: (show: boolean) => void) => void;
    currentPage: number;
    setLoading: (nodes: any) => void,
    setNodes: (nodes: any) => void;
    updateTitles: (titles: string[]) => void;
    updatePagination: (pages: number) => void;
    setShowModalFiltro: (show: boolean) => void;
    handleClose: () => void;
    itemsPerPage: any;
}

const FiltroAvancadoModal: React.FC<FiltroAvancadoModalProps> = ({
    showModal,
    onHide,
    listFiltros,
    setListFiltros,
    loadData,
    currentPage,
    setLoading,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro,
    itemsPerPage,
}) => {
    const [data_inicial, setDataInicio] = useState<Date | null>();
    const [data_final, setDataFim] = useState<Date | null>();
    const [tipo_consulta, setTipoConsulta] = useState<string>();
    const [plano_conta_id, setPlanoContaId] = useState<number>();
    const [centro_custo_id, setCentroCustoId] = useState<number>();
    const [situacao_pagamento, setSituacaoPagamento] = useState<string>();
    const [tipo_parcela, setTipoParcela] = useState<string>();

    const [listPlanoConta, setListPlanoConta] = useState([]);
    const [listCentroCusto, setListCentroCusto] = useState([]);

    async function InitPlanoConta() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/plano-conta`);
            setListPlanoConta(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }

    async function InitCentroCusto() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/centro-custo`);
            setListCentroCusto(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    };

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    useEffect(() => {
        setDataInicio(listFiltros?.data_inicial ? new Date(listFiltros?.data_inicial) : null);
        setDataFim(listFiltros?.data_final ? new Date(listFiltros?.data_final) : null);
        setTipoConsulta(listFiltros?.tipo_consulta ? listFiltros?.tipo_consulta : null);
        setPlanoContaId(listFiltros?.plano_conta_id ? listFiltros?.plano_conta_id : null);
        setCentroCustoId(listFiltros?.centro_custo_id ? listFiltros?.centro_custo_id : null);
        setSituacaoPagamento(listFiltros?.situacao_pagamento ? listFiltros?.situacao_pagamento : null);
        setTipoParcela(listFiltros?.tipo_parcela ? listFiltros?.tipo_parcela : null);
    }, [listFiltros]);

    useEffect(() => {
        InitPlanoConta();
        InitCentroCusto();
    }, []);

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="lg"
            className="modal-filtro-avancado"
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtros Avançados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className='mb-3'>
                        <Col md="3">
                            <Form.Label>Tipo Consulta</Form.Label>
                            <Form.Select
                                value={tipo_consulta || ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, tipo_consulta: e.target.value })}
                            >
                                <option>Selecione...</option>
                                <option value={'data_vencimento'}>Vencimento</option>
                                <option value={'data_pagamento'}>Pagamento</option>
                                <option value={'data_baixa'}>Baixa</option>
                                <option value={'data_emissao'}>Emissão</option>
                                <option value={'data_credito'}>Crédito</option>
                                <option value={'prox_reajuste'}>Prox. Reajuste</option>
                            </Form.Select>
                        </Col>
                        <Col md="3">
                            <Form.Label>Data Início</Form.Label>
                            <Calendar
                                value={data_inicial || null}
                                onChange={(event: any) => {
                                    const selectedDates = event.value;
                                    setDataInicio(selectedDates);
                                    setListFiltros({ ...listFiltros, data_inicial: selectedDates });
                                }}
                                placeholder="Data Inicio"
                                showButtonBar
                                selectionMode="single"
                                dateFormat="dd/mm/yy"
                                locale="pt"
                                readOnlyInput
                                style={{ textAlign: 'center', width: '100%' }}
                                panelClassName="custom-calendar-panel"
                            />
                        </Col>
                        <Col md="3">
                            <Form.Label>Data Fim</Form.Label>
                            <Calendar
                                value={data_final || null}
                                onChange={(event: any) => {
                                    const selectedDates = event.value;
                                    setDataInicio(selectedDates);
                                    setListFiltros({ ...listFiltros, data_final: selectedDates });
                                }}
                                placeholder="Data Inicio"
                                showButtonBar
                                selectionMode="single"
                                dateFormat="dd/mm/yy"
                                locale="pt"
                                readOnlyInput
                                style={{ textAlign: 'center', width: '100%' }}
                                panelClassName="custom-calendar-panel"
                            />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col md="3">
                            <Form.Label>Plano de Contas</Form.Label>
                            <Form.Select value={plano_conta_id}
                                onChange={(e) => setListFiltros({ ...listFiltros, plano_conta_id: e.target.value })}>
                                <option>Selecione...</option>
                                {listPlanoConta?.map((item: any) => (
                                    <option value={item.id} key={item.id}>
                                        {item.nome}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col md="3">
                            <Form.Label>Centro de Custo</Form.Label>
                            <Form.Select value={centro_custo_id}
                                onChange={(e) => setListFiltros({ ...listFiltros, centro_custo_id: e.target.value })}>
                                <option>Selecione...</option>
                                {listCentroCusto?.map((item: any) => (
                                    <option value={item.id} key={item.id}>
                                        {item.nome}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col md="3">
                            <Form.Label>Situação Pagamento</Form.Label>
                            <Form.Select value={situacao_pagamento || ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, situacao_pagamento: e.target.value })}>
                                <option>Selecione...</option>
                                <option value={0}>Aberto</option>
                                <option value={1}>Baixado</option>
                                <option value={2}>Inadimplente</option>
                            </Form.Select>
                        </Col>
                        <Col md="3">
                            <Form.Label>Tipo Parcela</Form.Label>
                            <Form.Select value={tipo_parcela || ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, tipo_parcela: e.target.value })}>
                                <option>Selecione...</option>
                                <option value={1}>Ordinária</option>
                                <option value={2}>Balão</option>
                                <option value={4}>Saldo Restante</option>
                                <option value={5}>Comissões</option>
                                <option value={6}>Temporário</option>
                                <option value={7}>Condomínio</option>
                                <option value={8}>Parcela Mensal</option>
                                <option value={9}>Acordo Avulso</option>
                                <option value={10}>Intermediação</option>
                                <option value={11}>Entrada</option>
                                <option value={12}>Agrupado</option>
                                <option value={13}>Lançamento Pai</option>
                                <option value={14}>Venda Avulsa</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded
                    onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded
                    onClick={() => {
                        setListFiltros({});
                        loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)
                    }}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltroAvancadoModal;
