import iconBarra from '../../../../../assets/images/barra-menu.svg';
import Nav from "react-bootstrap/Nav";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import TokenService from "../../../../services/Auth/token.service";
import { getImageByName } from "../../../../../assets/images";
interface SubmenuItem {
  name: string;
  icon: string;
  route: string;
  is_menu: boolean;
  is_context_menu: boolean;
}
interface SidebarProps {
  menu: SubmenuItem[] | null;
}
function MenuComponent({ menu }: SidebarProps) {
  const [selectedOption, setSelectedOption] = useState("#");
  const [selectedMenu, setSelectedMenu] = useState<any>();
  const navigate = useNavigate();
  const select = localStorage.getItem("menu-navbar");
  useEffect(() => {
    if (select) {
      setSelectedMenu(JSON.parse(select));
    }
  }, [select])
  useEffect(() => {
    const rotaAtual = window.location.pathname;

    const opcaoEncontrada: any = menu?.find((item) => item.route === rotaAtual);
    if (opcaoEncontrada) {
      setSelectedOption(opcaoEncontrada.icon);
    }

  }, []);
  const setMenuSelect = (selected: any) => {
    TokenService.setMenuSelect(selected);
  }
  const filteredMenu = menu?.filter((placement) => placement.is_menu); // Apenas menus válidos
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 6; // Número de itens visíveis por vez

  const handleNext = () => {
    if (filteredMenu && startIndex + itemsPerPage < filteredMenu.length) {
      setStartIndex((prev) => prev + itemsPerPage);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex((prev) => prev - itemsPerPage);
    }
  };

  const visibleItems = filteredMenu?.slice(startIndex, startIndex + itemsPerPage);

  // Estilo dinâmico para deslocamento do carrossel
  const translateX = `-${startIndex * 5}px`; // Ajuste conforme o valor de largura do item

  return (
    <div className="menu-container">

      <Nav
        className="me-auto menu-navbar"
        style={{ transform: `translateX(${translateX})` }}
      >
        {filteredMenu && filteredMenu.length > itemsPerPage && (
          <div className="carousel-controls">
            <button onClick={handlePrev} hidden={startIndex === 0}>
              <span style={{ color: '#fff' }}>{"<"}</span>
            </button>
          </div>
        )}
        {visibleItems?.map((placement, index) => (
          <Nav.Link
            key={index}
            className={`svgNavBar navBar ${selectedOption === placement.icon ? "selected" : ""}`}
            onClick={() => {
              setMenuSelect(placement);
              setSelectedOption(placement.icon);
              navigate(placement.route);
            }}
          >
            <img
              alt={placement.icon}
              className={placement.icon}
              src={getImageByName(placement.icon) ?? ""}
            />
            {placement.name}
            <div className="iconBarra"></div>
          </Nav.Link>
        ))}
        {filteredMenu && filteredMenu.length > itemsPerPage && (
          <div className="carousel-controls">
            <button
              onClick={handleNext}
              hidden={startIndex + itemsPerPage >= filteredMenu.length}
            >
              <span style={{ color: '#fff' }}>{">"}</span>
            </button>
          </div>
        )}
      </Nav>
    </div>
  );
}

export default MenuComponent;
