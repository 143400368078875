import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { AutoComplete } from 'primereact/autocomplete';
import { searchCliente, searchCorretor } from "./../Service/ImovelService";
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';

interface FiltroAvancadoModalProps {
    showModal: boolean;
    onHide: () => void;
    listFiltros: any;
    setListFiltros: any | null;
    loadData: (filtros: any, itemsPerPage: any, currentPage: number, setLoading: (nodes: any) => void, setNodes: (nodes: any) => void, updateTitles: (titles: string[]) => void, updatePagination: (pages: number) => void, setShowModalFiltro: (show: boolean) => void) => void;
    currentPage: number;
    setLoading: (nodes: any) => void;
    setNodes: (nodes: any) => void;
    updateTitles: (titles: string[]) => void;
    updatePagination: (pages: number) => void;
    setShowModalFiltro: (show: boolean) => void;
    handleClose: () => void;
    itemsPerPage: any;
}

const FiltroAvancadoModal: React.FC<FiltroAvancadoModalProps> = ({
    showModal,
    onHide,
    listFiltros,
    setListFiltros,
    setLoading,
    loadData,
    currentPage,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro,
    itemsPerPage
}) => {
    const { nome_cliente, proprietario_id, busca_captador, nome_captador, observacao, tipo_imovel_id, sub_tipo_imovel_id, status } = listFiltros || {};
    const [subTipoImovel, setSubTipoImovel] = useState<any>([]);
    const [tipoImovel, setTipoImovel] = useState<any>([]);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    const [FilteredCorretor, setFilteredCorretor] = useState([]);
    const [caracteristicas, setCaracteristicas] = useState([]);
    const [imoveis_caracteristicas, setImoveisCaracteristicas] = useState(listFiltros?.imoveis_caracteristicas?.split(';') || []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [responseTiposImovel, responseSubtiposImovel, responseCaracteristicas] = await Promise.all([
                    api.get(`${url}/api/v1/admin/vendas/tipoimovel`),
                    api.get(`${url}/api/v1/admin/vendas/subtipoimovel`),
                    api.get(`${url}/api/v1/admin/vendas/listagem-caracteristicas-imoveis-dinamicas`)
                ]);
                if (responseTiposImovel.status === 200) {
                    setTipoImovel(responseTiposImovel.data.data);
                }
                if (responseSubtiposImovel.status === 200) {
                    setSubTipoImovel(responseSubtiposImovel.data.data);
                }
                if (responseCaracteristicas.status === 200) {
                    setCaracteristicas(responseCaracteristicas.data.data);
                }
            } catch (error) {
                AlertService.error('Erro!', String('Erro desconhecido'));
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro);
    }, [listFiltros, itemsPerPage, currentPage]);

    return (
        <Modal
            show={showModal}
            size='lg'
            onHide={() => onHide()}
            className='modal-filtro-avancado'
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtros Avançados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form.Group className="imovel-form">
                        <Row className='mb-3'>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Proprietário</Form.Label>
                                <AutoComplete
                                    id="id_proprietario"
                                    field="nome_cliente"
                                    value={nome_cliente ? nome_cliente : ''}
                                    suggestions={FilteredPessoa}
                                    completeMethod={(e) => searchCliente(e.query, setFilteredPessoa)}
                                    onChange={(e) => {
                                        setListFiltros({ ...listFiltros, nome_cliente: e.value })
                                    }}
                                    onSelect={(e) => {
                                        setListFiltros({ ...listFiltros, proprietario_id: e.value.id })
                                        setListFiltros({ ...listFiltros, nome_cliente: e.value })
                                    }}
                                    dropdown
                                    style={{ height: "37px", width: "100%" }}
                                    placeholder="Selecione um proprietário"
                                />
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Captador</Form.Label>
                                <AutoComplete
                                    id="id_captador"
                                    field="nome_corretor"
                                    value={busca_captador || nome_captador}
                                    suggestions={FilteredCorretor}
                                    completeMethod={(e) => {
                                        searchCorretor(e, 1, setFilteredCorretor);
                                    }}
                                    onChange={(e) => {
                                        setListFiltros({ ...listFiltros, busca_captador: e.value })
                                    }}
                                    onSelect={(e) => {
                                        setListFiltros({ ...listFiltros, busca_captador: e.value })
                                        setListFiltros({ ...listFiltros, captador_id: e.value.id })
                                    }}
                                    dropdown
                                    style={{ height: "37px", width: "100%" }}
                                    placeholder="Selecione um captador"
                                />
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Observação</Form.Label>
                                <Form.Control
                                    value={observacao ? observacao : ''}
                                    onChange={(e) => setListFiltros({ ...listFiltros, observacao: e.target.value })}
                                />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Tipo Imóvel</Form.Label>
                                <Form.Select value={tipo_imovel_id || ''} onChange={(e) => setListFiltros({ ...listFiltros, tipo_imovel_id: e.target.value })}>
                                    <option>Selecione...</option>
                                    {tipoImovel.map((tipoimovel: any) => (
                                        <option value={tipoimovel.id}>{tipoimovel.nome}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>SubTipo Imóvel</Form.Label>
                                <Form.Select value={sub_tipo_imovel_id || ''} onChange={(e) => setListFiltros({ ...listFiltros, sub_tipo_imovel_id: e.target.value })}>
                                    <option>Selecione...</option>
                                    {subTipoImovel.map((subtipo: any) => (
                                        <option value={subtipo.id}>{subtipo.nome}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Status</Form.Label>
                                <Form.Select value={status || ''} onChange={(e) => setListFiltros({ ...listFiltros, status: e.target.value })}>
                                    <option>Selecione...</option>
                                    <option value={"1"}>Disponível</option>
                                    <option value={"2"}>Reservado</option>
                                    <option value={"3"}>Reserva Fixa</option>
                                    <option value={"4"}>Negociado</option>
                                    <option value={"5"}>Vendido</option>
                                    <option value={"6"}>Reserva Técnica</option>
                                    <option value={"7"}>Reserva Diretoria</option>
                                    <option value={"8"}>Alugado</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Caracteristicas</Form.Label>
                                <div className="card flex justify-content-center">
                                    <MultiSelect
                                        value={imoveis_caracteristicas}
                                        onChange={(e: MultiSelectChangeEvent) => {
                                            const selectedIds = e.value;
                                            setImoveisCaracteristicas(selectedIds);
                                            setListFiltros({ ...listFiltros, imoveis_caracteristicas: selectedIds.join(';') });
                                        }}
                                        options={caracteristicas}
                                        display="chip"
                                        optionLabel="nome_campos"
                                        optionValue="id"
                                        placeholder="Selecione..."
                                        maxSelectedLabels={3}
                                        style={{ width: '100%', zIndex: 9999 }}
                                        className="w-full md:w-20rem"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form.Group>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded
                    onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded
                    onClick={() => {
                        setListFiltros({});
                        setImoveisCaracteristicas([]);
                    }}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltroAvancadoModal;
