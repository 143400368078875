import 'react-datepicker/dist/react-datepicker.css';
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { Tag } from 'primereact/tag';
import { Card, Col, Row } from 'react-bootstrap';
import * as Icons from '@mui/icons-material';
import { useAppContext } from '../../../../contexts/AppContext';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { loadData } from './../../Imoveis/Service/ImovelService';
import { ProductService } from '../../Imoveis/Service/ProductService';
import FiltroAvancadoModal from '../../Imoveis/Components/ModalFiltroAvancado';
import Paginacao from '../../Imoveis/Components/Paginacao';
import { useAppContext as useResponseContext } from '../../../../contexts/ResponseContext';

interface ListImovelSelectProps {
    formData: any,
    setFormData: (value: any) => void,
}

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string | null;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

function ListImovelSelect({
    formData,
    setFormData,
}: ListImovelSelectProps) {

    const [products, setProducts] = useState<Product[]>([]);
    const [layout, setLayout] = useState<any>('list');
    const [listFiltros, setListFiltros] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const { titles, updateTitles, pagination, updatePagination } = useResponseContext();
    const [showModalFiltro, setShowModalFiltro] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState<number>(8);
    const [sortOptions, setSortOptions] = useState<{ field: string, order: number } | null>(null);
    const { isVisible, updateVisibility } = useSidebarContext();
    const [selectedImovelId, setSelectedImovelId] = useState<string | null>(null);

    useEffect(() => {
        loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro, sortOptions);
    }, [currentPage, sortOptions, itemsPerPage]);

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    useEffect(() => {
        ProductService.getProducts().then((data: any[]) => setProducts(data.slice(0, 12)));
    }, []);

    const getSeverity = (product: any) => {
        switch (product.status) {
            case 1:
                return 'success';

            case 2:
                return 'warning';

            case 5:
                return 'danger';

            default:
                return null;
        }
    };


    const listItem = (product: any) => {
        const imageUrl = product?.imagens?.data?.filter((image: any) => image.principal === true)[0]?.anexo.url;
        const isSelected = selectedImovelId === product.id;
        return (
            <div className="flex-initial shrink-0 w-full">
                <Card className="p-3 mb-1">
                    <div className="flex flex-col xl:flex-row xl:items-start gap-2 bg-white dark:bg-gray-900">
                        {imageUrl ? (
                            <img
                                className="w-3/4 sm:w-64 xl:w-32 shadow-md block xl:block mx-auto rounded-md"
                                src={imageUrl}
                                alt={product.name}
                            />
                        ) : (
                            <div className="flex justify-center items-center w-3/4 sm:w-64 xl:w-32 shadow-md h-40 bg-gray-200 rounded-lg">
                                <span className="text-gray-500">Sem imagem disponível</span>
                            </div>
                        )}
                        <div className="flex flex-col sm:flex-row justify-between items-center xl:items-start flex-1 gap-2">
                            <div className="flex flex-col items-center sm:items-start gap-2">
                                <div className="text-xl font-semibold text-gray-700">
                                    {product.titulo} - {product.titulo_reduzido}
                                </div>
                                <div>
                                    <span className="text-lg font-semibold text-gray-700 mr-2">{product.subtitulo}</span>
                                    <span className="text-sm rounded-md bg-cyan-500 border-cyan-500 font-bold text-white pl-4 pr-4">
                                        unid: {product.unidade}
                                    </span>
                                </div>
                                <div className="text-xs font-semibold text-gray-700">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: product.descricao_curta.replace(/\n/g, '<br />'),
                                        }}
                                    />
                                </div>
                                <div className="flex items-center gap-2">
                                    <span className="flex items-center gap-2">
                                        <i className="pi pi-arrows-h text-gray-700 "></i>
                                        <span className="font-semibold text-gray-700">
                                            {product.area_util} m²
                                        </span>
                                    </span>
                                    <Tag value={product.status_name} severity={getSeverity(product)} />
                                    {product?.imoveis_caracteristicas_imovel?.data?.length > 0 &&
                                        product?.imoveis_caracteristicas_imovel?.data
                                            ?.slice(0, 3)
                                            ?.map((item: any, index: any) => {
                                                const IconComponent = Icons[item.icone as keyof typeof Icons];
                                                return (
                                                    <Tag key={index} severity="warning">
                                                        {IconComponent && <IconComponent style={{ height: '16px' }} />}
                                                        {item.descricao}
                                                    </Tag>
                                                );
                                            })}
                                </div>
                            </div>
                            <div className="flex sm:flex-col items-end sm:align-end gap-2 sm:gap-2">
                                <span className="text-xl font-semibold text-gray-700">
                                    {formatMoney(product.valor_venda_revenda)}
                                </span>
                                <div className="flex justify-center items-center" style={{ marginTop: '30px', height: '100%', display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                            cursor: 'pointer',
                                        }}
                                        checked={isSelected}
                                        onChange={() => {
                                            if (isSelected) {
                                                setSelectedImovelId(null);
                                                setFormData({
                                                    ...formData,
                                                    imovel_id: null,
                                                    nome_imovel: null, 
                                                });
                                            } else {
                                                setSelectedImovelId(product.id);
                                                setFormData({
                                                    ...formData,
                                                    imovel_id: product.id,
                                                    nome_imovel: product.titulo, 
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </Card>
            </div>
        );
    };

    const formatMoney = (value: number): string => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(value);
    };
    const itemTemplate = (product: any, layout: any) => {
        if (!product) {
            return;
        }
        return listItem(product);
    };
    
    return (
        <div className="body-content">
            <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                <Row>
                    <Col className="mb-2">
                        <Button
                            style={{ height: '30px', borderRadius: '8px', fontSize: '14px' }}
                            type="button"
                            rounded outlined severity="secondary"
                            onClick={() => setShowModalFiltro(true)}
                            className="flex items-center justify-start p-2"
                            icon="pi pi-filter"
                        >
                            <span style={{ fontSize: '14px' }}>Filtros</span>
                        </Button>
                    </Col>
                </Row>
            </div>
            <FiltroAvancadoModal
                showModal={showModalFiltro}
                onHide={() => setShowModalFiltro(false)}
                handleClose={() => setShowModalFiltro(false)}
                listFiltros={listFiltros}
                setListFiltros={setListFiltros}
                loadData={loadData}
                setLoading={setLoading}
                currentPage={currentPage}
                setNodes={setNodes}
                updateTitles={updateTitles}
                updatePagination={updatePagination}
                setShowModalFiltro={setShowModalFiltro}
                itemsPerPage={itemsPerPage}
            />
            {nodes?.length > 0 ? (
                <>
                    <DataView value={nodes} itemTemplate={itemTemplate} layout={layout} />
                    <Paginacao
                        totalPages={pagination}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        setItemsPorPagina={setItemsPerPage}
                        setCurrentPage={setCurrentPage}
                    />
                </>
            ) :
                (
                    <div className="text-center">
                        <h3 className='text-sm text-gray-600 font-semibold p-4'>Nenhum imóvel encontrado</h3>
                    </div>
                )
            }
        </div>
    )
}

export default ListImovelSelect;