import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import './Styled/index.css';
import { useAppContext } from '../../../contexts/AppContext';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import iconFiltroAvancado from '../../../../assets/images/icon-filtro-avancado.png';
import { Col, Form, NavLink, Row } from 'react-bootstrap';
import { loadData, searchCliente } from './Service/ContratoService';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { useAppContext as useResponseContext } from '../../../contexts/ResponseContext';
import Paginacao from './Components/Paginacao';
import FiltroAvancadoModal from './Components/ModalFiltroAvancado';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { actionTemplate } from './Components/actionTemplate';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { AutoComplete } from 'primereact/autocomplete';
import { InputNumber } from 'primereact/inputnumber';
import ModalRescisao from './Components/ModalRescisao';

interface Product {
  id: string;
  code: string;
  name: string;
  description: string;
  image: string | null;
  price: number;
  category: string;
  quantity: number;
  inventoryStatus: string;
  rating: number;
}

export default function LocacaoContratos() {
  const { setUrl_button_create } = useAppContext();
  const urlRedirectCreated = "/locacao/contratos/store";
  const url_redirect_edit = "/locacao/contratos/update";
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [nodes, setNodes] = useState<any | null>(null);
  const { titles, updateTitles, pagination, updatePagination } = useResponseContext();
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(8);
  const [sortOptions, setSortOptions] = useState<{ field: string, order: number } | null>(null);
  const { isVisible, updateVisibility } = useSidebarContext();
  const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
  const [nome_cliente, setNomeCliente] = useState<any>(null);
  const [unidade, setUnidade] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const [modalRescisao, setModalRescisao] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  useEffect(() => {
    loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro, sortOptions);
  }, [currentPage, sortOptions, itemsPerPage]);

  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Locação',
        url: '/locacao',
      },
      {
        label: 'Lista de Contratos',
        url: '/contratos',
      },
    ]);
  }, []);

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  useEffect(() => {
    setUrl_button_create(urlRedirectCreated);
  }, []);

  const handleSort = (field: string) => {
    let order = 1;
    if (sortOptions && sortOptions.field === field) {
      order = sortOptions.order === 1 ? -1 : sortOptions.order === -1 ? 0 : 1;
    }
    setSortOptions({ field, order });
  };

  const getSortIcon = (field: string) => {
    if (sortOptions?.field === field) {
      return sortOptions.order === 1 ? <FaSortUp /> : sortOptions.order === -1 ? <FaSortDown /> : <FaSort style={{ color: '#b8b8b8' }} />;
    }
    return <FaSort style={{ color: '#b8b8b8' }} />;
  };

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                style={{ width: '23px' }}
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Contratos</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <div style={{
            display: "flex",
            justifyContent: 'space-between'
          }}>
            <Row style={{ margin: '10px' }}>
              <Col style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Locatário</Form.Label>
                <AutoComplete
                  id="locatario_id"
                  field="nome_cliente"
                  value={nome_cliente ? nome_cliente : ''}
                  suggestions={FilteredPessoa}
                  completeMethod={(e) => searchCliente(e.query, setFilteredPessoa)}
                  onChange={(e) => {
                    setListFiltros({ ...listFiltros, nome_cliente: e.value })
                  }}
                  onSelect={(e) => {
                    setListFiltros({ ...listFiltros, locatario_id: e.value.id })
                    setListFiltros({ ...listFiltros, nome_cliente: e.value })

                  }}
                  dropdown
                  style={{ height: "37px", width: "100%" }}
                  placeholder="Selecione um locatário"
                />
              </Col>
              <Col style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Unidade</Form.Label>
                <InputNumber
                  id="unidade"
                  value={unidade}
                  onChange={(e) => setListFiltros({ ...listFiltros, unidade: e.value })}
                  placeholder="Digite a unidade"
                />
              </Col>
              <Col style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Status</Form.Label>
                <Form.Select value={status || ''} onChange={(e) => setListFiltros({ ...listFiltros, status: e.target.value })}>
                  <option>Selecione...</option>
                  <option value={"1"}>Disponível</option>
                  <option value={"2"}>Reservado</option>
                  <option value={"3"}>Reserva Fixa</option>
                  <option value={"4"}>Negociado</option>
                  <option value={"5"}>Vendido</option>
                  <option value={"6"}>Reserva Técnica</option>
                  <option value={"7"}>Reserva Diretoria</option>
                  <option value={"8"}>Alugado</option>
                </Form.Select>
              </Col>
            </Row>
            <Row style={{ marginTop: '40px', display: 'flex', justifyContent: 'right' }}>
              <Col style={{ display: 'flex', justifyContent: 'right' }} className="mb-2">
                <Button
                  style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  rounded outlined severity="secondary"
                  onClick={() => setShowModalFiltro(true)}
                >
                  <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                </Button>
                <Button
                  style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  severity="success"
                  icon="pi pi-filter"
                  rounded
                  onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                  <p className="d-inline-block" style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                  style={{ height: '30px', borderRadius: '8px', fontSize: '14px', marginRight: '15px' }}
                  type="button"
                  severity="danger"
                  icon="pi pi-delete-left"
                  rounded
                  onClick={() => {
                    setListFiltros({});
                    loadData({}, currentPage, itemsPerPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro);
                  }}
                >
                  <p className="d-inline-block" style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
              </Col>
            </Row>
          </div>
          <FiltroAvancadoModal
            showModal={showModalFiltro}
            onHide={() => setShowModalFiltro(false)}
            handleClose={() => setShowModalFiltro(false)}
            listFiltros={listFiltros}
            setListFiltros={setListFiltros}
            loadData={loadData}
            setLoading={setLoading}
            currentPage={currentPage}
            setNodes={setNodes}
            updateTitles={updateTitles}
            updatePagination={updatePagination}
            setShowModalFiltro={setShowModalFiltro}
            itemsPerPage={itemsPerPage}
          />
        </div >
      </div>
      <div className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}>
        <DataTable
          stripedRows
          lazy
          size="small"
          value={nodes ? nodes : []}
          tableStyle={{ minWidth: '50rem' }}
          emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
        >
          <Column
            style={{ textAlign: 'left' }}
            key={'id'}
            field={'id'}
            headerClassName="lefted-header"
            header={<div className="lefted-content" onClick={() => handleSort('id')}>Id{getSortIcon('id')}</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            style={{ textAlign: 'left' }}
            key={'unidade'}
            field={'unidade'}
            headerClassName="lefted-header"
            header={<div className="lefted-content" onClick={() => handleSort('unidade')}>Unidade {getSortIcon('unidade')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'nome_locatario'}
            field={'nome_locatario'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('nome_locatario')}>Locatário{getSortIcon('nome_locatario')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'valor_periodo'}
            field={'valor_periodo'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('valor_periodo')}>Valor Aluguel{getSortIcon('valor_periodo')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'status'}
            field={'status'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('status')}>Status{getSortIcon('status')}</div>}
            body={isLoading && (<Skeleton />)}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'duracao'}
            field={'duracao'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('duracao')}>Duração {getSortIcon('duracao')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'data_inicial'}
            field={'data_inicial'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('data_inicial')}>Data Inicial {getSortIcon('data_inicial')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'data_final'}
            field={'data_final'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('data_final')}>Data Final {getSortIcon('data_final')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'data_ult_reajuste'}
            field={'data_ult_reajuste'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('data_ult_reajuste')}>Data Ult.Reajuste{getSortIcon('data_ult_reajuste')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'data_prox_reaj'}
            field={'data_prox_reaj'}
            headerClassName="righted-header"
            header={<div className="righted-content" onClick={() => handleSort('data_prox_reaj')}>Data Prox.Reajuste {getSortIcon('data_prox_reaj')}</div>}
          />
          <Column
            style={{ textAlign: 'right' }}
            body={(e) => actionTemplate(
              e,
              url_redirect_edit,
              setSelectedRow,
              setModalRescisao
            )}
            header={<div className="righted-content">Menu</div>}
            headerClassName="righted-header"
          />
        </DataTable>
        <ModalRescisao
          showModal={modalRescisao}
          onHide={() => setModalRescisao(false)}
          data={selectedRow}
        />
        <Paginacao
          totalPages={pagination}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setItemsPorPagina={setItemsPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div >
  );
}