import { Col, Form, Row } from "react-bootstrap";
import { boolean } from "yup";

function ObraForm(props: any) {
    const { tipo_empreendimento,
        irpj,
        cofins,
        pis,
        csl,
        iss,
        aliq_pres_irpj,
        aliq_pres_csl,
        tipo_imoveis,
        qtd_max_parcelas,
        id_externo,
        arred_intermed,
        qtd_lote_corretor,
        tmp_reserva,
        tmp_min_ent_rsv,
        tmp_negociacao,
        gerar_parcelas_contas_receber,
        transf_valor,
        transf_carencia,
        qtd_imp_protocolo,
        qtd_imp_intermediacao,
        corretor_desreserva,
        qtd_lote_cliente,
        alias_identificador1,
        numero_contrato,
        alias_identificador2 } = props.obraData || {};

    const handleChange = (field: string, value: any) => {
        if (field === 'qtd_imp_intermediacao' || field === 'qtd_imp_protocolo') {
            if (/^\d*$/.test(value) && value.length <= 6) {
                props.onInputChange(field, value);
            }
        } else if (field === 'tmp_negociacao' || field === 'tmp_min_ent_rsv' || field === 'tmp_reserva') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChange(field, value);
            }
        } else if (field === 'qtd_lote_corretor' || field === 'qtd_max_parcelas') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChange(field, value);
            }
        } else if (field === 'aliq_pres_irpj' || field === 'aliq_pres_csl' || field === 'iss' || field === 'csl') {
            if (/^[0-9.;\/\-]*$/.test(value) && value.length <= 11) {
                props.onInputChange(field, value);
            }
        } else if (field === 'pis' || field === 'cofins' || field === 'irpj') {
            if (/^[0-9.;\/\-]*$/.test(value) && value.length <= 11) {
                props.onInputChange(field, value);
            }
        } else if (field === 'alias_identificador1') {
            if (!value || value.length <= 60) {
                props.onInputChange(field, value);
            }
        } else if (field === 'alias_identificador2') {
            if (!value || value.length <= 60) {
                props.onInputChange(field, value);
            }
        } else if (field === 'qtd_lote_cliente') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChange(field, value);
            }
        } else {
            props.onInputChange(field, value);
        }
    };

    return (
        <>
            <Form.Group className="formulario-empreendimento">
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label>Id Externo</Form.Label>
                        <Form.Control value={id_externo} onChange={(e) => handleChange('id_externo', e.target.value)} />
                    </Col>
                    <Col md="3">
                        <Form.Label>Alias do Indentificador 1</Form.Label>
                        <Form.Control value={alias_identificador1} onChange={(e) => handleChange('alias_identificador1', e.target.value)} />
                    </Col>
                    <Col md="3">
                        <Form.Label>Alias do Indentificador 2</Form.Label>
                        <Form.Control value={alias_identificador2} onChange={(e) => handleChange('alias_identificador2', e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Form.Label>Qtd. de lote por Cliente</Form.Label>
                        <Form.Control value={qtd_lote_cliente} onChange={(e) => handleChange('qtd_lote_cliente', e.target.value)} />
                    </Col>
                    <Col md="2" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Corretor pode Desreservar?</Form.Label>
                        <Form.Check
                            checked={corretor_desreserva || false}
                            onChange={(e) => handleChange("corretor_desreserva", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label>Qtd. Impressão da intermed</Form.Label>
                        <Form.Control value={qtd_imp_intermediacao} onChange={(e) => handleChange('qtd_imp_intermediacao', e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Form.Label>Qtd. Impressão do Protocolo</Form.Label>
                        <Form.Control value={qtd_imp_protocolo} onChange={(e) => handleChange('qtd_imp_protocolo', e.target.value)} />
                    </Col>
                    <Col md="3">
                        <Form.Label>Dias de Carência p/ Transferência</Form.Label>
                        <Form.Control value={transf_carencia} onChange={(e) => handleChange('transf_carencia', e.target.value)} />
                    </Col>
                    <Col md="3">
                        <Form.Label>Valor Minimo de Transferência</Form.Label>
                        <Form.Control value={transf_valor} onChange={(e) => handleChange('transf_valor', e.target.value)} />
                    </Col>
                    <Col md="2" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Gerar Contas à Receber?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={gerar_parcelas_contas_receber || false}
                            onChange={(e) => handleChange("gerar_parcelas_contas_receber", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label>Tempo Maximo de Negociação</Form.Label>
                        <Form.Control value={tmp_negociacao} onChange={(e) => handleChange('tmp_negociacao', e.target.value)} />
                    </Col>
                    <Col md="3">
                        <Form.Label>Tempo mínimo entre reservas</Form.Label>
                        <Form.Control value={tmp_min_ent_rsv} onChange={(e) => handleChange('tmp_min_ent_rsv', e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Form.Label>Tempo de Reserva</Form.Label>
                        <Form.Control value={tmp_reserva} onChange={(e) => handleChange('tmp_reserva', e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Form.Label>Qtd. de lote por Corretor</Form.Label>
                        <Form.Control value={qtd_lote_corretor} onChange={(e) => handleChange('qtd_lote_corretor', e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Form.Label> Número Contrato</Form.Label>
                        <Form.Control type="number" value={numero_contrato} onChange={(e) => handleChange('numero_contrato', e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label>Qtd. Maximo de Parcelas do Imóvel</Form.Label>
                        <Form.Control value={qtd_max_parcelas} onChange={(e) => handleChange('qtd_max_parcelas', e.target.value)} />
                    </Col>
                    <Col md="3">
                        <Form.Label>Tipo Imóveis</Form.Label>
                        <Form.Select value={tipo_imoveis || ''} onChange={(e) => handleChange('tipo_imoveis', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Urbano</option>
                            <option value={"2"}>Rural</option>
                        </Form.Select>
                    </Col>
                    <Col md="3">
                        <Form.Label>Aliquota CSL</Form.Label>
                        <Form.Control value={aliq_pres_csl} onChange={(e) => handleChange('aliq_pres_csl', e.target.value)} />
                    </Col>
                    <Col md="3">
                        <Form.Label>Aliquota IRPJ</Form.Label>
                        <Form.Control value={aliq_pres_irpj} onChange={(e) => handleChange('aliq_pres_irpj', e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label>ISS</Form.Label>
                        <Form.Control value={iss} onChange={(e) => handleChange('iss', e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Form.Label>CSL</Form.Label>
                        <Form.Control value={csl} onChange={(e) => handleChange('csl', e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Form.Label>PIS</Form.Label>
                        <Form.Control value={pis} onChange={(e) => handleChange('pis', e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Form.Label>CONFINS</Form.Label>
                        <Form.Control value={cofins} onChange={(e) => handleChange('cofins', e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Form.Label>IRPJ</Form.Label>
                        <Form.Control value={irpj} onChange={(e) => handleChange('irpj', e.target.value)} />
                    </Col>
                    <Col md="2">
                        <Form.Label>Tipo Empreendimento</Form.Label>
                        <Form.Select value={tipo_empreendimento || ''} onChange={(e) => handleChange('tipo_empreendimento', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Loteamento</option>
                            <option value={"2"}>Apartamento</option>
                        </Form.Select>
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default ObraForm;
