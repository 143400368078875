import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import { TelefoneData } from "../../contexts/TelefoneContext";
import { Button } from "primereact/button";

interface TelefoneFormProps {
    telefonesData?: any | null;
    onInputChange: (index: number, field: string, value: string) => void;
    onAddTelefone: () => void;
    onRemoveTelefone: (index: number) => void;
}

function TelefoneForm(props: TelefoneFormProps) {
    const { telefonesData } = props;
    const [table, setTable] = useState([]);
    const [activeEventKey, setActiveEventKey] = useState<string | null>(null);


    useEffect(() => {
        if (telefonesData?.data) {
            setTable(telefonesData.data);
        } else {
            setTable(telefonesData);
        }
    }, [telefonesData])
    const handleChange = (index: number, field: string, value: string) => {
        if (field === 'ddd') {
            if (/^\d*$/.test(value) && value.length <= 3) {
                props.onInputChange(index, field, value);
            }
        } else if (field === 'nome_contato') {
            if (!value || value.length <= 60) {
                props.onInputChange(index, field, value);
            }
        } else if (field === 'numero') {
            if (/^[\d.-]*$/.test(value) && value.length <= 11) {
                props.onInputChange(index, field, value);
            }
        } else if (field === 'observacao') {
            if (!value || value.length <= 60) {
                props.onInputChange(index, field, value);
            }
        }
        else {
            props.onInputChange(index, field, value);
        }
    };
    useEffect(() => {
        if (telefonesData.length > 0) {
            telefonesData.forEach((telefone: any, index: number) => {
                Object.keys(telefone).forEach((field) => {
                    handleChange(index, field, telefone[field]);
                });
            });
        }
    }, []);
    const handleAddTelefone = () => {
        props.onAddTelefone();
        const telefones = telefonesData?.data ? telefonesData?.data : telefonesData
        setActiveEventKey((telefones.length || 0).toString());
    };

    const handleRemoveTelefone = (index: number) => {
        props.onRemoveTelefone(index);
    };

    return (
        <>
            <Accordion style={{ height: '-webkit-fill-available' }} activeKey={activeEventKey} onSelect={(key) => setActiveEventKey(key as string)}>
                <Button type="button"
                    severity="success"
                    size="small"
                    icon="pi pi-plus"
                    raised={true}
                    label="Adicionar"
                    rounded={true}
                    className="ms-2 button-add-endereco p-button-rounded p-button-text"
                    style={{ float: "right", marginRight: '32px', marginTop: '20px' }}
                    onClick={handleAddTelefone}>
                </Button>
                {(table || []).map((telefone: TelefoneData, index: number) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>
                            {telefone.nome_contato ? telefone.nome_contato : "Telefone " + (index + 1)}
                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body>
                                <Form.Group className="usuario-form">
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label>Nome para Contato</Form.Label>
                                            <Form.Control
                                                value={telefone.nome_contato}
                                                onChange={(e) => handleChange(index, 'nome_contato', e.target.value)}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>DDD</Form.Label>
                                            <Form.Control value={telefone.ddd} onChange={(e) => handleChange(index, 'ddd', e.target.value)} />
                                        </Col>
                                        <Col>
                                            <Form.Label>Numero</Form.Label>
                                            <Form.Control value={telefone.numero} onChange={(e) => handleChange(index, 'numero', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridState">
                                            <Form.Label>Tipo de Telefone</Form.Label>
                                            <Form.Select
                                                value={telefone.tipo_telefone}
                                                onChange={(e) => handleChange(index, 'tipo_telefone', e.target.value)}
                                            >
                                                <option>Selecione...</option>
                                                <option value={1}>Pessoal</option>
                                                <option value={2}>Comercial</option>
                                                <option value={3}>Contato</option>
                                                <option value={4}>Recado</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Col>
                                            <Form.Label>Observação</Form.Label>
                                            <Form.Control
                                                value={telefone.observacao}
                                                onChange={(e) => handleChange(index, 'observacao', e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="row-delete-button">
                                        <Button
                                            severity="danger"
                                            size="small"
                                            className="ms-2 button-delete-endereco p-button-rounded p-button-text"
                                            icon="pi pi-trash"
                                            onClick={() => handleRemoveTelefone(index)}
                                        >
                                        </Button>
                                    </Row>
                                </Form.Group>
                            </Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
}

export default TelefoneForm;
