import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import TokenService from '../../../../../../services/Auth/token.service';
import { AlertService } from '../../../../../../components/AlertService';
import '../index.css';
import { url } from '../../../../../../environments/environments-develop';
import api from '../../../../../../services/api';
import { Steps } from 'primereact/steps';
import { addCompradores, removeCompradores } from '../../../../../../utils/CompradorUtils';
import VendaAvulsaForm from '../Formularios/VendaAvulsaForm';
import { showToast } from '../../../../../../components/ToastComponent';

interface ModalCloneVendaAvulsaProps {
    showModal: boolean;
    handleClose: () => void;
    selectedRow: any;
}

const ModalCloneVendaAvulsa: React.FC<ModalCloneVendaAvulsaProps> = ({ selectedRow, showModal, handleClose }) => {
    const [show, setShow] = useState(showModal);
    const [currentPart, setCurrentPart] = useState(0);
    const totalParts = 3;
    const [formBasico, setFormBasico] = useState<any>({});
    const [valorSemDescontoAcessorios, setValorSemDescontoAcessorios] = useState<any>(0);
    const [valorComDescontoAcessorios, setValorComDescontoAcessorios] = useState<any>(0);
    const [saldoDevedor, setSaldoDevedor] = useState<any>(0);
    useEffect(() => {
        setShow(showModal);
        if (showModal === false) {
            setFormBasico({
                tipo_venda: 1
            });
            setCurrentPart(0);
            setValorSemDescontoAcessorios(0);
            setValorComDescontoAcessorios(0);
            setSaldoDevedor(0);
        }
        if (selectedRow) {
            setFormBasico((prevImovelData: any) => {
                return {
                    ...selectedRow,
                    produto_servicos: selectedRow?.venda_itens?.data,
                    entrada: selectedRow?.valor_sinal,
                    qtd_parc_entrada: selectedRow?.qtd_parc_entrada,
                    valor_prim_parc_entrada: selectedRow?.valor_primeira_parcela_entrada,
                    tipo_desconto: selectedRow?.tipo_desconto,
                    desconto: selectedRow?.desconto_rodape,
                    observacao_condicao: selectedRow?.observacao_condicao,
                    venc_prim_parc_entrada: selectedRow?.vencimento_primeira_entrada,
                    data_prim_parcela: selectedRow?.vencimento_primeira_parcela
                };
            });
        }
    }, [showModal])
    useEffect(() => {
        setFormBasico((prevImovelData: any) => {
            return {
                ...selectedRow,
                produto_servicos: selectedRow?.venda_itens?.data,
                entrada: selectedRow?.valor_sinal,
                qtd_parc_entrada: selectedRow?.qtd_parc_entrada,
                valor_prim_parc_entrada: selectedRow?.valor_primeira_parcela_entrada,
                tipo_desconto: selectedRow?.tipo_desconto,
                desconto: selectedRow?.desconto_rodape,
                observacao_condicao: selectedRow?.observacao_condicao,
                venc_prim_parc_entrada: selectedRow?.vencimento_primeira_entrada,
                data_prim_parcela: selectedRow?.vencimento_primeira_parcela
            };
        });
    }, [selectedRow]);
    useEffect(() => {
        if (formBasico?.produto_servicos) {
            simularSeNecessario();
        }
    }, [
        formBasico?.tabela_preco_id,
        formBasico?.entrada,
        formBasico?.desconto,
        formBasico?.tipo_desconto,
        formBasico?.produto_servicos
    ]);

    const simularSeNecessario = async () => {
        try {
            let data = {
                "produtos_servicos": formBasico?.produto_servicos ?? [],
                "desconto_rodape": formBasico?.desconto ?? null,
                "tipo_desconto_rodape": formBasico?.tipo_desconto ?? null,
                "entrada": formBasico?.entrada ?? null,
                "empreendimento_id": TokenService.getEmpreendimentoId()?.id ?? null,
                "tabela_preco_id": formBasico?.tabela_preco_id ?? null,
            }
            const response = await api.post(`${url}/api/v1/admin/cemiterio/simular-total`, data);

            if (response.status === 200) {
                Object.keys(response.data.data).forEach(key => {
                    handleChange(key, response.data.data[key]);
                });
            }
        } catch (error) {
            console.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    };
    useEffect(() => {
        const totalValorComDescontoAcessorios = (formBasico?.produto_servicos || []).reduce((acc: number, form: any) => {
            if (form?.produto) {
                return acc + ((form?.quantidade ?? 1) * (form?.produto?.valor ?? 0)) - (form?.tipo_desconto == 1 ? (form?.desconto / 100 * ((form?.produto?.valor ?? 0) * (form?.quantidade ?? 1))) : (form?.desconto * (form?.quantidade ?? 1)));
            }
            return acc;
        }, 0);
        const totalValorSemDescontoAcessorios = (formBasico?.produto_servicos || []).reduce((acc: number, form: any) => {
            if (form?.produto) {
                return acc + ((form?.quantidade ?? 1) * (form?.produto?.valor ?? 0));
            }
            return acc;
        }, 0);
        setSaldoDevedor((totalValorComDescontoAcessorios ?? 0) - (formBasico?.desconto ?? 0) - (formBasico?.entrada ?? 0));
        setValorSemDescontoAcessorios(totalValorSemDescontoAcessorios);
        setValorComDescontoAcessorios(totalValorComDescontoAcessorios);
    }, [formBasico?.produto_servicos, formBasico?.desconto]);
    const validacao = () => {
        if (!formBasico?.data_venda) {
            showToast("error", "O campo de data da venda é obrigatório!")
            return false;
        }
        if (!formBasico?.corretor_id) {
            showToast("error", "É necessario incluir um corretor para prosseguir, corrija o formulário e tente novamente!")
            return false;
        }
        if (!formBasico?.tipo_venda) {
            showToast("error", "O campo Tipo Venda é obrigatório!")
            return false;
        }
        if (!formBasico?.dia_vencimento) {
            showToast("error", "O campo Dia do vencimento é obrigatório!")
            return false;
        }
        if (!formBasico?.pessoa_id) {
            showToast("error", "É necessario incluir um comprador para prosseguir, corrija o formulário e tente novamente!")
            return false;
        }
        if (!formBasico?.produto_servicos && currentPart === 1) {
            showToast("error", "É necessario incluir um produto ou serviço para prosseguir, corrija o formulário e tente novamente!")
            return false;
        }
        if (formBasico?.qtd_parc_entrada > TokenService.getEmpreendimentoId()?.config_venda?.qtd_parc_entrada && currentPart === 3) {
            showToast('error', 'Quantidade de parcelas da entrada não pode ser maior que a configurada no empreendimento!');
            return false;
        }
        return true;
    }

    const handleNext = () => {
        const valid = validacao();
        if (valid) {
            if (currentPart < totalParts) {
                setCurrentPart((prevPart) => prevPart + 1);
            }
        }
    };

    const handleChange = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    }

    const handleBack = () => {
        if (currentPart > 0) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    const handleSaveButton = async (): Promise<void> => {
        try {
            let data = {
                "produto_servicos": formBasico?.produto_servicos ?? [],
                "data_venda": formBasico?.data_venda ? new Date(formBasico?.data_venda) : new Date().toUTCString(),
                "desconto_rodape": formBasico?.desconto ?? null,
                "tipo_desconto_rodape": formBasico?.tipo_desconto ?? null,
                "valor_sinal": formBasico?.entrada ?? null,
                "valor_primeira_parcela_entrada": formBasico?.valor_prim_parc_entrada ?? null,
                "vencimento_primeira_parcela": formBasico?.data_prim_parcela ? new Date(formBasico?.data_prim_parcela) : null,
                "qtd_parc_entrada": formBasico?.qtd_parc_entrada ?? 0,
                "dia_vencimento": formBasico?.dia_vencimento ?? null,
                "vencimento_primeira_entrada": formBasico?.venc_prim_parc_entrada ? new Date(formBasico?.venc_prim_parc_entrada) : null,
                "observacao_condicao": formBasico?.observacao_condicao ?? null,
                "tipo_venda": formBasico?.tipo_venda ?? null,
                "empreendimento_id": TokenService.getEmpreendimentoId()?.id ?? null,
                "pessoa_id": formBasico?.pessoa_id ?? [],
                "contrato_id": formBasico?.contrato_id ?? null,
                "tabela_preco_id": formBasico?.tabela_preco_id ?? null,
                "corretor_id": formBasico?.corretor_id ?? null,
            }
            const response = await api.post(`${url}/api/v1/admin/vendas/venda-avulsa`, data);
            if (response.status === 200) {
                AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
                    setShow(false);
                    window.location.reload();
                });
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            Object.keys(error?.response?.data?.error?.message).forEach(key => {
                mensagens.push('\n' + error?.response?.data?.error?.message[key][0]);
            });
            AlertService.error('Erro!', String(mensagens));
        }
    };
    const items = [
        {
            label: 'Dados da Venda',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                } else {
                    return
                }
            }
        },
        {
            label: 'Produtos|Serviços',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                } else {
                    return
                }
            }
        },
        {
            label: 'Entrada',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                }
            }
        },
        {
            label: 'Parcelamento',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                } else {
                    return
                }
            }
        }
    ];

    const MoneyFormatter = (saldoDevedor: any) => {
        const formatMoney = (value: number): string => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(value);
        };
        return formatMoney(saldoDevedor);
    };

    const handleCompradoresInputChange = (index: number, field: string, value: any) => {
        const clientes = formBasico?.cliente_compradores;
        if (formBasico && clientes && index >= 0 && index < clientes.length) {
            setFormBasico((prevFormBasico: any) => {
                const updatedClientes = [...prevFormBasico.cliente_compradores];

                updatedClientes[index] = {
                    ...updatedClientes[index],
                    [field]: value,
                };
                return {
                    ...prevFormBasico,
                    cliente_compradores: updatedClientes,
                };
            });
        }
    };

    const handleAddCompradores = () => {
        addCompradores(formBasico, setFormBasico);
    };

    const handleRemoveCompradores = (index: number) => {
        removeCompradores(formBasico, setFormBasico, index);
    };
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
            className="modal-input-venda"
        >
            <Modal.Header style={{ display: 'flex', flexDirection: 'column' }}>
                <Modal.Title style={{ marginBottom: '25px', color: '#494949' }}>Clonar venda avulsa</Modal.Title>
                <div style={{ display: 'block', width: '-webkit-fill-available' }}>
                    <Steps model={items} activeIndex={currentPart} readOnly={false} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <VendaAvulsaForm
                    onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                    onCompradoresInputChange={(index: number, field: string, value: any) => handleCompradoresInputChange(index, field, value)}
                    onAddCompradores={handleAddCompradores}
                    onRemoveCompradores={handleRemoveCompradores}
                    currentPart={currentPart}
                    formBasico={formBasico}
                />
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', backgroundColor: '#4949498a', paddingLeft: '5px', paddingBottom: '2px', paddingTop: '2px', borderRadius: '7px' }}>
                    <label
                        htmlFor="ac"
                        style={{ display: 'flex', margin: '8px', color: '' }}
                    >
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                            <strong>Total Bruto</strong>
                        </p>
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                            <strong>{MoneyFormatter(valorSemDescontoAcessorios ?? 0)}</strong>
                        </p>
                    </label>
                    <label
                        htmlFor="ac"
                        style={{ display: 'flex', margin: '8px', color: '' }}
                    >
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                            <strong>Total c/Desconto</strong>
                        </p>
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                            <strong>{MoneyFormatter(valorComDescontoAcessorios ?? 0)}</strong>
                        </p>
                    </label>
                    <label
                        htmlFor="ac"
                        style={{ display: 'flex', margin: '8px' }}
                    >
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                            <strong>Sinal</strong>
                        </p>
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                            <strong>{MoneyFormatter(formBasico?.valor_sinal ?? 0)}</strong>
                        </p>
                    </label>
                    <label
                        htmlFor="ac"
                        style={{ display: 'flex', margin: '8px' }}
                    >
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                            <strong>Desconto de Rodapé</strong>
                        </p>
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                            <strong>{MoneyFormatter(formBasico?.desconto_rodape ?? 0)}</strong>
                        </p>
                    </label>
                    <label
                        htmlFor="ac"
                        style={{ display: 'flex', margin: '8px', color: '' }}
                    >
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                            <strong>Saldo Devedor</strong>
                        </p>
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                            <strong>{MoneyFormatter(saldoDevedor ?? 0)}</strong>
                        </p>
                    </label>
                    <label
                        style={{ display: 'flex', margin: '8px', marginRight: '15px' }}
                    >
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                            <strong>Parcelamento</strong>
                        </p>
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                            <strong> {formBasico?.quantidade_parcelas ?? '1'} x {formBasico?.valor_parcelas ? MoneyFormatter(formBasico?.valor_parcelas) : 'R$ 0,00'}</strong>
                        </p>
                    </label>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '15px' }}>
                    <Button onClick={() => { handleClose() }} className="button-back-form" variant="primary">
                        Fechar
                    </Button>
                    {currentPart !== 0 && (
                        <Button onClick={() => { handleBack() }} className="button-back-form" variant="primary">
                            Voltar
                        </Button>
                    )}
                    {currentPart !== totalParts && (
                        <Button className="button-next-form" variant="primary" onClick={handleNext}>
                            Próximo
                        </Button>
                    )}
                    {currentPart === totalParts && (<Button className='button-save-or-update' onClick={handleSaveButton} variant="success">Salvar</Button>)}
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalCloneVendaAvulsa;
