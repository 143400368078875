import 'react-datepicker/dist/react-datepicker.css';
import { booleanOptions, handleInputChange } from "../Service/ImovelService";
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Form } from 'react-bootstrap';

interface AspectoLegaisFormProps {
    formData: any,
    setFormData: (value: any) => void,
}
function AspectoLegaisForm({
    formData,
    setFormData,
}: AspectoLegaisFormProps) {

    return (
        <fieldset className="w-full border p-4 rounded mb-4">
            <legend className="font-bold text-lg">Aspectos Legais</legend>
            <div className="grid grid-cols-6 gap-4">
                <div className="flex flex-col">
                    <label htmlFor="conta_ativo" className="mb-1 font-medium">Conta contábil do ativo</label>
                    <InputText
                        id="conta_ativo"
                        value={formData?.conta_ativo}
                        onChange={(e) => handleInputChange("conta_ativo", e.target.value, setFormData)}
                        placeholder="Digite a conta contábil do ativo"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="conta_passivo" className="mb-1 font-medium">Conta contábil do passivo</label>
                    <InputText
                        id="conta_passivo"
                        value={formData?.conta_passivo}
                        onChange={(e) => handleInputChange("conta_passivo", e.target.value, setFormData)}
                        placeholder="Digite a conta contábil do passivo"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="habite_se" className="mb-1 font-medium">Habite-se</label>
                    <Form.Select
                        value={formData?.habite_se}
                        onChange={(e) => handleInputChange("habite_se", e.target.value, setFormData)}
                    >
                        {booleanOptions.map((option) =>
                            <option value={String(option.value)}>{option.label}</option>
                        )}
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="data_habite_se" className="mb-1 font-medium">Data de Emissão do Habite-se</label>
                    <Calendar
                        value={formData?.data_habite_se ? new Date(formData?.data_habite_se) : null}
                        onChange={(event: any) => {
                            const selectedDates = event.value;
                            handleInputChange("data_habite_se", selectedDates, setFormData);
                        }}
                        placeholder="Selecione a data"
                        showButtonBar
                        selectionMode="single"
                        dateFormat="dd/mm/yy"
                        locale="pt"
                        readOnlyInput
                        style={{ textAlign: 'center', width: '100%' }}
                        panelClassName="custom-calendar-panel"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="escriturado" className="mb-1 font-medium">Escriturado</label>
                    <Form.Select
                        value={formData?.escriturado}
                        onChange={(e) => handleInputChange("escriturado", e.target.value, setFormData)}
                    >
                        {booleanOptions.map((option) =>
                            <option value={String(option.value)}>{option.label}</option>
                        )}
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="averbado" className="mb-1 font-medium">Averbado</label>
                    <Form.Select
                        value={formData?.averbado}
                        onChange={(e) => handleInputChange("averbado", e.target.value, setFormData)}
                    >
                        {booleanOptions.map((option) =>
                            <option value={String(option.value)}>{option.label}</option>
                        )}
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="ult_pgto_iptu" className="mb-1 font-medium">Data do Último Pagamento do IPTU</label>
                    <Calendar
                        value={formData?.ult_pgto_iptu ? new Date(formData?.ult_pgto_iptu) : null}
                        onChange={(event: any) => {
                            const selectedDates = event.value;
                            handleInputChange("ult_pgto_iptu", selectedDates, setFormData);
                        }}
                        placeholder="Selecione a data"
                        showButtonBar
                        selectionMode="single"
                        dateFormat="dd/mm/yy"
                        locale="pt"
                        readOnlyInput
                        style={{ textAlign: 'center', width: '100%' }}
                        panelClassName="custom-calendar-panel"
                    />
                </div>
            </div>
        </fieldset>
    );
}

export default AspectoLegaisForm;
