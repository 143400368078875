import 'react-datepicker/dist/react-datepicker.css';
import { booleanOptions, handleChangeBanco, handleInputChange, searchBanco } from "../Service/ImovelService";
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Form } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';

interface ValoresCondicoesFormProps {
    formData: any,
    FilteredBanco: any,
    setFormData: (value: any) => void,
    setFilteredBanco: (value: any) => void,
}
function ValoresCondicoesForm({
    formData,
    FilteredBanco,
    setFormData,
    setFilteredBanco
}: ValoresCondicoesFormProps) {

    return (
        <fieldset className="w-full border p-4 rounded mb-4">
            <legend className="font-bold text-lg">Valores e Condições de Revenda</legend>
            <div className="grid grid-cols-6 gap-4">
                {/* Valor Atual do Imóvel */}
                <div className="flex flex-col">
                    <label htmlFor="valor_atual_imovel" className="mb-1 font-medium">Valor Atual do Imóvel</label>
                    <InputNumber
                        id="valor_atual_imovel"
                        invalid={!formData?.valor_atual_imovel}
                        value={formData?.valor_atual_imovel}
                        onValueChange={(e) => handleInputChange("valor_atual_imovel", e.value, setFormData)}
                        prefix="R$"
                        min={0}
                        placeholder="Valor atual do imóvel"
                    />
                </div>
                {/* Data do Valor Atual do Imóvel */}
                <div className="flex flex-col mb-4">
                    <label htmlFor="data_valor_atual_imovel" className="mb-1 font-medium">Data da Avaliação</label>
                    <Calendar
                        value={formData?.data_valor_atual_imovel ? new Date(formData?.data_valor_atual_imovel) : null}
                        onChange={(event: any) => {
                            const selectedDates = event.value;
                            handleInputChange("data_valor_atual_imovel", selectedDates, setFormData);
                        }}
                        placeholder="Selecione a data"
                        showButtonBar
                        selectionMode="single"
                        dateFormat="dd/mm/yy"
                        locale="pt"
                        readOnlyInput
                        style={{ textAlign: 'center', width: '100%' }}
                        panelClassName="custom-calendar-panel"
                    />
                </div>
                {/* Valor de Venda Revenda */}
                <div className="flex flex-col">
                    <label htmlFor="valor_venda_revenda" className="mb-1 font-medium">Valor de Venda de Revenda</label>
                    <InputNumber
                        id="valor_venda_revenda"
                        invalid={!formData?.valor_venda_revenda}
                        value={formData?.valor_venda_revenda}
                        onValueChange={(e) => handleInputChange("valor_venda_revenda", e.value, setFormData)}
                        prefix="R$"
                        min={0}
                        placeholder="Valor de revenda"
                    />
                </div>
                {/* Valor Agio */}
                <div className="flex flex-col">
                    <label htmlFor="valor_venda_agio" className="mb-1 font-medium">Valor de Ágio</label>
                    <InputNumber
                        id="valor_venda_agio"
                        value={formData?.valor_venda_agio}
                        onValueChange={(e) => handleInputChange("valor_venda_agio", e.value, setFormData)}
                        prefix="R$"
                        min={0}
                        placeholder="Valor de ágio"
                    />
                </div>
                {/* Aceita Permuta */}
                <div className="flex flex-col">
                    <label htmlFor="aceita_permuta" className="mb-1 font-medium">Aceita Permuta</label>
                    <Form.Select
                        value={formData?.aceita_permuta}
                        onChange={(e) => handleInputChange("aceita_permuta", e.target.value, setFormData)}
                    >
                        {booleanOptions.map((option) =>
                            <option value={String(option.value)}>{option.label}</option>
                        )}
                    </Form.Select>
                </div>
                {/* Condições da Permuta */}
                <div className="flex flex-col">
                    <label htmlFor="venda_condicoes" className="mb-1 font-medium">Condições da Permuta</label>
                    <InputText
                        id="venda_condicoes"
                        value={formData?.venda_condicoes}
                        onChange={(e) => handleInputChange("venda_condicoes", e.target.value, setFormData)}
                        placeholder="Detalhes da permuta"
                    />
                </div>
                {/* Aceita Financiamento */}
                <div className="flex flex-col">
                    <label htmlFor="aceita_financiamento" className="mb-1 font-medium">Aceita Financiamento</label>
                    <Form.Select
                        isInvalid={!formData?.aceita_financiamento}
                        value={formData?.aceita_financiamento}
                        onChange={(e) => handleInputChange("aceita_financiamento", e.target.value, setFormData)}
                    >
                        {booleanOptions.map((option) =>
                            <option value={String(option.value)}>{option.label}</option>
                        )}
                    </Form.Select>
                </div>
                {/* Possui Dívida */}
                <div className="flex flex-col">
                    <label htmlFor="possui_divida" className="mb-1 font-medium">Possui Dívida</label>
                    <Form.Select
                        value={formData?.possui_divida}
                        onChange={(e) => handleInputChange("possui_divida", e.target.value, setFormData)}
                    >
                        {booleanOptions.map((option) =>
                            <option value={String(option.value)}>{option.label}</option>
                        )}
                    </Form.Select>
                </div>
                {/* Saldo Dívida */}
                <div className="flex flex-col">
                    <label htmlFor="saldo_divida" className="mb-1 font-medium">Saldo da Dívida</label>
                    <InputNumber
                        id="saldo_divida"
                        value={formData?.saldo_divida}
                        onValueChange={(e) => handleInputChange("saldo_divida", e.value, setFormData)}
                        prefix="R$"
                        min={0}
                        placeholder="Saldo da dívida"
                        disabled={!formData?.possui_divida}
                    />
                </div>
                {/* Agência Financeira */}
                <div className="flex flex-col">
                    <label htmlFor="agencia_financeira_id" className="mb-1 font-medium">Agência Financeira</label>
                    <AutoComplete
                        placeholder='Pesquisar Bancos'
                        field="label"
                        style={{ height: "37px", width: '100%' }}
                        value={formData.banco_nome ? formData.banco_nome : ''}
                        suggestions={FilteredBanco}
                        completeMethod={(e) => searchBanco(e, setFilteredBanco)}
                        onChange={(e) => handleChangeBanco('agencia_financeira_id', e.value, setFormData)}
                        dropdown
                    />
                </div>
                {/* Valor da Mensalidade Dívida */}
                <div className="flex flex-col">
                    <label htmlFor="valor_mensalidade_divida" className="mb-1 font-medium">Valor da Mensalidade da Dívida</label>
                    <InputNumber
                        id="valor_mensalidade_divida"
                        value={formData?.valor_mensalidade_divida}
                        onValueChange={(e) => handleInputChange("valor_mensalidade_divida", e.value, setFormData)}
                        prefix="R$"
                        min={0}
                        placeholder="Valor da mensalidade"
                        disabled={!formData?.possui_divida}
                    />
                </div>
                {/* Dia de Vencimento da Dívida */}
                <div className="flex flex-col">
                    <label htmlFor="dia_vencimento_divida" className="mb-1 font-medium">Dia de Vencimento da Dívida</label>
                    <InputNumber
                        id="dia_vencimento_divida"
                        value={formData?.dia_vencimento_divida}
                        onValueChange={(e) => handleInputChange("dia_vencimento_divida", e.value, setFormData)}
                        min={1}
                        max={31}
                        placeholder="Dia de vencimento"
                        disabled={!formData?.possui_divida}
                    />
                </div>
                {/* Data Última Parcela Dívida */}
                <div className="flex flex-col mb-4">
                    <label htmlFor="data_ultima_parcela_divida" className="mb-1 font-medium">Data da Última Parcela da Dívida</label>
                    <Calendar
                        value={formData?.data_ultima_parcela_divida ? new Date(formData?.data_ultima_parcela_divida) : null}
                        onChange={(event: any) => {
                            const selectedDates = event.value;
                            handleInputChange("data_ultima_parcela_divida", selectedDates, setFormData);
                        }}
                        placeholder="Selecione a data"
                        showButtonBar
                        selectionMode="single"
                        dateFormat="dd/mm/yy"
                        locale="pt"
                        readOnlyInput
                        style={{ textAlign: 'center', width: '100%' }}
                        panelClassName="custom-calendar-panel"
                        disabled={!formData?.possui_divida}
                    />
                </div>
                {/* Quantidade de Parcelas Restantes */}
                <div className="flex flex-col">
                    <label htmlFor="qtd_parcela_restante_divida" className="mb-1 font-medium">Quantidade de Parcelas Restantes</label>
                    <InputNumber
                        id="qtd_parcela_restante_divida"
                        value={formData?.qtd_parcela_restante_divida}
                        onValueChange={(e) => handleInputChange("qtd_parcela_restante_divida", e.value, setFormData)}
                        min={0}
                        max={999}
                        placeholder="Parcelas restantes"
                        disabled={!formData?.possui_divida}
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="aluguel_valor" className="mb-1 font-medium">Valor do Aluguel</label>
                    <InputNumber
                        id="aluguel_valor"
                        value={formData?.aluguel_valor}
                        onValueChange={(e) => handleInputChange("aluguel_valor", e.value, setFormData)}
                        prefix="R$"
                        min={0}
                        placeholder="Valor do aluguel"
                    />
                </div>
            </div>
        </fieldset>
    );
}

export default ValoresCondicoesForm;
