import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import api from '../../../../services/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from "date-fns/locale/pt";
import { AlertService } from "../../../../components/AlertService";
import { url } from "../../../../environments/environments-develop";
import { AutoComplete } from "primereact/autocomplete";
import { searchProduto } from "../../../Vendas/NovaVenda/Services/VendaService";
import { InputNumber } from "primereact/inputnumber";
import TokenService from "../../../../services/Auth/token.service";

function ConfigCarteiraForm(props: any) {
    const { empreendimento_id,
        juros,
        multa,
        qtd_parcela_balao,
        balao_periodicidade,
        dias_protesto,
        prazo_pgto_apos_vencimento,
        carencia_inicio_multa,
        carencia_juros_mora,
        desconto_ate_vencimento,
        tempo_reajuste,
        tx_boleto,
        tx_boleto_parcela,
        vl_condominio,
        parcela_seguro_saldo_devedor,
        gera_comis_para,
        coef_adm_carteira,
        base_reajuste,
        parcela_honorario_advogado,
        produto_servico_manutencao_id,
        tipo_reaj_parc } = props.Data || {};
    const [listPermi, setListPermi] = useState([]);
    const [balao_venc_primeira, setBalaoVencPrimeira] = useState<Date | null>(null);
    const [filteredProduto, setFilteredProduto] = useState<any>(null);
    const [produto, setProduto] = useState<any>(null);
    const filial = TokenService.getFilialId();
    const [isDiasProtestoDisabled, setIsDiasProtestoDisabled] = useState(false);
    const [isPrazoPgtoDisabled, setIsPrazoPgtoDisabled] = useState(false);

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
        if (field === 'dias_protesto') {
            setIsPrazoPgtoDisabled(!!value);
        } else if (field === 'prazo_pgto_apos_vencimento') {
            setIsDiasProtestoDisabled(!!value);
        }
    };

    async function listEmpreendimento() {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/empreendimentos`);
            setListPermi(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    };

    useEffect(() => {
        listEmpreendimento();
    }, []);

    return (
        <>
            <div className="formulario-empreendimento">
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label>Juros</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={juros ? juros : null}
                            onChange={(e: any) => handleChange('juros', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label>Multa</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={multa ? multa : null}
                            onChange={(e: any) => handleChange('multa', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label>Qtd. Parcela Balão</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={qtd_parcela_balao ? qtd_parcela_balao : null}
                            onChange={(e: any) => handleChange('qtd_parcela_balao', e.value)}
                            locale="pt-BR"
                        />
                    </Col>
                    <Col md="2" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Taxar parcela do Boleto?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={tx_boleto_parcela || false}
                            onChange={(e) => handleChange("tx_boleto_parcela", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    <Col md="4" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Data Venc. Primeira Parcela Balão</Form.Label>
                        <DatePicker
                            selected={balao_venc_primeira || null}
                            onChange={(date: Date) => {
                                setBalaoVencPrimeira(date);
                                handleChange('balao_venc_primeira', date ? date.toISOString() : '');
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            fixedHeight
                            locale={pt}
                            customInput={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="Data do Ult. Pgto do Iptu"
                                        className="dateFormFinanceiro"
                                        value={balao_venc_primeira ? balao_venc_primeira.toLocaleDateString() : ''}
                                        readOnly
                                    />
                                    <img
                                        className="calendar-icon"
                                        src={CalendarIcon}
                                    />
                                </div>
                            }
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label>Base de Reajuste</Form.Label>
                        <Form.Select value={base_reajuste} onChange={(e) => handleChange('base_reajuste', Number(e.target.value))}>
                            <option>Selecione...</option>
                            <option value={1}>Primeiro Vencimento</option>
                            <option value={2}>Data Venda</option>
                        </Form.Select>
                    </Col>
                    <Col md="2">
                        <Form.Label>Tipo Reajuste Parcela</Form.Label>
                        <Form.Select value={tipo_reaj_parc} onChange={(e) => handleChange('tipo_reaj_parc', Number(e.target.value))}>
                            <option>Selecione...</option>
                            <option value={0}>Anual</option>
                            <option value={1}>Mensal</option>
                        </Form.Select>
                    </Col>
                    <Col md="2">
                        <Form.Label>Periodicidade Balão</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={balao_periodicidade ? balao_periodicidade : null}
                            onChange={(e: any) => handleChange('balao_periodicidade', e.value)}
                            locale="pt-BR"
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label>Dias de protesto</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={dias_protesto ? dias_protesto : null}
                            onChange={(e: any) => handleChange('dias_protesto', e.value)}
                            locale="pt-BR"
                            disabled={isDiasProtestoDisabled}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label>Carência do inicio do juros</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={carencia_juros_mora ? carencia_juros_mora : null}
                            onChange={(e: any) => handleChange('carencia_juros_mora', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label>Carência do inicio da multa</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={carencia_inicio_multa ? carencia_inicio_multa : null}
                            onChange={(e: any) => handleChange('carencia_inicio_multa', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>

                </Row>
                <Row className="mb-3">
                    <Col md="3">
                        <Form.Label>Desconto até o Vencimento</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={desconto_ate_vencimento ? desconto_ate_vencimento : null}
                            onChange={(e: any) => handleChange('desconto_ate_vencimento', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>
                    <Col md="3">
                        <Form.Label>Tempo reajuste</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={tempo_reajuste ? tempo_reajuste : null}
                            onChange={(e: any) => handleChange('tempo_reajuste', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>
                    <Col md="3">
                        <Form.Label>Taxa do Boleto</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={tx_boleto ? tx_boleto : null}
                            onChange={(e: any) => handleChange('tx_boleto', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>
                    {filial?.tipo_empresa === 2 && (<Col md="3">
                        <Form.Label>Taxa de Manutenção do Jazigo</Form.Label>
                        <AutoComplete
                            field="nome_servico"
                            style={{ height: "37px", width: '100%' }}
                            value={produto}
                            suggestions={filteredProduto}
                            completeMethod={(e) => searchProduto(e, 0, setFilteredProduto)}
                            onChange={(e) => setProduto(e.target.value)}
                            onSelect={(e) => {
                                setProduto(e.value);
                                handleChange('produto_servico_manutencao_id', e.value.id);
                            }}
                            dropdown
                        />
                    </Col>)}
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label>Valor do Condominio</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={vl_condominio ? vl_condominio : null}
                            onChange={(e: any) => handleChange('vl_condominio', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>
                    <Col md="3">
                        <Form.Label>Prazo PGTO apòs o vencimento</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={prazo_pgto_apos_vencimento ? prazo_pgto_apos_vencimento : null}
                            onChange={(e: any) => handleChange('prazo_pgto_apos_vencimento', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                            disabled={isPrazoPgtoDisabled}
                        />
                    </Col>
                    <Col md="3">
                        <Form.Label>Parc seguro do Saldo Devedor</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={parcela_seguro_saldo_devedor ? parcela_seguro_saldo_devedor : null}
                            onChange={(e: any) => handleChange('parcela_seguro_saldo_devedor', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label>Coeficiênte admin da carteira</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={coef_adm_carteira ? coef_adm_carteira : null}
                            onChange={(e: any) => handleChange('coef_adm_carteira', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label>Parc. Honorario do Advogado</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={parcela_honorario_advogado ? parcela_honorario_advogado : null}
                            onChange={(e: any) => handleChange('parcela_honorario_advogado', e.value)}
                            locale="pt-BR"
                            minFractionDigits={2}
                            maxFractionDigits={24}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ConfigCarteiraForm;
