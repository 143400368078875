import React from 'react';
import { Form, Col } from 'react-bootstrap';
import PessoaForm from '../../../Formularios/pessoa';
import EnderecoForm from '../../../Formularios/endereco';
import TelefoneForm from '../../../Formularios/telefone';

interface ModalPessoaFormProps {
    currentPart: number;
    formBasico: any;
    onInputChange: (field: string, value: string) => void;
    onInputChangeEndereco: (index: number, field: string, value: any) => void;
    onAddEndereco: any;
    onRemoveEndereco: any;
    onInputChangeTelefone: (index: number, field: string, value: any) => void;
    onAddTelefone: any;
    onRemoveTelefone: any;
}

const ModalPessoaForm: React.FC<ModalPessoaFormProps> = ({
    currentPart,
    formBasico,
    onInputChange,
    onInputChangeEndereco,
    onAddEndereco,
    onRemoveEndereco,
    onInputChangeTelefone,
    onAddTelefone,
    onRemoveTelefone
}) => {

    return (
        <Form.Group className="venda-jazigo" style={{ width: '-webkit-fill-available', minHeight: '350px', height: '-webkit-fill-available' }}>
            <Col md="12">
                {currentPart === 0 && (
                    <PessoaForm
                        isEnablePerfil={false}
                        tipoPessoa={4}
                        tipoAcao={"criar"}
                        pessoaData={formBasico?.pessoa}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)}
                    />
                )}
                {currentPart === 1 && (
                    <EnderecoForm
                        enderecosData={formBasico?.pessoa?.enderecos || []}
                        onInputChange={(index: number, field: string, value: any) => onInputChangeEndereco(index, field, value)}
                        onAddEndereco={onAddEndereco}
                        onRemoveEndereco={onRemoveEndereco}
                    />
                )}

                {currentPart === 2 && (
                    <TelefoneForm
                        telefonesData={formBasico?.pessoa?.telefones || []}
                        onInputChange={(index: number, field: string, value: string) => {
                            if (formBasico?.pessoa?.telefones && formBasico?.pessoa?.telefones?.length > 0) {
                                onInputChangeTelefone(index, field, value);
                            } else {
                                onAddTelefone();
                            }
                        }}
                        onAddTelefone={() => onAddTelefone()}
                        onRemoveTelefone={(index: number) => onRemoveTelefone(index)}
                    />
                )}
            </Col>
        </Form.Group>
    );
};

export default ModalPessoaForm;
