import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import { Skeleton } from "primereact/skeleton";
import { AlertService } from "../../../../components/AlertService";
import api from "../../../../services/api";
import { url } from "../../../../environments/environments-develop";
import { showToast } from "../../../../components/ToastComponent";
import { addEndereco, changeEndereco, removeEndereco } from "../../../../utils/EnderecoUtils";
import { Form } from "react-bootstrap";

interface EnderecoFormProps {
    enderecosData?: any | null;
    handleChange: (field: string, value: string) => void;
}

const FormEndereco = ({
    enderecosData,
    handleChange,
}: EnderecoFormProps) => {
    const tiposEndereco = [
        { label: "Residencial", value: 1 },
        { label: "Comercial", value: 2 },
        { label: "Contato", value: 3 },
        { label: "Cobrança", value: 4 },
    ];
    const [endereco, setEndereco] = useState<any | null>([]);
    const [activeEventKey, setActiveEventKey] = useState<string | null>(null);
    const [FilteredCidade, setFilteredCidade] = useState<any>(null);
    const [listCidade, setListCidade] = useState([]);
    const [nomeCidade, setNomeCidade] = useState();

    async function InitCidade() {
        try {
            const response = await api.get(`${url}/api/v1/admin/core/cidades/listar-cidades`);
            setListCidade(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de cidades. Erro:' + String(error));
        }
    }
    const searchCidade = (event: any) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _FilteredCidade: any;

            if (!event.query.trim().length) {
                _FilteredCidade = [...listCidade];
            }
            else {
                _FilteredCidade = listCidade.filter((item: any) => {
                    return item.nome.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredCidade(_FilteredCidade);
        }, 250);
    }
    useEffect(() => {
        if (enderecosData) {
            setEndereco(enderecosData);
        } else {
            setEndereco(enderecosData);
        }
    }, [enderecosData])
    useEffect(() => {
        InitCidade();
    }, []);
    const handleChangeCidade = (busca: any) => {
        const novoEndereco: any = {
            cidade: busca.value,
            cidade_id: busca.value.id,
            cidade_nome: busca.value.nome,
        };
        Object.keys(novoEndereco).forEach((field) => {
            handleChange(field, novoEndereco[field]);
        });
    };
    async function buscarCep(value: any): Promise<void> {
        try {
            const retorno = await api.get(
                `${url}/api/v1/admin/core/cep-localidade/${value}`
            );
            if (retorno?.data?.data?.cidade_id) {
                if (retorno && retorno.status === 200) {
                    const novoEndereco: any = {
                        apelido: "",
                        logradouro: retorno.data.data.logradouro,
                        tipo_endereco: "",
                        cep: retorno.data.data.cep,
                        cidade: retorno.data.data.cidade.data,
                        numero: "",
                        cidade_id: retorno.data.data.cidade_id,
                        cidade_nome: retorno.data.data.cidade_nome,
                        complemento: "",
                    };
                    Object.keys(novoEndereco).forEach((field) => {
                        handleChange(field, novoEndereco[field]);
                    });
                }
            } else {
                showToast('error', 'CEP não encontrado');
            }

        } catch (err) {
            showToast("error", `Erro: ${err}`);
        }
    }
    return (
        <>
            <div className="flex items-center justify-center">
                <div className="w-full flex flex-col">
                    <label className="block text-sm font-medium mb-1">CEP</label>
                    <InputText
                        className="w-full"
                        invalid={!endereco?.cep}
                        value={endereco?.cep || ""}
                        onChange={(e) => handleChange("cep", e.target.value)}
                    />
                </div>
                <div className="mt-4 flex flex-col">
                    <Button
                        icon="pi pi-search"
                        severity="success"
                        className="p-button-rounded p-button-text"
                        onClick={() => buscarCep(endereco?.cep)}
                    />
                </div>
            </div>

            <div className="flex flex-col">
                <label className="block text-sm font-medium mb-1">Logradouro</label>
                <InputText
                    className="w-full"
                    invalid={!endereco?.logradouro}
                    value={endereco?.logradouro || ""}
                    onChange={(e) => handleChange("logradouro", e.target.value)}
                />
            </div>

            <div className="flex flex-col">
                <label className="block text-sm font-medium mb-1">Número</label>
                <InputText
                    className="w-full"
                    invalid={!endereco?.numero}
                    value={endereco?.numero || ""}
                    onChange={(e) => handleChange("numero", e.target.value)}
                />
            </div>

            <div className="flex flex-col">
                <label className="block text-sm font-medium mb-1">Tipo de Endereço</label>
                <Form.Select
                    className="w-full"
                    value={endereco?.tipo_endereco}
                    isInvalid={!endereco?.tipo_endereco}
                    onChange={(e) => handleChange("tipo_endereco", e.target.value)}
                >
                    <option value="">Selecione...</option>
                    {tiposEndereco.map((option) =>
                        <option value={String(option.value)}>{option.label}</option>
                    )}
                </Form.Select>
            </div>

            <div className="flex flex-col">
                <label className="block text-sm font-medium mb-1">Cidade</label>
                <AutoComplete
                    className="w-full"
                    invalid={!endereco?.cidade_id}
                    value={endereco?.cidade_nome || endereco?.busca || ""}
                    suggestions={FilteredCidade?.length > 0
                        ? FilteredCidade.map((cidade: any) => ({
                            label: cidade.nome,
                            value: cidade
                        }))
                        : []}
                    completeMethod={searchCidade}
                    field="label"
                    onChange={(e) => handleChange("busca", e.target.value)}
                    onSelect={(e) => {
                        handleChangeCidade(e.value);
                        setNomeCidade(e.value?.nome);
                    }}
                    placeholder="Pesquisar cidade"
                />
            </div>
        </>
    );
};

export default FormEndereco;
