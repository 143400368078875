import 'react-datepicker/dist/react-datepicker.css';
import { handleInputChange } from "../Service/ContratoService";
import { InputTextarea } from 'primereact/inputtextarea';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
interface FiscalReajusteFormProps {
    formData: any,
    setFormData: (value: any) => void,
}

function FiscalReajusteForm({
    formData,
    setFormData,
}: FiscalReajusteFormProps) {

    const [tipoIndice, setTipoIndice] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [responseTipoIndice] = await Promise.all([
                    api.get(`${url}/api/v1/admin/financeiro/tipo-indice`),
                ]);
                if (responseTipoIndice.status === 200) {
                    const options = responseTipoIndice.data.data.map((item: any) => ({
                        label: item.nome,
                        value: item.id,
                    }));
                    setTipoIndice(options);
                }
            } catch (error) {
                AlertService.error('Erro!', String('Erro desconhecido'));
            }
        };
        fetchData();
    }, []);

    return (
        <fieldset className="w-full border p-4 rounded mb-2">
            <legend className="font-bold text-lg">Fiscal / Reajuste</legend>
            <div className="grid grid-cols-6 gap-4">
                <div className="flex flex-col">
                    <label htmlFor="tipo_indice_id" className="mb-1 font-medium">Tipo de Índice</label>
                    <Form.Select
                        id="tipo_indice_id"
                        style={{height: '33px', borderRadius: '3px'}}
                        value={formData?.tipo_indice_id || ''}
                        onChange={(e) => handleInputChange("tipo_indice_id", e.target.value, setFormData)}
                    >
                        <option value="" disabled>Selecione o tipo de índice</option>
                        {tipoIndice.map((option: any) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="dimob" className="mb-1 font-medium">DIMOB</label>
                    <Form.Select
                        id="dimob"
                        style={{height: '33px', borderRadius: '3px'}}
                        isInvalid={!formData?.dimob}
                        value={formData?.dimob === true ? "true" : formData?.dimob === false ? "false" : ''}
                        onChange={(e) => handleInputChange("dimob", e.target.value === "true", setFormData)}
                    >
                        <option value="" disabled>Selecione uma opção</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="anotacoes" className="mb-1 font-medium">Anotações</label>
                    <InputTextarea
                        id="anotacoes"
                        value={formData?.anotacoes}
                        onChange={(e) => handleInputChange("anotacoes", e.target.value, setFormData)}
                        placeholder="Digite as anotações"
                        rows={2}
                    />
                </div>
            </div>
        </fieldset>
    );
}

export default FiscalReajusteForm;
