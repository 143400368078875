import React, { useState, useEffect } from "react";
import { handleCheckboxChange, isCheckboxChecked } from "../Service/ImovelService";

const CACHE_KEY_IMOVEL = "caracteristicasImovel";
const CACHE_KEY_CONDOMINIO = "caracteristicasCondominio";

export default function CaracteristicasListagem({ listCaracteristicas, formData, setFormData }: { listCaracteristicas: any[], formData: any, setFormData: (value: any) => void }) {
    const [caracteristicasImovel, setCaracteristicasImovel] = useState<any[]>([]);
    const [caracteristicasCondominio, setCaracteristicasCondominio] = useState<any[]>([]);

    const fetchCaracteristicas = () => {
        if (listCaracteristicas?.length > 0) {
            const sortByTextThenNumber = (a: any, b: any) => {
                const regex = /^(\d*)\s*(.*)$/; // Regex para capturar número opcional e texto
                const [, numA, textA] = a.descricao.match(regex) || [];
                const [, numB, textB] = b.descricao.match(regex) || [];

                // Ordenar primeiro pelo texto
                const textComparison = textA.localeCompare(textB);
                if (textComparison !== 0) return textComparison;

                // Caso os textos sejam iguais, ordenar pelo número
                return (parseInt(numA, 10) || 0) - (parseInt(numB, 10) || 0);
            };

            // Filtro e ordenação
            const imovel = listCaracteristicas
                .filter((item: any) => item.tipo_caracteristica === 1)
                .sort(sortByTextThenNumber);
            const condominio = listCaracteristicas
                .filter((item: any) => item.tipo_caracteristica === 2)
                .sort(sortByTextThenNumber);

            // Atualiza o cache
            localStorage.setItem(CACHE_KEY_IMOVEL, JSON.stringify(imovel));
            localStorage.setItem(CACHE_KEY_CONDOMINIO, JSON.stringify(condominio));

            setCaracteristicasImovel(imovel);
            setCaracteristicasCondominio(condominio);
        }
    };

    useEffect(() => {
        // Verifica se há cache
        const cachedImovel = localStorage.getItem(CACHE_KEY_IMOVEL);
        const cachedCondominio = localStorage.getItem(CACHE_KEY_CONDOMINIO);

        if (cachedImovel && cachedCondominio) {
            // Carrega dados do cache
            setCaracteristicasImovel(JSON.parse(cachedImovel));
            setCaracteristicasCondominio(JSON.parse(cachedCondominio));
        } else {
            // Processa e popula o cache
            fetchCaracteristicas();
        }
    }, [listCaracteristicas]);

    return (
        <div className="w-full space-y-4">
            {caracteristicasImovel.length > 0 && (
                <fieldset className="w-full border p-3 rounded mb-4">
                    <legend className="font-bold text-lg">Características do Imóvel</legend>
                    <div className="grid grid-cols-6 gap-4 p-2">
                        {caracteristicasImovel.map((item: any) => (
                            <div key={item.id} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={`caracteristica-imovel-${item.id}`}
                                    name={`caracteristica-imovel-${item.id}`}
                                    className="mr-2"
                                    checked={isCheckboxChecked(item.id, formData)}
                                    onChange={(e) =>
                                        handleCheckboxChange(e.target.checked, item.id, setFormData)
                                    }
                                />
                                <label
                                    htmlFor={`caracteristica-imovel-${item.id}`}
                                    className="text-gray-700"
                                >
                                    {item.descricao}
                                </label>
                            </div>
                        ))}
                    </div>
                </fieldset>
            )}

            {caracteristicasCondominio.length > 0 && (
                <fieldset className="w-full border p-3 rounded mb-4">
                    <legend className="font-bold text-lg">Características do Condomínio</legend>
                    <div className="grid grid-cols-6 gap-4 p-2">
                        {caracteristicasCondominio.map((item: any) => (
                            <div key={item.id} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={`caracteristica-condominio-${item.id}`}
                                    name={`caracteristica-condominio-${item.id}`}
                                    className="mr-2"
                                    checked={isCheckboxChecked(item.id, formData)}
                                    onChange={(e) =>
                                        handleCheckboxChange(e.target.checked, item.id, setFormData)
                                    }
                                />
                                <label
                                    htmlFor={`caracteristica-condominio-${item.id}`}
                                    className="text-gray-700"
                                >
                                    {item.descricao}
                                </label>
                            </div>
                        ))}
                    </div>
                </fieldset>
            )}
        </div>
    );
}
