import { Col, Form, Row } from "react-bootstrap";

function CaracteristicaFormShow(props: any) {
    const {
        tipo_caracteristica,
        descricao,
        nome_campos,
        tipo_dado,
        tag,
        icone,
        tipo_imovel_id,
        sub_tipo_imovel_label,
    } = props.Data || {};

    return (
        <Form.Group className="imovel-form" style={{ overflowX: "hidden" }}>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Nome Campo</Form.Label>
                    <Form.Control
                        value={nome_campos}
                        readOnly
                    />
                </Col>
                <Col>
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        value={descricao}
                        readOnly
                    />
                </Col>
                <Col>
                    <Form.Label>Tipo de Dado</Form.Label>
                    <Form.Control
                        type="text"
                        value={tipo_caracteristica === 1 ? "Texto" : "Tag"}
                        readOnly
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Tipo Imóvel</Form.Label>
                    <Form.Control
                        value={tipo_imovel_id}
                        readOnly
                    />
                </Col>
                <Col>
                    <Form.Label>SubTipo Imóvel</Form.Label>
                    <Form.Control
                        value={sub_tipo_imovel_label}
                        readOnly
                    />
                </Col>
                <Col>
                    <Form.Label>Tipo de Característica</Form.Label>
                    <Form.Control
                        type="text"
                        value={tipo_caracteristica === 1 ? "Imóvel" : "Condomínio"}
                        readOnly
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Tag</Form.Label>
                    <Form.Control
                        value={tag}
                        readOnly
                    />
                </Col>
                <Col>
                    <Form.Label>Ícone</Form.Label>
                    <Form.Control
                        value={icone}
                        readOnly
                    />
                </Col>
            </Row>
        </Form.Group>
    );
}

export default CaracteristicaFormShow;
