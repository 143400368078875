import { Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import TokenService from "../../../../services/Auth/token.service";
import api from "../../../../services/api";
import { url } from "../../../../environments/environments-develop";
import { AlertService } from "../../../../components/AlertService";
import IconSelector from "../Components/IconeList";

function CaracteristicasImoveisForm(props: any) {
    const {
        tipo_caracteristica,
        descricao,
        nome_campos,
        tipo_dado,
        tag,
        icone,
        tipo_imovel_id,
        sub_tipo_imovel_id
    } = props.caracteristicaData || {};
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const [tipoImovel, setTipoImovel] = useState<any>([]);
    const [subTipoImovel, setSubTipoImovel] = useState<any>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const consulta: any = {
                    consulta: {
                        order: "id;desc",
                        filtro: {
                            "empreendimento_id": TokenService.getEmpreendimentoId()
                                ? TokenService.getEmpreendimentoId().id
                                : null
                        }
                    }
                };
                const queryParams: any = {};
                const camposEnviar = ["consulta"];
                camposEnviar.forEach((key: any) => {
                    const value = consulta[key];
                    if (value !== undefined && value !== null && value !== "") {
                        queryParams[key] = key === "consulta" ? JSON.stringify(value) : value;
                    }
                });
                const queryString = Object.entries(queryParams)
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
                    .join("&");

                const [responseTiposImovel, responseSubtiposImovel] = await Promise.all([
                    api.get(`${url}/api/v1/admin/vendas/tipoimovel?${queryString}`),
                    api.get(`${url}/api/v1/admin/vendas/subtipoimovel?${queryString}`)
                ]);
                if (responseTiposImovel.status === 200) setTipoImovel(responseTiposImovel.data.data);
                if (responseSubtiposImovel.status === 200) setSubTipoImovel(responseSubtiposImovel.data.data);
            } catch (error) {
                AlertService.error("Erro!", "Erro desconhecido");
            }
        };
        fetchData();
    }, []);

    return (
        <Form.Group className="imovel-form" style={{ overflowX: "hidden" }}>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Nome Campo</Form.Label>
                    <Form.Control
                        value={nome_campos}
                        onChange={(e) => handleChange("nome_campos", e.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        value={descricao}
                        isInvalid={!descricao}
                        onChange={(e) => handleChange("descricao", e.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label>Tipo de Dado</Form.Label>
                    <Form.Select
                        value={tipo_dado}
                        isInvalid={!tipo_dado}
                        onChange={(e) => handleChange("tipo_dado", e.target.value)}
                    >
                        <option>Selecione...</option>
                        <option value={1}>Texto</option>
                        <option value={2}>Tag</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Label>Tipo Imóvel</Form.Label>
                    <Form.Select
                        value={tipo_imovel_id}
                        onChange={(e) => handleChange("tipo_imovel_id", e.target.value)}
                    >
                        <option>Selecione...</option>
                        {tipoImovel.map((tipoimovel: any) => (
                            <option key={tipoimovel.id} value={tipoimovel.id}>
                                {tipoimovel.nome}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Label>SubTipo Imóvel</Form.Label>
                    <Form.Select
                        value={sub_tipo_imovel_id}
                        onChange={(e) => handleChange("sub_tipo_imovel_id", e.target.value)}
                    >
                        <option>Selecione...</option>
                        {subTipoImovel.map((subtipo: any) => (
                            <option key={subtipo.id} value={subtipo.id}>
                                {subtipo.nome}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Label>Tipo de Característica</Form.Label>
                    <Form.Select
                        value={tipo_caracteristica || ""}
                        onChange={(e) => handleChange("tipo_caracteristica", e.target.value)}
                    >
                        <option>Selecione...</option>
                        <option value={1}>Imóvel</option>
                        <option value={2}>Condomínio</option>
                    </Form.Select>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Form.Label>Tag</Form.Label>
                    <Form.Control
                        value={tag}
                        onChange={(e) => handleChange("tag", e.target.value)}
                        placeholder="${ nome_campo }"
                    />
                </Col>
                <IconSelector
                    icone={icone}
                    handleChange={handleChange}
                />
            </Row>
        </Form.Group>
    );
}

export default CaracteristicasImoveisForm;
