import React from 'react';
import { Modal, Row, Col, Form, Container } from 'react-bootstrap';
import { Button } from 'primereact/button';

interface FiltroAvancadoModalProps {
    showModal: boolean;
    onHide: () => void;
    handleClose: () => void;
    listFiltros: any;
    setListFiltros: (list: any) => void;
    setShowModalFiltro: (show: boolean) => void;
    handleFilter: () => void;
    handleClearFilters: () => void;
}
const FiltroAvancadoModal: React.FC<FiltroAvancadoModalProps> = ({
    showModal,
    onHide,
    handleClose,
    listFiltros,
    setListFiltros,
    setShowModalFiltro,
    handleFilter,
    handleClearFilters,
}) => {

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            size="sm"
            className="modal-input-venda"
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtros Avançados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col style={{ marginRight: "8px" }}>
                            <Form.Label>Contrato|ID</Form.Label>
                            <Form.Control
                                value={listFiltros?.contrato_id || ''}
                                onChange={(e) => setListFiltros({...listFiltros, contrato_id: e.target.value})}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        setShowModalFiltro(false);
                        handleFilter();
                    }}
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded>
                    <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                </Button>
                <Button
                    onClick={() => {
                        handleClearFilters();
                        setShowModalFiltro(false);
                    }}
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded>
                    <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltroAvancadoModal;
