import React from 'react';
import { Col, Container, Form, Button } from 'react-bootstrap';
import IdentificacaoForm from './IdentificacaoForm';
import ValoresVencimentoForm from './ValoresVencimentoForm';
import FiscalReajusteForm from './FiscalReajusteForm';

interface FormularioPreenchimentoProps {
    formData: any;
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    FilteredPessoa: any;
    FilteredCorretor: any;
    setFilteredPessoa: React.Dispatch<React.SetStateAction<any>>;
    setFilteredCorretor: React.Dispatch<React.SetStateAction<any>>;
}

const FormularioPreenchimento: React.FC<FormularioPreenchimentoProps> = ({
    formData,
    setFormData,
    FilteredPessoa,
    FilteredCorretor,
    setFilteredPessoa,
    setFilteredCorretor,
}) => {
    return (
        <Container>
            <IdentificacaoForm
                formData={formData}
                FilteredPessoa={FilteredPessoa}
                FilteredCorretor={FilteredCorretor}
                setFilteredPessoa={setFilteredPessoa}
                setFormData={setFormData}
                setFilteredCorretor={setFilteredCorretor}
            />
            <ValoresVencimentoForm
                formData={formData}
                setFormData={setFormData}
            />
            <FiscalReajusteForm
                formData={formData}
                setFormData={setFormData}
            />
        </Container>
    );
};

export default FormularioPreenchimento;
