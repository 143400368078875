import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { isNullOrUndefined } from 'util';
import TokenService from '../../../../services/Auth/token.service';
import '../style/index.css';

interface InformacoesImovelModalProps {
    showModal: boolean;
    onHide: () => void;
    id: number;
}

const InformacoesImovelModal: React.FC<InformacoesImovelModalProps> = ({
    showModal,
    onHide,
    id,
}) => {
    const [Imovel, setImovel] = useState<any | null>();

    async function InitImovel(id: number) {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/imovel/${id}`);
            setImovel(response.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }

    useEffect(() => {
        if (!isNullOrUndefined(id)) {
            InitImovel(id)
        }
    }, [id]);
    function formatCurrency(value: any) {
        return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    function formatNumber(value: any) {
        return Number(value).toLocaleString('pt-BR');
    }
    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="lg"
            className="modal-filtro-avancado"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Imovel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        {[
                            {
                                color: '#189428',
                                title: formatCurrency(Imovel?.imo_preco),
                                subtitle: 'Valor do Imovel',
                                bg: '#E1F5E4',
                                border: '#189428'
                            },
                            {
                                color: '#189428',
                                title: formatCurrency(Imovel?.imo_intermed),
                                subtitle: 'Valor da Intermediação',
                                bg: '#E1F5E4',
                                border: '#189428'
                            },
                            {
                                color: '#189428',
                                title: Imovel?.qtd_max_parcelas + 'X',
                                subtitle: 'Parcelamento Máximo',
                                bg: '#E1F5E4',
                                border: '#189428'
                            },
                            {
                                color: '#189428',
                                title: formatCurrency(Imovel?.valor_parcelas),
                                subtitle: 'Valor das Parcelas',
                                bg: '#E1F5E4',
                                border: '#189428'
                            },
                        ].map((card, index) => (
                            <Col md={3} key={index} className="p-0 mb-3">
                                <div className="d-flex" style={{
                                    backgroundColor: card.bg,
                                    border: `1px solid ${card.border}`,
                                    borderRadius: '5px',
                                    height: '125px',
                                    margin: '5px',
                                }}>
                                    <div className="flex-fill flex flex-column justify-content-center" style={{
                                        color: card.color,
                                        width: '66.666%'
                                    }}>
                                        <div className="text-center" style={{ fontWeight: 'bold', fontSize: '24px' }}>
                                            {card.title}
                                        </div>
                                        <div className="text-center d-flex align-items-center justify-content-center">
                                            {card.subtitle}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                        <div className="w-100 gap-6 mb-6">
                            <fieldset className="bg-gray-50 p-4 rounded-md shadow">
                                <legend className="text-sm text-gray-600">Detalhes do imóvel</legend>
                                <div className="flex justify-content-between items-center m-0">
                                    <p className="text-sm font-bold text-gray-600">{TokenService.getEmpreendimentoId() !== null ? TokenService.getEmpreendimentoId()?.alias_identificador1 : 'Identificador 1'}</p>
                                    <p className="text-sm text-gray-600">{Imovel?.identificador1}</p>
                                </div>
                                <div className="flex justify-content-between items-center m-0">
                                    <p className="text-sm font-bold text-gray-600">{TokenService.getEmpreendimentoId() !== null ? TokenService.getEmpreendimentoId()?.alias_identificador2 : 'Identificador 2'}</p>
                                    <p className="text-sm text-gray-600">{Imovel?.identificador2}</p>
                                </div>
                                <div className="flex justify-content-between items-center m-0">
                                    <p className="text-sm font-bold text-gray-600">Status</p>
                                    <p className="text-sm text-gray-600">{Imovel?.status_name}</p>
                                </div>
                                <div className="flex justify-content-between items-center m-0">
                                    <p className="text-sm font-bold text-gray-600">Unidade</p>
                                    <p className="text-sm text-gray-600">{Imovel?.unidade}</p>
                                </div>
                                <div className="flex justify-content-between items-center m-0">
                                    <p className="text-sm font-bold text-gray-600">Área do Imóvel</p>
                                    <p className="text-sm text-gray-600">{Imovel?.area_label}</p>
                                </div>
                            </fieldset>
                        </div>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default InformacoesImovelModal;
