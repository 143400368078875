import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function VendaAvulsa(props: any) {
    const [formData, setFormData] = useState<any>({});

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }
    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);
    return (
        <>
             <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o codicoin?</Form.Label>
                    <Form.Control
                        value={formData.codicoin || ''}
                        onChange={(e) => handleFormChange('codicoin', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codiclie?</Form.Label>
                    <Form.Control
                        value={formData.codiclie || ''}
                        onChange={(e) => handleFormChange('codiclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o sequcont ?</Form.Label>
                    <Form.Control
                        value={formData.sequcont || ''}
                        onChange={(e) => handleFormChange('sequcont', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codiindi?</Form.Label>
                    <Form.Control
                        value={formData.codiindi || ''}
                        onChange={(e) => handleFormChange('codiindi', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o adicparc?</Form.Label>
                    <Form.Control
                        value={formData.adicparc || ''}
                        onChange={(e) => handleFormChange('adicparc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o qtdeindi?</Form.Label>
                    <Form.Control
                        value={formData.qtdeindi || ''}
                        onChange={(e) => handleFormChange('qtdeindi', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valoindi?</Form.Label>
                    <Form.Control
                        value={formData.valoindi || ''}
                        onChange={(e) => handleFormChange('valoindi', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o dataemis?</Form.Label>
                    <Form.Control
                        value={formData.dataemis || ''}
                        onChange={(e) => handleFormChange('dataemis', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o datavenc?</Form.Label>
                    <Form.Control
                        value={formData.datavenc || ''}
                        onChange={(e) => handleFormChange('datavenc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o anoindi?</Form.Label>
                    <Form.Control
                        value={formData.anoindi || ''}
                        onChange={(e) => handleFormChange('anoindi', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o obseindi?</Form.Label>
                    <Form.Control
                        value={formData.obseindi || ''}
                        onChange={(e) => handleFormChange('obseindi', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o dataentr?</Form.Label>
                    <Form.Control
                        value={formData.dataentr || ''}
                        onChange={(e) => handleFormChange('dataentr', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o respentr?</Form.Label>
                    <Form.Control
                        value={formData.respentr || ''}
                        onChange={(e) => handleFormChange('respentr', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codilopa?</Form.Label>
                    <Form.Control
                        value={formData.codilopa || ''}
                        onChange={(e) => handleFormChange('codilopa', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codirepr?</Form.Label>
                    <Form.Control
                        value={formData.codirepr || ''}
                        onChange={(e) => handleFormChange('codirepr', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o qtdesina?</Form.Label>
                    <Form.Control
                        value={formData.qtdesina || ''}
                        onChange={(e) => handleFormChange('qtdesina', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o valosina?</Form.Label>
                    <Form.Control
                        value={formData.valosina || ''}
                        onChange={(e) => handleFormChange('valosina', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o statcoin?</Form.Label>
                    <Form.Control
                        value={formData.statcoin || ''}
                        onChange={(e) => handleFormChange('statcoin', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o logilanc?</Form.Label>
                    <Form.Control
                        value={formData.logilanc || ''}
                        onChange={(e) => handleFormChange('logilanc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o Indicacao?</Form.Label>
                    <Form.Control
                        value={formData.Indicacao || ''}
                        onChange={(e) => handleFormChange('Indicacao', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o codifopa?</Form.Label>
                    <Form.Control
                        value={formData.codifopa || ''}
                        onChange={(e) => handleFormChange('codifopa', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o VencSina?</Form.Label>
                    <Form.Control
                        value={formData.VencSina || ''}
                        onChange={(e) => handleFormChange('VencSina', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o IdGeracaoIndice?</Form.Label>
                    <Form.Control
                        value={formData.IdGeracaoIndice || ''}
                        onChange={(e) => handleFormChange('IdGeracaoIndice', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o DiaVencimento?</Form.Label>
                    <Form.Control
                        value={formData.DiaVencimento || ''}
                        onChange={(e) => handleFormChange('DiaVencimento', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default VendaAvulsa;
