import { AlertService } from "../../../../components/AlertService";
import TokenService from "../../../../services/Auth/token.service";
import ClienteService from "../../../Pessoas/Services/ClienteService";
import CorretorSevice from "../../../Pessoas/Services/CorretorSevice";
import ContratoService from "../../Services/ContratoService";
interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "id;desc",
};
interface Anexo {
  nome: string;
  alias: string;
  extensao: string;
  conteudo: string;
  url?: string;
  objectURL?: string;
}
export const loadData = (
  listFiltros: any,
  itensPorPagina: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: (value: any) => void,
  sortOptions: { field: string; order: number } | null = null
) => {
  setLoading(true);
  const form = {
    dia_vencimento: listFiltros?.dia_vencimento,
    data_inicial: listFiltros?.data_inicial,
    data_final: listFiltros?.data_final,
    data_ult_reajuste: listFiltros?.data_ult_reajuste,
    data_prox_reaj: listFiltros?.data_prox_reaj,
    unidade: listFiltros?.unidade,
    locatario_id: listFiltros?.locatario_id,
    "vendas.imoveis.status": listFiltros?.status,
    empreendimento_id: TokenService.getEmpreendimentoId()
      ? TokenService.getEmpreendimentoId()?.id
      : null,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
    if (sortOptions) {
      const orderDirection = sortOptions.order === 1 ? "asc" : "desc";
      consulta.order = `${sortOptions.field};${orderDirection}`;
    }
  }

  ImovelList(
    consulta,
    itensPorPagina,
    currentPage,
    setLoading,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro
  );
};

export const ImovelList = (
  filtros: any,
  itensPorPagina: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: any | null
) => {
  ContratoService.list(
    {
      include: "anexo,cliente,plano_conta,conta_financeiro,centro_custo",
      totalitems: itensPorPagina ?? 8,
      consulta: JSON.stringify(filtros),
      page: currentPage + 1,
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response) => {
      setNodes(response);
      setShowModalFiltro(false);
      setLoading(false);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const ImovelShow = (
  id: any,
  setNodes: (value: any) => void,
  setImages: (value: any) => void
) => {
  ContratoService.get(id).subscribe(
    (response) => {
      setNodes(response);
      response?.imagens?.data?.map((image: any) => {
        return setImages((prevData: any) => [
          ...prevData,
          { itemImageSrc: image?.anexo?.url },
        ]);
      });
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const excluirImovel = (id: any) => {
  AlertService.confirm({
    title: "Confirmar Exclusão!",
    text: "Deseja realmente excluir este imóvel?",
    cancelButtonText: "Não",
    confirmButtonText: "Sim",
  }).then((e: any) => {
    if (e?.isConfirmed) {
      ContratoService.excluir(id).subscribe(
        (response) => {
          AlertService.sucess("Sucesso!", "Excluído com sucesso!").then(() => {
            window.location.reload();
          });
        },
        (error) => {
          let mensagens: string[] = [];
          Object.keys(error?.response?.data?.error?.message).forEach((key) => {
            mensagens.push(
              "\n" + error?.response?.data?.error?.message[key][0]
            );
          });
          AlertService.error("Erro!", String(mensagens));
        }
      );
    }
  });
};

export const setSessionEditData = async (
  value: any,
  url_redirect_edit: any
) => {
  ContratoService.get(value, {
    include:
      "imoveis_caracteristicas",
  }).subscribe(
    (response) => {
      localStorage.setItem("dadosEdicao", JSON.stringify(response));
      window.location.href = url_redirect_edit;
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const searchCorretor = (
  event: any,
  currentPage: any,
  setFilteredClient: (value: any) => void
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  CorretorSevice.list({
    empreendimento_id: TokenService.getEmpreendimentoId().id,
  }).subscribe(
    (response) => {
      setFilteredClient(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const searchCliente = (
  query: any,
  setFilteredCliente: (value: any) => void
) => {
  const form = { nome_cliente: query };
  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
  }

  setTimeout(() => {
    ClienteService.list({
      consulta: JSON.stringify(consulta),
    }).subscribe(
      (response) => {
        setFilteredCliente(response);
      },
      (error) => {
        let mensagens: string[] = [];
        mensagens.push(error?.response?.data?.error?.message);
        AlertService.error(
          "Erro!",
          "Não foi possível buscar os clientes: " +
            error?.response?.data?.error?.message
        );
      }
    );
  }, 600);
};

export const handleInputChange = (
  field: string,
  value: any,
  setFormData: (value: any) => void
) => {
  setFormData((prevFormData: any) => ({ ...prevFormData, [field]: value }));
};
export const handleChangeBanco = (
  field: string,
  value: any,
  setFormData: (value: any) => void
) => {
  handleInputChange("banco_nome", value, setFormData);
  handleInputChange(field, value.value, setFormData);
};