import React, { useState, useEffect } from 'react';
import { Button, Col, Container, NavLink } from 'react-bootstrap';
import './style/index.css';
import { Steps } from 'primereact/steps';
import TokenService from '../../../services/Auth/token.service';
import { url } from '../../../environments/environments-develop';
import api from "../../../services/api";
import { showToast } from '../../../components/ToastComponent';
import VendaAvulsaForm from './Formularios/VendaAvulsaForm';
import { addCompradores, removeCompradores } from '../../../utils/CompradorUtils';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { useAppContext } from '../../../contexts/AppContext';
const CreateVendaAvulsa = () => {
    const [currentPart, setCurrentPart] = useState(0);
    const totalParts = 3;
    const [formBasico, setFormBasico] = useState<any>({
        tipo_venda: 1
    });
    const [valorSemDescontoAcessorios, setValorSemDescontoAcessorios] = useState<any>(0);
    const [valorComDescontoAcessorios, setValorComDescontoAcessorios] = useState<any>(0);
    const [saldoDevedor, setSaldoDevedor] = useState<any>(0);
    const { isVisible, updateVisibility } = useSidebarContext();
    const { setUrl, setBody, setItemsBreadsCrumbs, setShowButtonSave } = useAppContext();
    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };
    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Vendas',
                url: '/vendas',
            },
            {
                label: 'Lista de vendas avulsas',
                url: '/venda-avulsa',
            },
            {
                label: 'Criar'
            }
        ]);
        setUrl(`${url}/api/v1/admin/vendas/venda-avulsa`);
        setFormBasico((prevImovelData: any) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId()?.id : null
            };
        });
    }, []);
    useEffect(() => {
        if (formBasico?.produto_servicos) {
            simularSeNecessario();
        }
    }, [
        formBasico?.tabela_preco_id,
        formBasico?.entrada,
        formBasico?.desconto,
        formBasico?.tipo_desconto,
        formBasico?.produto_servicos
    ]);

    const simularSeNecessario = async () => {
        try {
            let data = {
                "produtos_servicos": formBasico?.produto_servicos ?? [],
                "desconto_rodape": formBasico?.desconto ?? null,
                "tipo_desconto_rodape": formBasico?.tipo_desconto ?? 2,
                "entrada": formBasico?.entrada ?? null,
                "empreendimento_id": TokenService.getEmpreendimentoId()?.id ?? null,
                "tabela_preco_id": formBasico?.tabela_preco_id ?? null,
            }
            const response = await api.post(`${url}/api/v1/admin/cemiterio/simular-total`, data);

            if (response.status === 200) {
                Object.keys(response.data.data).forEach(key => {
                    handleChange(key, response.data.data[key]);
                });
            }
        } catch (error) {
            console.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    };
    useEffect(() => {
        const totalValorComDescontoAcessorios = (formBasico?.produto_servicos || []).reduce((acc: number, form: any) => {
            if (form?.produto) {
                return acc + ((form?.quantidade ?? 1) * (form?.produto?.valor ?? 0)) - (form?.tipo_desconto == 1 ? (form?.desconto / 100 * ((form?.produto?.valor ?? 0) * (form?.quantidade ?? 1))) : (form?.desconto * (form?.quantidade ?? 1)));
            }
            return acc;
        }, 0);
        const totalValorSemDescontoAcessorios = (formBasico?.produto_servicos || []).reduce((acc: number, form: any) => {
            if (form?.produto) {
                return acc + ((form?.quantidade ?? 1) * (form?.produto?.valor ?? 0));
            }
            return acc;
        }, 0);
        setSaldoDevedor((totalValorComDescontoAcessorios ?? 0) - (formBasico?.desconto ?? 0) - (formBasico?.entrada ?? 0));
        setValorSemDescontoAcessorios(totalValorSemDescontoAcessorios);
        setValorComDescontoAcessorios(totalValorComDescontoAcessorios);
    }, [formBasico?.produto_servicos, formBasico?.desconto]);
    const validacao = () => {
        if (!formBasico?.data_venda) {
            showToast("error", "O campo de data da venda é obrigatório!")
            return false;
        }
        if (!formBasico?.corretor_id) {
            showToast("error", "É necessario incluir um corretor para prosseguir, corrija o formulário e tente novamente!")
            return false;
        }
        if (!formBasico?.tipo_venda) {
            showToast("error", "O campo Tipo Venda é obrigatório!")
            return false;
        }
        if (!formBasico?.dia_vencimento) {
            showToast("error", "O campo Dia do vencimento é obrigatório!")
            return false;
        }
        if (!formBasico?.cliente_id) {
            showToast("error", "É necessario incluir um comprador para prosseguir, corrija o formulário e tente novamente!")
            return false;
        }
        if (!formBasico?.produto_servicos && currentPart === 1) {
            showToast("error", "É necessario incluir um produto ou serviço para prosseguir, corrija o formulário e tente novamente!")
            return false;
        }
        if (formBasico?.qtd_parc_entrada > TokenService.getEmpreendimentoId()?.config_venda?.qtd_parc_entrada && currentPart === 3) {
            showToast('error', 'Quantidade de parcelas da entrada não pode ser maior que a configurada no empreendimento!');
            return false;
        }
        return true;
    }
    useEffect(() => {
        let data = {
            "produto_servicos": formBasico?.produto_servicos ?? [],
            "data_venda": formBasico?.data_venda ? new Date(formBasico?.data_venda) : new Date().toUTCString(),
            "desconto_rodape": formBasico?.desconto ?? null,
            "tipo_desconto_rodape": formBasico?.tipo_desconto ?? null,
            "valor_sinal": formBasico?.entrada ?? null,
            "valor_primeira_parcela_entrada": formBasico?.valor_prim_parc_entrada ?? null,
            "vencimento_primeira_parcela": formBasico?.data_prim_parcela ? new Date(formBasico?.data_prim_parcela) : null,
            "qtd_parc_entrada": formBasico?.qtd_parc_entrada ?? 0,
            "dia_vencimento": formBasico?.dia_vencimento ?? null,
            "vencimento_primeira_entrada": formBasico?.venc_prim_parc_entrada ? new Date(formBasico?.venc_prim_parc_entrada) : null,
            "observacao_condicao": formBasico?.observacao_condicao ?? null,
            "tipo_venda": formBasico?.tipo_venda ?? null,
            "empreendimento_id": TokenService.getEmpreendimentoId()?.id ?? null,
            "cliente_id": formBasico?.cliente_id ?? null,
            "contrato_id": formBasico?.contrato_id ?? null,
            "tabela_preco_id": formBasico?.tabela_preco_id ?? null,
            "corretor_id": formBasico?.corretor_id ?? null,
        }
        setBody(data);
    }, [formBasico])
    useEffect(() => {
        if (currentPart === totalParts) {
            setShowButtonSave(true);
        }
    }, [currentPart])
    const handleNext = () => {
        const valid = validacao();
        if (valid) {
            if (currentPart < totalParts) {
                setCurrentPart((prevPart) => prevPart + 1);
            }
        }
    };

    const handleChange = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    }

    const handleBack = () => {
        if (currentPart > 0) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    const items = [
        {
            label: 'Dados da Venda',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                } else {
                    return
                }
            }
        },
        {
            label: 'Produtos|Serviços',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                } else {
                    return
                }
            }
        },
        {
            label: 'Entrada',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                }
            }
        },
        {
            label: 'Parcelamento',
            command: (event: any) => {
                if (validacao()) {
                    setCurrentPart(event.index)
                } else {
                    return
                }
            }
        }
    ];

    const MoneyFormatter = (saldoDevedor: any) => {
        const formatMoney = (value: number): string => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(value);
        };
        return formatMoney(saldoDevedor);
    };

    const handleCompradoresInputChange = (index: number, field: string, value: any) => {
        const clientes = formBasico?.cliente_compradores;
        if (formBasico && clientes && index >= 0 && index < clientes.length) {
            setFormBasico((prevFormBasico: any) => {
                const updatedClientes = [...prevFormBasico.cliente_compradores];

                updatedClientes[index] = {
                    ...updatedClientes[index],
                    [field]: value,
                };
                return {
                    ...prevFormBasico,
                    cliente_compradores: updatedClientes,
                };
            });
        }
    };

    const handleAddCompradores = () => {
        addCompradores(formBasico, setFormBasico);
    };

    const handleRemoveCompradores = (index: number) => {
        removeCompradores(formBasico, setFormBasico, index);
    };

    return (
        <div className="body-content">
            <div className="form-user-create">
                <div className="contentSearch-header">
                    <NavLink onClick={toggleVisibility}>
                        <img
                            className="menu-context"
                            src={iconMenuTresPonto}
                        />
                    </NavLink>
                    <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Criar Venda Avulsa</Col>
                </div>
                <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
                <div style={{ padding: '22px' }}>
                    <Container >
                        <div style={{ display: 'block', width: '-webkit-fill-available' }}>
                            <Steps model={items} activeIndex={currentPart} readOnly={false} />
                        </div>
                        <VendaAvulsaForm
                            onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            onCompradoresInputChange={(index: number, field: string, value: any) => handleCompradoresInputChange(index, field, value)}
                            onAddCompradores={handleAddCompradores}
                            onRemoveCompradores={handleRemoveCompradores}
                            currentPart={currentPart}
                            formBasico={formBasico}
                        />
                        <div style={{ backgroundColor: '#4949498a' }} className="flex flex-wrap max-sm:flex-col justify-between max-sm:items-center w-full p-2 rounded-md">
                            <label className="flex items-center m-2">
                                <p style={{ backgroundColor: '#494949' }} className="text-sm m-0 p-1 text-white border rounded-tl-md">
                                    <strong>Total Bruto</strong>
                                </p>
                                <p style={{ backgroundColor: '#e8550f' }} className="text-sm m-0 p-1  text-white border border-orange-600 rounded-br-md">
                                    <strong>{MoneyFormatter(valorSemDescontoAcessorios ?? 0)}</strong>
                                </p>
                            </label>
                            <label className="flex items-center m-2">
                                <p style={{ backgroundColor: '#494949' }} className="text-sm m-0 p-1  text-white border rounded-tl-md">
                                    <strong>Total c/Desconto</strong>
                                </p>
                                <p style={{ backgroundColor: '#e8550f' }} className="text-sm m-0 p-1  text-white border border-orange-600 rounded-br-md">
                                    <strong>{MoneyFormatter(valorComDescontoAcessorios ?? 0)}</strong>
                                </p>
                            </label>
                            <label className="flex items-center m-2">
                                <p style={{ backgroundColor: '#494949' }} className="text-sm m-0 p-1  text-white border rounded-tl-md">
                                    <strong>Sinal</strong>
                                </p>
                                <p style={{ backgroundColor: '#e8550f' }} className="text-sm m-0 p-1  text-white border rounded-br-md">
                                    <strong>{MoneyFormatter(formBasico?.entrada ?? 0)}</strong>
                                </p>
                            </label>
                            <label className="flex items-center m-2">
                                <p style={{ backgroundColor: '#494949' }} className="text-sm m-0 p-1 text-white border rounded-tl-md">
                                    <strong>Desconto de Rodapé</strong>
                                </p>
                                <p style={{ backgroundColor: '#e8550f' }} className="text-sm m-0 p-1 text-white border rounded-br-md">
                                    <strong>{MoneyFormatter(formBasico?.desconto ?? 0)}</strong>
                                </p>
                            </label>
                            <label className="flex items-center m-2">
                                <p style={{ backgroundColor: '#494949' }} className="text-sm m-0 p-1 text-white border rounded-tl-md">
                                    <strong>Saldo Devedor</strong>
                                </p>
                                <p style={{ backgroundColor: '#e8550f' }} className="text-sm m-0 p-1 text-white border rounded-br-md">
                                    <strong>{MoneyFormatter(saldoDevedor ?? 0)}</strong>
                                </p>
                            </label>
                            <label className="flex items-center m-2 mr-4">
                                <p style={{ backgroundColor: '#494949' }} className="text-sm m-0 p-1 text-white border rounded-tl-md">
                                    <strong>Parcelamento</strong>
                                </p>
                                <p style={{ backgroundColor: '#e8550f' }} className="text-sm m-0 p-1 text-white border rounded-br-md">
                                    <strong>
                                        {formBasico?.quantidade_parcelas ?? '1'} x{' '}
                                        {formBasico?.valor_parcelas ? MoneyFormatter(formBasico?.valor_parcelas) : 'R$ 0,00'}
                                    </strong>
                                </p>
                            </label>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '15px' }}>
                            {currentPart !== 0 && (
                                <Button onClick={() => { handleBack() }} className="button-back-form" variant="primary">
                                    Voltar
                                </Button>
                            )}
                            {currentPart !== totalParts && (
                                <Button className="button-next-form" variant="primary" onClick={handleNext}>
                                    Próximo
                                </Button>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default CreateVendaAvulsa;
