import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function SepultamentoExumacao(props: any) {
    const [formData, setFormData] = useState<any>({});

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }
    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);
    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o inumado_id?</Form.Label>
                    <Form.Control
                        value={formData.inumado_id || ''}
                        onChange={(e) => handleFormChange('inumado_id', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o tipo_procedimento?</Form.Label>
                    <Form.Control
                        value={formData.tipo_procedimento || ''}
                        onChange={(e) => handleFormChange('tipo_procedimento', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o identificador_gaveta ?</Form.Label>
                    <Form.Control
                        value={formData.identificador_gaveta || ''}
                        onChange={(e) => handleFormChange('identificador_gaveta', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o data_procedimento?</Form.Label>
                    <Form.Control
                        value={formData.data_procedimento || ''}
                        onChange={(e) => handleFormChange('data_procedimento', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o id_externo?</Form.Label>
                    <Form.Control
                        value={formData.id_externo || ''}
                        onChange={(e) => handleFormChange('id_externo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o nome_requerente?</Form.Label>
                    <Form.Control
                        value={formData.nome_requerente || ''}
                        onChange={(e) => handleFormChange('nome_requerente', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o cpf_requerente?</Form.Label>
                    <Form.Control
                        value={formData.cpf_requerente || ''}
                        onChange={(e) => handleFormChange('cpf_requerente', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o rg_requerente?</Form.Label>
                    <Form.Control
                        value={formData.rg_requerente || ''}
                        onChange={(e) => handleFormChange('rg_requerente', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o jazigo_id?</Form.Label>
                    <Form.Control
                        value={formData.jazigo_id || ''}
                        onChange={(e) => handleFormChange('jazigo_id', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default SepultamentoExumacao;
