import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function InumadoImport(props: any) {
    const [formData, setFormData] = useState<any>({});

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }
    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);
    
    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o codiinum?</Form.Label>
                    <Form.Control
                        value={formData.codiinum || ''}
                        onChange={(e) => handleFormChange('codiinum', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datasepu?</Form.Label>
                    <Form.Control
                        value={formData.datasepu || ''}
                        onChange={(e) => handleFormChange('datasepu', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datafale?</Form.Label>
                    <Form.Control
                        value={formData.datafale || ''}
                        onChange={(e) => handleFormChange('datafale', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o numeroInterno?</Form.Label>
                    <Form.Control
                        value={formData.numeroInterno || ''}
                        onChange={(e) => handleFormChange('numeroInterno', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o locainum?</Form.Label>
                    <Form.Control
                        value={formData.locainum || ''}
                        onChange={(e) => handleFormChange('locainum', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o corpemba?</Form.Label>
                    <Form.Control
                        value={formData.corpemba || ''}
                        onChange={(e) => handleFormChange('corpemba', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datanasc?</Form.Label>
                    <Form.Control
                        value={formData.datanasc || ''}
                        onChange={(e) => handleFormChange('datanasc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o folhregi?</Form.Label>
                    <Form.Control
                        value={formData.folhregi || ''}
                        onChange={(e) => handleFormChange('folhregi', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o numeregi?</Form.Label>
                    <Form.Control
                        value={formData.numeregi || ''}
                        onChange={(e) => handleFormChange('numeregi', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o desccaus?</Form.Label>
                    <Form.Control
                        value={formData.desccaus || ''}
                        onChange={(e) => handleFormChange('desccaus', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o Peso?</Form.Label>
                    <Form.Control
                        value={formData.Peso || ''}
                        onChange={(e) => handleFormChange('Peso', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o rginum?</Form.Label>
                    <Form.Control
                        value={formData.rginum || ''}
                        onChange={(e) => handleFormChange('rginum', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o nomeinum?</Form.Label>
                    <Form.Control
                        value={formData.nomeinum || ''}
                        onChange={(e) => handleFormChange('nomeinum', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o sexoinum?</Form.Label>
                    <Form.Control
                        value={formData.sexoinum || ''}
                        onChange={(e) => handleFormChange('sexoinum', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o cpfinum?</Form.Label>
                    <Form.Control
                        value={formData.cpfinum || ''}
                        onChange={(e) => handleFormChange('cpfinum', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o maeinum?</Form.Label>
                    <Form.Control
                        value={formData.maeinum || ''}
                        onChange={(e) => handleFormChange('maeinum', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o paiinum?</Form.Label>
                    <Form.Control
                        value={formData.paiinum || ''}
                        onChange={(e) => handleFormChange('paiinum', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o estado_civil?</Form.Label>
                    <Form.Control
                        value={formData.estado_civil || ''}
                        onChange={(e) => handleFormChange('estado_civil', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o logradouro?</Form.Label>
                    <Form.Control
                        value={formData.logradouro || ''}
                        onChange={(e) => handleFormChange('logradouro', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o bairro?</Form.Label>
                    <Form.Control
                        value={formData.bairro || ''}
                        onChange={(e) => handleFormChange('bairro', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o origem?</Form.Label>
                    <Form.Control
                        value={formData.origem || ''}
                        onChange={(e) => handleFormChange('origem', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o cidade?</Form.Label>
                    <Form.Control
                        value={formData.cidade || ''}
                        onChange={(e) => handleFormChange('cidade', e.target.value)} />
                </Col>
                
            </Row>
        </>
    );
}

export default InumadoImport;
