import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import api from '../../../../services/api';
import { url, url_financeiro } from '../../../../environments/environments-develop';
import { showToast } from '../../../../components/ToastComponent';
import { InputNumber } from 'primereact/inputnumber';
import { AlertService } from '../../../../components/AlertService';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { TreeNode } from "primereact/treenode";
import { TreeSelect } from "primereact/treeselect";
import { addLocale } from 'primereact/api';
import AnexoForm from '../Formularios/AnexoForm';
import { searchCliente } from '../Service/MovCaixaService';
import ProgressBarClonar from '../../../Formularios/progressBarClonar';

interface PropsData {
    data: any;
    showModal: boolean;
    onHide: () => void;
    onInputChange: (field: string, value: any) => void;
}

function ModalClonar(props: PropsData) {
    const teste = { ...props.data };
    const [formData, setFormData] = useState<any>({});
    const [listPlanoConta, setListPlanoConta] = useState<TreeNode[]>([]);
    const [listCentroCusto, setListCentroCusto] = useState<TreeNode[]>([]);
    const [data_pagamento, setDataPagamento] = useState<Date | null>(null);
    const [data_vencimento, setDataVencimento] = useState<Date | null>(null);
    const [listContaFinanceira, setListContaFinanceira] = useState([]);
    const [FilteredCliente, setFilteredCliente] = useState<any>(null);

    useEffect(() => {
        setFormData((prevGrupoData: any) => {
            const updatedData = {
                ...prevGrupoData,
                ['tipo_documento']: teste?.tipo_documento,
                ['tipo']: teste?.tipo_enum?.data?.id,
                ['data_pagamento']: teste?.data_pagamento,
                ['data_vencimento']: teste?.data_vencimento,
                ['numero_documento']: teste?.numero_documento,
                ['descricao_caixa']: teste?.descricao_caixa,
                ['conta_financeiro_id']: teste?.conta_financeiro_id,
                ['plano_conta_id']: teste?.plano_conta_id,
                ['centro_custo']: teste?.centro_custo,
                ['valor_original']: teste?.valor_original,
                ['data_competencia']: teste?.data_competencia,
                ['justificativa']: teste?.justificativa,
                ['cliente_nome']: teste?.cliente_nome || teste?.nome,
                ['anexo']: [],
                ['empreendimento_id']: teste?.empreendimento_id,
            };
            if (teste.cliente_id) {
                updatedData['cliente_id'] = teste.cliente_id;
                delete updatedData['fornecedor_id'];
            } else if (teste.fornecedor_id) {
                updatedData['fornecedor_id'] = teste.fornecedor_id;
                delete updatedData['cliente_id'];
            }
            return updatedData;
        });
        setDataPagamento(teste.data_pagamento ? new Date(teste.data_pagamento) : null);
        setDataVencimento(teste.data_vencimento ? new Date(teste.data_vencimento) : null);
    }, [props.data]);

    async function InitContaFinanceira() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/conta-financeira`);
            setListContaFinanceira(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    };

    async function InitPlanoConta() {
        try {
            const includeParam = 'tipo_label,filhos.filhos.filhos.filhos.filhos.filhos';
            const response = await api.get(`${url}/api/v1/admin/financeiro/plano-conta?include=${includeParam}`);
            if (response.status === 200) {
                const data = response.data.data;

                const buildTreeNode = (item: any): TreeNode | null => {
                    if (item) {
                        const hasChildren = item.filhos?.data && item.filhos.data.length > 0;
                        return {
                            key: item.id.toString(),
                            label: item.nome || item.id.toString(),
                            data: item,
                            icon: hasChildren ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file',
                            children: hasChildren ? item.filhos.data.map((child: any) => buildTreeNode(child)) : null,
                            selectable: !hasChildren,
                        };
                    }
                    return null;
                };
                const nodesData: TreeNode[] = data
                    ? data.reduce((acc: TreeNode[], item: any) => {
                        const treeNode = buildTreeNode(item);
                        if (treeNode) {
                            acc.push(treeNode);
                        }
                        return acc;
                    }, [])
                    : [];
                setListPlanoConta(nodesData);
            }
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro: ' + String(error));
        }
    };

    async function InitCentroCusto() {
        try {
            const includeParam = 'tipo_label,filhos.filhos.filhos.filhos.filhos.filhos'
            const response = await api.get(`${url}/api/v1/admin/financeiro/centro-custo?include=${includeParam}`);
            if (response.status === 200) {
                const data = response.data.data;

                const buildTreeNode = (item: any): TreeNode | null => {
                    if (item) {
                        const hasChildren = item.filhos?.data && item.filhos.data.length > 0;
                        return {
                            key: item.id.toString(),
                            label: item.nome || item.id.toString(),
                            data: item,
                            icon: hasChildren ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file',
                            children: hasChildren ? item.filhos.data.map((child: any) => buildTreeNode(child)) : null,
                            selectable: !hasChildren,
                        };
                    }
                    return null;
                };
                const nodesData: TreeNode[] = data
                    ? data.reduce((acc: TreeNode[], item: any) => {
                        const treeNode = buildTreeNode(item);
                        if (treeNode) {
                            acc.push(treeNode);
                        }
                        return acc;
                    }, [])
                    : [];
                setListCentroCusto(nodesData);
            }
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    };

    useEffect(() => {
        InitContaFinanceira();
        InitPlanoConta();
        InitCentroCusto();
    }, []);

    const clonar = async () => {
        try {
            const response = await api.post(`${url_financeiro}/api/v1/admin/financeiro/lancamentofinanceiro`, {
                ...formData
            });
            if (response.data.error) {
                showToast("error", "Erro ao clonar característica.");
            } else {
                showToast("success", "A característica foi clonada com sucesso.");
                window.location.reload();
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            if (error?.response?.data?.error) {
                const errorData = error?.response?.data?.error;
                if (typeof errorData.message === 'string') {
                    mensagens.push(errorData.message);
                }
                else if (typeof errorData.message === 'object') {
                    for (const key in errorData.message) {
                        if (errorData.message.hasOwnProperty(key)) {
                            const messageArray = errorData.message[key];
                            if (Array.isArray(messageArray)) {
                                mensagens = mensagens.concat(messageArray);
                            }
                        }
                    }
                }
            }
            if (error?.response?.data?.message) {
                mensagens.push(error?.response?.data?.message);
            }
            AlertService.error("Erro!", mensagens.join("\n"));
        }
    };

    const handleChange = (field: string, value: any) => {
        setFormData((prevGrupoData: any) => ({
            ...prevGrupoData,
            [field]: value,
        }));

        if (field === 'data_pagamento') {
            setDataPagamento(value);
        } else if (field === 'data_vencimento') {
            setDataVencimento(value);
        }
    };

    const handleAnexoChange = (field: string, value: any) => {
        setFormData((prevGrupoData: any) => ({
            ...prevGrupoData,
            [field]: value,
        }));
    };

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 2;

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    const handleNext = () => {
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    return (
        <div>
            <Modal
                show={props.showModal}
                onHide={props.onHide}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Clone | Movimentação Financeira Id: {teste.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <ProgressBarClonar
                            currentPart={currentPart}
                            totalParts={totalParts}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            steps={["Lançamento", "Anexos"]}
                            body={formData}
                        >
                            {currentPart === 1 && (
                                <FormMovimentacaoFinanceira
                                    formData={formData}
                                    setFormData={handleChange}
                                    data_pagamento={data_pagamento}
                                    data_vencimento={data_vencimento}
                                    listContaFinanceira={listContaFinanceira}
                                    listPlanoConta={listPlanoConta}
                                    listCentroCusto={listCentroCusto}
                                    FilteredCliente={FilteredCliente}
                                    setFilteredCliente={setFilteredCliente}
                                />
                            )}
                            {currentPart === 2 && (
                                <AnexoForm
                                    Data={{ anexo: formData.anexo }}
                                    onInputChange={handleAnexoChange}
                                />

                            )}
                        </ProgressBarClonar>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={clonar}
                        className='button-end-form'
                        style={{ marginRight: '11px' }}
                    >
                        Confirmar
                    </Button>
                    <Button variant="danger" onClick={props.onHide}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalClonar;

const FormMovimentacaoFinanceira = (props: any) => {
    const {
        formData,
        setFormData,
        data_pagamento,
        data_vencimento,
        listContaFinanceira,
        listPlanoConta,
        listCentroCusto,
        FilteredCliente,
        setFilteredCliente
    } = props;

    return (
        <Form>
            <Row className="mb-3">
                <Col md="3">
                    <Form.Label>Cliente|Fornecedor</Form.Label>
                    <AutoComplete
                        field="nome"
                        style={{ height: "33px" }}
                        value={formData.cliente_nome}
                        suggestions={FilteredCliente}
                        completeMethod={(e) => searchCliente(e, setFilteredCliente)}
                        onChange={(e) => {
                            setFormData('cliente_nome', e.value ? e.value.nome : '');
                        }}
                        onSelect={(e) => {
                            if (e.value?.id) {
                                if (e.value?.cliente_id) {
                                    setFormData('cliente_id', e.value.id);
                                    setFormData('fornecedor_id', '');
                                } else if (e.value?.fornecedor_id) {
                                    setFormData('fornecedor_id', e.value.id);
                                    setFormData('cliente_id', '');
                                }
                            }
                            setFormData('cliente_nome', e.value.nome);
                        }}
                        dropdown
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Tipos de Documentos</Form.Label>
                    <Form.Select value={formData.tipo_documento} style={{ height: '33px' }} onChange={(e) => setFormData('tipo_documento', e.target.value)}>
                        <option>Selecione...</option>
                        <option value={0}>Nota Fiscal</option>
                        <option value={1}>Fatura</option>
                        <option value={2}>Recibo</option>
                        <option value={3}>Boleto</option>
                        <option value={4}>Comprovante de Despesa</option>
                        <option value={5}>Cheque</option>
                    </Form.Select>
                </Col>
                <Col md="3">
                    <Form.Label>Tipo de Lançamento</Form.Label>
                    <Form.Select isInvalid={!formData.tipo} style={{ height: '33px' }} value={formData.tipo} onChange={(e) => setFormData('tipo', e.target.value)}>
                        <option>Selecione...</option>
                        <option value={0}>Entrada</option>
                        <option value={1}>Saída</option>
                    </Form.Select>
                </Col>
                <Col md="3">
                    <Form.Label>Data do Movimento de Caixa</Form.Label>
                    <Calendar
                        value={data_pagamento}
                        onChange={(e) => setFormData('data_pagamento', e.value)}
                        placeholder="Data de pagamento"
                        showButtonBar
                        selectionMode="single"
                        dateFormat="dd/mm/yy"
                        locale="pt"
                        readOnlyInput
                        style={{ height: '33px' }}
                        panelClassName="custom-calendar-panel"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3">
                    <Form.Label>Data de Vencimento</Form.Label>
                    <Calendar
                        value={data_vencimento}
                        onChange={(e) => setFormData('data_vencimento', e.value)}
                        placeholder="Data de vencimento"
                        showButtonBar
                        selectionMode="single"
                        dateFormat="dd/mm/yy"
                        locale="pt"
                        readOnlyInput
                        style={{ height: '33px' }}
                        panelClassName="custom-calendar-panel"
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Número Documento</Form.Label>
                    <Form.Control value={formData.numero_documento || ''} onChange={(e) => setFormData('numero_documento', e.target.value)} style={{ height: '33px' }} />
                </Col>
                <Col md="3">
                    <Form.Label>Descrição de caixa</Form.Label>
                    <Form.Control style={{ height: '33px' }} placeholder="PGTO REF [Serviço/Produto] [VENC 99/99/9999] [PARC 1/9]" value={formData.descricao_caixa} onChange={(e) => setFormData('descricao_caixa', e.target.value)} />
                </Col>
                <Col md="3">
                    <Form.Label>Conta Financeira</Form.Label>
                    <Form.Select value={formData.conta_financeiro_id || ''} onChange={(e) => setFormData('conta_financeiro_id', e.target.value)} style={{ height: '33px' }}>
                        <option>Selecione...</option>
                        {listContaFinanceira?.map((item: any) => (
                            <option value={item.id} key={item.id}>
                                {item.nome}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3">
                    <Form.Label>Plano de Contas</Form.Label>
                    <TreeSelect
                        value={formData.plano_conta_id}
                        options={listPlanoConta}
                        onChange={(e) => setFormData('plano_conta_id', e.value)}
                        filter
                        selectionMode="single"
                        placeholder="Selecione um plano de conta"
                        display="chip"
                        className="w-full md:w-20rem"
                        style={{ height: '33px' }}
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Centro de Custo</Form.Label>
                    <TreeSelect
                        value={formData.centro_custo ? formData.centro_custo?.reduce((acc: any, item: any) => {
                            acc[item] = true;
                            return acc;
                        }, []) : []}
                        options={listCentroCusto}
                        onChange={(e) => {
                            const selectedValues: any = e.value || [];
                            let centros: any[] = [];
                            Object.keys(selectedValues).forEach((key) => {
                                centros.push(Number(key));
                            });
                            setFormData('centro_custo', centros);
                        }}
                        filter
                        placeholder="Selecione um centro de custo"
                        display="chip"
                        selectionMode="multiple"
                        className="w-full md:w-20rem"
                        style={{ height: '33px' }}
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Valor Total do Lançamento</Form.Label>
                    <InputNumber
                        invalid={!formData.valor_original}
                        value={typeof formData.valor_original === 'string'
                            ? (formData.valor_original ? parseFloat(formData.valor_original.replace(',', '.')) : null)
                            : (formData.valor_original ? formData.valor_original : null)}
                        onChange={(e) => setFormData('valor_original', e.value)}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        style={{ height: '33px' }}
                        locale="pt-BR"
                    />
                </Col>
                <Col md="3">
                    <Form.Label>Data de Competência</Form.Label>
                    <InputMask
                        mask="99/9999"
                        value={formData.data_competencia || ''}
                        onChange={(e) => setFormData('data_competencia', e.target.value)}
                        placeholder="xx/xxxx"
                        className="form-control"
                        style={{ height: '33px' }}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="9">
                    <Form.Label>Justificativa do Lançamento</Form.Label>
                    <Form.Control isInvalid={!formData.justificativa} value={formData.justificativa || ''} onChange={(e) => setFormData('justificativa', e.target.value)} style={{ height: '33px' }} />
                </Col>
            </Row>
        </Form>
    );
};