import { Col, NavLink } from 'react-bootstrap';
import { useAppContext } from '../../../contexts/AppContext';
import MapaLote from './Components/MapaLote';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
function MapaDisponibilidade() {
    const { showSubSidebar } = useAppContext();
    const { isVisible, updateVisibility } = useSidebarContext();
    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };
    return (
        < div className="body-content" >
            <div
                className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
            >
                <div className="contentSearch">
                    <div className="contentSearch-header">
                        <NavLink onClick={toggleVisibility}>
                            <img
                                style={{ width: '23px' }}
                                src={iconMenuTresPonto}
                            />
                        </NavLink>
                        <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Espelho de vendas</Col>
                    </div>
                    <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
                </div>
            </div>
            <MapaLote />
        </div>
    );
}

export default MapaDisponibilidade;
