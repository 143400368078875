import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function ClienteImport(props: any) {
    const [formData, setFormData] = useState<any>({});

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }
    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);
    
    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o codiclie?</Form.Label>
                    <Form.Control
                        value={formData.codiclie || ''}
                        onChange={(e) => handleFormChange('codiclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o sequcont?</Form.Label>
                    <Form.Control
                        value={formData.sequcont || ''}
                        onChange={(e) => handleFormChange('sequcont', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o coditija?</Form.Label>
                    <Form.Control
                        value={formData.coditija || ''}
                        onChange={(e) => handleFormChange('coditija', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datacont?</Form.Label>
                    <Form.Control
                        value={formData.datacont || ''}
                        onChange={(e) => handleFormChange('datacont', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o valocont?</Form.Label>
                    <Form.Control
                        value={formData.valocont || ''}
                        onChange={(e) => handleFormChange('valocont', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valosina?</Form.Label>
                    <Form.Control
                        value={formData.valosina || ''}
                        onChange={(e) => handleFormChange('valosina', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o carevalo?</Form.Label>
                    <Form.Control
                        value={formData.carevalo || ''}
                        onChange={(e) => handleFormChange('carevalo', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o caretemp?</Form.Label>
                    <Form.Control
                        value={formData.caretemp || ''}
                        onChange={(e) => handleFormChange('caretemp', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o codirepr?</Form.Label>
                    <Form.Control
                        value={formData.codirepr || ''}
                        onChange={(e) => handleFormChange('codirepr', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o funcrepr?</Form.Label>
                    <Form.Control
                        value={formData.funcrepr || ''}
                        onChange={(e) => handleFormChange('funcrepr', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o dataentr?</Form.Label>
                    <Form.Control
                        value={formData.dataentr || ''}
                        onChange={(e) => handleFormChange('dataentr', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o statcont?</Form.Label>
                    <Form.Control
                        value={formData.statcont || ''}
                        onChange={(e) => handleFormChange('statcont', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o usoimed?</Form.Label>
                    <Form.Control
                        value={formData.usoimed || ''}
                        onChange={(e) => handleFormChange('usoimed', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o tipocont?</Form.Label>
                    <Form.Control
                        value={formData.tipocont || ''}
                        onChange={(e) => handleFormChange('tipocont', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datareto?</Form.Label>
                    <Form.Control
                        value={formData.datareto || ''}
                        onChange={(e) => handleFormChange('datareto', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o usomodo?</Form.Label>
                    <Form.Control
                        value={formData.usomodo || ''}
                        onChange={(e) => handleFormChange('usomodo', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o codiclas?</Form.Label>
                    <Form.Control
                        value={formData.codiclas || ''}
                        onChange={(e) => handleFormChange('codiclas', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o Competencia?</Form.Label>
                    <Form.Control
                        value={formData.Competencia || ''}
                        onChange={(e) => handleFormChange('Competencia', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o mesVencimento?</Form.Label>
                    <Form.Control
                        value={formData.mesVencimento || ''}
                        onChange={(e) => handleFormChange('mesVencimento', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o padraoVencimentoManutencao?</Form.Label>
                    <Form.Control
                        value={formData.padraoVencimentoManutencao || ''}
                        onChange={(e) => handleFormChange('padraoVencimentoManutencao', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o COMISSAO?</Form.Label>
                    <Form.Control
                        value={formData.COMISSAO || ''}
                        onChange={(e) => handleFormChange('COMISSAO', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o TipoVenda?</Form.Label>
                    <Form.Control
                        value={formData.TipoVenda || ''}
                        onChange={(e) => handleFormChange('TipoVenda', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codicoja?</Form.Label>
                    <Form.Control
                        value={formData.codicoja || ''}
                        onChange={(e) => handleFormChange('codicoja', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codijazi?</Form.Label>
                    <Form.Control
                        value={formData.codijazi || ''}
                        onChange={(e) => handleFormChange('codijazi', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o datamarc?</Form.Label>
                    <Form.Control
                        value={formData.datamarc || ''}
                        onChange={(e) => handleFormChange('datamarc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o dia_pagamento</Form.Label>
                    <Form.Control
                        value={formData.dia_pagamento || ''}
                        onChange={(e) => handleFormChange('dia_pagamento', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o numero_parcela</Form.Label>
                    <Form.Control
                        value={formData.numero_parcela || ''}
                        onChange={(e) => handleFormChange('numero_parcela', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default ClienteImport;
