import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface PropsData {
    data: any;
    showModal: boolean;
    onHide: () => void;
    onInputChange: (field: string, value: any) => void;
}

function ModalShowImportacao(props: PropsData) {
    const { data } = props;

    function formatDateTime(value: any) {
        return new Date(value).toLocaleString('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    }

    return (
        <div>
            <Modal
                show={props.showModal}
                onHide={props.onHide}
                size='xl'
                className="modal-input-venda"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes da Importação #{data?.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Label>Id</Form.Label>
                            <Form.Control readOnly value={data?.id || 'N/A'} />
                        </Col>
                        <Col>
                            <Form.Label>Nome do Empreendimento</Form.Label>
                            <Form.Control readOnly value={data?.empreendimento_name || 'N/A'} />
                        </Col>
                        <Col>
                            <Form.Label>Nome da Filial</Form.Label>
                            <Form.Control readOnly value={data?.filial_name || 'N/A'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Usuário</Form.Label>
                            <Form.Control readOnly value={data?.user_name || 'N/A'} />
                        </Col>
                        <Col>
                            <Form.Label>Status</Form.Label>
                            <Form.Control readOnly value={data?.status_label?.data?.descricao || 'N/A'} />
                        </Col>
                        <Col>
                            <Form.Label>Modo de Importação</Form.Label>
                            <Form.Control readOnly value={data?.modo_import_label?.data?.descricao || 'N/A'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Data e Horário de Início</Form.Label>
                            <Form.Control readOnly value={formatDateTime(data?.start_data_import) || 'N/A'} />
                        </Col>
                        <Col>
                            <Form.Label>Data e Horário de Término</Form.Label>
                            <Form.Control readOnly value={formatDateTime(data?.end_data_import) || 'N/A'} />
                        </Col>
                        <Col>
                            <Form.Label>Local de Importação</Form.Label>
                            <Form.Control readOnly value={data?.localImport_label?.data?.descricao || 'N/A'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Log de Sucesso</Form.Label>
                            <Form.Control as="textarea" rows={4} readOnly value={data?.log_sucess || 'N/A'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Arquivo Original</Form.Label>
                            <Form.Control readOnly value={data?.file_original || 'N/A'} />
                        </Col>
                        <Col>
                            <Form.Label>Extensão do Arquivo</Form.Label>
                            <Form.Control readOnly value={data?.extension_file_original || 'N/A'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Erros</Form.Label>
                            <Form.Control as="textarea" rows={3} readOnly value={data?.errors.join(', ') || 'Nenhum erro registrado'} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={props.onHide}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalShowImportacao;
