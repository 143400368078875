import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import GroupForm from './Formularios/groupPermission';
import { AlertService } from '../../../components/AlertService';
interface PropsGrupoData {
    nome?: string;
    descricao?: string;
    padrao?: boolean;
}
function EditGroup() {
    const { setUrl, setBody, setShowButtonSave, setTypeRequest, setItemsBreadsCrumbs } = useAppContext();
    const [grupoData, setGrupoData] = useState<PropsGrupoData>({});

    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Configurações',
                    url: '/configuracoes',
                },
                {
                    label: 'Grupo de Permissões',
                    url: '/grupo',
                },
                {
                    label: 'Editar'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/core/grupo/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);
    useEffect(() => {
        setBody(grupoData)
    }, [grupoData]);

    const handleChange = (field: string, value: string) => {
        setGrupoData((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setTypeRequest('PUT');
    }
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar Grupo de permissões</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <GroupForm
                            initialRightValues={grupoData}
                            grupoData={grupoData}
                            onInputChange={(field: string, value: any) => handleChange(field, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default EditGroup;
