import { Col, Form, Row } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { booleanOptions, handleInputChange, imovelVisibilidadeOptions, searchCliente, searchCorretor, statusImovel } from "../Service/ImovelService";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

interface IdentificacaoFormProps {
    formData: any,
    tipoImovel: any,
    subTipoImovel: any,
    FilteredPessoa: any,
    FilteredCorretor: any,
    setFilteredPessoa: (value: any) => void,
    setFormData: (value: any) => void,
    setFilteredCorretor: (value: any) => void,
}
function IdentificacaoForm({
    formData,
    tipoImovel,
    subTipoImovel,
    FilteredPessoa,
    FilteredCorretor,
    setFilteredPessoa,
    setFormData,
    setFilteredCorretor,
}: IdentificacaoFormProps) {

    return (
        <fieldset className="w-full border p-3 rounded mb-4" >
            <legend className="font-bold text-lg">Identificação</legend>
            <div className="grid grid-cols-6 gap-4">
                <div className="flex flex-col">
                    <label htmlFor="identificador1" className="mb-1 font-medium">Torre, Bloco, Quadra, Loteamento</label>
                    <InputText
                        id="identificador1"
                        value={formData?.identificador1}
                        onChange={(e) => handleInputChange("identificador1", e.target.value, setFormData)}
                        placeholder="Digite o identificador"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="identificador2" className="mb-1 font-medium">No Lote, No Apto</label>
                    <InputText
                        id="identificador2"
                        value={formData?.identificador2}
                        onChange={(e) => handleInputChange("identificador2", e.target.value, setFormData)}
                        placeholder="Digite o identificador 2"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="unidade" className="mb-1 font-medium">Unidade</label>
                    <InputText
                        id="unidade"
                        invalid={!formData?.unidade}
                        value={formData?.unidade}
                        onChange={(e) => handleInputChange("unidade", e.target.value, setFormData)}
                        placeholder="Digite a unidade"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="titulo_reduzido" className="mb-1 font-medium">Título Reduzido</label>
                    <InputText
                        id="titulo_reduzido"
                        invalid={!formData?.titulo_reduzido}
                        value={formData?.titulo_reduzido}
                        onChange={(e) => handleInputChange("titulo_reduzido", e.target.value, setFormData)}
                        placeholder="Digite o título reduzido"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="id_proprietario" className="mb-1 font-medium">Proprietário</label>
                    <AutoComplete
                        id="id_proprietario"
                        invalid={!formData?.proprietario_id}
                        field="nome_cliente"
                        value={formData?.nome_cliente ? formData?.nome_cliente : (formData?.cliente ? formData?.cliente?.nome_cliente : '')}
                        suggestions={FilteredPessoa}
                        completeMethod={(e) => searchCliente(e.query, setFilteredPessoa)}
                        onChange={(e) => {
                            handleInputChange('nome_cliente', e.value, setFormData);
                        }}
                        onSelect={(e) => {
                            handleInputChange('proprietario_id', e.value.id, setFormData);
                            handleInputChange('nome_cliente', e.value, setFormData);
                        }}
                        dropdown
                        style={{ height: "37px", width: "100%" }}
                        placeholder="Selecione um proprietário"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="id_antigo" className="mb-1 font-medium">ID Externo</label>
                    <InputText
                        id="id_antigo"
                        value={formData?.id_antigo}
                        onChange={(e) => handleInputChange("id_antigo", e.target.value, setFormData)}
                        placeholder="Digite o ID antigo"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="pasta" className="mb-1 font-medium">Pasta</label>
                    <InputText
                        id="pasta"
                        value={formData?.pasta}
                        onChange={(e) => handleInputChange("pasta", e.target.value, setFormData)}
                        placeholder="Digite o nome da pasta"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="id_captador" className="mb-1 font-medium">Captador</label>
                    <AutoComplete
                        id="id_captador"
                        invalid={!formData?.captador_id}
                        field="nome_corretor"
                        value={formData?.busca_captador || formData?.nome_captador}
                        suggestions={FilteredCorretor}
                        completeMethod={(e) => {
                            searchCorretor(e, 1, setFilteredCorretor);
                        }}
                        onChange={(e) => {
                            handleInputChange('busca_captador', e.value, setFormData);
                        }}
                        onSelect={(e) => {
                            handleInputChange('nome_captador', e.value.nome_corretor, setFormData);
                            handleInputChange('captador_id', e.value.id, setFormData);
                        }}
                        dropdown
                        style={{ height: "37px", width: "100%" }}
                        placeholder="Selecione um captador"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="id_gerente" className="mb-1 font-medium">Gerente</label>
                    <AutoComplete
                        id="id_gerente"
                        field="nome_corretor"
                        value={formData?.busca_gerente || formData?.nome_gerente}
                        suggestions={FilteredCorretor}
                        completeMethod={(e) => {
                            searchCorretor(e, 1, setFilteredCorretor);
                        }}
                        onChange={(e) => {
                            handleInputChange('busca_gerente', e.value, setFormData);
                        }}
                        onSelect={(e) => {
                            handleInputChange('nome_gerente', e.value.nome_corretor, setFormData);
                            handleInputChange('gerente_id', e.value.id, setFormData);
                        }}
                        dropdown
                        style={{ height: "37px", width: "100%" }}
                        placeholder="Selecione um gerente"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="id_corretor_venda" className="mb-1 font-medium">Corretor Venda</label>
                    <AutoComplete
                        id="id_corretor_venda"
                        field="nome_corretor"
                        value={formData?.busca_corretor || formData?.corretor_nome}
                        suggestions={FilteredCorretor}
                        completeMethod={(e) => {
                            searchCorretor(e, 1, setFilteredCorretor);
                        }}
                        onChange={(e) => {
                            handleInputChange('busca_corretor', e.value, setFormData);
                        }}
                        onSelect={(e) => {
                            handleInputChange('corretor_nome', e.value.nome_corretor, setFormData);
                            handleInputChange('corretor_venda_id', e.value.id, setFormData);
                        }}
                        dropdown
                        style={{ height: "37px", width: "100%" }}
                        placeholder="Selecione um corretor"
                    />
                </div>

                <div className="flex flex-col">
                    <label htmlFor="captacao_tipo" className="mb-1 font-medium">Captacao Tipo</label>
                    <Form.Select
                        id="captacao_tipo"
                        value={formData?.captacao_tipo}
                        isInvalid={!formData?.captacao_tipo}
                        onChange={(e) => handleInputChange("captacao_tipo", e.target.value, setFormData)}
                    >
                        <option value="">Selecione...</option>
                        <option value={1}>Revenda</option>
                        <option value={3}>Revenda & Locação</option>
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="captacao_vencimento" className="mb-1 font-medium">Captacao Vencimento</label>
                    <Calendar
                        id="captacao_vencimento"
                        value={formData?.captacao_vencimento ? new Date(formData?.captacao_vencimento) : null}
                        onChange={(event: any) => {
                            const selectedDates = event.value;
                            handleInputChange("captacao_vencimento", selectedDates, setFormData);
                        }}
                        placeholder="Selecione a data"
                        showButtonBar
                        selectionMode="single"
                        dateFormat="dd/mm/yy"
                        locale="pt"
                        readOnlyInput
                        style={{ textAlign: 'center', width: '100%' }}
                        panelClassName="custom-calendar-panel"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="imovel_visibilidade" className="mb-1 font-medium">Visibilidade do Imóvel</label>
                    <Form.Select
                        value={formData?.imovel_visibilidade}
                        onChange={(e) => handleInputChange("imovel_visibilidade", e.target.value, setFormData)}
                    >
                        {imovelVisibilidadeOptions.map((option) =>
                            <option value={String(option.value)}>{option.label}</option>
                        )}
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="tipo_imovel_id" className="mb-1 font-medium">Tipo de Imóvel</label>
                    <Dropdown
                        id="tipo_imovel_id"
                        name="nome"
                        invalid={!formData?.tipo_imovel_id}
                        value={formData?.tipo_imovel_id}
                        options={tipoImovel.map((tipo: any) => ({ label: tipo.nome, value: tipo.id }))}
                        onChange={(e) => handleInputChange("tipo_imovel_id", e.value, setFormData)}
                        placeholder="Selecione o tipo de imóvel"
                    />

                </div>
                <div className="flex flex-col">
                    <label htmlFor="sub_tipo_imovel_id" className="mb-1 font-medium">Subtipo de Imóvel</label>
                    <Dropdown
                        id="sub_tipo_imovel_id"
                        name="nome"
                        invalid={!formData?.sub_tipo_imovel_id}
                        value={formData?.sub_tipo_imovel_id}
                        options={subTipoImovel.map((tipo: any) => ({ label: tipo.nome, value: tipo.id }))}
                        onChange={(e) => handleInputChange("sub_tipo_imovel_id", e.value, setFormData)}
                        placeholder="Selecione o subtipo de imóvel"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="status" className="mb-1 font-medium">Status</label>
                    <Form.Select
                        id="status"
                        value={formData?.status}
                        isInvalid={!formData?.status}
                        onChange={(e) => handleInputChange("status", e.target.value, setFormData)}
                    >
                        <option value="">Selecione...</option>
                        {statusImovel.map((status) =>
                            <option value={status.value}>{status.label}</option>
                        )}
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="exclusividade" className="mb-1 font-medium">Exclusividade</label>
                    <Form.Select
                        id="exclusividade"
                        value={formData?.exclusividade}
                        isInvalid={!formData?.exclusividade}
                        onChange={(e) => handleInputChange("exclusividade", e.target.value, setFormData)}
                    >
                        <option value="">Selecione...</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="imovel_novo" className="mb-1 font-medium">Imóvel Novo</label>
                    <Form.Select
                        value={formData?.imovel_novo}
                        isInvalid={!formData?.imovel_novo}
                        onChange={(e) => handleInputChange("imovel_novo", e.target.value, setFormData)}
                    >
                        <option value="">Selecione...</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="iptu_insc" className="mb-1 font-medium">Inscrição do IPTU</label>
                    <InputText
                        id="iptu_insc"
                        value={formData?.iptu_insc}
                        onChange={(e) => handleInputChange("iptu_insc", e.target.value, setFormData)}
                        placeholder="Digite a inscrição do IPTU"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="agua_ligada" className="mb-1 font-medium">Água Ligada</label>
                    <Form.Select
                        id="agua_ligada"
                        value={formData?.agua_ligada}
                        isInvalid={!formData?.agua_ligada}
                        onChange={(e) => handleInputChange("agua_ligada", e.target.value, setFormData)}
                    >
                        <option value="">Selecione...</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="agua_venc" className="mb-1 font-medium">Vencimento da Água</label>
                    <InputNumber
                        id="agua_venc"
                        value={formData?.agua_venc}
                        onChange={(e) => handleInputChange("agua_venc", e.value, setFormData)}
                        placeholder="Digite o vencimento da água"
                        mode="decimal"
                        min={1}
                        max={31}
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="agua_insc" className="mb-1 font-medium">Inscrição da Água</label>
                    <InputText
                        id="agua_insc"
                        value={formData?.agua_insc}
                        onChange={(e) => handleInputChange("agua_insc", e.target.value, setFormData)}
                        placeholder="Digite a inscrição da água"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="agua_titular" className="mb-1 font-medium">Titular da Água</label>
                    <InputText
                        id="agua_titular"
                        value={formData?.agua_titular}
                        onChange={(e) => handleInputChange("agua_titular", e.target.value, setFormData)}
                        placeholder="Digite o titular da água"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="energia_ligada" className="mb-1 font-medium">Energia Ligada</label>
                    <Form.Select
                        value={formData?.energia_ligada}
                        onChange={(e) => handleInputChange("energia_ligada", e.target.value, setFormData)}
                    >
                        {booleanOptions.map((option) =>
                            <option value={String(option.value)}>{option.label}</option>
                        )}
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="energia_venc" className="mb-1 font-medium">Vencimento da Energia</label>
                    <InputNumber
                        id="energia_venc"
                        value={formData?.energia_venc}
                        onChange={(e) => handleInputChange("energia_venc", e.value, setFormData)}
                        placeholder="Digite o vencimento da energia"
                        mode="decimal"
                        min={1}
                        max={31}
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="energia_insc" className="mb-1 font-medium">Inscrição da Energia</label>
                    <InputText
                        id="energia_insc"
                        value={formData?.energia_insc}
                        onChange={(e) => handleInputChange("energia_insc", e.target.value, setFormData)}
                        placeholder="Digite a inscrição da energia"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="energia_titular" className="mb-1 font-medium">Titular da Energia</label>
                    <InputText
                        id="energia_titular"
                        value={formData?.energia_titular}
                        onChange={(e) => handleInputChange("energia_titular", e.target.value, setFormData)}
                        placeholder="Digite o titular da energia"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="gas_ligado" className="mb-1 font-medium">Gás Ligado</label>
                    <Form.Select
                        value={formData?.gas_ligado}
                        onChange={(e) => handleInputChange("gas_ligado", e.target.value, setFormData)}
                    >
                        {booleanOptions.map((option) =>
                            <option value={String(option.value)}>{option.label}</option>
                        )}
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="gas_insc" className="mb-1 font-medium">Inscrição da Conta de Gás</label>
                    <InputText
                        id="gas_insc"
                        value={formData?.gas_insc}
                        onChange={(e) => handleInputChange("gas_insc", e.target.value, setFormData)}
                        placeholder="Digite a inscrição da conta de gás"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="hidrometro_individual" className="mb-1 font-medium">Hidrometro Individual</label>
                    <Form.Select
                        value={formData?.hidrometro_individual}
                        onChange={(e) => handleInputChange("hidrometro_individual", e.target.value, setFormData)}
                    >
                        {booleanOptions.map((option) =>
                            <option value={String(option.value)}>{option.label}</option>
                        )}
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="numero_chaves" className="mb-1 font-medium">Número de Chaves</label>
                    <InputNumber
                        id="numero_chaves"
                        value={formData?.numero_chaves}
                        onValueChange={(e) => handleInputChange("numero_chaves", e.value, setFormData)}
                        placeholder="Digite o número de chaves"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="descricao_chaves" className="mb-1 font-medium">Descrição das Chaves</label>
                    <InputText
                        id="descricao_chaves"
                        value={formData?.descricao_chaves}
                        onChange={(e) => handleInputChange("descricao_chaves", e.target.value, setFormData)}
                        placeholder="Digite a descrição das chaves"
                    />
                </div>
            </div>
        </fieldset >
    );
}

export default IdentificacaoForm;
