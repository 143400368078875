import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar } from 'primereact/calendar';

interface ModalRescisaoProps {
    showModal: boolean;
    onHide: () => void;
    data: any;
}

const ModalRescisao: React.FC<ModalRescisaoProps> = ({
    showModal,
    onHide,
    data
}) => {

    return (
        <Modal
            show={showModal}
            size='lg'
            onHide={() => onHide()}
            className='modal-filtro-avancado'
        >
            <Modal.Header closeButton>
                <Modal.Title>Rescisão do Contrato</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form.Group className="imovel-form">
                        <Row className='mb-3'>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Data do Aviso</Form.Label>
                                <Calendar
                                    id="data_aviso"
                                    disabled
                                    dateFormat="dd/mm/yy"
                                    panelClassName="custom-calendar-panel"
                                    placeholder='Selecione a data'
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Consultar</p>
                </Button>
              
            </Modal.Footer>
        </Modal>
    );
};

export default ModalRescisao;
