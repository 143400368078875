import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import { AlertService } from '../../../components/AlertService';
import CaracteristicaFormShow from './Formularios/CaracteristicaFormShow';

interface PropsData {
    codigo?: string;
    nome?: string;
    tipo?: string;
    parent_id?: string;
    status?: boolean;
    recebe_lancamento?: boolean;
    contabil?: boolean;
}

function CaracteristicaShow() {
    const { setUrl, setBody, setShowButtonSave, setTypeRequest, setItemsBreadsCrumbs } = useAppContext();
    const [Data, setData] = useState<PropsData>({});

    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Revenda',
                    url: '/revenda',
                },
                {
                    label: 'Caracteristicas do imóvel',
                    url: '/revenda/caracteristicas',
                },
                {
                    label: 'Detalhes'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setData((prevGrupoData) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...JSON.parse(storedData),
                        cliente_compradores: JSON.parse(storedData).cliente_compradores?.data
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/vendas/caracteristicas-imoveis-dinamicas/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);
    useEffect(() => {
        setBody(Data)
    }, [Data]);

    const handleChange = (field: string, value: string) => {
        setData((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setTypeRequest('PUT');
    }

    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Detalhes do Contrato</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <CaracteristicaFormShow
                            initialRightValues={Data}
                            Data={Data}
                            onInputChange={(field: string, value: any) => handleChange(field, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CaracteristicaShow;
