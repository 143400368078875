import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { showToast } from '../../../../components/ToastComponent';
import { InputNumber } from 'primereact/inputnumber';
import TokenService from '../../../../services/Auth/token.service';
import { AlertService } from '../../../../components/AlertService';

interface PropsData {
    data: any;
    showModal: boolean;
    onHide: () => void;
    onInputChange: (field: string, value: any) => void;
}

function ModalClonar(props: PropsData) {
    const [identificador, setIdentificador] = useState('');
    const teste = { ...props.data };
    const [formData, setFormData] = useState<any>({});
    const [tipoImovel, setTipoImovel] = useState<any>([]);
    const [subTipoImovel, setSubTipoImovel] = useState<any>([]);

    useEffect(() => {
        setFormData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ['tipo_caracteristica']: teste.tipo_caracteristica || '',
                ['descricao']: teste.descricao || '',
                ['nome_campos']: teste.nome_campos || '',
                ['tipo_dado']: teste.tipo_dado || '',
                ['tag']: teste.tag || '',
                ['icone']: teste.icone || '',
                ['tipo_imovel_id']: teste.tipo_imovel_id || '',
                ['sub_tipo_imovel_id']: teste.sub_tipo_imovel_id || '',
            };
        });
    }, [props.data]);


    const clonar = async () => {
        try {
            const response = await api.post(`${url}/api/v1/admin/vendas/caracteristicas-imoveis-dinamicas`, {
                ...formData,
            });
            const toast = useRef<Toast>();
            if (response.data.error) {
                showToast("error", "Erro ao clonar característica.");
            }
        } catch (success) {
            showToast("success", "A Característicafoi clonado com sucesso.");
            window.location.reload();
        }
    }

    const handleChange = (field: string, value: any) => {
        setFormData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const consulta: any = {
                    consulta: {
                        order: "id;desc",
                        filtro: {
                            "empreendimento_id": TokenService.getEmpreendimentoId()
                                ? TokenService.getEmpreendimentoId().id
                                : null
                        }
                    }
                };
                const queryParams: any = {};
                const camposEnviar = ["consulta"];
                camposEnviar.forEach((key: any) => {
                    const value = consulta[key];
                    if (value !== undefined && value !== null && value !== "") {
                        queryParams[key] = key === "consulta" ? JSON.stringify(value) : value;
                    }
                });
                const queryString = Object.entries(queryParams)
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
                    .join("&");

                const [responseTiposImovel, responseSubtiposImovel] = await Promise.all([
                    api.get(`${url}/api/v1/admin/vendas/tipoimovel?${queryString}`),
                    api.get(`${url}/api/v1/admin/vendas/subtipoimovel?${queryString}`)
                ]);
                if (responseTiposImovel.status === 200) setTipoImovel(responseTiposImovel.data.data);
                if (responseSubtiposImovel.status === 200) setSubTipoImovel(responseSubtiposImovel.data.data);
            } catch (error) {
                AlertService.error("Erro!", "Erro desconhecido");
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            <Modal
                show={props.showModal}
                onHide={props.onHide}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Clone | Característica: #{teste.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="forms">
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Nome Campo</Form.Label>
                                <Form.Control
                                    value={formData.nome_campos}
                                    onChange={(e) => handleChange("nome_campos", e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control
                                    value={formData.descricao}
                                    onChange={(e) => handleChange("descricao", e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Tipo de Dado</Form.Label>
                                <Form.Select
                                    value={formData.tipo_dado}
                                    onChange={(e) => handleChange("tipo_dado", e.target.value)}
                                >
                                    <option>Selecione...</option>
                                    <option value={1}>Texto</option>
                                    <option value={2}>Tag</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Tipo Imóvel</Form.Label>
                                <Form.Select
                                    value={formData.tipo_imovel_id}
                                    onChange={(e) => handleChange("tipo_imovel_id", e.target.value)}
                                >
                                    <option>Selecione...</option>
                                    {tipoImovel.map((tipoimovel: any) => (
                                        <option key={tipoimovel.id} value={tipoimovel.id}>
                                            {tipoimovel.nome}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>SubTipo Imóvel</Form.Label>
                                <Form.Select
                                    value={formData.sub_tipo_imovel_id}
                                    onChange={(e) => handleChange("sub_tipo_imovel_id", e.target.value)}
                                >
                                    <option>Selecione...</option>
                                    {subTipoImovel.map((subtipo: any) => (
                                        <option key={subtipo.id} value={subtipo.id}>
                                            {subtipo.nome}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Tipo de Característica</Form.Label>
                                <Form.Select
                                    value={formData.tipo_caracteristica || ""}
                                    onChange={(e) => handleChange("tipo_caracteristica", e.target.value)}
                                >
                                    <option>Selecione...</option>
                                    <option value={1}>Imóvel</option>
                                    <option value={2}>Condomínio</option>
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Tag</Form.Label>
                                <Form.Control
                                    value={formData.tag}
                                    onChange={(e) => handleChange("tag", e.target.value)}
                                    placeholder="<%nome%>"
                                />
                            </Col>
                            <Col>
                                <Form.Label>Ícone</Form.Label>
                                <Form.Control
                                    value={formData.icone}
                                    onChange={(e) => handleChange("icone", e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={clonar}
                        className='button-end-form'
                        style={{ marginRight: '11px' }}
                    >
                        Confirmar
                    </Button>
                    <Button variant="danger" onClick={props.onHide}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalClonar;