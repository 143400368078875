import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Container } from "react-bootstrap";
import { useAppContext } from "../../../contexts/AppContext";
import { url } from "../../../environments/environments-develop";
import TokenService from "../../../services/Auth/token.service";
import { fetchData, handleAddImage, handleDeleteImage, handleExpandImage, handleInputChange, InitCaracteristicas } from "./Service/ImovelService";
import IdentificacaoForm from "./Formularios/IdentificacaoForm";
import DimensoesForm from "./Formularios/DimensoesForm";
import TextualForm from "./Formularios/TextualForm";
import LocalizacaoForm from "./Formularios/LocalizacaoForm";
import TempoDataForm from "./Formularios/TempoDataForm";
import ValoresCondicoesForm from "./Formularios/ValoresCondicoesForm";
import AspectoLegaisForm from "./Formularios/AspectoLegaisForm";
import CarouselImagemForm from "./Formularios/CarouselImagemForm";
import CaracteristicasListagem from "./Formularios/CaracteristicasListagem";
import { addLocale } from "primereact/api";

interface ImageItem {
    id: number;
    url: string;
}

function EditLocacaoImoveis() {
    const [images, setImages] = useState<ImageItem[]>([]);
    const [nextId, setNextId] = useState(1);
    const [FilteredBanco, setFilteredBanco] = useState<any>(null);
    const { setUrl, setBody, setShowButtonSave, setItemsBreadsCrumbs, setTypeRequest, setValidationRequest } = useAppContext();
    const [formData, setFormData] = useState<any | null>({
        imoveis_caracteristicas: [],
        enderecos: [],
        empreendimento_id: TokenService.getEmpreendimentoId()?.id,
    });
    const [FilteredCorretor, setFilteredCorretor] = useState([]);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    const [listCaracteristicas, setListCaracteristicas] = useState<any[]>([]);
    const [tipoImovel, setTipoImovel] = useState<any>([]);
    const [subTipoImovel, setSubTipoImovel] = useState<any>([]);
    const [anexo, setAnexos] = useState<any[]>([]);

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Revenda',
                url: '/revenda',
            },
            {
                label: 'Lista de Imóveis',
                url: '/imovel',
            },
            {
                label: 'Editar'
            }
        ]);
        const storedData = localStorage.getItem('dadosEdicao');
        if (storedData) {
            const data = JSON.parse(storedData);
            setFormData(data);
            setFormData((data: any) => {
                return {
                    ...data,
                    ...JSON.parse(storedData),
                    enderecos: JSON.parse(storedData)?.enderecos?.length > 0 ? [...JSON.parse(storedData)?.enderecos?.data] : [],
                    imoveis_caracteristicas: JSON.parse(storedData)?.imoveis_caracteristicas?.length > 0 ? [...JSON.parse(storedData)?.imoveis_caracteristicas?.data] : []
                }
            });
            setTypeRequest('PUT');
            setUrl(`${url}/api/v1/admin/vendas/imoveis-captados/${data.id}`);
        }
        InitCaracteristicas(setListCaracteristicas);
        fetchData(setTipoImovel, setSubTipoImovel);
        setShowButtonSave(true);
    }, []);

    useEffect(() => {
        if (
            formData?.unidade &&
            formData?.titulo_reduzido &&
            formData?.proprietario_id &&
            formData?.captador_id &&
            formData?.captacao_tipo &&
            formData?.tipo_imovel_id &&
            formData?.sub_tipo_imovel_id &&
            formData?.status &&
            formData?.exclusividade &&
            formData?.imovel_novo &&
            formData?.area_util &&
            formData?.area_construida &&
            formData?.titulo &&
            formData?.subtitulo &&
            formData?.descricao_detalhada &&
            formData?.descricao_curta &&
            formData?.valor_atual_imovel &&
            formData?.valor_venda_revenda &&
            formData?.aceita_financiamento &&
            formData?.enderecos?.data?.[0]?.cep &&
            formData?.enderecos?.data?.[0]?.logradouro &&
            formData?.enderecos?.data?.[0]?.cidade_id &&
            formData?.enderecos?.data?.[0]?.numero &&
            formData?.enderecos?.data?.[0]?.tipo_endereco &&
            formData?.imoveis_caracteristicas_imovel?.data?.length > 0
        ) {
            setValidationRequest(true);
        }
        setBody(formData);
    }, [formData]);

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    useEffect(() => {
        handleInputChange('imagens', anexo, setFormData);
    }, [anexo]);

    const imageTemplate = (item: ImageItem) => (
        <div className="image-container">
            <img
                src={item.url}
                alt={`Imagem ${item.id}`}
                className="w-full h-auto rounded-lg shadow-md"
            />
            <div className="overlay">
                <Button
                    icon="pi pi-plus"
                    className="overlay-button add p-button-rounded p-button-success p-button-sm m-2"
                    onClick={() => document.getElementById(`fileInput-${item.id}`)?.click()}
                />
                <Button
                    icon="pi pi-trash"
                    className="overlay-button delete p-button-rounded p-button-danger p-button-sm m-2"
                    onClick={() => handleDeleteImage(item.id, setImages, setAnexos)}
                />
                <Button
                    icon="pi pi-search"
                    className="overlay-button expand p-button-rounded p-button-info p-button-sm m-2"
                    onClick={() => handleExpandImage(item.url)}
                />
            </div>
            <input
                id={`fileInput-${item.id}`}
                type="file"
                accept="image/*"
                onChange={(e) => handleAddImage(e, setImages, setNextId, setAnexos, nextId, anexo)}
                className="hidden"
            />
        </div>
    );

    return (
        <div className="body-content">
            <div className="form-group-create">
                <Container>
                    <div className="p-6 rounded">
                        <h2 className="text-2xl font-bold mb-6 text-center">Editar Imóvel</h2>
                        <hr />
                        <div className="flex flex-wrap gap-1">
                            <CarouselImagemForm
                                images={images}
                                imageTemplate={imageTemplate}
                                setImages={setImages}
                                setNextId={setNextId}
                                setAnexos={setAnexos}
                                nextId={nextId}
                                anexo={anexo}
                            />
                            <IdentificacaoForm
                                formData={formData}
                                tipoImovel={tipoImovel}
                                subTipoImovel={subTipoImovel}
                                FilteredPessoa={FilteredPessoa}
                                FilteredCorretor={FilteredCorretor}
                                setFilteredPessoa={setFilteredPessoa}
                                setFormData={setFormData}
                                setFilteredCorretor={setFilteredCorretor}
                            />
                            <DimensoesForm
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <TextualForm
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <LocalizacaoForm
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <TempoDataForm
                                formData={formData}
                                setFormData={setFormData}
                            />
                            {formData.captacao_tipo == 3 && <ValoresCondicoesForm
                                FilteredBanco={FilteredBanco}
                                setFilteredBanco={setFilteredBanco}
                                formData={formData}
                                setFormData={setFormData}
                            />}
                            {formData.captacao_tipo == 3 && <AspectoLegaisForm
                                formData={formData}
                                setFormData={setFormData}
                            />}
                            <CaracteristicasListagem
                                listCaracteristicas={listCaracteristicas}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default EditLocacaoImoveis;
