import React, { useEffect, useRef, useState } from "react";
import { Galleria } from "primereact/galleria";
import { Button } from "primereact/button";
import { classNames } from 'primereact/utils';
import './Styled/detalhesImovel.css';
import { Chip } from "primereact/chip";
import "primereact/resources/themes/saga-blue/theme.css"; // Estilos do tema
import "primereact/resources/primereact.min.css"; // Estilos principais
import "primeicons/primeicons.css"; // Ícones do PrimeReact
import { useParams } from "react-router-dom";
import { ImovelShowPublic } from "./Service/ImovelService";
import * as Icons from '@mui/icons-material';
import { Container, Navbar } from "react-bootstrap";
import LogoComponent from "../../Layout/navbar-layout/components/LogoComponent";
import { Tag } from "primereact/tag";

const DetalhesAnuncio: React.FC = () => {
    const [images, setImages] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [showThumbnails, setShowThumbnails] = useState<boolean>(false);
    const [isAutoPlayActive, setAutoPlayActive] = useState<boolean>(true);
    const [isFullScreen, setFullScreen] = useState<boolean>(false);
    const [node, setNode] = useState<any | null>(false);
    const { id } = useParams();
    const galleria: any = useRef(null)
    type ResponsiveOption = {
        breakpoint: string;
        numVisible: number;
    };

    const responsiveOptions: ResponsiveOption[] = [
        {
            breakpoint: '1024px',
            numVisible: 5,
        },
        {
            breakpoint: '960px',
            numVisible: 4,
        },
        {
            breakpoint: '768px',
            numVisible: 3,
        },
        {
            breakpoint: '560px',
            numVisible: 1,
        },
    ];

    useEffect(() => {
        ImovelShowPublic(id, setNode, setImages);
        bindDocumentListeners();

        return () => unbindDocumentListeners();
    }, []);

    useEffect(() => {
        setAutoPlayActive(galleria?.current?.isAutoPlayActive())
    }, [isAutoPlayActive]);

    const onItemChange = (event: any) => {
        setActiveIndex(event.index)
    }

    const toggleFullScreen = () => {
        if (isFullScreen) {
            closeFullScreen();
        }
        else {
            openFullScreen();
        }
    }

    const onFullScreenChange = () => {
        setFullScreen(prevState => !prevState)
    }

    const openFullScreen = () => {
        let elem: any = document.querySelector('.custom-galleria');
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        }
        else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        }
        else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        }
        else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }

    const closeFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
            (document as any).mozCancelFullScreen();
        } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
        } else if ((document as any).msExitFullscreen) {
            (document as any).msExitFullscreen();
        }
    };


    const bindDocumentListeners = () => {
        document.addEventListener("fullscreenchange", onFullScreenChange);
        document.addEventListener("mozfullscreenchange", onFullScreenChange);
        document.addEventListener("webkitfullscreenchange", onFullScreenChange);
        document.addEventListener("msfullscreenchange", onFullScreenChange);
    }

    const unbindDocumentListeners = () => {
        document.removeEventListener("fullscreenchange", onFullScreenChange);
        document.removeEventListener("mozfullscreenchange", onFullScreenChange);
        document.removeEventListener("webkitfullscreenchange", onFullScreenChange);
        document.removeEventListener("msfullscreenchange", onFullScreenChange);
    }

    const thumbnailTemplate = (item: any) => {
        return (
            <div className="grid grid-nogutter justify-content-center">
                <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />
            </div>
        );
    }

    const itemTemplate = (item: any) => {
        if (!item.itemImageSrc) {
            if (isFullScreen) {
                return <img src="https://via.placeholder.com/600x400" alt="imagem" />
            }

            return <img src="https://via.placeholder.com/600x400" alt="imagem" style={{ width: '100%', maxHeight: '400px', display: 'block' }} />
        }
        if (isFullScreen) {
            return <img src={item.itemImageSrc} alt={item.alt} />
        }

        return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', maxHeight: '400px', display: 'block' }} />
    }

    const renderFooter = () => {
        let fullScreenClassName = classNames('pi', {
            'pi-window-maximize': !isFullScreen,
            'pi-window-minimize': isFullScreen
        });

        return (
            <div className="custom-galleria-footer">
                <Button icon={fullScreenClassName} onClick={() => toggleFullScreen()} className="fullscreen-button" />
            </div>
        );
    }

    const footer = renderFooter();
    const galleriaClassName = classNames('custom-galleria', {
        'fullscreen': isFullScreen
    });

    const styles = {
        gridContainer: {
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)", // Altere para 3 colunas, se necessário
            gap: "16px", // Espaçamento entre os itens
            marginBottom: "24px",
        },
    };
    const formatMoney = (value: number): string => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(value);
    };
    return (
        <>
            <Navbar variant="dark" className="navbar-header" bg="#494949" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#home">
                        <LogoComponent empreendimento={null}></LogoComponent>
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <div className="w-full  bg-gray-100 flex justify-center items-center p-4">
                <div className=" bg-white shadow-lg rounded-lg  w-full p-6">
                    {/* Título e Preço */}
                    <div className="border-b pb-4 mb-4">
                        <div className="flex max-md:flex-col max-md:mb-2 justify-between max-md:justify-center items-center">
                            <div className="flex max-md:flex-col max-md:mb-2 items-center">
                                <h1 className="text-xl max-md:text-center max-md:mb-2 font-bold text-gray-800 pr-6">
                                    {node.titulo} - {node.titulo_reduzido}
                                </h1>
                                {node.imovel_novo == true ? (<span className="flex text-center text-1sm rounded-md bg-green-500 border-cyan-500 font-bold text-white pl-6 pr-6">
                                    <p className="text-center font-bold text-1sm p-0 m-0">Imóvel Novo</p>
                                </span>)
                                    : (<span className="flex text-center text-1sm rounded-md bg-orange-500 border-cyan-500 font-bold text-white pl-6 pr-6">
                                        <p className="text-center font-bold text-1sm p-0 m-0"> Imóvel Usado</p>
                                    </span>)
                                }
                            </div>
                            <div className="flex items-center">
                                <span className="text-1sm rounded-md bg-cyan-500 border-cyan-500 font-bold text-white pl-6 pr-6">
                                    unid: {node.unidade}
                                </span>
                            </div>
                        </div>
                        <p className="flex max-md:justify-center max-md:mb-2 text-gray-600">
                            {node.subtitulo}
                        </p>
                        <span className="flex flex-col items-start max-md:items-center text-1xl text-green-600 font-semibold mt-2">{formatMoney(node.valor_venda_revenda)}</span>
                    </div>
                    <div className="flex  justify-start items-start flex-col border-b pb-4 mb-4">
                        {/* Galleria de Imagens */}
                        {images.length === 0 ? (
                            <div style={{ width: '65%' }} className="image-container">
                                <div style={{ height: '350px ' }} className="flex justify-start items-start bg-gray-200 rounded-lg">
                                    <span className="text-gray-500">Sem imagem disponível</span>
                                </div>
                            </div>
                        ) : (
                            <div className="w-2/5 max-md:w-full mb-6 galleria-demo">
                                <Galleria ref={galleria} value={images} activeIndex={activeIndex} onItemChange={onItemChange}
                                    showThumbnails={showThumbnails} showItemNavigators showItemNavigatorsOnHover
                                    numVisible={5} circular autoPlay transitionInterval={3000} responsiveOptions={responsiveOptions}
                                    item={itemTemplate} thumbnail={thumbnailTemplate} footer={footer}
                                    style={{ maxWidth: '640px' }} className={galleriaClassName} />
                            </div>
                        )}
                    </div>

                    {/* Descrição e Características */}
                    <div className="space-y-4">
                        <h2 className="text-lg font-semibold text-gray-800">Descrição Detalhada</h2>
                        <p className="text-gray-600">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: node?.descricao_detalhada?.replace(/\n/g, '<br />'),
                                }}
                            />
                        </p>
                        <h2 className="text-lg font-semibold text-gray-800">Informações do Imóvel</h2>
                        <div className="w-4/5 max-md:w-full">
                            <div className="grid grid-cols-3 max-md:grid-cols-1 max-lg:grid-cols-1 max-xl:grid-cols-1 gap-6 mb-6">
                                <fieldset className="bg-gray-50 p-4 rounded-md shadow">
                                    <legend className="text-sm text-gray-600">Dimensões do imóvel</legend>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Área Útil</p>
                                        <p className="text-lg text-gray-600">{node.area_util_format}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Área Contruída</p>
                                        <p className="text-lg text-gray-600">{node.area_construida_format}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Área Privativa</p>
                                        <p className="text-lg text-gray-600">{node.area_privativa_format ?? 'Não informado'}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Área Comum</p>
                                        <p className="text-lg text-gray-600">{node.area_comum_format ?? 'Não informado'}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Área Total</p>
                                        <p className="text-lg  text-gray-600">{node.area_total_format ?? 'Não informado'}</p>
                                    </div>
                                </fieldset>
                                <fieldset className="bg-gray-50 p-4 rounded-md shadow">
                                    <legend className="text-sm text-gray-600">Datas e Tempo</legend>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Vago desde:</p>
                                        <p className="text-lg text-gray-600">{node.vago_desde ?? 'Não informado'}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Ano de Contrução</p>
                                        <p className="text-lg text-gray-600">{node.ano_construcao ?? 'Não informado'}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Ultima Reforma</p>
                                        <p className="text-lg text-gray-600">{node.ano_ult_reforma ?? 'Não informado'}</p>
                                    </div>
                                </fieldset>
                                <fieldset className="bg-gray-50 p-4 rounded-md shadow">
                                    <legend className="text-sm text-gray-600">Localização</legend>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Endereço:</p>
                                        <p className="text-lg text-gray-600">{node.enderecos?.data?.length > 0 ? (node.enderecos?.data[0]?.logradouro) : 'N/A'}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Condomínio:</p>
                                        <p className="text-lg text-gray-600">{node.edificio_condominio ?? 'Não informado'}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Ponto de Referência</p>
                                        <p className="text-lg text-gray-600">{node.enderecos?.data?.length > 0 && node.enderecos?.data[0]?.complemento ? (node.enderecos?.data[0]?.complemento) : 'Não informado'}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Topografia</p>
                                        <p className="text-lg text-gray-600">{node.topografia_label ?? 'Não informado'}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Posição Solar</p>
                                        <p className="text-lg text-gray-600">{node.posicao_solar_label ?? 'Não informado'}</p>
                                    </div>
                                    <div className="flex justify-between items-center m-0">
                                        <p className="text-sm font-bold text-gray-600">Tipo de Pavimentação</p>
                                        <p className="text-lg text-gray-600">{node.tipo_pavimentacao_label ?? 'Não informado'}</p>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="w-2/4 max-md:w-full">
                            {node?.imoveis_caracteristicas_imovel?.data?.length > 0 && (
                                <>
                                    <h2 className="text-lg font-semibold text-gray-800">
                                        Características do Imóvel
                                    </h2>
                                    <div className="flex flex-cols-6 max-md:flex-cols-3 gap-2 mb-6">
                                        {node?.imoveis_caracteristicas_imovel?.data?.map((item: any, index: any) => {
                                            const IconComponent = Icons[item.icone as keyof typeof Icons];
                                            return (
                                                <Tag key={index} severity="warning">
                                                    {IconComponent && <IconComponent style={{ height: '20px' }} />} {/* Exibe o ícone, se houver */}
                                                    {item.descricao}
                                                </Tag>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                            {node?.imoveis_caracteristicas_condominio?.data?.length > 0 && (
                                <>
                                    <h2 className="text-lg font-semibold text-gray-800">
                                        Características do Condomínio
                                    </h2>
                                    <div className="flex flex-cols-6 max-md:flex-cols-3 gap-4 mb-6">
                                        {node?.imoveis_caracteristicas_condominio?.data?.map((item: any, index: any) => {
                                            const IconComponent = Icons[item.icone as keyof typeof Icons];
                                            return (
                                                <Tag key={index} severity="warning">
                                                    {IconComponent && <IconComponent style={{ height: '20px' }} />} {/* Exibe o ícone, se houver */}
                                                    {item.descricao}
                                                </Tag>
                                            );
                                        })}
                                    </div>
                                </>
                            )}


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetalhesAnuncio;
