import React, { useRef } from "react";
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { SpeedDial } from "primereact/speeddial";
import { setSessionEditData, excluirCaracteristica } from "../Service/CaracteristicaService";

export const actionTemplate = (
    value: any,
    url_redirect_edit: string,
    setSelectedRow: (value: any) => void,
    setShowModal: (value: any) => void,
    urlShow: string
) => {
    const data = value.data ? value.data : value;
    const toast = useRef<Toast>(null);
    const commonStyle = {
        width: '25px',
        height: '25px',
        top: '0px',
        color: '#fff'
    };

    const items: any[] = [
        {
            label: 'Excluir',
            icon: 'pi pi-trash',
            style: { ...commonStyle, backgroundColor: 'red', display: 'flex' },
            command: () => {
                excluirCaracteristica(data.id);
            },
            tooltip: 'Excluir'
        },
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            style: { ...commonStyle, backgroundColor: '#007bff', display: 'flex' },
            command: () => {
                setSessionEditData(data.id, url_redirect_edit, null);
            },
            tooltip: 'Editar'
        },
        {
            label: 'Clonar Característica',
            icon: 'pi pi-clone',
            style: { ...commonStyle, backgroundColor: '#28a745', display: 'flex' },
            command: () => {
                setShowModal(true);
                setSelectedRow(data);
            },
            tooltip: 'Clonar Característica'
        },
        {
            label: 'Visualizar',
            icon: 'pi pi-eye',
            style: { ...commonStyle, backgroundColor: '#FFA500', display: 'flex' },
            command: () => {
                setSessionEditData(data.id,url_redirect_edit, urlShow);
            },
            tooltip: 'Visualizar'
        }
    ];

    return (
        <div
            id="unique-speeddial-imovel"
            style={{ justifyContent: 'center', alignItems: 'center' }}
        >
            <Toast ref={toast} />
            {items.map((item, index) => (
                <Tooltip
                    key={index}
                    target={`#unique-speeddial-imovel .p-speeddial-action:nth-child(${index + 1})`}
                    content={item.tooltip}
                    position="left"
                />
            ))}
            <div style={{ position: 'relative' }}>
                <SpeedDial
                    model={items}
                    radius={45}
                    showIcon="pi pi-bars"
                    hideIcon="pi pi-times"
                    type="linear"
                    direction="left"
                    style={{ top: '-15px', right: 0 }}
                    buttonClassName="button-speed-dial"
                />
            </div>
        </div>
    );
};
