import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import { AlertService } from '../../../components/AlertService';
import FormProduto from './Formulario/forms';

interface PropsData {
    nome_servico?: string;
    descricao_resumida?: string;
    descricao_detalhada?: string;
    identificador?: string;
    unidade?: number;
    n_serie?: string;
    acessorios?: string;
    patrimonio?: string;
    tipo?: number;
    control_saldo?: boolean;
    valor?: number;
    status?: number;
    tipo_comissao?: number;
    percentual_comissao?: number;
    stk_atual?: number;
    pontos?: number;
    condicoes_proposta?: string;
    localizacao?: number;
    vl_aquisic?: number;
    tipo_manutencao?: number;
    id_externo?: number;
}
function EditProduto() {
    const { setUrl, setBody, setShowButtonSave, setTypeRequest, setItemsBreadsCrumbs, setValidationRequest } = useAppContext();
    const [Data, setData] = useState<PropsData>({});

    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Cemitério',
                    url: '/cemiterio',
                },
                {
                    label: 'Lista de Produtos',
                    url: '/produto',
                },
                {
                    label: 'Editar'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setData((prevGrupoData) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...JSON.parse(storedData),
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/cemiterio/produto/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);

    useEffect(() => {
        if (
            Data?.nome_servico &&
            Data?.identificador &&
            Data?.unidade &&
            Data?.tipo &&
            Data?.status
        ) {
            setValidationRequest(true);
        }
        setBody(Data);
    }, [Data]);


    const handleChange = (field: string, value: string) => {
        setData((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setTypeRequest('PUT');
    }
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Alterar Produto</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <FormProduto
                            initialRightValues={Data}
                            Data={Data}
                            onInputChange={(field: string, value: any) => handleChange(field, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default EditProduto;