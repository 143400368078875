import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';

interface FiltroAvancadoModalProps {
    showModal: boolean;
    onHide: () => void;
    listFiltros: any;
    setListFiltros: any | null;
    loadData: (filtros: any, itemsPerPage: any, currentPage: number, setLoading: (nodes: any) => void, setNodes: (nodes: any) => void, updateTitles: (titles: string[]) => void, updatePagination: (pages: number) => void, setShowModalFiltro: (show: boolean) => void) => void;
    currentPage: number;
    setLoading: (nodes: any) => void;
    setNodes: (nodes: any) => void;
    updateTitles: (titles: string[]) => void;
    updatePagination: (pages: number) => void;
    setShowModalFiltro: (show: boolean) => void;
    handleClose: () => void;
    itemsPerPage: any;
}

const FiltroAvancadoModal: React.FC<FiltroAvancadoModalProps> = ({
    showModal,
    onHide,
    listFiltros,
    setListFiltros,
    setLoading,
    loadData,
    currentPage,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro,
    itemsPerPage
}) => {
    const { nome_campos, tipo_dado, tipo_imovel_id, sub_tipo_imovel_id } = listFiltros || {};
    const [subTipoImovel, setSubTipoImovel] = useState<any>([]);
    const [tipoImovel, setTipoImovel] = useState<any>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [responseTiposImovel, responseSubtiposImovel] = await Promise.all([
                    api.get(`${url}/api/v1/admin/vendas/tipoimovel`),
                    api.get(`${url}/api/v1/admin/vendas/subtipoimovel`)
                ]);
                if (responseTiposImovel.status === 200) {
                    setTipoImovel(responseTiposImovel.data.data);
                }
                if (responseSubtiposImovel.status === 200) {
                    setSubTipoImovel(responseSubtiposImovel.data.data);
                }
            } catch (error) {
                AlertService.error('Erro!', String('Erro desconhecido'));
            }
        };
        fetchData();
    }, []);

    return (
        <Modal
            show={showModal}
            size='lg'
            onHide={() => onHide()}
            className='modal-filtro-avancado'
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtros Avançados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className='mb-3'>
                        <Col style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Nome Campo</Form.Label>
                            <Form.Control
                                value={nome_campos ? nome_campos : ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, nome_campos: e.target.value })}
                            />
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Tipo Dado</Form.Label>
                            <Form.Select
                                value={listFiltros?.tipo_dado ? listFiltros?.tipo_dado : ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, tipo_dado: e.target.value })}
                            >
                                <option>Selecione...</option>
                                <option value={1}>Texto</option>
                                <option value={2}>Tag</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Tipo Imóvel</Form.Label>
                            <Form.Select value={tipo_imovel_id || ''} onChange={(e) => setListFiltros({ ...listFiltros, tipo_imovel_id: e.target.value })}>
                                <option>Selecione...</option>
                                {tipoImovel.map((tipoimovel: any) => (
                                    <option value={tipoimovel.id}>{tipoimovel.nome}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>SubTipo Imóvel</Form.Label>
                            <Form.Select value={sub_tipo_imovel_id || ''} onChange={(e) => setListFiltros({ ...listFiltros, sub_tipo_imovel_id: e.target.value })}>
                                <option>Selecione...</option>
                                {subTipoImovel.map((subtipo: any) => (
                                    <option value={subtipo.id}>{subtipo.nome}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded
                    onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded
                    onClick={() => {
                        setListFiltros({});
                        loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)
                    }}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltroAvancadoModal;
