import 'react-datepicker/dist/react-datepicker.css';
import { handleInputChange } from "../Service/ImovelService";
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';

interface TempoDataFormProps {
    formData: any,
    setFormData: (value: any) => void,
}
function TempoDataForm({
    formData,
    setFormData,
}: TempoDataFormProps) {

    return (
        <fieldset className="w-full border p-4 rounded mb-4">
            <legend className="font-bold text-lg">Tempo e Data</legend>
            <div className="grid grid-cols-6 gap-4">
                <div className="flex flex-col mb-4">
                    <label htmlFor="vago_desde" className="mb-1 font-medium">Vago Desde</label>
                    <Calendar
                        value={formData?.vago_desde ? new Date(formData?.vago_desde) : null}
                        onChange={(event: any) => {
                            const selectedDates = event.value;
                            handleInputChange("vago_desde", selectedDates, setFormData);
                        }}
                        placeholder="Selecione a data"
                        showButtonBar
                        selectionMode="single"
                        dateFormat="dd/mm/yy"
                        locale="pt"
                        readOnlyInput
                        style={{ textAlign: 'center', width: '100%' }}
                        panelClassName="custom-calendar-panel"
                    />
                </div>
                {/* Ano Construção */}
                <div className="flex flex-col mb-4">
                    <label htmlFor="ano_construcao" className="mb-1 font-medium">Ano de Construção</label>
                    <InputNumber
                        id="ano_construcao"
                        value={formData?.ano_construcao}
                        onChange={(e) => handleInputChange("ano_construcao", e.value, setFormData)}
                        placeholder="Digite o ano"
                        mode="decimal"
                        min={0}
                        max={9999}
                    />
                </div>

                {/* Ano Última Reforma */}
                <div className="flex flex-col mb-4">
                    <label htmlFor="ano_ult_reforma" className="mb-1 font-medium">Ano da Última Reforma</label>
                    <InputNumber
                        id="ano_ult_reforma"
                        value={formData?.ano_ult_reforma}
                        onChange={(e) => handleInputChange("ano_ult_reforma", e.value, setFormData)}
                        placeholder="Digite o ano"
                        mode="decimal"
                        min={0}
                        max={9999}
                    />
                </div>

                {/* Data de Captação */}
                <div className="flex flex-col mb-4">
                    <label htmlFor="data_captacao" className="mb-1 font-medium">Data de Captação</label>
                    <Calendar
                        value={formData?.data_captacao ? new Date(formData?.data_captacao) : null}
                        onChange={(event: any) => {
                            const selectedDates = event.value;
                            handleInputChange("data_captacao", selectedDates, setFormData);
                        }}
                        placeholder="Selecione a data"
                        showButtonBar
                        selectionMode="single"
                        dateFormat="dd/mm/yy"
                        locale="pt"
                        readOnlyInput
                        style={{ textAlign: 'center', width: '100%' }}
                        panelClassName="custom-calendar-panel"
                    />
                </div>
                {/* Hora Visita
                                    <div className="flex flex-col mb-4">
                                        <label htmlFor="hora_visita" className="mb-1 font-medium">Hora Visita</label>
                                        <input
                                            id="hora_visita"
                                            type="datetime-local"
                                            value={formData?.hora_visita}
                                            onChange={(e) => handleInputChange("hora_visita", e.target.value, setFormData)}
                                            className="p-inputtext p-component"
                                        />
                                    </div> */}

                {/* <div className="flex flex-col mb-4">
                                        <label htmlFor="data_reserva" className="mb-1 font-medium">Data de Reserva</label>
                                        <Calendar
                                            value={formData?.data_reserva ? new Date(formData?.data_reserva) : null}
                                            onChange={(event: any) => {
                                                const selectedDates = event.value;
                                                handleInputChange("data_reserva", selectedDates, setFormData);
                                            }}
                                            placeholder="Selecione a data"
                                            showButtonBar
                                            selectionMode="single"
                                            dateFormat="dd/mm/yy"
                                            locale="pt"
                                            readOnlyInput
                                            style={{ textAlign: 'center', width: '100%' }}
                                            panelClassName="custom-calendar-panel"
                                        />
                                    </div> */}
            </div>
        </fieldset>
    );
}

export default TempoDataForm;
