import { Form } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { AutoComplete } from "primereact/autocomplete";
import { handleInputChange, searchCliente, searchCorretor } from "../Service/ContratoService";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";

interface IdentificacaoFormProps {
    formData: any,
    FilteredPessoa: any,
    FilteredCorretor: any,
    setFilteredPessoa: (value: any) => void,
    setFormData: (value: any) => void,
    setFilteredCorretor: (value: any) => void,
}
function IdentificacaoForm({
    formData,
    FilteredPessoa,
    FilteredCorretor,
    setFilteredPessoa,
    setFormData,
    setFilteredCorretor,
}: IdentificacaoFormProps) {

    return (
        <fieldset className="w-full border p-3 rounded mb-2 mt-2">
            <legend className="font-bold text-lg">Classificação / Identificação</legend>
            <div className="grid grid-cols-6 gap-4">
                <div className="flex flex-col">
                    <label htmlFor="tipo_contrato" className="mb-1 font-medium">Tipo Contrato </label>
                    <Form.Select
                        id="tipo_contrato"
                        value={formData?.tipo_contrato}
                        style={{ height: '33px', borderRadius: '3px' }}
                        onChange={(e) => handleInputChange("tipo_contrato", e.target.value, setFormData)}
                    >(Locação Anual / Temporada)
                        <option value="">Selecione...</option>
                        <option value={1}>Locação Anual</option>
                        <option value={2}>Temporada</option>
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="id_externo" className="mb-1 font-medium">ID Externo</label>
                    <InputText
                        id="id_externo"
                        value={formData?.id_externo}
                        onChange={(e) => handleInputChange("id_externo", e.target.value, setFormData)}
                        placeholder="Digite o ID antigo"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="data_contrato" className="mb-1 font-medium">Data Contrato</label>
                    <Calendar
                        id="data_contrato"
                        value={formData?.data_contrato}
                        invalid={!formData?.data_contrato}
                        placeholder="Selecione a data"
                        dateFormat="dd/mm/yy"
                        panelClassName="custom-calendar-panel"
                        onChange={(e) => handleInputChange("data_contrato", e.value, setFormData)}
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="corretor_id" className="mb-1 font-medium">Corretor</label>
                    <AutoComplete
                        id="corretor_id"
                        field="nome_corretor"
                        value={formData?.corretor_nome}
                        suggestions={FilteredCorretor}
                        completeMethod={(e) => searchCorretor(e, 1, setFilteredCorretor)}
                        onChange={(e) => handleInputChange('corretor_nome', e.value, setFormData)}
                        onSelect={(e) => {
                            handleInputChange('corretor_nome', e.value.nome_corretor, setFormData);
                            handleInputChange('corretor_id', e.value.id, setFormData);
                        }}
                        dropdown
                        style={{ height: "37px", width: "100%" }}
                        placeholder="Selecione um corretor"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="vistoriador_id" className="mb-1 font-medium">Vistoriador</label>
                    <AutoComplete
                        id="vistoriador_id"
                        field="nome_corretor"
                        invalid={!formData?.vistoriador_id}
                        value={formData?.nome_vistoriador || ""} 
                        suggestions={FilteredCorretor} 
                        completeMethod={(e) => searchCorretor(e.query, 1, setFilteredCorretor)} 
                        onChange={(e) =>
                            handleInputChange("nome_vistoriador", e.value, setFormData) 
                        }
                        onSelect={(e) => {
                            handleInputChange("vistoriador_id", e.value.id, setFormData);
                            handleInputChange("nome_vistoriador", e.value.nome_corretor, setFormData);
                        }}
                        dropdown
                        style={{ height: "37px", width: "100%" }}
                        placeholder="Selecione um vistoriador"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="locatario_id" className="mb-1 font-medium">Locatário</label>
                    <AutoComplete
                        id="locatario_id"
                        invalid={!formData?.locatario_id}
                        field="nome_cliente"
                        value={formData?.nome_locatario || formData?.cliente?.nome_cliente || ''}
                        suggestions={FilteredPessoa}
                        completeMethod={(e) => searchCliente(e.query, setFilteredPessoa)}
                        onChange={(e) => handleInputChange('nome_locatario', e.value, setFormData)}
                        onSelect={(e) => {
                            handleInputChange('locatario_id', e.value.id, setFormData);
                            handleInputChange('nome_locatario', e.value.nome_cliente, setFormData);
                        }}
                        dropdown
                        style={{ height: "37px", width: "100%" }}
                        placeholder="Selecione um locatário"
                    />
                </div>
            </div>
        </fieldset>
    );
}

export default IdentificacaoForm;
