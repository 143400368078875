import React, { useEffect, useRef, useState } from 'react';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import { showToast } from '../../../../components/ToastComponent';
import InformacoesImovelModal from './InformacoesImovelModal';
import { Button } from 'primereact/button';
import TokenService from '../../../../services/Auth/token.service';
import loadingMap from '../../../../../assets/images/loading-mapa.gif';
interface PolygonProps {
  points: string;
  lote: string;
  isClicked: boolean;
  statusImovel: any;
  nameClass: any;
  onClick: () => void;
}

const getCentroid2 = (arr: number[][]) => {
  let twoTimesSignedArea = 0;
  let cxTimes6SignedArea = 0;
  let cyTimes6SignedArea = 0;

  const length = arr.length;

  const x = (i: number) => arr[i % length][0];
  const y = (i: number) => arr[i % length][1];

  for (let i = 0; i < length; i++) {
    const twoSA = x(i) * y(i + 1) - x(i + 1) * y(i);
    twoTimesSignedArea += twoSA;
    cxTimes6SignedArea += (x(i) + x(i + 1)) * twoSA;
    cyTimes6SignedArea += (y(i) + y(i + 1)) * twoSA;
  }

  const sixSignedArea = 3 * twoTimesSignedArea;
  return [cxTimes6SignedArea / sixSignedArea, cyTimes6SignedArea / sixSignedArea];
};
const getStatusColorImovel = (status: any) => {
  switch (status) {
    case 1:
      return '#289b1e'; // Background color for Disponível
    case 2:
      return '#f5da41'; // Background color for Reservado
    case 3:
      return '#f5da41'; // Background color for Reserva Fixa
    case 4:
      return '#a8a8a8'; // Background color for Negociado
    case 5:
      return '#ff3232'; // Background color for Vendido
    case 6:
      return '#fff'; // Background color for Reserva Técnica
    case 7:
      return '#fff'; // Background color for Reserva Diretoria
    default:
      return '#fff';
  }
};

const InteractivePolygon: React.FC<PolygonProps> = ({
  points,
  lote,
  nameClass,
  isClicked,
  onClick,
  statusImovel
}) => {

  const handleClick = () => {
    onClick();
  };

  const coordsArray = points.trim().split(' ').map(parseFloat);

  const pointPairs = [];
  for (let i = 0; i < coordsArray.length; i += 2) {
    pointPairs.push([coordsArray[i], coordsArray[i + 1]]);
  }
  const cleanNameClass = nameClass.replace('cls-33', '').trim();
  const [loteCenterX, loteCenterY] = getCentroid2(pointPairs);

  return (
    <>
      <polygon
        points={points}
        className={`${isClicked ? '' : cleanNameClass} hover-effect `}
        onClick={handleClick}
        fill={isClicked ? 'blue' : getStatusColorImovel(statusImovel)}
        style={{ cursor: 'pointer', border: '1px solid black' }}
      />
      <text
        x={loteCenterX}
        y={loteCenterY}
        fill="black"
        fontSize="11"
        textAnchor="middle"
        dominantBaseline="central"
        fontWeight="bold"
      >
        {lote}
      </text>
    </>
  );
};

interface RectProps {
  id: string;
  lote: string;
  nameClass: any;
  statusImovel: any;
  x: string;
  y: string;
  width: string;
  height: string;
  isSelected: boolean;
  onClick: () => void;
}
const InteractiveRect: React.FC<RectProps> = ({
  id,
  lote,
  x,
  y,
  nameClass,
  width,
  height,
  isSelected,
  onClick,
  statusImovel
}) => {
  const centerX = parseFloat(x) + parseFloat(width) / 2;
  const centerY = parseFloat(y) + parseFloat(height) / 2;
  const cleanNameClass = nameClass.replace('.cls-33', '').trim();
  return (
    <>
      <rect
        id={id}
        x={x}
        y={y}
        width={width}
        fill={isSelected ? 'blue' : getStatusColorImovel(statusImovel)}
        height={height}
        className={`${isSelected ? '' : cleanNameClass} hover-effect`}
        onClick={onClick}
        style={{ cursor: 'pointer', border: '1px solid black' }}
      />
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        fontWeight="bold"
        fontSize="12"
      >
        {lote}
      </text>
    </>
  );
};

const MapaLote: React.FC = () => {
  const [selectedRect, setSelectedRect] = useState<string | null>(null);
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<any>(false);
  const [imodelId, setimodelId] = useState<any>(null);
  const [width, setWidth] = useState<any | undefined>(undefined);
  const [height, setHeight] = useState<any | undefined>(undefined);
  const [viewBox, setViewBox] = useState<any | undefined>(undefined);
  const [viewBoxPadrao, setViewBoxPadrao] = useState<any | undefined>(undefined);
  const [imoveis, setImoveis] = useState<any | undefined>();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [dragging, setDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(null);

  const handleRectClick = (rectId: string, click: any, imovelId: number) => {
    setSelectedRect((prevSelectedRect) => (prevSelectedRect === rectId ? null : rectId));
    setimodelId(imovelId);
    setShowModal(true);
  };
  const handleHideClick = () => {
    setSelectedRect(null);
    setimodelId(null);
    setShowModal(false);
  };

  useEffect(() => {
    buscarImoveis();
    buscarMapa();
  }, []);

  const buscarMapa = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(`${url}/api/v1/admin/vendas/pegar-mapa/${TokenService.getEmpreendimentoId()?.id}`);
      if (response.status === 200) {
        const data = response.data.data;
        downloadMapa(data?.anexo?.data[0]?.url);
      }
    } catch (error: any) {
      const mensagens: string[] = [error?.response?.data?.error?.message];
      showToast('error', mensagens.join(', '));
    }
  };

  function downloadMapa(linkMapa: string): void {
    try {
      fetch(linkMapa)
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao baixar o mapa');
          }
          return response.blob();
        })
        .then(blob => {
          const reader = new FileReader();

          reader.onloadend = () => {
            setSvgContent(reader.result as string);
          };

          reader.readAsText(blob);
          setIsLoading(false)
        })
        .catch(error => {
          console.error('Erro ao baixar o arquivo:', error);
        });
    } catch (error) {
      console.error('URL inválida:', error);
    }
  }

  const buscarImoveis = async () => {
    try {
      const response = await api.get(`${url}/api/v1/admin/vendas/listagem-imoveis`);
      if (response.status === 200) {
        const data = response.data.data;
        setImoveis(data);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      showToast('error', String(mensagens));
    }
  };
  const renderRectsAndPolygonsFromSVG = () => {
    if (!svgContent) {
      return null;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(svgContent, 'image/svg+xml');

    const styleTags = doc.querySelectorAll('style');
    const styles = Array.from(styleTags).map(styleTag => styleTag.innerHTML).join('\n');
    const allowedTags = ['tspan', 'image', 'g'];
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;

    const elements = doc.querySelectorAll('*');

    const quadras: { [key: string]: Element[] } = {};
    const svgElement = Array.from(elements).find((element) => element.tagName === 'svg');

    if (!width && !height) {
      if (svgElement) {
        const viewBox = svgElement.getAttribute('viewBox');
        setViewBox(viewBox);
        setViewBoxPadrao(viewBox);
        if (viewBox) {
          const [minX, minY, width, height] = viewBox.split(' ').map(Number);
          setWidth(width);
          setHeight(height);
        }
      }
    }

    const processElement = (element: Element) => {
      const id = element.getAttribute('id') || '';
      const quadraNumber = id.match(/^Q(\d+)/)?.[1];

      if (quadraNumber && !quadras[quadraNumber]) {
        quadras[quadraNumber] = [];
      }
      if (quadraNumber) {
        quadras[quadraNumber].push(element);
      }
    };

    elements.forEach(processElement);
    return (
      <>
        <style>{styles}</style>
        {Array.from(elements).map((element, index) => {
          const elementProps: any = {};
          Array.from(element.attributes).forEach(attr => {
            elementProps[attr.name] = attr.value;
          });

          if (element.tagName === 'image') {
            elementProps.href = element.getAttribute('xlink:href') || element.getAttribute('href');
          }

          return React.createElement(
            element.tagName,
            { key: index, ...elementProps },
            element.childNodes.length > 0
              ? Array.from(element.childNodes).map((child, childIndex) =>
                child.nodeType === Node.TEXT_NODE
                  ? child.textContent
                  : allowedTags.includes((child as Element).tagName)
                    ? React.createElement(
                      (child as Element).tagName,
                      {
                        key: `${index}-${childIndex}`,
                        ...Array.from((child as Element).attributes).reduce(
                          (acc, attr) => ({
                            ...acc,
                            [attr.name]: attr.value,
                          }),
                          {}
                        ),
                      },
                      child.childNodes.length > 0
                        ? Array.from(child.childNodes).map((grandChild, grandChildIndex) =>
                          grandChild.nodeType === Node.TEXT_NODE
                            ? grandChild.textContent
                            : allowedTags.includes((grandChild as Element).tagName)
                              ? React.createElement(
                                (grandChild as Element).tagName,
                                {
                                  key: `${index}-${childIndex}-${grandChildIndex}`,
                                  ...Array.from((grandChild as Element).attributes).reduce(
                                    (acc, attr) => ({
                                      ...acc,
                                      [attr.name]: attr.value,
                                    }),
                                    {}
                                  ),
                                },
                                grandChild.textContent
                              )
                              : null
                        )
                        : null
                    )
                    : null
              )
              : null
          );
        })}
        {Object.entries(quadras).map(([quadra, quadraElements], quadraIndex) => {
          let totalX = 0;
          let totalY = 0;
          let totalPoints = 0;
          quadraElements.forEach((element: any, index) => {

            if (element.tagName === 'polygon') {
              const points = element.getAttribute('points').trim().split(' ').map(Number);
              const ps = [];
              for (let i = 0; i < points.length; i += 2) {
                ps.push([points[i], points[i + 1]]);
              }
              ps.forEach(([x, y]) => {
                totalX += x;
                totalY += y - 8;
                totalPoints += 1;
              });
            } else if (element.tagName === 'rect') {
              const x = parseFloat(element.getAttribute('x')) || 0;
              const y = parseFloat(element.getAttribute('y')) || 0;
              const width = parseFloat(element.getAttribute('width')) || 0;
              const height = parseFloat(element.getAttribute('height')) || 0;

              totalX += x + width / 2;
              totalY += y + height / 2;
              totalPoints += 1;
            }
          });

          const quadraCenterX = totalX / totalPoints;
          const quadraCenterY = totalY / totalPoints;


          return (
            <React.Fragment key={quadraIndex}>
              {quadraElements.map((element: any, elementIndex: number) => {

                const id = element.getAttribute('id') || `element${quadraIndex + 1}-${elementIndex + 1}`;
                const loteNumber = id.match(/LT(\d+)/)?.[1];
                const imovel = imoveis?.filter((imovel: any) => imovel.identificador1 == quadra && imovel.identificador2 == loteNumber ? imovel.status : null)[0]
                const points = element.getAttribute('points');
                const x = element.getAttribute('x');
                const y = element.getAttribute('y');
                const width = element.getAttribute('width');
                const height = element.getAttribute('height');
                const nameClass = element.getAttribute('class') || '';
                return points ? (
                  <InteractivePolygon
                    key={elementIndex}
                    points={points}
                    lote={loteNumber}
                    statusImovel={imovel?.status}
                    nameClass={nameClass}
                    onClick={() => imovel ? handleRectClick(id, selectedRect === id, imovel?.id) : showToast('warn', 'Imóvel não configurado')}
                    isClicked={selectedRect === id}
                  />
                ) : (
                  <InteractiveRect
                    statusImovel={imovel?.status}
                    key={elementIndex}
                    id={id}
                    lote={loteNumber}
                    x={x}
                    y={y}
                    nameClass={nameClass}
                    width={width}
                    height={height}
                    onClick={() => imovel ? handleRectClick(id, selectedRect === id, imovel?.id) : showToast('warn', 'Imóvel não configurado')}
                    isSelected={selectedRect === id}
                  />
                );
              })}
              <circle
                cx={quadraCenterX}
                cy={quadraCenterY}
                r={10}
                fill="white"
                stroke="black"
                strokeWidth="1"
              />
              <text
                x={quadraCenterX}
                y={quadraCenterY}
                textAnchor="middle"
                dominantBaseline="central"
                fill="black"
                fontSize="9"
                fontWeight="bold"
              >
                {quadra}
              </text>

            </React.Fragment>
          );
        })}
      </>
    );
  };

  function desabilitarScroll() {
    document.body.style.overflow = "hidden";
  }

  function habilitarScroll() {
    document.body.style.overflow = "";
  }

  const handleWheel = (e: React.WheelEvent) => {
    e.preventDefault();

    if (svgRef.current && svgContent) {
      const scaleFactor = 0.1;
      const svgBoundingBox = svgRef.current.getBoundingClientRect();
      const mouseX = e.clientX - svgBoundingBox.left;
      const mouseY = e.clientY - svgBoundingBox.top;

      let [minX, minY, width, height] = viewBox?.split(" ")?.map(Number);
      const centerX = (mouseX / svgBoundingBox.width) * width + minX;
      const centerY = (mouseY / svgBoundingBox.height) * height + minY;

      if (e.deltaY < 0) {
        width -= width * scaleFactor;
        height -= height * scaleFactor;
      } else if (e.deltaY > 0) {
        width += width * scaleFactor;
        height += height * scaleFactor;
      }

      const newMinX = centerX - (mouseX / svgBoundingBox.width) * width;
      const newMinY = centerY - (mouseY / svgBoundingBox.height) * height;

      setViewBox(`${newMinX} ${newMinY} ${width} ${height}`);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
    desabilitarScroll();
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (dragging && svgRef.current && dragStart) {
      const deltaX = e.clientX - dragStart.x;
      const deltaY = e.clientY - dragStart.y;

      let [minX, minY, width, height] = viewBox.split(" ").map(Number);
      setViewBox(`${minX - deltaX} ${minY - deltaY} ${width} ${height}`);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    setDragStart(null);
    habilitarScroll();
  };

  return (
    <>
      <div className="flex container-section-body">
        <div className="w-full flex items-center" style={{ justifyContent: 'end' }}>
          <div className="flex m-2">
            <Button className='opacity-70 bg-gray-400 rounded' severity='help' onClick={() => setViewBox(viewBoxPadrao)} icon="pi pi-arrows-alt" />
          </div>
        </div>
        {!isLoading && <svg
          ref={svgRef}
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="auto"
          viewBox={viewBox}
          className="transition-transform duration-200"
          onMouseEnter={() => desabilitarScroll()}
          onMouseLeave={() => habilitarScroll()}
          onWheel={handleWheel}
          onContextMenu={(e) => e.preventDefault()}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          {renderRectsAndPolygonsFromSVG()}
        </svg>}
        {isLoading && (
          <div className='flex flex-col' style={{ justifyContent: 'center', justifyItems: 'center', alignItems: 'center', alignContent: 'center', flexWrap: 'wrap' }}>
            <img style={{ width: '400px' }} src={loadingMap} />
            <h3 className='opacity-70 text-gray-400'>Carregando...</h3>
          </div>
        )}
      </div>
      <InformacoesImovelModal
        showModal={showModal}
        onHide={() => handleHideClick()}
        id={imodelId}
      />
    </>
  );
};

export default MapaLote;
