import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, NavLink } from "react-bootstrap";
import { addLocale } from "primereact/api";
import { url } from "../../../environments/environments-develop";
import TokenService from "../../../services/Auth/token.service";
import { useAppContext } from "../../../contexts/AppContext";
import { useSidebarContext } from "../../../contexts/SidebarContext";
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import ListImovelSelect from "./Formularios/ListImovelSelect";
import FormularioPreenchimento from "./Formularios/FormularioPreenchimento";
import { AlertService } from "../../../components/AlertService";
import { useSearchParams } from "react-router-dom";

function CreateLocacaoContratos() {
    const { setUrl, setBody, setShowButtonSave, setItemsBreadsCrumbs, setValidationRequest } = useAppContext();
    const [formData, setFormData] = useState<any | null>({
        filial_id: TokenService.getFilialId()?.id,
        empreendimento_id: TokenService.getEmpreendimentoId()?.id,
        imovel_id: "",
        nome_imovel: "",
        antecipado: true,
    });
    const [FilteredCorretor, setFilteredCorretor] = useState([]);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 2;
    const { isVisible, updateVisibility } = useSidebarContext();
    const [searchParams] = useSearchParams();
    const nodeId = searchParams.get("numero");

    useEffect(() => {
        if (nodeId) {
            setFormData((prevData: any) => ({
                ...prevData,
                imovel_id: nodeId,
            }));
            setCurrentPart(2);
        }
    }, [nodeId]);

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Locação',
                url: '/locacao',
            },
            {
                label: 'Lista de Contratos',
                url: '/contratos',
            },
            {
                label: 'Criar'
            }
        ]);
        setUrl(`${url}/api/v1/admin/vendas/contrato-imovel-captado`);
        setShowButtonSave(true);
        setValidationRequest(false);
    }, []);

    useEffect(() => {
        const areAllFieldsFilled =
            formData?.imovel_id &&
            formData?.dimob &&
            formData?.data_contrato &&
            formData?.vistoriador_id &&
            formData?.locatario_id &&
            formData?.valor_periodo &&
            formData?.antecipado &&
            formData?.dia_vencimento &&
            formData?.data_primeiro_venc &&
            formData?.vl_primeira_parcela &&
            formData?.data_inicial &&
            formData?.duracao &&
            formData?.data_final;
        setValidationRequest(areAllFieldsFilled);
        setShowButtonSave(areAllFieldsFilled);
        setBody(formData);
    }, [formData]);

    const handleNext = () => {
        if (currentPart === 1 && (formData.imovel_id === null || formData.imovel_id === "")) {
            AlertService.infomessage('Atenção', 'Selecione um imovel!')
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    return (
        <div className="body-content">
            <div className="form-group-create">
                <div className="contentSearch-header">
                    <NavLink onClick={toggleVisibility}>
                        <img
                            className="menu-context"
                            src={iconMenuTresPonto}
                        />
                    </NavLink>
                    <Col className='text-center' style={{ fontSize: '20px' }}>Criação de Contrato </Col>
                </div>
                <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
                <Container>
                    {currentPart === 1 && (
                        <ListImovelSelect formData={formData} setFormData={setFormData} />
                    )}
                    {currentPart === 2 && (
                        <FormularioPreenchimento
                            formData={formData}
                            setFormData={setFormData}
                            FilteredPessoa={FilteredPessoa}
                            FilteredCorretor={FilteredCorretor}
                            setFilteredPessoa={setFilteredPessoa}
                            setFilteredCorretor={setFilteredCorretor}
                        />
                    )}
                    <Col md="12" className="d-flex justify-content-end botoes-progress-bar">
                        <Form.Group className="mb-3 foto-perfil-form" style={{ marginRight: '36px', marginBottom: '15px !important' }}>
                            {currentPart !== 1 && !nodeId && (
                                <Button onClick={() => { handleBack() }} className="button-back-form">
                                    Anterior
                                </Button>
                            )}
                            {currentPart !== totalParts && (
                                <Button className="button-next-form" onClick={handleNext}>
                                    Próximo
                                </Button>
                            )}
                        </Form.Group>
                    </Col>
                </Container>
            </div>
        </div>
    );
}

export default CreateLocacaoContratos;
