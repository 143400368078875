import React, { useEffect, useState } from 'react';
import { Modal, Col, Row, Form } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MDBPagination, MDBPageItem, MDBPageNav } from 'mdbreact';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';

interface ModalIndicesProps {
    showModal: boolean;
    handleClose: () => void;
    filtro: any;
    imovelSelected: any;
}

const ModalIndices: React.FC<ModalIndicesProps> = ({ showModal, handleClose, filtro, imovelSelected }) => {
    const [nodes, setNodes] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pagination, setPagination] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredNodes, setFilteredNodes] = useState<any[]>([]);
    const [filterType, setFilterType] = useState('produto_name');
    const [availableSlots, setAvailableSlots] = useState(0);

    const applyFilters = () => {
        const filteredData = nodes.filter(item => {
            const itemValue = item[filterType]?.toString().toLowerCase();
            return itemValue && itemValue.includes(searchTerm.toLowerCase());
        });
        setFilteredNodes(filteredData);
    };

    const clearFilters = () => {
        setSearchTerm('');
        setFilteredNodes(nodes);
    };

    useEffect(() => {
        if (filtro && filtro.vendas_itens && filtro.vendas_itens.data) {
            setNodes(filtro.vendas_itens.data);
        }
    }, [filtro, showModal]);



    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 0; i < pagination; i++) {
            pageNumbers.push(
                <MDBPageItem key={i} active={i === currentPage}>
                    <MDBPageNav onClick={() => setCurrentPage(i)}>
                        {i + 1}
                    </MDBPageNav>
                </MDBPageItem>
            );
        }
        return pageNumbers;
    };

    useEffect(() => {
        let count = 0;
        let index = 0;
        while (index < (nodes?.length ?? 0)) {
            if (nodes && nodes[index]?.quantidade > 0) {
                count++;
            }
            index++;
        };
        setAvailableSlots(count);
    }, [nodes]);


    return (
        <Modal show={showModal} onHide={handleClose} size="xl" backdrop="static" keyboard={false}>
            <Modal.Header style={{ display: 'flex', flexDirection: 'column' }}>
                <Modal.Title>Índices</Modal.Title>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ marginInlineEnd: '1' }}>
                        <span style={{ fontWeight: 'bold' }}>Cliente: </span>
                        {imovelSelected?.cliente_nome}
                    </div>
                    <div style={{ marginInlineEnd: '1' }}>
                        <span style={{ fontWeight: 'bold' }}>Quantidade: </span>
                        {availableSlots} {availableSlots === 1 ? 'unidade' : 'unidades'}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md='2'>
                        <Form.Label>Escolha o Tipo da Pesquisa</Form.Label>
                        <Form.Select value={filterType} onChange={e => setFilterType(e.target.value)}>
                            <option value="produto_name">Descrição</option>
                            <option value="quantidade">Quantidade</option>
                            <option value="valor_unitario">Valor Unitário</option>
                            <option value="valor_unitario_liquido">Valor Líquido</option>
                        </Form.Select>
                    </Col>
                    <Col md='3'>
                        <Form.Label>Pesquisar</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Col>
                    <Col className='div-filtros-avancados'>
                        <Button
                            style={{ position: 'relative', float: 'right', height: '30px', borderRadius: '7px', marginRight: '5px' }}
                            type="button"
                            icon="pi pi-search"
                            severity="success"
                            onClick={applyFilters}
                            rounded>
                            Filtrar
                        </Button>
                        <Button
                            style={{ height: '30px', borderRadius: '7px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                            type="button"
                            severity="danger"
                            icon="pi pi-delete-left"
                            rounded
                            onClick={clearFilters}
                        >
                            <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                        </Button>
                    </Col>
                </Row>
                <hr />
                <div className="container-section-table">
                    <DataTable
                        className="justify-content-center"
                        stripedRows
                        lazy
                        value={filteredNodes.length > 0 ? filteredNodes : nodes}
                        emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                    >
                        <Column field="produto_name" header="Descrição"></Column>
                        <Column field="quantidade" header="Quantidade"></Column>
                        <Column field="status_label" header="Status"></Column>
                        <Column field="valor_unitario" header="Valor Unitário"></Column>
                        <Column field="valor_unitario_liquido" header="Valor Liquido"></Column>
                    </DataTable>
                    <MDBPagination className="justify-content-center">
                        {renderPageNumbers()}
                    </MDBPagination>
                    {isLoading && <Skeleton />}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button onClick={() => { handleClose() }} className="button-back-form" severity="secondary" style={{borderRadius:'5px'}}>
                        Fechar
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalIndices;
