import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url, url_financeiro } from '../../../environments/environments-develop';
import { AlertService } from '../../../components/AlertService';
import ProgressBarForm from '../../Formularios/progressBarForm';
import ContasPagarForm from './Formularios/ContasPagarFormEdit';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { Col, NavLink } from 'react-bootstrap';
import TaxasJurosForm from './Formularios/TaxasJurosForm';
import AnexoForm from './Formularios/AnexoForm';
import TokenService from '../../../services/Auth/token.service';
import ContasPagarFormEdit from './Formularios/ContasPagarFormEdit';

interface PropsData {
    numero_documento?: string;
    conta_financeiro_id?: string;
    plano_conta_id?: string;
    centro_custo?: string[];
    tipo_documento?: number;
    justificativa?: string;
    pessoa_id?: string;
    taxa_juros?: number;
    taxa_multa?: number;
    valor_multa?: number;
    valor_juros?: number;
    valor_desconto?: number;
    valor_taxa_bancaria?: number;
    valor_outros?: number;
    qtd_dias_carencia_multa?: number;
    qtd_dias_carencia_juros?: number;
    recorrente?: boolean;
    valor_original?: number;
    data_competencia?: string;
    num_parcela?: number;
    data_vencimento?: string;
    data_emissao?: string;
    data_efetivacao?: string;
    tipo?: number;
    anexo?: Array<any>;
    fornecedor_id?: string;
}

function EditContasPagar() {
    const { setUrl, setTypeRequest, setBody, setShowButtonSave, setItemsBreadsCrumbs } = useAppContext();
    const [formBasico, setFormBasico] = useState<PropsData>({});
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 3;
    const { isVisible, updateVisibility } = useSidebarContext();

    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Financeiro',
                    url: '/financeiro',
                },
                {
                    label: 'Contas à Pagar',
                    url: '/financeiro/contas-pagar',
                },
                {
                    label: 'Editar'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setFormBasico((prevGrupoData) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...JSON.parse(storedData),
                        ['tipo']: '1',
                        ['pessoa']: JSON.parse(storedData)?.fornecedor?.pessoa,
                        ['empreendimento_id']: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId()?.id : null,
                        ['centro_custo']: JSON.parse(storedData)?.centro_custo?.data ? JSON.parse(storedData)?.centro_custo?.data : JSON.parse(storedData)?.centro_custo,
                        ['centro_custo_format']: JSON.parse(storedData)?.centro_custo?.data ? JSON.parse(storedData)?.centro_custo?.data : JSON.parse(storedData)?.centro_custo
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url_financeiro}/api/v1/admin/financeiro/contasPagar/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);

    useEffect(() => {
        setBody(formBasico)
    }, [formBasico])

    const handleNext = () => {
        if (currentPart === 2 && formBasico.valor_original === undefined) {
            AlertService.infomessage('Atenção', 'Valor Original não informado!')
            return;
        }
        if (currentPart === 2 && formBasico.data_vencimento === undefined) {
            AlertService.infomessage('Atenção', 'Data de vencimento não informado!')
            return;
        }

        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };
    const handleChange = (field: string, value: string) => {
        setFormBasico((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };
    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };
    return (
        <div className="body-content">
            <div className="form-user-create">
                <div className="contentSearch-header">
                    <NavLink onClick={toggleVisibility}>
                        <img
                            className="menu-context"
                            src={iconMenuTresPonto}
                        />
                    </NavLink>
                    <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Contas à Pagar</Col>
                </div>
                <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={["Identificação", "Taxas e Juros", "Anexo"]}
                        body={formBasico}
                    >
                        {currentPart === 1 && (
                            <ContasPagarFormEdit
                                Data={formBasico}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <TaxasJurosForm
                                Data={formBasico}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <AnexoForm
                                Data={formBasico}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                    </ProgressBarForm>
                </Container>
            </div>
        </div>
    );
}

export default EditContasPagar;
