// export const OAUTH_CONFIG = {
//   client_id: 2,
//   client_secret: "6Mv2TbSyupzZ8CizU4OQTsvXRGW2xbgdukVxZ1wr",
//   grant_type: "password"
// }
// export const ambiente = "homolog"
// export const url_front = "http://192.168.10.100:3000";
// export const url = "http://192.168.10.100:80";
// export const url_financeiro = "http://192.168.10.100:8087";
// export const url_remessa = "http://192.168.10.100:9000/local/remessas/";
// export const url_boleto = "http://192.168.10.100:9000/local/boletos/";
// export const url_contrato = "http://192.168.10.100:9000/local/modelos-documentos/contratos-emitidos/";

//PROD
export const OAUTH_CONFIG = {
  client_id: 2,
  client_secret: "6Mv2TbSyupzZ8CizU4OQTsvXRGW2xbgdukVxZ1wr",
  grant_type: "password"
}
export const ambiente: string = "prod"
export const url_front = "https://sistema.omniteq.com.br";
export const url = "https://api.omniteq.com.br";
export const url_remessa = "https://omniteq.s3.us-east-2.amazonaws.com/omniteq/remessas/";
export const url_boleto = "https://omniteq.s3.us-east-2.amazonaws.com/omniteq/boletos/";
export const url_financeiro = "https://api-financeiro.omniteq.com.br";

//HML
// export const OAUTH_CONFIG = {
//   client_id: 2,
//   client_secret: "Y28ndwvv1vbYWPXqZrwx8JU2tn70Wgk2aAzoFToH",
//   grant_type: "password"
// }
// export const ambiente = "homolog"
// export const url_front = "https://sandbox.omniteq.com.br";
// export const url = "https://sandbox-api.omniteq.com.br";
// export const url_remessa = "https://sandbox-minio.omniteq.com.br/local/remessas/";
// export const url_boleto = "https://sandbox-minio.omniteq.com.br/local/boletos/";
// export const url_financeiro = "https://sandbox-api-financeiro.omniteq.com.br";