import { Col, Form } from "react-bootstrap";
import SecaoVendaAvulsa from "./SecaoVendaAvulsa";
import ProdutosServicosVendaAvulsa from "./ProdutosServicosVendaAvulsa";
import SecaoParcelamentoVendaAvulsa from "./SecaoParcelamentoVendaAvulsa";
import SecaoEntradaVendaAvulsa from "./SecaoEntradaVendaAvulsa";


interface ProgressBarFormProps {
    currentPart: number;
    formBasico: any;
    onInputChange: (field: string, value: string) => void;
    onCompradoresInputChange: (index: number, field: string, value: any) => void;
    onAddCompradores: any;
    onRemoveCompradores: any;
}

const VendaAvulsaForm: React.FC<ProgressBarFormProps> = ({
    currentPart,
    formBasico,
    onInputChange,
}) => {
    return (
        <Form.Group style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }}>
            <Col md="12">
                {currentPart === 0 && (
                    <SecaoVendaAvulsa
                        SecaoVendaAvulsaData={formBasico}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)} />
                )}
                {currentPart === 1 && (
                    <ProdutosServicosVendaAvulsa
                        produtoData={formBasico}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)} />
                )}

                {currentPart === 2 && (
                    <SecaoEntradaVendaAvulsa
                        secaoEntradaData={formBasico}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)} />
                )}
                {currentPart === 3 && (
                    <SecaoParcelamentoVendaAvulsa
                        SecaoParcelamentoVendaAvulsaData={formBasico}
                        onInputChange={(field: string, value: string) => onInputChange(`${field}`, value)} />
                )}
            </Col>
        </Form.Group>
    );
}

export default VendaAvulsaForm;
