import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function ClienteImport(props: any) {
    const [formData, setFormData] = useState<any>({});

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }
    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);
    
    return (
        <>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o codiclie?</Form.Label>
                    <Form.Control
                        value={formData.codiclie || ''}
                        onChange={(e) => handleFormChange('codiclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o nomeclie?</Form.Label>
                    <Form.Control
                        value={formData.nomeclie || ''}
                        onChange={(e) => handleFormChange('nomeclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codigrup?</Form.Label>
                    <Form.Control
                        value={formData.codigrup || ''}
                        onChange={(e) => handleFormChange('codigrup', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o coditicl?</Form.Label>
                    <Form.Control
                        value={formData.coditicl || ''}
                        onChange={(e) => handleFormChange('coditicl', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o pessclie?</Form.Label>
                    <Form.Control
                        value={formData.pessclie || ''}
                        onChange={(e) => handleFormChange('pessclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datacada?</Form.Label>
                    <Form.Control
                        value={formData.datacada || ''}
                        onChange={(e) => handleFormChange('datacada', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datanasc?</Form.Label>
                    <Form.Control
                        value={formData.datanasc || ''}
                        onChange={(e) => handleFormChange('datanasc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o cpfclie?</Form.Label>
                    <Form.Control
                        value={formData.cpfclie || ''}
                        onChange={(e) => handleFormChange('cpfclie', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o rgclie?</Form.Label>
                    <Form.Control
                        value={formData.rgclie || ''}
                        onChange={(e) => handleFormChange('rgclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o sexoclie?</Form.Label>
                    <Form.Control
                        value={formData.sexoclie || ''}
                        onChange={(e) => handleFormChange('sexoclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codiprof?</Form.Label>
                    <Form.Control
                        value={formData.codiprof || ''}
                        onChange={(e) => handleFormChange('codiprof', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o razaclie?</Form.Label>
                    <Form.Control
                        value={formData.razaclie || ''}
                        onChange={(e) => handleFormChange('razaclie', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o cnpjclie?</Form.Label>
                    <Form.Control
                        value={formData.cnpjclie || ''}
                        onChange={(e) => handleFormChange('cnpjclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o cgfclie?</Form.Label>
                    <Form.Control
                        value={formData.cgfclie || ''}
                        onChange={(e) => handleFormChange('cgfclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o inscclie?</Form.Label>
                    <Form.Control
                        value={formData.inscclie || ''}
                        onChange={(e) => handleFormChange('inscclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o tiloresi?</Form.Label>
                    <Form.Control
                        value={formData.tiloresi || ''}
                        onChange={(e) => handleFormChange('tiloresi', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o logrresi?</Form.Label>
                    <Form.Control
                        value={formData.logrresi || ''}
                        onChange={(e) => handleFormChange('logrresi', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o foneresi?</Form.Label>
                    <Form.Control
                        value={formData.foneresi || ''}
                        onChange={(e) => handleFormChange('foneresi', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o celuresi?</Form.Label>
                    <Form.Control
                        value={formData.celuresi || ''}
                        onChange={(e) => handleFormChange('celuresi', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o referesi?</Form.Label>
                    <Form.Control
                        value={formData.referesi || ''}
                        onChange={(e) => handleFormChange('referesi', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o corrresi?</Form.Label>
                    <Form.Control
                        value={formData.corrresi || ''}
                        onChange={(e) => handleFormChange('corrresi', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o tilocome?</Form.Label>
                    <Form.Control
                        value={formData.tilocome || ''}
                        onChange={(e) => handleFormChange('tilocome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o logrcome?</Form.Label>
                    <Form.Control
                        value={formData.logrcome || ''}
                        onChange={(e) => handleFormChange('logrcome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o numecome?</Form.Label>
                    <Form.Control
                        value={formData.numecome || ''}
                        onChange={(e) => handleFormChange('numecome', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o compcome?</Form.Label>
                    <Form.Control
                        value={formData.compcome || ''}
                        onChange={(e) => handleFormChange('compcome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o baircome?</Form.Label>
                    <Form.Control
                        value={formData.baircome || ''}
                        onChange={(e) => handleFormChange('baircome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o cidacome?</Form.Label>
                    <Form.Control
                        value={formData.cidacome || ''}
                        onChange={(e) => handleFormChange('cidacome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o cepcome?</Form.Label>
                    <Form.Control
                        value={formData.cepcome || ''}
                        onChange={(e) => handleFormChange('cepcome', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o fonecome?</Form.Label>
                    <Form.Control
                        value={formData.fonecome || ''}
                        onChange={(e) => handleFormChange('fonecome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o ramacome?</Form.Label>
                    <Form.Control
                        value={formData.ramacome || ''}
                        onChange={(e) => handleFormChange('ramacome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o faxcome?</Form.Label>
                    <Form.Control
                        value={formData.faxcome || ''}
                        onChange={(e) => handleFormChange('faxcome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o refecome?</Form.Label>
                    <Form.Control
                        value={formData.refecome || ''}
                        onChange={(e) => handleFormChange('refecome', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o corrcome?</Form.Label>
                    <Form.Control
                        value={formData.corrcome || ''}
                        onChange={(e) => handleFormChange('corrcome', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o statclie?</Form.Label>
                    <Form.Control
                        value={formData.statclie || ''}
                        onChange={(e) => handleFormChange('statclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o dataafas?</Form.Label>
                    <Form.Control
                        value={formData.dataafas || ''}
                        onChange={(e) => handleFormChange('dataafas', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o nomebanc?</Form.Label>
                    <Form.Control
                        value={formData.nomebanc || ''}
                        onChange={(e) => handleFormChange('nomebanc', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o agenbanc?</Form.Label>
                    <Form.Control
                        value={formData.agenbanc || ''}
                        onChange={(e) => handleFormChange('agenbanc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o contbanc?</Form.Label>
                    <Form.Control
                        value={formData.contbanc || ''}
                        onChange={(e) => handleFormChange('contbanc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o emaiclie?</Form.Label>
                    <Form.Control
                        value={formData.emaiclie || ''}
                        onChange={(e) => handleFormChange('emaiclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o dispcobr?</Form.Label>
                    <Form.Control
                        value={formData.dispcobr || ''}
                        onChange={(e) => handleFormChange('dispcobr', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o paiclie?</Form.Label>
                    <Form.Control
                        value={formData.paiclie || ''}
                        onChange={(e) => handleFormChange('paiclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o maeclie?</Form.Label>
                    <Form.Control
                        value={formData.maeclie || ''}
                        onChange={(e) => handleFormChange('maeclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o obseclie?</Form.Label>
                    <Form.Control
                        value={formData.obseclie || ''}
                        onChange={(e) => handleFormChange('obseclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codiconv?</Form.Label>
                    <Form.Control
                        value={formData.codiconv || ''}
                        onChange={(e) => handleFormChange('codiconv', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o tipocont?</Form.Label>
                    <Form.Control
                        value={formData.tipocont || ''}
                        onChange={(e) => handleFormChange('tipocont', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o infoclie?</Form.Label>
                    <Form.Control
                        value={formData.infoclie || ''}
                        onChange={(e) => handleFormChange('infoclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datadisp?</Form.Label>
                    <Form.Control
                        value={formData.datadisp || ''}
                        onChange={(e) => handleFormChange('datadisp', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o modocobr?</Form.Label>
                    <Form.Control
                        value={formData.modocobr || ''}
                        onChange={(e) => handleFormChange('modocobr', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o emprclie?</Form.Label>
                    <Form.Control
                        value={formData.emprclie || ''}
                        onChange={(e) => handleFormChange('emprclie', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o bairro?</Form.Label>
                    <Form.Control
                        value={formData.bairro || ''}
                        onChange={(e) => handleFormChange('bairro', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o lougradouro?</Form.Label>
                    <Form.Control
                        value={formData.lougradouro || ''}
                        onChange={(e) => handleFormChange('lougradouro', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o cep?</Form.Label>
                    <Form.Control
                        value={formData.cep || ''}
                        onChange={(e) => handleFormChange('cep', e.target.value)} />
                </Col>
            </Row>
            <Row className='group-form'>
                <Col>
                    <Form.Label>Em qual Coluna está o religiao?</Form.Label>
                    <Form.Control
                        value={formData.religiao || ''}
                        onChange={(e) => handleFormChange('religiao', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o profissao?</Form.Label>
                    <Form.Control
                        value={formData.profissao || ''}
                        onChange={(e) => handleFormChange('profissao', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default ClienteImport;
