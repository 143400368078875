import 'react-datepicker/dist/react-datepicker.css';
import { handleChangeEndereco, handleInputChange } from "../Service/ImovelService";
import FormEndereco from './FormEndereco';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Form } from 'react-bootstrap';

interface LocalizacaoFormProps {
    formData: any,
    setFormData: (value: any) => void,
}
function LocalizacaoForm({
    formData,
    setFormData,
}: LocalizacaoFormProps) {

    return (
        <fieldset className="w-full border p-4 rounded mb-4">
            <legend className="font-bold text-lg">Localização</legend>
            <div className="grid grid-cols-6 gap-4">
                <FormEndereco
                    enderecosData={formData?.enderecos[0] || []}
                    handleChange={(field: string, value: any) => handleChangeEndereco(field, value, setFormData)}
                />
                <div className="flex flex-col">
                    <label htmlFor="edificio_condominio" className="mb-1 font-medium">Nome do condominio ou edificio</label>
                    <InputText
                        id="edificio_condominio"
                        value={formData?.edificio_condominio}
                        onChange={(e) => handleInputChange("edificio_condominio", e.target.value, setFormData)}
                        placeholder="Digite o Nome do condominio ou edificio"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="ponto_referencia" className="mb-1 font-medium">Ponto de referência</label>
                    <InputText
                        id="ponto_referencia"
                        value={formData?.enderecos[0]?.complemento}
                        onChange={(e) => handleChangeEndereco("complemento", e.target.value, setFormData)}
                        placeholder="Digite o ponto de referência"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="topografia" className="mb-1 font-medium">Topografia</label>
                    <Form.Select
                        id="topografia"
                        value={formData?.topografia}
                        onChange={(e) => handleInputChange("topografia", e.target.value, setFormData)}
                    >
                        <option value="">Selecione...</option>
                        <option value={1}>Plano</option>
                        <option value={2}>Acidentado</option>
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="posicao_solar" className="mb-1 font-medium">Posição solar</label>
                    <Form.Select
                        id="posicao_solar"
                        value={formData?.posicao_solar}
                        onChange={(e) => handleInputChange("posicao_solar", e.target.value, setFormData)}
                    >
                        <option value="">Selecione...</option>
                        <option value={1}>Face Norte</option>
                        <option value={2}>Face Sul</option>
                        <option value={3}>Face Leste/Oeste</option>
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="tipo_pavimentacao" className="mb-1 font-medium">Tipo de Pavimentação</label>
                    <Form.Select
                        id="tipo_pavimentacao"
                        value={formData?.tipo_pavimentacao}
                        onChange={(e) => handleInputChange("tipo_pavimentacao", e.target.value, setFormData)}
                    >
                        <option value="">Selecione...</option>
                        <option value={1}>Asfalto</option>
                        <option value={2}>Terra</option>
                    </Form.Select>
                </div>
            </div>
        </fieldset>
    );
}

export default LocalizacaoForm;
