import React, { useEffect } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import 'react-datepicker/dist/react-datepicker.css';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';

interface FiltroAvancadoModalProps {
    showModal: boolean;
    onHide: () => void;
    listFiltros: any;
    setListFiltros: any | null;
    loadData: (filtros: any, itemsPerPage: any, currentPage: number, setLoading: (nodes: any) => void, setNodes: (nodes: any) => void, updateTitles: (titles: string[]) => void, updatePagination: (pages: number) => void, setShowModalFiltro: (show: boolean) => void) => void;
    currentPage: number;
    setLoading: (nodes: any) => void;
    setNodes: (nodes: any) => void;
    updateTitles: (titles: string[]) => void;
    updatePagination: (pages: number) => void;
    setShowModalFiltro: (show: boolean) => void;
    handleClose: () => void;
    itemsPerPage: any;
}

const FiltroAvancadoModal: React.FC<FiltroAvancadoModalProps> = ({
    showModal,
    onHide,
    listFiltros,
    setListFiltros,
    setLoading,
    loadData,
    currentPage,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro,
    itemsPerPage
}) => {
    const {dia_vencimento, data_inicial, data_final, data_ult_reajuste, data_prox_reaj } = listFiltros || {};

    useEffect(() => {
        loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro);
    }, [listFiltros, itemsPerPage, currentPage]);

    return (
        <Modal
            show={showModal}
            size='lg'
            onHide={() => onHide()}
            className='modal-filtro-avancado'
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtros Avançados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form.Group className="imovel-form">
                        <Row className='mb-3'>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Dia do Vencimento</Form.Label>
                                <InputNumber
                                    id="dia_vencimento"
                                    value={dia_vencimento}
                                    onChange={(e) => setListFiltros({ ...listFiltros, dia_vencimento: e.value })}
                                    placeholder="Dia do mês"
                                />
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Data Inicial</Form.Label>
                                <Calendar
                                    id="data_inicial"
                                    value={data_inicial}
                                    onChange={(e) => setListFiltros({ ...listFiltros, data_inicial: e.value })}
                                    dateFormat="dd/mm/yy"
                                    panelClassName="custom-calendar-panel"
                                    placeholder='Selecione a data'
                                />
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Data Final</Form.Label>
                                <Calendar
                                    id="data_final"
                                    value={data_final}
                                    onChange={(e) => setListFiltros({ ...listFiltros, data_final: e.value })}
                                    dateFormat="dd/mm/yy"
                                    panelClassName="custom-calendar-panel"
                                    placeholder='Selecione a data'
                                />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Data Último Reajuste</Form.Label>
                                <Calendar
                                    id="data_ult_reajuste"
                                    value={data_ult_reajuste}
                                    onChange={(e) => setListFiltros({ ...listFiltros, data_ult_reajuste: e.value })}
                                    dateFormat="dd/mm/yy"
                                    panelClassName="custom-calendar-panel"
                                    placeholder='Selecione a data'
                                />
                            </Col>
                            <Col md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label>Data Próximo Reajuste</Form.Label>
                                <Calendar
                                    id="data_prox_reaj"
                                    value={data_prox_reaj}
                                    onChange={(e) => setListFiltros({ ...listFiltros, data_prox_reaj: e.value })}
                                    dateFormat="dd/mm/yy"
                                    panelClassName="custom-calendar-panel"
                                    placeholder='Selecione a data'
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded
                    onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded
                    onClick={() => {
                        setListFiltros({});
                    }}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltroAvancadoModal;
