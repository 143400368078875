import { Button } from 'primereact/button';

export const actionTemplate = (
    value: any,
    setShowModalDetalhes: (value: any) => void,
    setSelectedRow: (value: any) => void
) => {
    const data = value.data ? value.data : value;

    const handleDetalhesClick = () => {
        setShowModalDetalhes(true);
        setSelectedRow(data);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
                icon="pi pi-eye"
                style={{
                    backgroundColor: '#FFA500',
                    borderColor: '#FFA500',
                    color: '#fff',
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%'
                }}
                tooltip="Detalhes"
                tooltipOptions={{ position: 'left' }}
                onClick={handleDetalhesClick}
            />
        </div>
    );
};
