import 'react-datepicker/dist/react-datepicker.css';
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Dropdown } from 'primereact/dropdown';
import { handleInputChange } from '../Service/ContratoService';
import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { differenceInDays } from 'date-fns';
import { addLocale } from 'primereact/api';

interface ValoresVencimentoFormProps {
    formData: any,
    setFormData: (value: any) => void,
}

function ValoresVencimentoForm({
    formData,
    setFormData,
}: ValoresVencimentoFormProps) {

    useEffect(() => {
        var diferencaDias = 0;

        if (formData?.data_inicial) {
            const dataInicial = new Date(formData.data_inicial);
            const dataVencimento = new Date(dataInicial);
            let diaVencimento = formData?.dia_vencimento - 1;
            dataVencimento.setMonth(dataVencimento.getMonth() + 1); 
            dataVencimento.setDate(diaVencimento);
            diferencaDias = differenceInDays(dataVencimento, dataInicial);
            const valorAluguelProporcional = (formData.valor_periodo / 30) * diferencaDias;
            const dataFinalContrato = new Date(dataInicial);
            dataFinalContrato.setMonth(dataFinalContrato.getMonth() + formData?.duracao);
            setFormData({
                ...formData,
                vl_primeira_parcela: valorAluguelProporcional,
                data_final: dataFinalContrato
            });
        }
    }, [formData?.data_inicial, formData?.duracao, formData?.dia_vencimento]);

    useEffect(() => {
        if (formData?.data_inicial && typeof formData.data_inicial === 'string') {
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                data_inicial: new Date(formData.data_inicial),
                data_final: formData.data_final ? new Date(formData.data_final) : null,
                data_primeiro_venc: formData.data_primeiro_venc ? new Date(formData.data_primeiro_venc) : null,
                data_contrato: formData.data_contrato ? new Date(formData.data_contrato) : null,
            }));
        }
    }, [formData?.data_inicial]);
    

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    return (
        <fieldset className="w-full border p-4 rounded mb-2">
            <legend className="font-bold text-lg">Valores e Vencimentos / Recorrências</legend>
            <div className="grid grid-cols-6 gap-4">
                <div className="flex flex-col">
                    <label htmlFor="valor_periodo" className="mb-1 font-medium">Valor Aluguel</label>
                    <InputNumber
                        invalid={!formData?.valor_periodo}
                        id="valor_periodo"
                        value={formData?.valor_periodo}
                        onChange={(e) => handleInputChange("valor_periodo", e.value, setFormData)}
                        placeholder="Digite o valor do aluguel"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={18}
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="data_inicial" className="mb-1 font-medium">Data Inicial Contrato</label>
                    <Calendar
                        id="data_inicial"
                        invalid={!formData?.data_inicial}
                        value={formData?.data_inicial}
                        onChange={(e) => handleInputChange("data_inicial", e.value, setFormData)}
                        dateFormat="dd/mm/yy"
                        panelClassName="custom-calendar-panel"
                        placeholder='Selecione a data'
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="duracao" className="mb-1 font-medium">Duração Contrato (meses)</label>
                    <InputNumber
                        invalid={!formData?.duracao}
                        id="duracao"
                        value={formData?.duracao}
                        onChange={(e) => handleInputChange("duracao", e.value, setFormData)}
                        placeholder="Duração em meses"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="data_final" className="mb-1 font-medium">Data Final Contrato</label>
                    <Calendar
                        id="data_final"
                        invalid={!formData?.data_final}
                        value={formData?.data_final}
                        onChange={(e) => handleInputChange("data_final", e.value, setFormData)}
                        dateFormat="dd/mm/yy"
                        panelClassName="custom-calendar-panel"
                        placeholder='Selecione a data'
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="dia_vencimento" className="mb-1 font-medium">Dia de Vencimento</label>
                    <InputNumber
                        invalid={!formData?.dia_vencimento}
                        id="dia_vencimento"
                        value={formData?.dia_vencimento}
                        onChange={(e) => handleInputChange("dia_vencimento", e.value, setFormData)}
                        placeholder="Dia do mês"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="data_primeiro_venc" className="mb-1 font-medium">Data Primeiro Vencimento</label>
                    <Calendar
                        id="data_primeiro_venc"
                        invalid={!formData?.data_primeiro_venc}
                        value={formData?.data_primeiro_venc}
                        onChange={(e) => handleInputChange("data_primeiro_venc", e.value, setFormData)}
                        dateFormat="dd/mm/yy"
                        panelClassName="custom-calendar-panel"
                        placeholder='Selecione a data'
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="vl_primeira_parcela" className="mb-1 font-medium">Valor Primeira Parcela</label>
                    <InputNumber
                        invalid={!formData?.vl_primeira_parcela}
                        id="vl_primeira_parcela"
                        value={formData?.vl_primeira_parcela}
                        onChange={(e) => handleInputChange("vl_primeira_parcela", e.value, setFormData)}
                        placeholder="Valor da Primeira Parcela"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="antecipado" className="mb-1 font-medium">Antecipado</label>
                    <Form.Select
                        id="antecipado"
                        isInvalid={!formData?.antecipado}
                        value={formData?.antecipado || ''}
                        style={{ height: '33px', borderRadius: '3px' }}
                        onChange={(e) => handleInputChange("antecipado", e.target.value, setFormData)}
                    >
                        <option value="" disabled>Selecione uma opção</option>
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="tipo_desc_pontualidade" className="mb-1 font-medium">Tipo Desconto</label>
                    <Form.Select
                        id="tipo_desc_pontualidade"
                        value={formData?.tipo_desc_pontualidade || ''}
                        style={{ height: '33px', borderRadius: '3px' }}
                        onChange={(e) => handleInputChange("tipo_desc_pontualidade", e.target.value, setFormData)}
                    >
                        <option value="" disabled>Selecione o tipo</option>
                        <option value="1">Valor</option>
                        <option value="2">%</option>
                    </Form.Select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="vlr_desc_pontualidade" className="mb-1 font-medium">Valor Desconto</label>
                    <InputNumber
                        id="vlr_desc_pontualidade"
                        value={formData?.vlr_desc_pontualidade}
                        onChange={(e) => handleInputChange("vlr_desc_pontualidade", e.value, setFormData)}
                        placeholder="Valor ou %"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={6}
                    />
                </div>
            </div>
        </fieldset>
    );
}

export default ValoresVencimentoForm;
