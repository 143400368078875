import React, { useState } from 'react';
import * as Icons from '@mui/icons-material'; // Importa todos os ícones
import { Col, Form, Button } from 'react-bootstrap';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IconButton } from '@mui/material';

type IconName = keyof typeof Icons;

interface IconSelectorProps {
    icone: IconName;
    handleChange: (field: string, value: string) => void;
}

const iconsList = Object.keys(Icons) as IconName[];

const ICONS_PER_PAGE = 80;

const IconSelector: React.FC<IconSelectorProps> = ({ icone, handleChange }) => {
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const filteredIcons = iconsList.filter((iconName) =>
        iconName.toLowerCase().includes(filter.toLowerCase())
    );

    const currentIcons = filteredIcons.slice(
        (currentPage - 1) * ICONS_PER_PAGE,
        currentPage * ICONS_PER_PAGE
    );

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredIcons.length / ICONS_PER_PAGE)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Col>
            <div className="flex flex-col">
                <Form.Label>Ícone</Form.Label>
                <Button variant="outline-secondary" onClick={handleOpen}>
                    Selecionar Ícone
                </Button>
            </div>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>Selecione um Ícone</DialogTitle>
                <DialogContent>
                    <Form.Control
                        placeholder="Filtrar ícones"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />

                    <div className="d-flex flex-wrap mt-3">
                        {currentIcons.map((iconName) => {
                            const IconComponent = Icons[iconName];
                            return (
                                <IconButton
                                    key={iconName}
                                    onClick={() => {
                                        handleChange("icone", iconName);
                                        handleClose();
                                    }}
                                    style={{
                                        fontSize: 40,
                                        margin: 10,
                                        cursor: 'pointer',
                                    }}
                                >
                                    <IconComponent />
                                </IconButton>
                            );
                        })}
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                        Anterior
                    </Button>
                    <Button
                        onClick={handleNextPage}
                        disabled={currentPage === Math.ceil(filteredIcons.length / ICONS_PER_PAGE)}
                    >
                        Próxima
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            {icone && (
                <div className="mt-3">
                    <p>Ícone selecionado:</p>
                    {React.createElement(Icons[icone] as React.ElementType, { style: { fontSize: 30 } })}
                </div>
            )}
        </Col>
    );
};

export default IconSelector;
