import React, { useEffect, useRef, useState } from 'react';
import { Modal, Container, Row } from 'react-bootstrap';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';
import '../style/index.css';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { showToast } from '../../../../components/ToastComponent';
import TokenService from '../../../../services/Auth/token.service';

interface ImportMapaDisponibilidadeModalProps {
    showModal: boolean;
    onHide: () => void;
}
interface Anexo {
    nome: string;
    alias: string;
    extensao: string;
    conteudo: string;
}

const ImportMapaDisponibilidadeModal: React.FC<ImportMapaDisponibilidadeModalProps> = ({
    showModal,
    onHide,
}) => {
    const [anexo, setAnexos] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>(null);
    const fileUploadRef = useRef<FileUpload>(null);
    const handleFileSelect = (e: { files: File[] }) => {
        e.files.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                const conteudo = reader.result as string;
                const novoAnexo: Anexo = {
                    nome: file.name.split(' ').join('_'),
                    alias: file.name,
                    extensao: file.name.split('.').pop() || '',
                    conteudo: conteudo
                };
                setAnexos((anexosAnteriores: any[]) => [...anexosAnteriores, novoAnexo]);
            };
            reader.readAsDataURL(file);
        });
    };
    const buscarMapa = async () => {
        try {
            const response = await api.get(`${url}/api/v1/admin/vendas/pegar-mapa/${TokenService.getEmpreendimentoId()?.id}`);
            if (response.status === 200) {
                setAnexos((prevGrupoData) => {
                    const anexosFormatados = [response?.data?.data?.anexo?.data[0]].map((item: any) => ({
                        id: item.id,
                        nome: item.nome,
                        alias: item.alias,
                        extensao: item.extensao,
                        conteudo: item.url,
                        url: item.url,
                        objectURL: item.url_thumb
                    }));
                    return anexosFormatados;
                });
            }
        } catch (error: any) {
            const mensagens: string[] = [error?.response?.data?.error?.message];
            showToast('error', mensagens.join(', '));
        }
    };
    useEffect(() => {
        if (anexo?.length > 0) {
            setFormData((form: any) => {
                return {
                    ...form,
                    anexo: anexo[0],
                    empreendimento_id: TokenService?.getEmpreendimentoId()?.id
                }
            });
        }
    }, [anexo]);
    useEffect(() => {
        buscarMapa();
    }, []);
    async function salvarMapa() {
        try {
            await api.post(`${url}/api/v1/admin/vendas/salvar-mapa`, formData);
            showToast('success', 'Mapa de disponibilidade salvo com sucesso!');
            onHide();
        } catch (error) {
            showToast('error', 'Ocorreu um erro ao salvar o mapa de disponibilidade. Erro:' + String(error));
        }
    }
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-400)' }}></i>
                <span style={{ fontSize: '1.3em', color: 'var(--surface-600)' }}>
                    Arraste e solte a imagem aqui
                </span>
            </div>
        );
    };

    const chooseOptions = {
        icon: 'pi pi-fw pi-images',
        iconOnly: true,
        className: 'custom-choose-btn p-button-rounded p-button-outlined',
        style: { borderRadius: '50%' }
    };

    const cancelOptions = {
        icon: 'pi pi-fw pi-times',
        iconOnly: true,
        className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
        style: { borderRadius: '50%' }
    };

    const headerTemplate = (options: { className: any; chooseButton: any; cancelButton: any; }) => {
        const { className, chooseButton, cancelButton } = options;
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    };

    const onTemplateRemove = (file: { name: string; size: number; }, callback: () => void) => {
        setAnexos((anexosAnteriores: any) => anexosAnteriores.filter((anexo: any) => anexo.nome !== file.name));
        callback();
    };

    const itemTemplate = (file: any, props: any) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Button
                    type="button"
                    icon="pi pi-times"
                    style={{ borderRadius: '50%' }}
                    className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                    onClick={() => {
                        onTemplateRemove(file, props.onRemove)
                    }
                    }
                />
            </div>
        );
    };
    const onRemove = (file: Anexo) => {
        setAnexos(anexosAnteriores => anexosAnteriores.filter(anexo => anexo.nome !== file.nome));
        showToast('success', 'Arquivo removido')
    };
    const redirectFile = (url_redirect: string, nameFile: string) => {
        const url = new URL(url_redirect);

        if (!/^https?:$/.test(url.protocol)) {
            throw new Error('URL com protocolo inválido');
        }
        window.open(url.href, '_blank');
    };

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="lg"
            className="modal-filtro-avancado"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Importar Mapa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="flex flex-wrap">
                        {anexo?.map((file: any) => (
                            file?.objectURL ? (
                                <div key={file.nome} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', backgroundColor: '#494949', marginRight: '10px', marginBottom: '10px', padding: '0px' }}>
                                    <div style={{ backgroundColor: '#b9b7b7', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingLeft: '5px', paddingBottom: '2px', paddingTop: '2px' }}>
                                        <a
                                            type='submit'
                                            onClick={() => redirectFile(file.conteudo, file.alias)}
                                            style={{ display: 'flex', margin: '8px' }}
                                        >
                                            <strong>{file.alias || file.nome}</strong>
                                        </a>
                                        <Button
                                            icon="pi pi-times"
                                            style={{ backgroundColor: '#4949498a', marginRight: '15px', width: '30px', height: '30px', borderRadius: '7px' }}
                                            onClick={() => onRemove(file)}
                                        />
                                    </div>
                                </div>
                            ) : null
                        ))}
                    </div>
                    <FileUpload ref={fileUploadRef} name="demo[]" multiple accept='.svg'
                        onSelect={handleFileSelect}
                        onClear={() => setAnexos([])}
                        headerTemplate={headerTemplate}
                        itemTemplate={itemTemplate}
                        emptyTemplate={emptyTemplate}
                        chooseOptions={chooseOptions}
                        cancelOptions={cancelOptions}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                {anexo?.length > 0 && <Button severity='success' onClick={salvarMapa}>Salvar Mapa</Button>}
            </Modal.Footer>
        </Modal>
    );
};

export default ImportMapaDisponibilidadeModal;
