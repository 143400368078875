import 'react-datepicker/dist/react-datepicker.css';
import { InputNumber } from "primereact/inputnumber";
import { area_comum, area_construida, area_privativa, area_total, area_util, handleInputChange } from "../Service/ImovelService";

interface DimensoesFormProps {
    formData: any,
    setFormData: (value: any) => void,
}
function DimensoesForm({
    formData,
    setFormData,
}: DimensoesFormProps) {

    return (
        <fieldset className="w-full border p-4 rounded mb-4">
            <legend className="font-bold text-lg">Dimensões do Imóvel</legend>
            <div className="grid grid-cols-5 gap-4">
                <div className="flex flex-col">
                    <label htmlFor="area_util" className="mb-1 font-medium">Área Útil</label>
                    <InputNumber
                        id="area_util"
                        value={formData?.area_util}
                        invalid={!formData?.area_util}
                        tooltip={area_util}
                        onChange={(e) => handleInputChange("area_util", e.value, setFormData)}
                        placeholder="Digite a Área Útil"
                        tooltipOptions={{ position: 'bottom', style: { width: '30%' } }}
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={24}
                        suffix=" m²"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="area_construida" className="mb-1 font-medium">Área construída</label>
                    <InputNumber
                        tooltip={area_construida}
                        invalid={!formData?.area_construida}
                        id="area_construida"
                        value={formData?.area_construida}
                        onChange={(e) => handleInputChange("area_construida", e.value, setFormData)}
                        placeholder="Digite a Área construída"
                        tooltipOptions={{ position: 'bottom', style: { width: '30%' } }}
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={24}
                        suffix=" m²"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="area_privativa" className="mb-1 font-medium">Área privada</label>
                    <InputNumber
                        id="area_privativa"
                        value={formData?.area_privativa}
                        tooltip={area_privativa}
                        onChange={(e) => handleInputChange("area_privativa", e.value, setFormData)}
                        placeholder="Digite a Área privada"
                        tooltipOptions={{ position: 'bottom', style: { width: '30%' } }}
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={24}
                        suffix=" m²"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="area_comum" className="mb-1 font-medium">Área comum</label>
                    <InputNumber
                        id="area_comum"
                        value={formData?.area_comum}
                        tooltip={area_comum}
                        onChange={(e) => handleInputChange("area_comum", e.value, setFormData)}
                        placeholder="Digite a Área comum"
                        tooltipOptions={{ position: 'bottom', style: { width: '30%' } }}
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={24}
                        suffix=" m²"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="area_total" className="mb-1 font-medium">Área total</label>
                    <InputNumber
                        id="area_total"
                        value={formData?.area_total}
                        tooltip={area_total}
                        onChange={(e) => handleInputChange("area_total", e.value, setFormData)}
                        placeholder="Digite a Área total"
                        tooltipOptions={{ position: 'bottom', style: { width: '20%' } }}
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={24}
                        suffix=" m²"
                    />
                </div>
            </div>
        </fieldset>
    );
}

export default DimensoesForm;
