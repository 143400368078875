import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import TokenService from '../../../services/Auth/token.service';
import ContaFinanceiraForm from './Formularios/ContaFinanceiraForm';

function CreateContaFinanceira() {
    const { setUrl, setBody, setShowButtonSave, setItemsBreadsCrumbs } = useAppContext();
    const [formBasico, setFormBasico] = useState<any>({});
    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Financeiro',
                url: '/financeiro',
            },
            {
                label: 'Conta Financeira',
                url: '/financeiro/conta-financeira',
            },
            {
                label: 'Criar'
            }
        ]);
        setUrl(`${url}/api/v1/admin/financeiro/conta-financeira`);
        setFormBasico((prevData: any) => {
            return {
                ...prevData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id
            };
        });
    }, []);

    const handleChangeInformacaoBancaria = (field: string, value: any) => {
        if (setFormBasico) {
            if (formBasico) {
                if (field.startsWith('informacao_bancaria.')) {
                    const informacaoField = field.replace('informacao_bancaria.', '');
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            informacao_bancaria: {
                                ...prevGrupoData.informacao_bancaria,
                                [informacaoField]: value,
                            },
                        };
                    });
                }
            }
        }
    }

    const handleChange = (field: string, value: string) => {
        if (setFormBasico) {
            if (formBasico) {
                setFormBasico((prevGrupoData: any) => {
                    return {
                        ...prevGrupoData,
                        [field]: value,
                    };
                });
            }
        }
    }

    useEffect(() => {
        setBody(formBasico)
        setShowButtonSave(true);
    }, [formBasico]);
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar Conta Financeira</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <ContaFinanceiraForm
                            initialRightValues={[]}
                            formBasico={formBasico}
                            onInputChangeInformacaoBancaria={(field: string, value: any) => handleChangeInformacaoBancaria(`informacao_bancaria.${field}`, value)}
                            onInputChange={(field: string, value: any) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateContaFinanceira;
