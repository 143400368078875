import React from 'react';
import { Modal, Row, Col, Form, Container } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

interface FiltroAvancadoModalProps {
    showModal: boolean;
    onHide: () => void;
    handleClose: () => void;
    listFiltros: any;
    setListFiltros: (list: any) => void;
    setShowModalFiltro: (show: boolean) => void;
    handleFilter: () => void;
    handleClearFilters: () => void;
}
const FiltroAvancadoModal: React.FC<FiltroAvancadoModalProps> = ({
    showModal,
    onHide,
    handleClose,
    listFiltros,
    setListFiltros,
    setShowModalFiltro,
    handleFilter,
    handleClearFilters,
}) => {

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });
    
    return (
        <Modal
            show={showModal}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modal-input-venda"
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtros Avançados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <Form.Label>Tipo Consulta</Form.Label>
                            <Form.Select
                                value={listFiltros.tipo_consulta || ''}
                                onChange={(e) =>
                                    setListFiltros({ ...listFiltros, tipo_consulta: e.target.value })
                                }
                            >
                                <option defaultValue={''}>Selecione...</option>
                                <option value="start_data_import">Inicio da Importação</option>
                                <option value="created_at">Criação da Importação</option>
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Label>Data Início</Form.Label>
                            <Calendar
                                value={listFiltros.dataInicio || null}
                                onChange={(event) =>
                                    setListFiltros({ ...listFiltros, dataInicio: event.value })
                                }
                                placeholder="Data Início"
                                showButtonBar
                                selectionMode="single"
                                dateFormat="dd/mm/yy"
                                locale="pt"
                                readOnlyInput
                                style={{ textAlign: 'center', width: '100%', height: '29px' }}
                                panelClassName="custom-calendar-panel"
                            />
                        </Col>
                        <Col>
                            <Form.Label>Data Fim</Form.Label>
                            <Calendar
                                value={listFiltros.dataFim || null}
                                onChange={(event) =>
                                    setListFiltros({ ...listFiltros, dataFim: event.value })
                                }
                                placeholder="Data Fim"
                                showButtonBar
                                selectionMode="single"
                                dateFormat="dd/mm/yy"
                                locale="pt"
                                readOnlyInput
                                style={{ textAlign: 'center', width: '100%' }}
                                panelClassName="custom-calendar-panel"
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        setShowModalFiltro(false);
                        handleFilter();
                    }}
                    style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px', fontSize: '14px', marginLeft: '10px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded>
                    <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                </Button>
                <Button
                    onClick={() => {
                        handleClearFilters();
                        setShowModalFiltro(false);
                    }}
                    style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px ', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded>
                    <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltroAvancadoModal;
