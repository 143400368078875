import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ModalPessoaForm from './ModalPessoaForm';
import { addEndereco, changeEndereco, removeEndereco } from '../../../../utils/EnderecoUtils';
import { addTelefone, changeTelefone, removeTelefone } from '../../../../utils/TelefoneUtils';
import { Steps } from 'primereact/steps';
import { showToast } from '../../../../components/ToastComponent';

interface ModalPessoaProps {
    formData: boolean;
    showModal: boolean;
    handleClose: () => void;
    onInputChange: (field: string, value: string) => void;
}

const ModalPessoa: React.FC<ModalPessoaProps> = ({ formData, showModal, handleClose, onInputChange }) => {
    const [show, setShow] = useState(showModal);
    const [formBasico, setFormBasico] = useState<any | null>(formData || null);
    const [currentPart, setCurrentPart] = useState(0);
    const totalParts = 2;
    const handleNext = () => {
        if (!formBasico?.pessoa?.cpf_cnpj && currentPart === 0) {
            showToast("error", "O campo CPF ou CNPJ é obrigatório!")
            return;
        }
        if (formBasico?.pessoa && !formBasico?.pessoa?.nome && currentPart === 0) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (formBasico?.pessoa && formBasico.pessoa.cpf_cnpj.length > 14 && !formBasico?.pessoa?.razao_social && currentPart === 0) {
            showToast("error", "O campo Razão social é obrigatório!")
            return;
        }
        if (formBasico?.pessoa && !formBasico.pessoa.data_fundacao && formBasico.pessoa.cpf_cnpj.length > 14 && currentPart === 0) {
            showToast("error", "O campo de Data de Fundação é obrigatório!")
            return;
        }
        if (formBasico?.pessoa && formBasico?.pessoa?.enderecos && formBasico?.pessoa?.enderecos.length === 0 && currentPart === 1) {
            showToast("error", "É necessario incluir ao menos um endereço!")
            return;
        }
        if (formBasico?.pessoa && !formBasico?.pessoa?.enderecos && currentPart === 1) {
            showToast("error", "É necessario incluir ao menos um endereço!")
            return;
        }
        if (formBasico?.pessoa && formBasico?.pessoa?.telefones && formBasico?.pessoa?.telefones.length === 0 && currentPart === 2) {
            showToast("error", "É necessario incluir ao menos um telefone!")
            return;
        }
        if (formBasico?.pessoa && !formBasico?.pessoa?.telefones && currentPart === 2) {
            showToast("error", "É necessario incluir ao menos um telefone!")
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };
    const handleChange = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                pessoa: {
                    ...prevGrupoData?.pessoa,
                    [field]: value
                }
            };
        });
    }
    const handleSaveButton = () => {
        if (!formBasico?.pessoa?.cpf_cnpj && currentPart === 0) {
            showToast("error", "O campo CPF ou CNPJ é obrigatório!")
            return;
        }
        if (formBasico?.pessoa && !formBasico?.pessoa?.nome && currentPart === 0) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (formBasico?.pessoa && formBasico.pessoa.cpf_cnpj.length > 14 && !formBasico?.pessoa?.razao_social && currentPart === 0) {
            showToast("error", "O campo Razão social é obrigatório!")
            return;
        }
        if (formBasico?.pessoa && !formBasico.pessoa.data_fundacao && formBasico.pessoa.cpf_cnpj.length > 14 && currentPart === 0) {
            showToast("error", "O campo de Data de Fundação é obrigatório!")
            return;
        }
        if (formBasico?.pessoa && formBasico?.pessoa?.enderecos && formBasico?.pessoa?.enderecos.length === 0 && currentPart === 1) {
            showToast("error", "É necessario incluir ao menos um endereço!")
            return;
        }
        if (formBasico?.pessoa && !formBasico?.pessoa?.enderecos && currentPart === 1) {
            showToast("error", "É necessario incluir ao menos um endereço!")
            return;
        }
        if (formBasico?.pessoa && formBasico?.pessoa?.telefones && formBasico?.pessoa?.telefones.length === 0 && currentPart === 2) {
            showToast("error", "É necessario incluir ao menos um telefone!")
            return;
        }
        if (formBasico?.pessoa && !formBasico?.pessoa?.telefones && currentPart === 2) {
            showToast("error", "É necessario incluir ao menos um telefone!")
            return;
        }
        onInputChange('pessoa_nome', formBasico?.pessoa?.razao_social);
        onInputChange('pessoa', formBasico.pessoa);
        setCurrentPart(0)
        handleClose();
    };
    const handleBack = () => {
        if (currentPart > 0) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };
    useEffect(() => {
        setShow(showModal);
    }, [showModal]);

    // Endereco Formulario Utils
    const handleEnderecoInputChange = (index: number, field: string, value: string) => {
        changeEndereco(formBasico, setFormBasico, index, field, value);
    };
    const handleAddEndereco = () => {
        addEndereco(formBasico, setFormBasico);
    };
    const handleRemoveEndereco = (index: number) => {
        removeEndereco(formBasico, setFormBasico, index);
    };

    // Telefone Formulario Utils
    const handleTelefoneInputChange = (index: number, field: string, value: string) => {
        changeTelefone(formBasico, setFormBasico, index, field, value);
    };
    const handleAddTelefone = () => {
        addTelefone(formBasico, setFormBasico);
    };
    const handleRemoveTelefone = (index: number) => {
        removeTelefone(formBasico, setFormBasico, index);
    };
    const items = [
        {
            label: 'Pessoa',
            command: (event: any) => {
                setCurrentPart(event.index)
            }
        },
        {
            label: 'Endereço',
            command: (event: any) => {
                setCurrentPart(event.index)
            }
        },
        {
            label: 'Telefone',
            command: (event: any) => {
                setCurrentPart(event.index)
            }
        },
    ];
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size='xl'
        >
            <Modal.Header style={{ display: 'flex', flexDirection: 'column' }}>
                <Modal.Title style={{ marginBottom: '25px', color: '#494949' }}>Cadastro de Pessoa</Modal.Title>
                <div style={{ display: 'block', width: '-webkit-fill-available' }}>
                    <Steps model={items} activeIndex={currentPart} readOnly={false} />
                </div>
            </Modal.Header>
            <Modal.Body style={{ padding: '0px' }}>
                <ModalPessoaForm
                    formBasico={formBasico}
                    onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                    onInputChangeEndereco={(index: number, field: string, value: any) => handleEnderecoInputChange(index, field, value)}
                    onAddEndereco={handleAddEndereco}
                    onRemoveEndereco={handleRemoveEndereco}
                    onInputChangeTelefone={(index: number, field: string, value: any) => handleTelefoneInputChange(index, field, value)}
                    onAddTelefone={handleAddTelefone}
                    onRemoveTelefone={handleRemoveTelefone}
                    currentPart={currentPart}
                />
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', padding: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '15px' }}>
                    <Button onClick={() => { handleClose() }} className="button-back-form" variant="primary">
                        Fechar
                    </Button>
                    {currentPart !== 0 && (
                        <Button onClick={() => { handleBack() }} className="button-back-form" variant="primary">
                            Voltar
                        </Button>
                    )}
                    {currentPart !== totalParts && (
                        <Button className="button-next-form" variant="primary" onClick={handleNext}>
                            Próximo
                        </Button>
                    )}
                    {currentPart === totalParts && (<Button className='button-save-or-update' onClick={() => handleSaveButton()} variant="success">Salvar</Button>)}
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalPessoa;
