import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import PessoaForm from '../../Formularios/pessoa';
import EnderecoForm from './Formularios/enderecoEdit';
import TelefoneForm from '../../Formularios/telefone';
import { showToast } from '../../../components/ToastComponent';
import { addTelefone, changeTelefone, removeTelefone } from '../../../utils/TelefoneUtils';
import { addEndereco, changeEndereco, removeEndereco } from '../../../utils/EnderecoUtils';
import { changeUsuario } from '../../../utils/UsuarioUtils';
import ProgressBarForm from '../../Formularios/progressBarForm';
import { AlertService } from '../../../components/AlertService';
import TokenService from '../../../services/Auth/token.service';
import ClientesForm from './Formularios/ClientesForm';

interface PropsData {
    observacao: string,
    corretor_id: string,
    status: string,
    empreendimento_id: string,
    inibe_remessa: string,
    segurado: string,
    cad_desatualizado: string,
    negativado: string,
    pessoa: pessoa,
    conjugue: pessoa
}
interface pessoa {
    id: string,
    nome: string,
    email: string,
    cpf_cnpj: string,
    sexo: string,
    razao_social: string,
    inscricao_municipal: string,
    inscricao_estadual: string,
    filiacao_mae: string,
    filiacao_pai: string,
    rg: string,
    estado_civil: string,
    regime_uniao: string,
    data_nascimento: string,
    data_fundacao: string,
    enderecos: [],
    telefones: [],
}
function EditClientes() {
    const { setUrl, setTypeRequest, setBody, setItemsBreadsCrumbs } = useAppContext();
    const [currentPart, setCurrentPart] = useState(1);
    const [totalParts, setTotalParts] = useState(4)
    const [steps, setSteps] = useState<string[]>(["Pessoa", "Cliente", "Endereço", "Telefone"]);
    const [formBasico, setFormBasico] = useState<any>({
        observacao: '',
        corretor_id: '',
        status: '',
        empreendimento_id: '',
        inibe_remessa: '',
        segurado: '',
        cad_desatualizado: '',
        negativado: '',
        pessoa: {
            nome: '',
            email: '',
            cpf_cnpj: '',
            sexo: '',
            razao_social: '',
            inscricao_municipal: '',
            inscricao_estadual: '',
            filiacao_mae: '',
            filiacao_pai: '',
            rg: '',
            estado_civil: '',
            regime_uniao: '',
            data_nascimento: '',
            data_fundacao: '',
            enderecos: [],
            telefones: [],
        },
    });
    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Pessoas',
                    url: '/pessoas',
                },
                {
                    label: 'Compradores',
                    url: '/cliente',
                },
                {
                    label: 'Editar'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setFormBasico((data: PropsData) => {
                    return {
                        ...data,
                        ...JSON.parse(storedData),
                        pessoa: {
                            ...data.pessoa,
                            ...JSON.parse(storedData).pessoa,
                            enderecos: [...JSON.parse(storedData).pessoa.enderecos.data],
                            telefones: [...JSON.parse(storedData).pessoa.telefones.data],
                        },
                        ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id
                    }
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/financeiro/cliente/${storedData && JSON.parse(storedData).id}`);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);
    const handleNext = () => {
        if (!formBasico) {
            showToast("error", "Os campos de Nome e CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formBasico?.pessoa?.cpf_cnpj) {
            showToast("error", "O campo CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formBasico.pessoa.nome) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (!formBasico.pessoa.data_fundacao && formBasico?.pessoa?.cpf_cnpj.length > 14) {
            showToast("error", "O campo de Data de Fundação é obrigatório!")
            return;
        }
        if (formBasico.pessoa.enderecos && formBasico.pessoa.enderecos.length === 0 && currentPart === 4) {
            showToast("error", "O comprador deve possuir ao menos um endereço!")
            return;
        }
        if (!formBasico.pessoa.enderecos && currentPart === 4) {
            showToast("error", "O comprador deve possuir ao menos um endereço!")
            return;
        }

        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    useEffect(() => {
        setBody(formBasico);
    }, [formBasico]);

    const handleChange = (field: string, value: string) => {
        if (setFormBasico) {
            let newForm: PropsData = { ...formBasico,
                pessoa: { enderecos: [], telefones: [] },
                configVendas: {},
                configCarteira: {},
            };

            if (formBasico) {
                if (field.startsWith('pessoa.')) {
                    const pessoaField = field.replace('pessoa.', '');
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            pessoa: {
                                ...newForm.pessoa,
                                [pessoaField]: value,
                            },
                        };
                    });
                } else if (field.startsWith('conjugue.')) {
                    const conjugueField = field.replace('conjugue.', '');
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            conjugue: {
                                ...newForm.conjugue,
                                [conjugueField]: value,
                            },
                        };
                    });
                } else {
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            [field]: value,
                        };
                    });
                }
            }
        }
    };

    // Endereco Formulario Utils
    const handleEnderecoInputChange = (index: number, field: string, value: string) => {
        changeEndereco(formBasico, setFormBasico, index, field, value)
    };

    const handleAddEndereco = () => {
        addEndereco(formBasico, setFormBasico);
    };
    const handleRemoveEndereco = (index: number) => {
        removeEndereco(formBasico, setFormBasico, index);
    };

    // Telefone Formulario Utils
    const handleTelefoneInputChange = (index: number, field: string, value: string) => {
        changeTelefone(formBasico, setFormBasico, index, field, value);
    };
    const handleAddTelefone = () => {
        addTelefone(formBasico, setFormBasico);
    };
    const handleRemoveTelefone = (index: number) => {
        removeTelefone(formBasico, setFormBasico, index);
    };
    useEffect(() => {
        if (formBasico?.pessoa.estado_civil === '2' || formBasico?.pessoa.estado_civil === '6') {
            setTotalParts(5);
            setSteps(["Pessoa", "Cliente", "Endereço", "Telefone", "Cônjugue"]);
        } else {
            setTotalParts(4);
            setSteps(["Pessoa", "Cliente", "Endereço", "Telefone"]);
        }
    }, [formBasico]);

    return (
        <div className="body-content">
            <div className="form-user-create">
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={steps}
                        body={formBasico}
                    >
                        {currentPart === 1 && (
                            <PessoaForm
                                isEnablePerfil={false}
                                tipoPessoa={1}
                                tipoAcao={"editar"}
                                pessoaData={formBasico?.pessoa}
                                onInputChange={(field: string, value: string) => handleChange(`pessoa.${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <ClientesForm
                                clienteData={formBasico}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <EnderecoForm
                                enderecosData={formBasico?.pessoa?.enderecos || []}
                                onInputChange={(index: number, field: string, value: string) => handleEnderecoInputChange(index, field, value)}
                                onAddEndereco={handleAddEndereco}
                                onRemoveEndereco={handleRemoveEndereco}
                            />
                        )}
                        {currentPart === 4 && (
                            <TelefoneForm
                                telefonesData={formBasico?.pessoa?.telefones || []}
                                onInputChange={(index: number, field: string, value: string) => {
                                    if (formBasico?.pessoa?.telefones && formBasico?.pessoa?.telefones?.length > 0) {
                                        handleTelefoneInputChange(index, field, value);
                                    } else {
                                        handleAddTelefone();
                                    }
                                }}
                                onAddTelefone={() => handleAddTelefone()}
                                onRemoveTelefone={(index: number) => handleRemoveTelefone(index)}
                            />
                        )}
                    </ProgressBarForm>

                </Container>
            </div>
        </div>
    );
}

export default EditClientes;
