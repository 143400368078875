import styled, { keyframes } from "styled-components";
import { shade } from "polished";

import signInBackgroundImg from "../Login/logo/carrosel1.png";


export const Container = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  width: 50.9%;
  height: 100%;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    max-width: 100%;
  }
`;

export const Background = styled.div`
  flex: 1;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2); /* Ajuste a opacidade conforme necessário */
  }
  @media (max-width: 992px) {
    display: none;
  }
`;

const AppearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${AppearFromLeft} 1s;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
      color: #fff !important;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    a {
      color: #e8550f;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, "#e8550f")};
      }
    }
  }

  > a {
    color: #e8550f;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      &:hover {
        color: ${shade(0.2, "#e8550f")};
      }
    }
  }
`;

