import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { showToast } from "../../../../components/ToastComponent";
import { searchProduto } from "../Services/VendaService";
import { InputNumber } from "primereact/inputnumber";
import { url } from "../../../../environments/environments-develop";
import api from "../../../../services/api";
import TokenService from "../../../../services/Auth/token.service";
import { isNullOrUndefined } from "util";

function ProdutosServicos(props: any) {
    const { produto_servicos } = props.produtoData || [];
    const [filteredProduto, setFilteredProduto] = useState<any>(null);
    const [formData, setFormData] = useState<any>({
        produto_id: null,
        produto: null,
        quantidade: 1,
        tipo_desconto: 2,
        desconto: 0,
        isTaxa: false
    });
    const handleChange = (field: string, value: any) => {
        setFormData((prevGrupoData: any) => ({
            ...prevGrupoData,
            [field]: value
        }));
    };
    const MoneyFormatter = (valor: number): string => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(valor);
    };

    async function actionButton(): Promise<void> {
        try {
            setFormData((prevFormData: any) => {
                let vendaItens = props.produtoData?.venda_itens?.data || [];
                let updatedProduto = produto_servicos;

                vendaItens.forEach((item: any) => {
                    if (!updatedProduto.some((p: any) => p.produto_id === item.produto_id && p.isTaxa != true)) {
                        updatedProduto.push(item);
                    } else {
                        showToast('error', 'Este Produto já foi adicionado');
                    }
                });
                const newProduto = {
                    produto_id: formData?.produto_id ?? null,
                    produto: formData?.produto ?? null,
                    quantidade: formData?.quantidade ?? 1,
                    tipo_desconto: formData?.tipo_desconto ?? 2,
                    desconto: formData?.desconto ?? 0,
                    isTaxa: isNullOrUndefined(formData?.produto?.tipo_manutencao) ? false : true,
                };
                if (!newProduto?.produto_id || !newProduto?.quantidade) {
                    showToast('warn', 'Inclua um produto e sua quantidade!');
                    return prevFormData;
                };
                if (!updatedProduto.some((p: any) => p.produto_id === newProduto.produto_id)) {
                    if (newProduto?.isTaxa) {
                        if (!updatedProduto.some((p: any) => p.isTaxa === true)) {
                            updatedProduto.push(newProduto);
                        } else {
                            showToast('warn', 'Já existe uma taxa de manutenção inclusa na lista!');
                            return prevFormData;
                        }
                    } else {
                        updatedProduto.push(newProduto);
                    }
                } else {
                    showToast('warn', 'Este produto já foi incluído!');
                    return prevFormData;
                }
                setFormData({
                    produto_id: null,
                    produto: null,
                    quantidade: 1,
                    tipo_desconto: 2,
                    desconto: 0,
                });
                props.onInputChange('produto_servicos', updatedProduto);
                return {
                    ...prevFormData,
                    produto_servicos: updatedProduto,
                };
            });
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.message || 'Erro desconhecido');
            showToast('error', mensagens.join(', '));
        }
    };

    const coef = props?.produtoData?.coef_manutencao ?? null;
    const empreendimentoId = TokenService.getEmpreendimentoId()?.id;

    const [linha, setLinha] = useState<any>([]);
    const bodyTaxas = {
        "empreendimento_id": empreendimentoId,
        "coef_manutencao": coef
    }

    async function buscarTaxas() {
        try {
            const response = await api.post(`${url}/api/v1/admin/cemiterio/buscar-taxas`, bodyTaxas);
            const data = response?.data?.data;
            let formattedData: any[] = [];
            let updatedProduto = produto_servicos || [];
            if (coef) {
                if (data?.manutencao && data?.manutencao?.indice) {
                    const manutencao = data?.manutencao;
                    const newProduto = {
                        produto_id: manutencao?.indice?.id,
                        produto: {
                            descricao_resumida: manutencao?.indice?.nome_servico,
                            valor: parseCurrency(manutencao?.indice?.valor_taxa),
                        },
                        quantidade: 1,
                        tipo_desconto: 2,
                        desconto: 0,
                        isTaxa: true
                    };
                    if (Array.isArray(updatedProduto) && !updatedProduto.some((p: any) => p.produto_id === newProduto.produto_id)) {
                        if (!updatedProduto.some((p: any) => p.isTaxa === true)) {
                            formattedData.push(newProduto);
                        }
                    }
                }
            }
            setLinha(formattedData);
            let updatedProdutoServicos = [...updatedProduto, ...formattedData];
            props.onInputChange('produto_servicos', updatedProdutoServicos);

        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.message || 'Erro desconhecido');
            showToast('error', mensagens.join(', '));
        }
    };

    const parseCurrency = (value: string): number => {
        return parseFloat(value.replace(/[R$\s.]/g, '').replace(',', '.')) || 0;
    };

    useEffect(() => {
        buscarTaxas();
    }, []);

    const tableData = produto_servicos || [];

    const removeItem = (item: any): void => {
        const updatedProdutoServicos = (produto_servicos || []).filter((p: any) => p.produto_id !== item.produto_id);
        if (item.isTaxa) {
            const updatedLinha = (linha || []).filter((p: any) => p.produto_id !== item.produto_id);
            setLinha(updatedLinha);
        }
        props.onInputChange('produto_servicos', updatedProdutoServicos);
    };

    const actionTrash = (rowData: any) => {
        return (
            <Button
                icon="pi pi-trash"
                style={{ width: '25px', height: '25px', border: 'none', borderRadius: '4px', backgroundColor: 'red', marginRight: '10px' }}
                className="ms-2 button-delete-Endereco"
                onClick={() => removeItem(rowData)}
            />
        );
    };

    const formatDesconto = (desconto: any, tipoDesconto: any) => {
        if (tipoDesconto === '2') {
            if (typeof desconto !== 'number') {
                desconto = parseFloat(desconto) || 0;
            }
            return desconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        } else if (tipoDesconto === '1') {
            return `${desconto}%`;
        }
        return desconto;
    };

    return (
        <div style={{ minHeight: '350px', paddingLeft: '16px', paddingRight: '16px' }}>
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#4949498a', paddingBottom: '15px', paddingTop: '5px', borderRadius: '7px' }}>
                <Col md="10">
                    <Row>
                        <Col md="5" style={{ marginTop: '16px', paddingTop: '10px', paddingRight: '6px' }}>
                            <span className="p-float-label">
                                <AutoComplete
                                    field="nome_servico"
                                    style={{ height: "37px", width: '100%' }}
                                    value={formData?.produto}
                                    suggestions={filteredProduto}
                                    completeMethod={(e) => searchProduto(e, 0, setFilteredProduto)}
                                    onChange={(e) => handleChange('produto', e.target.value)}
                                    onSelect={(e) => {
                                        handleChange('produto', e.value);
                                        handleChange('produto_id', e.value.id);
                                    }}
                                    dropdown
                                />
                                <label htmlFor="ac">Produtos | Serviços</label>
                            </span>
                        </Col>
                        {formData?.produto && (
                            <div style={{ width: '9%', marginTop: '14px', paddingLeft: '0px', marginRight: '3px' }}>
                                <Form.Label style={{ margin: '0px', padding: '0px' }}>
                                    <p style={{
                                        margin: '0px',
                                        padding: '3px',
                                        backgroundColor: '#494949',
                                        color: '#fff',
                                        border: '1px solid #494949',
                                        borderTopRightRadius: '4px',
                                        borderTopLeftRadius: '4px'
                                    }}>
                                        Vlr.Tabela
                                    </p>
                                    <p style={{
                                        margin: '0px',
                                        padding: '3px',
                                        backgroundColor: '#e8550f',
                                        color: '#fff',
                                        border: '1px solid #e8550f',
                                        borderBottomRightRadius: '4px',
                                        borderBottomLeftRadius: '4px'
                                    }}>
                                        {MoneyFormatter(formData?.produto?.valor ?? 0)}
                                    </p>
                                </Form.Label>
                            </div>
                        )}
                        <Col md="1" style={{ marginTop: '26px', padding: '0px', width: '7%' }}>
                            <span className="p-float-label">
                                <InputNumber
                                    style={{ fontSize: '16px', width: '50px' }}
                                    disabled={!formData?.produto}
                                    value={formData?.quantidade ?? 1}
                                    onValueChange={(e) => handleChange('quantidade', e.value)}
                                    mode="decimal"
                                    showButtons
                                    min={0}
                                    max={100}
                                />
                            </span>
                        </Col>
                        <Col md="1" style={{ width: '7%', marginTop: '26px', padding: '0px', marginRight: '10px' }}>
                            <Form.Select
                                style={{ width: '100%' }}
                                disabled={!formData?.produto && !formData?.quantidade}
                                value={formData?.tipo_desconto}
                                onChange={(e) => handleChange('tipo_desconto', e.target.value)}
                            >
                                <option value={1}>%</option>
                                <option value={2}>R$</option>
                            </Form.Select>
                        </Col>
                        <Col md="2" style={{ marginTop: '26px', padding: '0px' }}>
                            <span className="p-float-label">
                                <InputNumber
                                    disabled={!formData?.produto && !formData?.quantidade}
                                    style={{ width: '100%', height: '38px' }}
                                    value={formData?.desconto ? formData.desconto : null}
                                    onChange={(e: any) => handleChange('desconto', e.value)}
                                    locale="pt-BR"
                                    minFractionDigits={2}
                                    maxFractionDigits={24}
                                />
                                <label htmlFor="ac">Desconto Unit.</label>
                            </span>
                        </Col>
                        {formData?.produto && (
                            <div style={{ width: '9%', marginTop: '14px', marginRight: '10px', paddingLeft: '8px', paddingRight: '0px' }}>
                                <Form.Label style={{ margin: '0px', padding: '0px' }}>
                                    <p style={{
                                        margin: '0px',
                                        padding: '3px',
                                        backgroundColor: '#494949',
                                        color: '#fff',
                                        border: '1px solid #494949',
                                        borderTopRightRadius: '4px',
                                        borderTopLeftRadius: '4px'
                                    }}>
                                        Vlr.Liquido:
                                    </p>
                                    <p style={{
                                        margin: '0px',
                                        padding: '3px',
                                        backgroundColor: '#e8550f',
                                        color: '#fff',
                                        border: '1px solid #e8550f',
                                        borderBottomRightRadius: '4px',
                                        borderBottomLeftRadius: '4px'
                                    }}>
                                        {MoneyFormatter(
                                            (formData?.produto?.valor ?? 0) -
                                            (formData?.tipo_desconto === 1
                                                ? (formData?.desconto / 100) * (formData?.produto?.valor ?? 0)
                                                : formData?.desconto)
                                        )}
                                    </p>
                                </Form.Label>
                            </div>
                        )}
                    </Row>
                </Col>
                <Col md="2" style={{ marginTop: '20px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <Form.Label></Form.Label>
                    <Button
                        disabled={!formData?.produto && !formData?.quantidade}
                        className="button-form"
                        onClick={() => actionButton()}
                        icon="pi pi-plus"
                    >
                        <p style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '13px',
                            marginLeft: '10px'
                        }}>
                            Adicionar
                        </p>
                    </Button>
                </Col>
            </Row >
            <hr />
            <DataTable
                value={tableData}
                paginator
                rows={8}
                rowsPerPageOptions={[8, 16, 25, 50]}
                dataKey="produto_id"
                tableStyle={{ minWidth: '50rem', textAlign: 'center' }}
                emptyMessage={
                    <span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>
                        Nenhum item encontrado.
                    </span>
                }
            >
                <Column style={{ textAlign: 'center' }} field="produto_id" header="Id Prod"></Column>
                <Column style={{ textAlign: 'center' }} field="produto.descricao_resumida" header="Descrição Resumida"></Column>
                <Column style={{ textAlign: 'center' }} field="quantidade" header="Qtd"></Column>
                <Column
                    style={{ textAlign: 'center' }}
                    field="produto.valor"
                    header="Vlr.Unit Bruto"
                    body={(rowData) => MoneyFormatter(rowData.produto?.valor || 0)}
                />
                <Column
                    style={{ textAlign: 'center' }}
                    field="desconto"
                    header="Desconto"
                    body={(rowData) => formatDesconto(rowData.desconto, rowData.tipo_desconto)}
                />
                <Column
                    style={{ textAlign: 'center' }}
                    header="Vlr.Unit c/Desconto"
                    body={(rowData) => {
                        const valorUnitario = rowData.produto?.valor || 0;
                        const desconto = rowData.tipo_desconto === 1
                            ? (rowData.desconto / 100) * valorUnitario
                            : rowData.desconto;
                        return MoneyFormatter(valorUnitario - desconto);
                    }}
                />
                <Column
                    style={{ textAlign: 'center' }}
                    header="Vlr.Total"
                    body={(rowData) => {
                        const quantidade = rowData.quantidade || 1;
                        const valorUnitario = rowData.produto?.valor || 0;
                        const desconto = rowData.tipo_desconto === 1
                            ? (rowData.desconto / 100) * valorUnitario * quantidade
                            : rowData.desconto * quantidade;
                        return MoneyFormatter((valorUnitario * quantidade) - desconto);
                    }}
                />
                <Column
                    style={{ textAlign: 'center' }}
                    body={actionTrash}
                    header="Ações"
                />
            </DataTable>
        </div>
    );

}

export default ProdutosServicos;
