
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { ButtonGroup } from 'primereact/buttongroup';
import { DataView } from 'primereact/dataview';
import { Tag } from 'primereact/tag';
import { ProductService } from './Service/ProductService';
import './Styled/index.css';
import { useAppContext } from '../../../contexts/AppContext';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import iconFiltroAvancado from '../../../../assets/images/icon-filtro-avancado.png';
import { Col, Form, NavLink, Row } from 'react-bootstrap';
import { loadData, setSessionEditData } from './Service/ImovelService';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { useAppContext as useResponseContext } from '../../../contexts/ResponseContext';
import Paginacao from './Components/Paginacao';
import FiltroAvancadoModal from './Components/ModalFiltroAvancado';
import * as Icons from '@mui/icons-material';

interface Product {
  id: string;
  code: string;
  name: string;
  description: string;
  image: string | null;
  price: number;
  category: string;
  quantity: number;
  inventoryStatus: string;
  rating: number;
}

export default function LocacaoImoveis() {
  const [products, setProducts] = useState<Product[]>([]);
  const [layout, setLayout] = useState<any>('list');
  const { setUrl_button_create } = useAppContext();
  const urlRedirectCreated = "/locacao/imovel/store";
  const url_redirect_edit = "/locacao/imovel/update";
  const { showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [formBasico, setFormBasico] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [nodes, setNodes] = useState<any | null>(null);
  const { titles, updateTitles, pagination, updatePagination } = useResponseContext();
  const { setContextItemsButtonMenu } = useAppContext();
  const [show, setShow] = useState(false);
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(8);
  const [sortOptions, setSortOptions] = useState<{ field: string, order: number } | null>(null);
  const { isVisible, updateVisibility } = useSidebarContext();
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro, sortOptions);
  }, [currentPage, sortOptions, itemsPerPage]);

  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Revenda',
        url: '/locacao',
      },
      {
        label: 'Lista de Imóveis',
        url: '/imovel',
      },
    ]);
  }, []);

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  useEffect(() => {
    setUrl_button_create(urlRedirectCreated);
    ProductService.getProducts().then((data) => setProducts(data.slice(0, 12)));
  }, []);
  const getSeverity = (product: any) => {
    switch (product.status) {
      case 1:
        return 'success';

      case 2:
        return 'warning';

      case 5:
        return 'danger';

      default:
        return null;
    }
  };
  const listItem = (product: any) => {
    const imageUrl = product?.imagens?.data?.filter((image: any) => image.principal === true)[0]?.anexo.url;
    return (
      <div className="flex-initial shrink-0 w-full">
        <div className="flex flex-col xl:flex-row xl:items-start p-4 gap-4 bg-white dark:bg-gray-900">
          {imageUrl ? (<img
            className="w-3/4 sm:w-64 xl:w-40 shadow-md block xl:block mx-auto rounded-md"
            src={imageUrl}
            alt={product.name}
          />) : (
            <div className="flex justify-center items-center w-3/4 sm:w-64 xl:w-40 shadow-md h-40 bg-gray-200 rounded-lg">
              <span className="text-gray-500">Sem imagem disponível</span>
            </div>
          )}
          <div className="flex flex-col sm:flex-row justify-between items-center xl:items-start flex-1 gap-4">
            <div className="flex flex-col items-center sm:items-start gap-3">
              <div className="text-2xl font-bold text-gray-700 ">
                {product.titulo} - {product.titulo_reduzido}
              </div>
              <div >
                <span className="text-1lg font-bold text-gray-700 mr-2">{product.subtitulo}</span>
                <span className="text-1sm rounded-md bg-cyan-500 border-cyan-500 font-bold text-white pl-6 pr-6">unid: {product.unidade}</span>
              </div>
              <div className="text-1xs font-bold text-gray-700">
                <p
                  dangerouslySetInnerHTML={{
                    __html: product.descricao_curta.replace(/\n/g, '<br />'),
                  }}
                />
              </div>


              <div className="flex items-center gap-3">
                <span className="flex items-center gap-2">
                  <i className="pi pi-arrows-h text-gray-700 "></i>
                  <span className="font-semibold text-gray-700 ">
                    {product.area_util} m²
                  </span>
                </span>
                <Tag value={product.status_name} severity={getSeverity(product)} />
                {product?.imoveis_caracteristicas_imovel?.data?.length > 0 && (
                  product?.imoveis_caracteristicas_imovel?.data
                    ?.slice(0, 4)
                    ?.map((item: any, index: any) => {
                      // Recupera o componente de ícone correspondente
                      const IconComponent = Icons[item.icone as keyof typeof Icons];

                      return (
                        <Tag key={index} severity="warning">
                          {IconComponent && <IconComponent style={{ height: '20px' }} />} {/* Exibe o ícone, se houver */}
                          {item.descricao}
                        </Tag>
                      );
                    })
                )}
              </div>
            </div>
            <div className="flex sm:flex-col items-end sm:align-end gap-3 sm:gap-2">
              <span className="text-2xl font-semibold text-gray-700">
                {formatMoney(product.aluguel_valor)}
              </span>
              <ButtonGroup>
                <Button
                  className='!bg-blue-500 !border-blue-500 !text-white mt-4'
                  onClick={() => {
                    setSessionEditData(product.id, url_redirect_edit);
                  }}
                  icon="pi pi-pencil"
                  rounded
                  style={{ borderRadius: '8px', marginRight: '10px' }}
                />
                <Button
                  className='!bg-green-500 !border-green-500 !text-white mt-4'
                  onClick={() => {
                    window.location.href = `/locacao/imovel/show/${product.id}`;
                  }}
                  icon="pi pi-chevron-right"
                  rounded
                  style={{ borderRadius: '8px' }}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  };
  const formatMoney = (value: number): string => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };
  const itemTemplate = (product: any, layout: any) => {
    if (!product) {
      return;
    }

    return listItem(product);
  };
  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                style={{ width: '23px' }}
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Imóveis</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <div style={{
            display: "flex",
            justifyContent: 'space-between'
          }}>
            <Row style={{ margin: '10px' }}>
              <Col md={4}>
                <Form.Label>Quadra</Form.Label>
                <Form.Control
                  value={listFiltros?.identificador1 || ''}
                  onChange={(e) => setListFiltros({ ...listFiltros, identificador1: e.target.value })}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Lote</Form.Label>
                <Form.Control
                  value={listFiltros?.identificador2 || ''}
                  onChange={(e) => setListFiltros({ ...listFiltros, identificador2: e.target.value })}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '40px', display: 'flex', justifyContent: 'right' }}>
              <Col style={{ display: 'flex', justifyContent: 'right' }} className="mb-2">
                <Button
                  style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  rounded outlined severity="secondary"
                  onClick={() => setShowModalFiltro(true)}
                >
                  <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                </Button>
                <Button
                  style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  severity="success"
                  icon="pi pi-filter"
                  rounded
                  onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                  <p className="d-inline-block" style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                  style={{ height: '30px', borderRadius: '8px', fontSize: '14px', marginRight: '15px' }}
                  type="button"
                  severity="danger"
                  icon="pi pi-delete-left"
                  rounded
                  onClick={() => {
                    setListFiltros({});
                    loadData({}, currentPage, itemsPerPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro);
                  }}
                >
                  <p className="d-inline-block" style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
              </Col>
            </Row>
          </div>
          <FiltroAvancadoModal
            showModal={showModalFiltro}
            onHide={() => setShowModalFiltro(false)}
            handleClose={() => setShowModalFiltro(false)}
            listFiltros={listFiltros}
            setListFiltros={setListFiltros}
            loadData={loadData}
            setLoading={setLoading}
            currentPage={currentPage}
            setNodes={setNodes}
            updateTitles={updateTitles}
            updatePagination={updatePagination}
            setShowModalFiltro={setShowModalFiltro}
            itemsPerPage={itemsPerPage}
          />
        </div >
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <div className="card">
          {nodes?.length > 0 ? (
            <>
              <DataView value={nodes} itemTemplate={itemTemplate} layout={layout} />
              <Paginacao
                totalPages={pagination}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setItemsPorPagina={setItemsPerPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          ) :
            (
              <div className="text-center">
                <h3 className='text-sm text-gray-600 font-semibold p-4'>Nenhum imóvel encontrado</h3>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}
