import { useEffect, useState } from "react";
import { VendaAvulsaList } from "./Services/VendaService";
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from "mdbreact";
import { Col, Form, NavLink, Row } from "react-bootstrap";
import { AutoComplete } from "primereact/autocomplete";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { actionTemplateVendaAvulsa } from "../NovaVenda/Pages/Cemiterio/Components/actionTemplateVendaAvulsa";
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import FiltroAvancadoModal from "../NovaVenda/Pages/Cemiterio/Components/ModalFiltroAvancado";
import ModalImpressaoVendaAvulsa from "../NovaVenda/Pages/Cemiterio/Components/ModalImpressaoVendaAvulsa";
import ModalCloneVendaAvulsa from "../NovaVenda/Pages/Cemiterio/Components/ModalCloneVendaAvulsa";
import ModalConfirmarVendaAvulsa from "../NovaVenda/Pages/Cemiterio/Components/ModalConfirmarVendaAvulsa";
import { useAppContext } from "../../../contexts/AppContext";
import { searchCliente } from "../../Financeiro/ContasReceber/Service/ContasReceberService";
import iconFiltroAvancado from '../../../../assets/images/icon-filtro-avancado.png';
import { useSidebarContext } from "../../../contexts/SidebarContext";
function VendaAvulsa() {
    const [nodes, setNodes] = useState<any | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [titles, setTitles] = useState<any[]>([]);
    const [pagination, setPagination] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    const [showModalVendaAvulsa, setShowModalVendaAvulsa] = useState(false);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    const [listFiltros, setListFiltros] = useState<any>({});
    const [showModalFiltro, setShowModalFiltro] = useState(false);
    const [showModalConfirmarVenda, setShowModalConfirmarVenda] = useState(false);
    const [showModalCloneVenda, setShowModalCloneVenda] = useState(false);
    const [showModalImpressaoVendaAvulsa, setShowModalImpressaoVendaAvulsa] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const { showSubSidebar, setItemsBreadsCrumbs, setUrl_button_create } = useAppContext();
    const { isVisible, updateVisibility } = useSidebarContext();
    useEffect(() => {
        VendaAvulsaList({ ...listFiltros }, currentPage, setLoading, setNodes, setTitles, setPagination);
    }, [currentPage]);
    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Vendas',
                url: '/vendas',
            },
            {
                label: 'Lista de vendas avulsas',
                url: '/venda-avulsa',
            },
        ]);
        setUrl_button_create("venda-avulsa/store");
    }, []);
    const handleChange = (field: string, value: any) => {
        setListFiltros((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };
    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };
    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 0; i < pagination; i++) {
            if (i < 3 || i === pagination - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>
                );
            }
        }
        return pageNumbers;
    };
    return (
        <div className="body-content">
            <div
                className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
            >
                <div className="contentSearch">
                    <div className="contentSearch-header">
                        <NavLink onClick={toggleVisibility}>
                            <img
                                className="menu-context"
                                src={iconMenuTresPonto}
                            />
                        </NavLink>
                        <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Venda Avulsa</Col>
                    </div>
                    <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
                    <div className="contentSearch-body">
                        <div className='div-calendar'>
                            <Col md="3" style={{ marginRight: "8px" }}>
                                <Form.Label>Cliente | Beneficiário</Form.Label>
                                <AutoComplete
                                    field="nome_cliente"
                                    style={{ height: "28px" }}
                                    value={listFiltros.cliente}
                                    suggestions={FilteredPessoa}
                                    completeMethod={(e) => searchCliente(e, setFilteredPessoa)}
                                    onChange={(e) => {
                                        setListFiltros({ ...listFiltros, cliente: e.value })
                                        handleChange('cliente', e.value)
                                    }}
                                    onSelect={(e) => {
                                        handleChange('cliente_id', e.value.id)
                                        setListFiltros({ ...listFiltros, cliente_id: e.value.id, cliente: e.value })
                                    }}
                                    dropdown
                                />
                            </Col>
                            <Col md="3" style={{ marginRight: "8px" }}>
                                <Form.Label>Tipo</Form.Label>
                                <Form.Select value={listFiltros?.tipo} onChange={(e) => handleChange('tipo', Number(e.target.value))}>
                                    <option>Selecione o tipo</option>
                                    <option value={1}>Orçamento</option>
                                    <option value={2}>Venda</option>
                                </Form.Select>
                            </Col>
                            <Col md="3" style={{ marginRight: "8px" }}>
                                <Form.Label>Dia Vencimento</Form.Label>
                                <InputNumber
                                    placeholder='Dia do vencimento'
                                    style={{ width: '100%', height: '28px' }}
                                    value={listFiltros?.dia_vencimento}
                                    onChange={(e: any) => handleChange('dia_vencimento', e.value)}
                                    locale="pt-BR"
                                    min={0}
                                    max={31}
                                    maxLength={2}
                                />
                            </Col>
                        </div>
                        <div className='div-filtros-avancados' style={{ marginTop: "35px" }}>
                            <Button
                                style={{ height: '30px', borderRadius: '7px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                                type="button"
                                rounded outlined severity="secondary"
                                onClick={() => { setShowModalFiltro(true) }}>
                                <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                            </Button>
                            <Button
                                style={{ position: 'relative', float: 'right', height: '30px', borderRadius: '7px', marginRight: '5px' }}
                                type="button"
                                icon="pi pi-search"
                                severity="success"
                                onClick={() => {
                                    VendaAvulsaList({ ...listFiltros }, currentPage, setLoading, setNodes, setTitles, setPagination);
                                }}
                                rounded>
                                Filtrar
                            </Button>
                            <Button
                                style={{ height: '30px', borderRadius: '7px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                                type="button"
                                severity="danger"
                                icon="pi pi-delete-left"
                                rounded
                                onClick={() => {
                                    setListFiltros({});
                                    VendaAvulsaList({ ...listFiltros }, currentPage, setLoading, setNodes, setTitles, setPagination);
                                }}
                            >
                                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                            </Button>
                        </div>
                    </div>
                </div >
            </div>
            <div
                className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
            >
                <DataTable
                    stripedRows
                    lazy
                    value={nodes ? nodes : []}
                    style={{ minHeight: '350px' }}
                    tableStyle={{ minWidth: '50rem', overflowX: 'hidden', overflowY: 'hidden' }}
                    emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                >
                    <Column
                        style={{ textAlign: 'left' }}
                        key={'data_venda_label'}
                        field={'data_venda_label'}
                        headerClassName="sticky-header lefted-header"
                        header={<div className="lefted-content" >Data da Venda</div>}
                    />
                    <Column
                        style={{ textAlign: 'left' }}
                        key={'pessoa_nome_label'}
                        field={'pessoa_nome_label'}
                        headerClassName="sticky-header lefted-header"
                        header={<div className="lefted-content" >Cliente </div>}
                    />
                    <Column
                        style={{ textAlign: 'right' }}
                        key={'valor_final_label'}
                        field={'valor_final_label'}
                        headerClassName="sticky-header righted-header"
                        header={<div className="righted-content" >Total </div>}
                    />
                    <Column
                        style={{ textAlign: 'right' }}
                        key={'valor_sinal_label'}
                        field={'valor_sinal_label'}
                        headerClassName="sticky-header righted-header"
                        header={<div className="righted-content" >Vlr.Sinal </div>}
                    />
                    <Column
                        style={{ textAlign: 'right' }}
                        key={'quantidade_parcelas_label'}
                        field={'quantidade_parcelas_label'}
                        headerClassName="sticky-header righted-header"
                        header={<div className="righted-content" >Qtd.Parcelas </div>}
                    />
                    <Column
                        style={{ textAlign: 'right' }}
                        key={'valor_parcela_label'}
                        field={'valor_parcela_label'}
                        headerClassName="sticky-header righted-header"
                        header={<div className="righted-content" >Vlr.Parcelas </div>}
                    />
                    <Column
                        style={{ textAlign: 'left' }}
                        key={'corretor_label'}
                        field={'corretor_label'}
                        headerClassName="sticky-header lefted-header"
                        header={<div className="lefted-content" >Corretor </div>}
                    />
                    <Column
                        style={{ textAlign: 'left' }}
                        key={'tipo_venda_label'}
                        field={'tipo_venda_label'}
                        headerClassName="sticky-header lefted-header"
                        header={<div className="lefted-content" >Tipo </div>}
                    />
                    <Column
                        className='menu-context-grid-contas'
                        body={(e) => actionTemplateVendaAvulsa(
                            e,
                            setSelectedRow,
                            setShowModalConfirmarVenda,
                            setShowModalCloneVenda,
                            setShowModalImpressaoVendaAvulsa
                        )}
                        header="Menu"
                        headerClassName="sticky-header"
                    />
                </DataTable>
                <ModalConfirmarVendaAvulsa
                    selectedRow={selectedRow}
                    showModal={showModalConfirmarVenda}
                    handleClose={() => setShowModalConfirmarVenda(false)}
                />
                <ModalCloneVendaAvulsa
                    selectedRow={selectedRow}
                    showModal={showModalCloneVenda}
                    handleClose={() => setShowModalCloneVenda(false)}
                />
                <ModalImpressaoVendaAvulsa
                    selectedRow={selectedRow}
                    showModal={showModalImpressaoVendaAvulsa}
                    handleClose={() => setShowModalImpressaoVendaAvulsa(false)}
                />
                <FiltroAvancadoModal
                    showModal={showModalFiltro}
                    onHide={() => setShowModalFiltro(false)}
                    handleClose={() => setShowModalFiltro(false)}
                    listFiltros={listFiltros}
                    setListFiltros={setListFiltros}
                    loadData={VendaAvulsaList}
                    currentPage={currentPage}
                    setLoading={setLoading}
                    setNodes={setNodes}
                    updateTitles={setTitles}
                    updatePagination={setPagination}
                    setShowModalFiltro={setShowModalFiltro}
                />
                <div className="pagination-footer" style={{ display: 'flex' }}>
                    <MDBRow>
                        <MDBCol>
                            <MDBPagination circle>
                                <MDBPageItem disabled={currentPage === 0}>
                                    <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                        <span aria-hidden="true">&laquo;</span>
                                    </MDBPageNav>
                                </MDBPageItem>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {renderPageNumbers()}
                                </div>
                                <MDBPageItem disabled={currentPage === pagination - 1}>
                                    <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                        <span aria-hidden="true">&raquo;</span>
                                    </MDBPageNav>
                                </MDBPageItem>
                            </MDBPagination>
                        </MDBCol>
                    </MDBRow>
                </div>
            </div>
        </div>
    );
}

export default VendaAvulsa;
