import { useEffect, useState } from "react";
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import { showToast } from "../../components/ToastComponent";
import api from "../../services/api";
import { url } from "../../environments/environments-develop";
import { useUsuarioContext } from "../../contexts/UsuarioContext";
import { EnderecoData } from "../../contexts/EnderecoContext";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { AlertService } from "../../components/AlertService";
import { Skeleton } from "primereact/skeleton";

interface EnderecoFormProps {
    enderecosData?: any | null;
    onInputChange: (index: number, field: string, value: string) => void;
    onAddEndereco: () => void;
    onRemoveEndereco: (index: number) => void;
}

function EnderecoForm(props: any) {
    var { enderecosData } = props;
    const [table, setTable] = useState([]);
    const [activeEventKey, setActiveEventKey] = useState<string | null>(null);
    const [FilteredCidade, setFilteredCidade] = useState<any>(null);
    const [listCidade, setListCidade] = useState([]);
    const [nomeCidade, setNomeCidade] = useState();
    const [isLoading, setLoading] = useState(false);

    const handleChange = (index: number, field: string, value: string) => {
        if (field === 'numero') {
            if (/^\d*$/.test(value) && value.length <= 6) {
                props.onInputChange(index, field, value);
            }
        } else if (field === 'logradouro') {
            if (!value || value.length <= 180) {
                props.onInputChange(index, field, value);
            }
        } else if (field === 'cep') {
            if (/^[\d.-]*$/.test(value) && value.length <= 11) {
                props.onInputChange(index, field, value);
            }
        } else if (field === 'complemento') {
            if (!value || value.length <= 60) {
                props.onInputChange(index, field, value);
            }
        } else if (field === 'apelido') {
            if (!value || value.length <= 30) {
                props.onInputChange(index, field, value);
            }
        }
        else {
            props.onInputChange(index, field, value);
        }
    };
    async function InitCidade() {
        try {
            const response = await api.get(`${url}/api/v1/admin/core/cidades/listar-cidades`);
            setListCidade(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de cidades. Erro:' + String(error));
        }
    }
    const searchCidade = (event: any) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _FilteredCidade: any;

            if (!event.query.trim().length) {
                _FilteredCidade = [...listCidade];
            }
            else {
                _FilteredCidade = listCidade.filter((item: any) => {
                    return item.nome.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredCidade(_FilteredCidade);
        }, 250);
    }
    useEffect(() => {
        if (enderecosData) {
            setTable(enderecosData);
        } else {
            setTable(enderecosData);
        }
    }, [enderecosData])
    useEffect(() => {
        (enderecosData || []).forEach((endereco: any, index: number) => {
            Object.keys(endereco).forEach((field) => {
                handleChange(index, field, endereco[field]);
            });
        });
        InitCidade();
    }, []);
    const handleChangeCidade = (index: any, value: any) => {
        const novoEndereco: any = {
            cidade: value,
            cidade_id: value.id,
            cidade_nome: value.nome,
        };
        Object.keys(novoEndereco).forEach((field) => {
            handleChange(index, field, novoEndereco[field]);
        });
    };
    async function buscarCep(value: any, index: number): Promise<void> {
        try {
            setLoading(true);
            const retorno = await api.get(
                `${url}/api/v1/admin/core/cep-localidade/${value}`
            );
            if (retorno?.data?.data?.cidade_id) {
                if (retorno && retorno.status === 200) {
                    const novoEndereco: any = {
                        apelido: "",
                        logradouro: retorno.data.data.logradouro,
                        tipo_endereco: "",
                        cep: retorno.data.data.cep,
                        cidade: retorno.data.data.cidade.data,
                        numero: "",
                        cidade_id: retorno.data.data.cidade_id,
                        cidade_nome: retorno.data.data.cidade_nome,
                        complemento: "",
                    };
                    Object.keys(novoEndereco).forEach((field) => {
                        handleChange(index, field, novoEndereco[field]);
                    });
                    setLoading(false);
                }
            } else {
                showToast('error', 'CEP não encontrado');
            }

        } catch (err) {
            showToast("error", `Erro: ${err}`);
        }
    }

    const handleAddEndereco = () => {
        props.onAddEndereco();
        setActiveEventKey((enderecosData?.length || 0).toString());
    };

    const handleRemoveEndereco = (index: number) => {
        props.onRemoveEndereco(index);
    };
    return (
        <>
            <Accordion
                style={{ height: '-webkit-fill-available' }}
                activeKey={activeEventKey}
                onSelect={(key) => setActiveEventKey(key as string)}
            >
                <Button type="button"
                    severity="success"
                    size="small"
                    icon="pi pi-plus"
                    raised={true}
                    label="Adicionar"
                    rounded={true}
                    className="ms-2 button-add-endereco p-button-rounded p-button-text"
                    style={{ float: "right", marginRight: '32px', marginTop: '20px' }}
                    onClick={handleAddEndereco}>
                </Button>

                {(table || []).map((endereco: any, index: number) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>
                            {endereco.apelido ? endereco.apelido : endereco.logradouro}
                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body>
                                <Form.Group className="usuario-form">
                                    <Row className="mb-3">
                                        <Col style={{ display: 'flex' }}>
                                            <Col md="10">
                                                {isLoading ? (<Skeleton />) : (
                                                    <>
                                                        <Form.Label>CEP</Form.Label>
                                                        <Form.Control
                                                            value={endereco.cep || ''}
                                                            onChange={(e) => handleChange(index, "cep", e.target.value)}

                                                        />
                                                    </>
                                                )}
                                            </Col>
                                            <Col md="1">
                                                {isLoading ? (<Skeleton />) : (
                                                    <>
                                                        <Form.Label></Form.Label>
                                                        <Button
                                                            severity="success"
                                                            className="p-button-rounded p-button-text"
                                                            icon="pi pi-search"
                                                            style={{ borderRadius: '30px', width: '25px', height: '15px', marginTop: '10px', marginLeft: '10px' }}
                                                            onClick={(e) => buscarCep(endereco.cep, index)}
                                                        ></Button>
                                                    </>
                                                )}
                                            </Col>
                                        </Col>
                                        <Col>
                                            {isLoading ? (<Skeleton />) : (
                                                <>
                                                    <Form.Label>Logradouro</Form.Label>
                                                    <Form.Control
                                                        value={endereco.logradouro}
                                                        onChange={(e) =>
                                                            handleChange(index, "logradouro", e.target.value)
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Col>
                                        <Col>
                                            {isLoading ? (<Skeleton />) : (
                                                <>
                                                    <Form.Label>Número</Form.Label>
                                                    <Form.Control
                                                        value={endereco.numero}
                                                        onChange={(e) =>
                                                            handleChange(index, "numero", e.target.value)
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Col>
                                        <Form.Group as={Col} controlId="formGridState">
                                            {isLoading ? (<Skeleton />) : (
                                                <>
                                                    <Form.Label>Tipo de Endereço</Form.Label>
                                                    <Form.Select
                                                        value={endereco.tipo_endereco}
                                                        onChange={(e) =>
                                                            handleChange(index, "tipo_endereco", e.target.value)
                                                        }
                                                    >
                                                        <option>Selecione...</option>
                                                        <option value={1}>Residencial</option>
                                                        <option value={2}>Comercial</option>
                                                        <option value={3}>Contato</option>
                                                        <option value={4}>Cobrança</option>
                                                    </Form.Select>
                                                </>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                                            {isLoading ? (<Skeleton />) : (
                                                <>
                                                    <Form.Label>Cidade</Form.Label>
                                                    <AutoComplete
                                                        required
                                                        placeholder='Pesquisar cidade'
                                                        field="nome"
                                                        style={{ height: "37px" }}
                                                        value={endereco?.cidade ? endereco?.cidade : endereco?.busca}
                                                        suggestions={FilteredCidade}
                                                        completeMethod={searchCidade}
                                                        onChange={(e) => handleChange(index, 'busca', e.value)}
                                                        onSelect={(e) => {
                                                            handleChangeCidade(index, e.value);
                                                            setNomeCidade(e.value.nome);
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Col>
                                        <Col md="3">
                                            {isLoading ? (<Skeleton />) : (
                                                <>
                                                    <Form.Label>Apelido para o Endereço</Form.Label>
                                                    <Form.Control
                                                        value={endereco.apelido}
                                                        onChange={(e) =>
                                                            handleChange(index, "apelido", e.target.value)
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Col>
                                        <Col md="6">
                                            {isLoading ? (<Skeleton />) : (
                                                <>
                                                    <Form.Label>Complemento</Form.Label>
                                                    <Form.Control
                                                        value={endereco.complemento}
                                                        onChange={(e) =>
                                                            handleChange(index, "complemento", e.target.value)
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="row-delete-button">
                                        {isLoading ? (
                                            <Col md="1">
                                                <Skeleton />
                                            </Col>
                                        ) : (
                                            <>
                                                <Button
                                                    severity="danger"
                                                    size="small"
                                                    className="ms-2 button-delete-endereco p-button-rounded p-button-text"
                                                    icon="pi pi-trash"
                                                    onClick={() => handleRemoveEndereco(index)}
                                                >
                                                </Button>
                                            </>
                                        )}
                                    </Row>
                                </Form.Group>
                            </Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
}

export default EnderecoForm;
