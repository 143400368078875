import { AlertService } from "../../../../components/AlertService";
import { url } from "../../../../environments/environments-develop";
import TokenService from "../../../../services/Auth/token.service";
import BancoService from "../../../Financeiro/Services/BancoService";
import ClienteService from "../../../Pessoas/Services/ClienteService";
import CorretorSevice from "../../../Pessoas/Services/CorretorSevice";
import ImovelService from "../../Services/ImovelService";
import api from "../../../../services/api";
import { showToast } from "../../../../components/ToastComponent";
interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "id;asc",
};
interface Anexo {
  nome: string;
  alias: string;
  extensao: string;
  conteudo: string;
  url?: string;
  objectURL?: string;
}
export const loadData = (
  listFiltros: any,
  itensPorPagina: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: (value: any) => void,
  sortOptions: { field: string; order: number } | null = null
) => {
  setLoading(true);
  const form = {
    identificador1: listFiltros?.identificador1,
    identificador2: listFiltros?.identificador2,
    proprietario_id: listFiltros?.proprietario_id,
    captador_id: listFiltros?.captador_id,
    observacao: listFiltros?.observacao,
    status: listFiltros?.status,
    sub_tipo_imovel_id: listFiltros?.sub_tipo_imovel_id,
    tipo_imovel_id: listFiltros?.tipo_imovel_id,
    imoveis_caracteristicas: listFiltros?.imoveis_caracteristicas,
    captacao_tipo: "2;3",
    empreendimento_id: TokenService.getEmpreendimentoId()
      ? TokenService.getEmpreendimentoId()?.id
      : null,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
    if (sortOptions) {
      const orderDirection = sortOptions.order === 1 ? "asc" : "desc";
      consulta.order = `${sortOptions.field};${orderDirection}`;
    }
  }

  ImovelList(
    consulta,
    itensPorPagina,
    currentPage,
    setLoading,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro
  );
};
export const isCheckboxChecked = (itemId: number, formData: any) => {
  return formData?.imoveis_caracteristicas?.some(
    (item: any) => item.caracteristica_imovel_id === itemId
  );
};

export const handleCheckboxChange = (
  checked: boolean,
  itemId: number,
  setFormData: (value: any) => void
) => {
  setFormData((prevState: any) => {
    const existingIndex = prevState.imoveis_caracteristicas.findIndex(
      (item: any) => item.caracteristica_imovel_id === itemId
    );

    if (checked) {
      // Adicionar o item ao estado se marcado
      if (existingIndex === -1) {
        return {
          ...prevState,
          imoveis_caracteristicas: [
            ...prevState.imoveis_caracteristicas,
            {
              valor_caracteristica: null,
              tipo_unidade: null,
              caracteristica_imovel_id: itemId,
            },
          ],
        };
      }
    } else {
      // Remover o item do estado se desmarcado
      if (existingIndex !== -1) {
        const updatedCaracteristicas = [...prevState.imoveis_caracteristicas];
        updatedCaracteristicas.splice(existingIndex, 1);
        return {
          ...prevState,
          imoveis_caracteristicas: updatedCaracteristicas,
        };
      }
    }
    return prevState;
  });
};

export const ImovelList = (
  filtros: any,
  itensPorPagina: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: any | null
) => {
  ImovelService.list(
    {
      include: "anexo,cliente,plano_conta,conta_financeiro,centro_custo",
      totalitems: itensPorPagina ?? 8,
      consulta: JSON.stringify(filtros),
      page: currentPage + 1,
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response) => {
      setNodes(response);
      setShowModalFiltro(false);
      setLoading(false);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const ImovelShow = (
  id: any,
  setNodes: (value: any) => void,
  setImages: (value: any) => void
) => {
  ImovelService.get(id).subscribe(
    (response) => {
      setNodes(response);
      response?.imagens?.data?.map((image: any) => {
        return setImages((prevData: any) => [
          ...prevData,
          { itemImageSrc: image?.anexo?.url },
        ]);
      });
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};
export const searchBanco = (
  event: any,
  setFilteredBanco: (value: any) => void
) => {
  const query = event.query;
  const form = {
    busca: query,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(([_, value]) => value !== null)
  );

  if (consulta) {
    consulta.filtro = filtro;
  }
  setTimeout(() => {
    BancoService.encontrar_banco({
      consulta: JSON.stringify(consulta),
    }).subscribe(
      (response) => {
        setFilteredBanco(response);
      },
      (error) => {
        let mensagens: string[] = [];
        mensagens.push(error?.response?.data?.error?.message);
        AlertService.error("Erro!", String(mensagens));
      }
    );
  }, 600);
};
export const ImovelShowPublic = (
  id: any,
  setNodes: (value: any) => void,
  setImages: (value: any) => void
) => {
  ImovelService.show_public(id).subscribe(
    (response) => {
      setNodes(response);
      response?.imagens?.data?.map((image: any) => {
        return setImages((prevData: any) => [
          ...prevData,
          { itemImageSrc: image?.anexo?.url },
        ]);
      });
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const handleChangeEndereco = (
  field: string,
  value: string,
  setFormData: (value: any) => void
) => {
  setFormData((prevFormBasico: any) => {
    const updatedEnderecos = [...prevFormBasico.enderecos];

    updatedEnderecos[0] = {
      ...updatedEnderecos[0],
      [field]: value,
    };
    return {
      ...prevFormBasico,
      enderecos: updatedEnderecos,
    };
  });
};

export const excluirImovel = (id: any) => {
  AlertService.confirm({
    title: "Confirmar Exclusão!",
    text: "Deseja realmente excluir este imóvel?",
    cancelButtonText: "Não",
    confirmButtonText: "Sim",
  }).then((e: any) => {
    if (e?.isConfirmed) {
      ImovelService.excluir(id).subscribe(
        (response) => {
          AlertService.sucess("Sucesso!", "Excluído com sucesso!").then(() => {
            window.location.reload();
          });
        },
        (error) => {
          let mensagens: string[] = [];
          Object.keys(error?.response?.data?.error?.message).forEach((key) => {
            mensagens.push(
              "\n" + error?.response?.data?.error?.message[key][0]
            );
          });
          AlertService.error("Erro!", String(mensagens));
        }
      );
    }
  });
};

export const setSessionEditData = async (
  value: any,
  url_redirect_edit: any
) => {
  ImovelService.get(value, {
    include:
      "imoveis_caracteristicas",
  }).subscribe(
    (response) => {
      localStorage.setItem("dadosEdicao", JSON.stringify(response));
      window.location.href = url_redirect_edit;
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const searchCorretor = (
  event: any,
  currentPage: any,
  setFilteredClient: (value: any) => void
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  CorretorSevice.list({
    empreendimento_id: TokenService.getEmpreendimentoId().id,
  }).subscribe(
    (response) => {
      setFilteredClient(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};
export const searchCliente = (
  query: any,
  setFilteredCliente: (value: any) => void
) => {
  const form = { nome_cliente: query };
  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
  }

  setTimeout(() => {
    ClienteService.list({
      consulta: JSON.stringify(consulta),
    }).subscribe(
      (response) => {
        setFilteredCliente(response);
      },
      (error) => {
        let mensagens: string[] = [];
        mensagens.push(error?.response?.data?.error?.message);
        AlertService.error(
          "Erro!",
          "Não foi possível buscar os clientes: " +
            error?.response?.data?.error?.message
        );
      }
    );
  }, 600);
};

export const handleInputChange = (
  field: string,
  value: any,
  setFormData: (value: any) => void
) => {
  setFormData((prevFormData: any) => ({ ...prevFormData, [field]: value }));
};
export const handleChangeBanco = (
  field: string,
  value: any,
  setFormData: (value: any) => void
) => {
  handleInputChange("banco_nome", value, setFormData);
  handleInputChange(field, value.value, setFormData);
};

export const fetchData = async (
  setTipoImovel: (value: any) => void,
  setSubTipoImovel: (value: any) => void
) => {
  try {
    const consulta: any = {
      consulta: {
        order: "id;desc",
        filtro: {
          empreendimento_id: TokenService.getEmpreendimentoId()
            ? TokenService.getEmpreendimentoId().id
            : null,
        },
      },
    };
    const queryParams: any = {};
    const camposEnviar = ["consulta"];
    camposEnviar.forEach((key: any) => {
      const value = consulta[key];
      if (value !== undefined && value !== null && value !== "") {
        queryParams[key] = key === "consulta" ? JSON.stringify(value) : value;
      }
    });
    const queryString = Object.entries(queryParams)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
      )
      .join("&");

    const [responseTiposImovel, responseSubtiposImovel] = await Promise.all([
      api.get(`${url}/api/v1/admin/vendas/tipoimovel?${queryString}`),
      api.get(`${url}/api/v1/admin/vendas/subtipoimovel?${queryString}`),
    ]);
    if (responseTiposImovel.status === 200)
      setTipoImovel(responseTiposImovel.data.data);
    if (responseSubtiposImovel.status === 200)
      setSubTipoImovel(responseSubtiposImovel.data.data);
  } catch (error) {
    showToast("error", "Erro desconhecido");
  }
};

export const booleanOptions = [
  { label: "Selecione", value: null },
  { label: "Sim", value: true },
  { label: "Não", value: false },
];
export const imovelVisibilidadeOptions = [
  { label: "Selecione", value: null },
  { label: "Visível", value: true },
  { label: "Não Visível", value: false },
];
export const statusImovel = [
  {
    label: "Disponivel",
    value: 1,
  },
  {
    label: "Reservado",
    value: 2,
  },
  {
    label: "Reserva Fixa",
    value: 3,
  },
  {
    label: "Negociado",
    value: 4,
  },
  {
    label: "Vendido",
    value: 5,
  },
  {
    label: "Reserva Técnica",
    value: 6,
  },
  {
    label: "Reserva Diretoria",
    value: 7,
  },
  {
    label: "Alugado",
    value: 8,
  },
];

export async function InitCaracteristicas(
  setListCaracteristicas: (value: any) => void
) {
  try {
    const response = await api.get(
      `${url}/api/v1/admin/vendas/listagem-caracteristicas-imoveis-dinamicas`
    );
    setListCaracteristicas(response.data.data);
  } catch (error) {
    showToast(
      "error",
      "Ocorreu um erro ao buscar a lista de permissões. Erro:" + String(error)
    );
  }
}
export const handleDeleteImage = (
  imageId: number,
  setImages: (value: any) => void,
  setAnexos: (value: any) => void
) => {
  setImages((prevImages: any) =>
    prevImages.filter((image: any) => image.id !== imageId)
  );

  setAnexos((prevAnexos: any) => {
    const updatedAnexos = prevAnexos.filter(
      (_: any, index: any) => index !== imageId - 1
    );
    const wasPrincipalDeleted = prevAnexos.some(
      (anexo: any) =>
        anexo.principal && prevAnexos.indexOf(anexo) === imageId - 1
    );
    if (wasPrincipalDeleted && updatedAnexos.length > 0) {
      updatedAnexos[0] = { ...updatedAnexos[0], ordem: 1, principal: true };
    }

    return updatedAnexos;
  });
};

export const handleExpandImage = (imageUrl: string) => {
  const win = window.open();
  if (win) {
    win.document.write(
      `<img src="${imageUrl}" style="width: 100%; height: 100vh; object-fit: contain;" />`
    );
  }
};

export const area_construida =
  "Tudo aqui que tem alguma cobertura (telhado / teto). Neste cálculo, a espessura das paredes e o teto também entram na conta. E, por eliminação, áreas descobertas ou ao ar livre não são consideradas aqui. O que também conta no cálculo da área construída é a quantidade de pavimentos (andares) de uma construção. Uma casa de dois andares, portanto, terá essa medida maior do que uma térrea.";
export const area_util =
  "Área interna de todo o imóvel, conhecida como área de vassoura ao se referir ao espaço em que os moradores irão viver na casa e por consequencia varrer. leva-se em consideração o piso da residencia (sem cobertura / telhado / teto). Na área útil não se conta o espaço ocupado por paredes, pilares, garagens, depósito e tudo mais o que for considerado como área externa, como um jardim.";
export const area_privativa =
  "Depois do piso e do teto, temos agora a área que compreende tudo o que está da porta para dentro. Outro conceito que ajuda a entender o que é área privativa é a exclusividade. Tudo aquilo que só o proprietário puder utilizar (e estiver registrado na escritura e na matrícula do imóvel) é considerado como tal. É a soma da área útil, paredes e outras áreas que pertencem ao proprietário, como por exemplo vaga de garagem ou depósito privativo em um prédio de apartamento. Pode incluir também varandas e jardins desde que sejam sempre de uso exclusivo de quem é dono do bem. A área privativa será sempre maior do que a área útil e menor do que a área total e geralmente ela também consta na matrícula imobiliária. Condomínios de apartamento usam a área privativa como base para a cobrança do condomínio.";
export const area_comum =
  "Essa medida soma tudo aquilo que todos os moradores de um residencial podem usar. Se na área privativa a palavra é exclusividade, na comum é compartilhamento. Piscina, quadra, salão de festa, parquinho e área pet compõem a área comum. Corredores, elevadores, escadas, hall, jardins, portaria também. Tudo que é administrado pelo condomínio entra no bolo da área comum. Quem investe em imóveis para aluguel ou revenda costuma levar essa medida em consideração uma vez que o tamanho da área comum pode ser um fator de valorização da unidade, além de impactar no valor a ser pago no condomínio e a ser cobrado pela locação ou venda.";
export const area_total =
  "Aqui há uma pequena diferença quando falamos de apartamentos e casas. A área total de um apartamento é a soma da área privativa com aquilo que se chama fração ideal de um condomínio. Fração ideal é o nome dado à parte dos espaços comuns de um condomínio que pertencem ao proprietário de uma unidade autônoma. Em outras palavras, é a porcentagem do terreno e de todas as áreas compartilhadas de que um proprietário de um imóvel é “dono”. Agora, quando falamos de casas, o cálculo muda um pouco. Aqui o que compreende a área total é a área construída e a não construída, ou seja, os espaços livres que fazem parte do terreno, como áreas de lazer ao ar livre, garagem não coberta, pátios etc. Se ela for construída em um condomínio, deve-se somar a fração ideal para chegar na área total.";
export const handleAddImage = (
  event: any,
  setImages: (value: any) => void,
  setNextId: (value: any) => void,
  setAnexos: (value: any) => void,
  nextId: any,
  anexo: any
) => {
  const files: any = Array.from(event.target.files || []); // Converte FileList para Array
  if (files.length > 0) {
    handleFileSelect(files, anexo, setAnexos); // Passa o array para a função handleFileSelect
    const file = files[0]; // Seleciona o primeiro arquivo
    const imageUrl = URL.createObjectURL(file);
    setImages((prevImages: any) => [
      ...prevImages,
      { id: nextId, url: imageUrl },
    ]);
    setNextId((prevId: any) => prevId + 1);
  }
};

export const handleFileSelect = (
  files: File[],
  anexo: any,
  setAnexos: (value: any) => void
) => {
  files.forEach((file, index) => {
    const isDuplicate = anexo.some(
      (anexoExistente: any) => anexoExistente.nome === file.name
    );

    if (!isDuplicate) {
      const reader = new FileReader();
      reader.onload = () => {
        const conteudo = reader.result as string;

        const novoAnexo: Anexo = {
          nome: file.name,
          alias: file.name,
          extensao: file.name.split(".").pop() || "",
          conteudo: conteudo,
        };

        const novaImagem = {
          ordem: anexo.length + 1,
          principal: anexo.length === 0,
          anexo: novoAnexo,
        };

        setAnexos((anexosAnteriores: any) => [...anexosAnteriores, novaImagem]);
      };
      reader.readAsDataURL(file);
    }
  });
};
