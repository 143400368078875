import React from 'react';

interface TogglerStatusProps {
    status: number;
}

const getStatusColorCard = (status: number) => {
    switch (status) {
        case 1: return '#A9A9A9'; // Aguardando
        case 2: return '#ff9800'; // Processando
        case 3: return '#289b1e'; // Concluído
        default: return '#000';   // Default color for unknown statuses
    }
};

const TogglerStatus: React.FC<TogglerStatusProps> = ({ status }) => {
    const backgroundColor = getStatusColorCard(status);

    return (
        <div style={{ display: 'flex' }}>
            <div style={{
                width: '5px',
                position: 'relative',
                top: '-5px',
                display: 'flex',
                flex: '1',
                alignItems: 'center',
                backgroundColor: backgroundColor,
                margin: '3px',
                borderRadius: '12px',
                marginTop: '12px',
                boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                padding: '8px'
            }}></div>
        </div>
    );
};

export default TogglerStatus;
