import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import CaracteristicasImoveisForm from './Formularios/CaracteristicasImoveisForm';
import TokenService from '../../../services/Auth/token.service';

interface PropsCaracteristicaData {
    tipo_caracteristica?: number;
    descricao?: string;
    nome_campos?: string;
    tipo_dado?: number;
    tag?: string;
    icone?: string;
    tipo_imovel_id?: number;
    sub_tipo_imovel_id?: number;
}

function CreateCaracteristicasImoveis() {
    const { setUrl, setBody, setShowButtonSave, setItemsBreadsCrumbs } = useAppContext();
    const [caracteristicaData, setCaracteristicaData] = useState<PropsCaracteristicaData>({});

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Revenda',
                url: '/revenda',
            },
            {
                label: 'Características do imóvel',
                url: '/revenda/caracteristicas',
            },
            {
                label: 'Criar'
            }
        ]);
        setUrl(`${url}/api/v1/admin/vendas/caracteristicas-imoveis-dinamicas`);
        setShowButtonSave(true);
        setCaracteristicaData((prevCaracteristicaData) => {
            return {
                ...prevCaracteristicaData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id,
            };
        });
    }, []);

    useEffect(() => {
        setBody(caracteristicaData)
    }, [caracteristicaData]);

    const handleChange = (field: string, value: any) => {
        setCaracteristicaData((prevCaracteristicaData) => {
            return {
                ...prevCaracteristicaData,
                [field]: value
            };
        });
    }
    return (
        <div className="body-content">
            <div className="form-create-group">
                <div className="form-create-group-header">
                    <h3>Cadastrar Característica Imovel</h3>
                </div>
            </div>
            <div className="form-group-create">
                <Container>
                    <CaracteristicasImoveisForm
                        initialRightValues={[]}
                        caracteristicaData={caracteristicaData}
                        onInputChange={(field: string, value: any) => handleChange(field, value)}
                    />
                </Container>
            </div>
        </div>
    );
}

export default CreateCaracteristicasImoveis;
