import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import { AlertService } from '../../../components/AlertService';
import ContaFinanceiraForm from './Formularios/ContaFinanceiraForm';

function EditContaFinanceira() {
    const { setUrl, setBody, setShowButtonSave, setTypeRequest, setItemsBreadsCrumbs } = useAppContext();
    const [formBasico, setFormBasico] = useState<any>({})

    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Financeiro',
                    url: '/financeiro',
                },
                {
                    label: 'Conta Financeira',
                    url: '/financeiro/conta-financeira',
                },
                {
                    label: 'Editar'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                let endereco = JSON.parse(storedData)?.informacao_bancaria?.pessoa?.enderecos ? JSON.parse(storedData)?.informacao_bancaria?.pessoa?.enderecos?.data : [];
                let telefone = JSON.parse(storedData)?.informacao_bancaria?.pessoa?.telefones ? JSON.parse(storedData)?.informacao_bancaria?.pessoa?.telefones?.data : [];
                setFormBasico((prevGrupoData: any) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...JSON.parse(storedData),
                        informacao_bancaria: {
                            ...JSON.parse(storedData)?.informacao_bancaria,
                            pessoa: {
                                ...JSON.parse(storedData)?.informacao_bancaria?.pessoa,
                                enderecos: [...endereco],
                                telefones: [...telefone],
                            }
                        }
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/financeiro/conta-financeira/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);
    useEffect(() => {
        setBody(formBasico)
    }, [formBasico]);

    const handleChangeInformacaoBancaria = (field: string, value: any) => {
        if (setFormBasico) {
            if (formBasico) {
                if (field.startsWith('informacao_bancaria.')) {
                    const informacaoField = field.replace('informacao_bancaria.', '');
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            informacao_bancaria: {
                                ...prevGrupoData.informacao_bancaria,
                                [informacaoField]: value,
                            },
                        };
                    });
                }
            }
        }
    }
    const handleChange = (field: string, value: string) => {
        if (setFormBasico) {
            if (formBasico) {
                setFormBasico((prevGrupoData: any) => {
                    return {
                        ...prevGrupoData,
                        [field]: value,
                    };
                });
            }
        }
    }

    useEffect(() => {
        setBody(formBasico)
        setShowButtonSave(true);
    }, [formBasico]);

    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Editar Conta Financeira</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <ContaFinanceiraForm
                            initialRightValues={[]}
                            formBasico={formBasico}
                            onInputChangeInformacaoBancaria={(field: string, value: any) => handleChangeInformacaoBancaria(`informacao_bancaria.${field}`, value)}
                            onInputChange={(field: string, value: any) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default EditContaFinanceira;
