import { Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { url } from "../../../../environments/environments-develop";
import api from "../../../../services/api";
import TokenService from "../../../../services/Auth/token.service";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import { InputNumber } from "primereact/inputnumber";
import { showToast } from "../../../../components/ToastComponent";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { searchCorretor, searchJazigo, searchLogradouro, searchLotes, searchQuadra } from "../Services/VendaService";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

function SecaoJazigo(props: any) {
    const [formData, setFormData] = useState<any>(props.secaoImovelData || {});
    const [data_venda, setDataVenda] = useState<any | null>(null);
    const [data_entrega, setDataEntrega] = useState<any | null>(null);
    const [data_prim_manutencao, setDataPrimManutencao] = useState<any | null>(null);
    const [imovelDisponivel, setImovelDIsponivel] = useState<any>(formData?.identificador1 && formData?.identificador2 ? true : false);
    const [FilteredCorretor, setFilteredCorretor] = useState<any>(null);
    const [filteredJazigo, setFilteredJazigo] = useState<any>(null);
    const [filteredQuadras, setFilteredQuadras] = useState<any>(null);
    const [filteredLotes, setFilteredLotes] = useState<any>(null);
    const list_manutencao_periodicidade: any[] = [
        { name: 'Mensal', code: 1 },
        { name: 'Bimestral', code: 2 },
        { name: 'Trimestral', code: 3 },
        { name: 'Semestral', code: 4 },
        { name: 'Anual', code: 5 }
    ];
    const list_tipo_jazigos_vendas: any[] = [
        { name: 'Preventivo', code: 1 },
        { name: 'Imediato', code: 2 },
    ];
    const list_modo_jazigo: any[] = [
        { name: 'Perpétuo', code: 1 },
        { name: 'Temporário', code: 2 },
    ];
    const handleChange = (field: string, value: any) => {
        let valid = true;
        if (field == 'manutencao_periodicidade') {
            props.onInputChange('manutencao_periodicidade_label', value);
            props.onInputChange(field, value?.code ? value.code : '');
            valid = false;
        }
        if (field == 'modo_jazigo') {
            props.onInputChange('modo_jazigo_label', value);
            props.onInputChange(field, value?.code ? value.code : '');
            valid = false;

        }
        if (field == 'tipo_jazigos_vendas') {
            props.onInputChange('tipo_jazigos_vendas_label', value);
            props.onInputChange(field, value?.code ? value.code : '');
            valid = false;

        }
        if (field == 'corretor_id') {
            props.onInputChange('nome_corretor', value?.nome ? value.nome : '');
            props.onInputChange(field, value?.id ? value.id : '');
            valid = false;

        }
        if (valid) {
            props.onInputChange(field, value);
        }
    };
    useEffect(() => {
        setFormData((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ...props.secaoImovelData
            };
        });
    }, [props.secaoImovelData]);

    useEffect(() => {
        if (formData?.imovel_id) {
            setImovelDIsponivel(true);
        }
    }, [formData?.imovel_id]);

    useEffect(() => {
        setDataVenda(formData?.data_venda ? new Date(formData?.data_venda) : null);
        setDataEntrega(formData?.data_entrega ? new Date(formData?.data_entrega) : null);
        setDataPrimManutencao(formData?.data_prim_manutencao ? new Date(formData?.data_prim_manutencao) : null);
    }, [formData])
    async function actionButton(): Promise<void> {
        try {
            var response = await api.get(`${url}/api/v1/admin/cemiterio/imovel-gaveta/verifica-disponibilidade?tipo_jazigo_id=${props.secaoImovelData.tipo_jazigo_id}&identificador1=${props.secaoImovelData.identificador1}&identificador2=${props.secaoImovelData.identificador2}&logradouro=${props.secaoImovelData.logradouro}&empreendimento_id=${TokenService.getEmpreendimentoId()?.id}`);
            if (response.status === 200) {
                if (response.data.status === 1) {
                    Object.keys(response.data).forEach(key => {
                        if (key === 'id') {
                            handleChange('imovel_id', response.data[key])
                        } else if (key === 'qtd_max_parcelas') {
                            handleChange('qtd_parcelas', response.data[key])
                        } else {
                            handleChange(key, response.data[key])
                        }
                    })
                    setImovelDIsponivel(true);
                    showToast('success', 'Este Imovel está disponível para venda!');
                } else {
                    setImovelDIsponivel(false);
                    showToast('error', `Este Imovel se encontra ${response.data.data.status_enum.data.descricao}, por isso não é possivel prosseguir com a venda!`);
                }
            }
        } catch (error: any) {
            setImovelDIsponivel(false);
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.message);
            showToast('error', String(mensagens));
        }
    }
    const MoneyFormatter = (saldoDevedor: any) => {
        const formatMoney = (value: number): string => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(value);
        };
        return formatMoney(saldoDevedor);
    };
    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            height: '-webkit-fill-available',
            marginTop: '6px',
            minHeight: '350px'
        }}>
            <Row style={{ marginTop: '15px', paddingTop: '8px', paddingBottom: '8px' }}>
                <Col md="9" style={{ display: 'flex' }}>
                    <Col md="3">
                        <span className="p-float-label">
                            <AutoComplete
                                invalid={!formData?.tipo_jazigo_id}
                                field="sigla"
                                style={{ height: "37px", marginRight: '8px' }}
                                value={formData?.tipo_jazigo}
                                suggestions={filteredJazigo}
                                completeMethod={(e) => searchJazigo(e, 0, setFilteredJazigo)}
                                onChange={(e) => handleChange('tipo_jazigo', e.target.value)}
                                onSelect={(e) => {
                                    handleChange('tipo_jazigo', e.value);
                                    handleChange('tipo_jazigo_id', e.value.id);
                                }}
                                dropdown
                            />
                            <label htmlFor="ac" style={{ opacity: '35%' }}>Tipo Jazigo</label>
                        </span>
                    </Col>
                    <Col md="3" style={{ marginRight: '8px' }}>
                        <span className="p-float-label">
                            <AutoComplete
                                invalid={!formData?.identificador1}
                                disabled={!formData?.tipo_jazigo_id}
                                field="quadra"
                                style={{ height: "37px", marginRight: '8px' }}
                                value={formData?.identificador1Label}
                                suggestions={filteredQuadras}
                                completeMethod={(e) => searchQuadra(e, 0, setFilteredQuadras, formData?.tipo_jazigo_id)}
                                onChange={(e) => handleChange('identificador1', e.target.value)}
                                onSelect={(e) => {
                                    handleChange('identificador1Label', e.value);
                                    handleChange('identificador1', e.value.quadra);
                                }}
                                dropdown
                            />
                            <label htmlFor="ac" style={{ opacity: '35%' }}>Quadra</label>
                        </span>
                    </Col>
                    <Col md="3" style={{ marginRight: '8px' }}>
                        <span className="p-float-label">
                            <AutoComplete
                                invalid={!formData?.identificador2}
                                disabled={!formData?.tipo_jazigo_id || !formData?.identificador1}
                                field="logradouro"
                                style={{ height: "37px", marginRight: '8px' }}
                                value={formData?.logradouroLabel}
                                suggestions={filteredLotes}
                                completeMethod={(e) => searchLogradouro(e, 0, setFilteredLotes, formData?.tipo_jazigo_id, formData?.identificador1)}
                                onChange={(e) => handleChange('logradouro', e.target.value)}
                                onSelect={(e) => {
                                    handleChange('logradouroLabel', e.value);
                                    handleChange('logradouro', e.value.logradouro);
                                }}
                                dropdown
                            />
                            <label htmlFor="ac" style={{ opacity: '35%' }}>Ala</label>
                        </span>
                    </Col>
                    <Col md="3" style={{ marginRight: '8px' }}>
                        <span className="p-float-label">
                            <AutoComplete
                                invalid={!formData?.identificador2}
                                disabled={!formData?.tipo_jazigo_id || !formData?.identificador1 || !formData?.logradouro}
                                field="identificador2"
                                style={{ height: "37px", marginRight: '8px' }}
                                value={formData?.identificador2Label}
                                suggestions={filteredLotes}
                                completeMethod={(e) => searchLotes(e, 0, setFilteredLotes, formData?.tipo_jazigo_id, formData?.identificador1, formData?.logradouro)}
                                onChange={(e) => handleChange('identificador2', e.target.value)}
                                onSelect={(e) => {
                                    handleChange('identificador2Label', e.value);
                                    handleChange('identificador2', e.value.identificador2);
                                }}
                                dropdown
                            />
                            <label htmlFor="ac" style={{ opacity: '35%' }}>Jazigo</label>
                        </span>
                    </Col>
                </Col>
                <Col md="3" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Form.Label></Form.Label>
                    <Button
                        disabled={!formData?.identificador2}
                        className="button-form"
                        onClick={() => actionButton()}
                        icon="pi pi-key"
                    >
                        <p style={{ display: 'flex', justifyContent: 'center', marginTop: '13px', marginLeft: '10px' }}>Verificar disponibilidade</p>
                    </Button>
                </Col>
            </Row>
            <hr />
            {!imovelDisponivel && <Row>
                <Col>
                    <h4
                        style={{ display: 'flex', justifyContent: 'center', opacity: '30%' }}
                    >
                        Verifique as disponibilidades acima para prosseguir!
                    </h4>
                </Col>
            </Row>
            }
            {formData?.imo_preco && (
                <div style={{ display: 'flex', width: '100%', backgroundColor: '#4949498a', paddingLeft: '20px', paddingBottom: '20px', paddingTop: '25px', borderRadius: '7px' }}>
                    <div style={{ display: 'flex', width: '19%', height: '32px', paddingLeft: '0px', marginRight: '3px' }}>
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#494949', color: '#fff', border: '1px solid #494949', borderTopLeftRadius: '4px' }}>
                            <strong>Valor de Tabela</strong>
                        </p>
                        <p style={{ fontSize: '14px !important', margin: '0px', padding: '3px', backgroundColor: '#e8550f', color: '#fff', border: '1px solid #e8550f', borderBottomRightRadius: '4px' }}>
                            <strong>{MoneyFormatter(formData?.imo_preco ?? 0)}</strong>
                        </p>
                    </div>
                    <div style={{ width: '20%', paddingLeft: '0px', marginRight: '14px' }}>
                        <span className="p-float-label">
                            <InputNumber
                                style={{ width: '100%', height: '38px' }}
                                value={formData?.valor_desconto ? Number(formData?.valor_desconto) : null}
                                onChange={(e: any) => handleChange('valor_desconto', e.value)}
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                            />
                            <label htmlFor="ac" >Valor Desconto Jazigo</label>
                        </span>
                    </div>
                    <div style={{ width: '40%', paddingLeft: '0px', marginRight: '8px' }}>
                        <span className="p-float-label">
                            <InputText
                                style={{ width: '100%', height: '38px', paddingTop: '10px !important' }}
                                value={formData?.justificativa_desc ?? ''}
                                onChange={(e) => handleChange('justificativa_desc', e.target.value)}
                            />
                            <label style={{ paddingTop: '2px ' }} htmlFor="ac" >Justificativa de Desconto</label>
                        </span>
                    </div>
                </div>
            )}
            <hr />
            {imovelDisponivel && <Row className="mb-3">
                <Col md={3} style={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}>
                    <span className="p-float-label">
                        <Calendar
                            invalid={!data_venda}
                            value={data_venda || null}
                            onChange={(event: any) => {
                                const selectedDates = event.value;
                                setDataVenda(selectedDates);
                                handleChange('data_venda', selectedDates ? selectedDates.toISOString() : null);
                            }}
                            showButtonBar
                            selectionMode="single"
                            dateFormat="dd/mm/yy"
                            locale="pt"
                            readOnlyInput
                            style={{ textAlign: 'center', width: '100%' }}
                            panelClassName="custom-calendar-panel"
                            required
                        />
                        <label style={{ paddingTop: '2px ' }} htmlFor="ac" >Data da Venda</label>
                    </span>
                </Col>
                {TokenService.getEmpreendimentoId()?.config_venda?.num_contrato_manual === true && (
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}>
                        <span className="p-float-label">
                            <InputText
                                invalid={!formData?.numero}
                                value={formData?.numero ?? ''}
                                onChange={(e) => handleChange('numero', e.target.value)}
                            />
                            <label style={{ paddingTop: '2px ' }} htmlFor="ac" >N° Contrato</label>
                        </span>
                    </Col>
                )}
                <Col md="6" style={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}>
                    <span className="p-float-label">
                        <InputText
                            value={formData?.anotacoes ?? ''}
                            onChange={(e) => handleChange('anotacoes', e.target.value)} />
                        <label style={{ paddingTop: '2px ' }} htmlFor="ac">Anotações</label>
                    </span>
                </Col>
            </Row>}
            {imovelDisponivel && <Row className="mb-3">
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <AutoComplete
                            invalid={!formData?.corretor_id}
                            field="nome_corretor"
                            style={{ height: "37px", width: '100%' }}
                            value={formData?.nome_corretor ? formData?.nome_corretor : (formData?.busca ? formData?.busca : '')}
                            suggestions={FilteredCorretor}
                            completeMethod={(e) => searchCorretor(e, 0, setFilteredCorretor, formData?.tipo_jazigo_id)}
                            onChange={(e) =>
                                handleChange('busca', e.value)}
                            onSelect={(e) =>
                                handleChange('corretor_id', e.value)}
                            dropdown
                        />
                        <label htmlFor="ac">Corretor</label>
                    </span>
                </Col>
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <Dropdown
                            className="dropdown-custom"
                            invalid={!formData?.modo_jazigo}
                            value={formData.modo_jazigo_label}
                            onChange={(e: DropdownChangeEvent) => handleChange('modo_jazigo', e.value)}
                            options={list_modo_jazigo}
                            optionLabel="name"
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="ac">Modo jazigo</label>
                    </span>
                </Col>
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <Dropdown
                            className="dropdown-custom"
                            invalid={!formData?.tipo_jazigos_vendas}
                            value={formData.tipo_jazigos_vendas_label}
                            onChange={(e: DropdownChangeEvent) => handleChange('tipo_jazigos_vendas', e.target.value)}
                            options={list_tipo_jazigos_vendas}
                            optionLabel="name"
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="ac">Tipo da Venda</label>
                    </span>
                </Col>
            </Row>}
            {imovelDisponivel && <Row className="mb-3">
                <Col md="3" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <Dropdown
                            className="dropdown-custom"
                            invalid={!formData?.manutencao_periodicidade}
                            value={formData.manutencao_periodicidade_label}
                            onChange={(e: DropdownChangeEvent) => handleChange('manutencao_periodicidade', e.target.value)}
                            options={list_manutencao_periodicidade}
                            optionLabel="name"
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="ac">Manutenção</label>
                    </span>
                </Col>
                <Col md="2" style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <InputNumber
                            minFractionDigits={2}
                            maxFractionDigits={24}
                            style={{ width: '100%', height: '38px' }}
                            value={formData?.coef_manutencao ? Number(formData?.coef_manutencao) : null}
                            onChange={(e: any) => handleChange('coef_manutencao', e.value)}
                            locale="pt-BR" />
                        <label htmlFor="ac">Coef. de manutenção</label>
                    </span>
                </Col>
                <Col md={3} style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <Calendar
                            value={data_entrega || null}
                            invalid={!data_entrega}
                            onChange={(event: any) => {
                                const selectedDates = event.value;
                                setDataEntrega(selectedDates);
                                handleChange('data_entrega', selectedDates ? selectedDates.toISOString() : null);
                            }}
                            showButtonBar
                            selectionMode="single"
                            dateFormat="dd/mm/yy"
                            locale="pt"
                            readOnlyInput
                            style={{ textAlign: 'center', width: '100%' }}
                            panelClassName="custom-calendar-panel"
                            required
                        />
                        <label style={{ paddingTop: '2px ' }} htmlFor="ac" >Data da Entrega</label>
                    </span>
                </Col>
                <Col md={3} style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <span className="p-float-label">
                        <Calendar
                            value={data_prim_manutencao || null}
                            invalid={!data_prim_manutencao}
                            onChange={(event: any) => {
                                const selectedDates = event.value;
                                setDataPrimManutencao(selectedDates);
                                handleChange('data_prim_manutencao', selectedDates ? selectedDates.toISOString() : null);
                            }}
                            showButtonBar
                            selectionMode="single"
                            dateFormat="dd/mm/yy"
                            locale="pt"
                            readOnlyInput
                            style={{ textAlign: 'center', width: '100%' }}
                            panelClassName="custom-calendar-panel"
                            required
                        />
                        <label style={{ paddingTop: '2px ' }} htmlFor="ac" >Data da Primeira Manutenção</label>
                    </span>
                </Col>
            </Row>}
        </div>
    );
}

export default SecaoJazigo;