import { useAppContext } from '../../../contexts/AppContext';
import { useAppContext as useResponseContext } from '../../../contexts/ResponseContext';
import { useEffect, useState } from 'react';
import { loadData, setSessionEditData } from './Service/CaracteristicaService';
import { Button } from 'primereact/button';
import Paginacao from './Components/Paginacao';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import FiltroAvancadoModal from './Components/ModalFiltroAvancado';
import { Col, Container, Form, NavLink, Row } from 'react-bootstrap';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import iconFiltroAvancado from '../../../../assets/images/icon-filtro-avancado.png';
import { actionTemplate } from './Components/actionTemplate';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

function RelatorioLancamento() {
  const { setUrl_button_create, showSubSidebar, setItemsBreadsCrumbs } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [formBasico, setFormBasico] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [nodes, setNodes] = useState<any | null>(null);
  const { titles, updateTitles, pagination, updatePagination, } = useResponseContext();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(8);
  const url_redirect_edit = "/revenda/caracteristicas/update";
  const urlRedirectCreated = "/revenda/caracteristicas/store";
  const urlShow = "/revenda/caracteristicas/show";
  const [sortOptions, setSortOptions] = useState<{ field: string, order: number } | null>(null);
  const [showModal, setShowModal] = useState(false);
  const { isVisible, updateVisibility } = useSidebarContext();

  useEffect(() => {
    // setUrl_button_create(urlRedirectCreated);
    loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro, sortOptions);
  }, [currentPage, sortOptions, itemsPerPage]);

  useEffect(() => {
    setItemsBreadsCrumbs([
      {
        label: 'Relatório Lançamento',
        url: '/relatorio-lancamento',
      },
    ]);
  }, []);

  const handleChange = (field: string, value: string) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  const handleChangeList = (field: string, value: string) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros(null);
    setFormBasico(null);
  };

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };


  const handleFilter = () => {
    setShowModal(false);
  };

  const handleSort = (field: string) => {
    let order = 1;
    if (sortOptions && sortOptions.field === field) {
      order = sortOptions.order === 1 ? -1 : sortOptions.order === -1 ? 0 : 1;
    }
    setSortOptions({ field, order });
  };

  const getSortIcon = (field: string) => {
    if (sortOptions?.field === field) {
      return sortOptions.order === 1 ? <FaSortUp /> : sortOptions.order === -1 ? <FaSortDown /> : <FaSort style={{ color: '#b8b8b8' }} />;
    }
    return <FaSort style={{ color: '#b8b8b8' }} />;
  };

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                style={{ width: '23px' }}
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Relatório de Lançamento</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <div style={{
            display: "flex",
            justifyContent: 'space-between'
          }}>
            <Row style={{ margin: '10px' }}>
              <Col>
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  value={listFiltros?.descricao || ''}
                  onChange={(e) => setListFiltros({ ...listFiltros, descricao: e.target.value })}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '40px', display: 'flex', justifyContent: 'right' }}>
              <Col style={{ display: 'flex', justifyContent: 'right' }} className="mb-2">
                <Button
                  style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  rounded outlined severity="secondary"
                  onClick={() => setShowModalFiltro(true)}
                >
                  <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                </Button>
                <Button
                  style={{ marginRight: '5px', height: '30px', borderRadius: '8px', fontSize: '14px' }}
                  type="button"
                  severity="success"
                  icon="pi pi-filter"
                  rounded
                  onClick={() => loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                  <p className="d-inline-block" style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                  style={{ height: '30px', borderRadius: '8px', fontSize: '14px', marginRight: '15px' }}
                  type="button"
                  severity="danger"
                  icon="pi pi-delete-left"
                  rounded
                  onClick={() => {
                    setListFiltros({});
                    loadData(listFiltros, itemsPerPage, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)
                  }}
                >
                  <p className="d-inline-block" style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
              </Col>
            </Row>
          </div>
          <FiltroAvancadoModal
            showModal={showModalFiltro}
            onHide={() => setShowModalFiltro(false)}
            handleClose={() => setShowModalFiltro(false)}
            listFiltros={listFiltros}
            setListFiltros={setListFiltros}
            loadData={loadData}
            setLoading={setLoading}
            currentPage={currentPage}
            setNodes={setNodes}
            updateTitles={updateTitles}
            updatePagination={updatePagination}
            setShowModalFiltro={setShowModalFiltro}
            itemsPerPage={itemsPerPage}
          />
        </div >
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <div className="card" style={{ paddingRight: '12px' }}>
          <DataTable
            lazy
            size="small"
            stripedRows
            value={nodes || []}
            tableStyle={{ minWidth: '50rem' }}
            emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
          >
            <Column
              style={{ textAlign: 'right' }}
              key={'id'}
              field={'id'}
              headerClassName="righted-header"
              header={<div className="righted-content" onClick={() => handleSort('id')}>Id{getSortIcon('id')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'left' }}
              key={'nome_campos'}
              field={'nome_campos'}
              headerClassName="lefted-header"
              header={<div className="lefted-content" onClick={() => handleSort('nome_campos')}>Nome Campo{getSortIcon('nome_campos')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'left' }}
              key={'descricao'}
              field={'descricao'}
              headerClassName="lefted-header"
              header={<div className="lefted-content" onClick={() => handleSort('descricao')}>Descrição{getSortIcon('descricao')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'left' }}
              key={'tipo_imovel_label'}
              field={'tipo_imovel_label'}
              headerClassName="lefted-header"
              header={<div className="lefted-content" onClick={() => handleSort('tipo_imovel_id')}>Tipo Imovel{getSortIcon('tipo_imovel_id')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'left' }}
              key={'sub_tipo_imovel_label'}
              field={'sub_tipo_imovel_label'}
              headerClassName="lefted-header"
              header={<div className="lefted-content" onClick={() => handleSort('sub_tipo_imovel_id')}>Sub Tipo Imovel{getSortIcon('sub_tipo_imovel_id')}</div>}
              body={isLoading && (<Skeleton />)}
            />
            <Column
              style={{ textAlign: 'left' }}
              key={'tipo_dado'}
              field={'tipo_dado'}
              headerClassName="lefted-header"
              header={
                <div className="lefted-content" onClick={() => handleSort('tipo_dado')}>
                  Tipo Dado {getSortIcon('tipo_dado')}
                </div>
              }
              body={(rowData) => isLoading ? (<Skeleton />) : (<span>{rowData.tipo_dado === 1 ? 'Texto' : rowData.tipo_dado === 2 ? 'Tag' : ''}</span>)}
            />
            <Column
              style={{ textAlign: 'right' }}
              body={(e) => actionTemplate(e,
                url_redirect_edit,
                setSelectedRow,
                setShowModal,
                urlShow,
              )}
              headerClassName="righted-header"
              header={<div className="righted-content">Menu</div>}
            />
          </DataTable>
          <Paginacao
            totalPages={pagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            setItemsPorPagina={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default RelatorioLancamento;
