import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import { AlertService } from '../../../components/AlertService';
import CaracteristicasImoveisForm from './Formularios/CaracteristicasImoveisForm';

interface PropsCaracteristicaData {
    tipo_caracteristica?: number;
    descricao?: string;
    nome_campos?: string;
    tipo_dado?: number;
    tag?: string;
    icone?: string;
    tipo_imovel_id?: number;
    sub_tipo_imovel_id?: number;
}

function EditCaracteristicasImoveis() {
    const { setUrl, setBody, setShowButtonSave, setTypeRequest, setItemsBreadsCrumbs } = useAppContext();
    const [caracteristicaData, setCaracteristicaData] = useState<PropsCaracteristicaData>({});

    useEffect(() => {
        try {
            setItemsBreadsCrumbs([
                {
                    label: 'Revenda',
                    url: '/revenda',
                },
                {
                    label: 'Características do imóvel',
                    url: '/revenda/caracteristicas',
                },
                {
                    label: 'Editar'
                }
            ]);
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setCaracteristicaData((prevCaracteristicaData) => {
                    const dadosAtualizados = {
                        ...prevCaracteristicaData,
                        ...JSON.parse(storedData)
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/vendas/caracteristicas-imoveis-dinamicas/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);
    useEffect(() => {
        setBody(caracteristicaData)
    }, [caracteristicaData]);

    const handleChange = (field: string, value: any) => {
        setCaracteristicaData((prevCaracteristicaData) => {
            return {
                ...prevCaracteristicaData,
                [field]: value
            };
        });
        setTypeRequest('PUT');
    };

    return (
        <div className="body-content">
            <div className="form-create-group">
                <div className="form-create-group-header">
                    <h3>Alterar Característica Imovel</h3>
                </div>
            </div>
            <div className="form-group-edit-imovel">
                <Container>
                    <CaracteristicasImoveisForm
                        initialRightValues={[]}
                        caracteristicaData={caracteristicaData}
                        onInputChange={(field: string, value: any) => handleChange(field, value)}
                    />
                </Container>
            </div>
        </div>
    );
}

export default EditCaracteristicasImoveis;
