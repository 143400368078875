import React, { useState, useEffect } from 'react';
import './../../styles/global.css';
import { Card, Col, Row } from 'react-bootstrap';
import { AlertService } from '../../components/AlertService';
import { url } from '../../environments/environments-develop';
import api from '../../services/api';
import TokenService from '../../services/Auth/token.service';
import { useSidebarContext } from '../../contexts/SidebarContext';
import CircleChart from './Componentes/CircleChart';
import DoughnutChartEmpreendimento from './Componentes/DoughnutChart';
import { useAppContext } from '../../contexts/AppContext';

function Locacao() {
  const { updateVisibility, isVisible } = useSidebarContext();
  const { setItemsBreadsCrumbs, situacaoLicenca } = useAppContext();
  const [dashGeral, setDashGeral] = useState<any>({});

  const queryParams = {
    empreendimento_id: TokenService.getEmpreendimentoId()?.id ?? null
  };

  useEffect(() => {
    if (situacaoLicenca === 2) {
      AlertService.infomessage(
        'Lembrete',
        'Identificamos que valor(es) referente a este serviço encontra-se em aberto. Para evitar suspensão automática do serviço em breve, recomendamos que entre em contato com nosso financeiro pelo Whatsapp 6398446-0134 ou adm@qative.com.br para regularização.'
      );
    } else if (situacaoLicenca === 3) {
      AlertService.error(
        'Aviso',
        'Este serviço encontra-se suspenso temporariamente. Restabeleça o seu acesso entrando em contato com nosso financeiro pelo Whatsapp 6398446-0134 ou adm@qative.com.br para regularização.'
      );
    }
    setItemsBreadsCrumbs([
      {
        label: 'Empreendimento',
        url: '/empreendimento',
      },
    ]);
    montarFiltro(1);
    updateVisibility(true);
  }, []);

  async function montarFiltro(filtroQuery: any) {
    try {
      initDashGeral(queryParams);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  };

  async function initDashGeral(queryParams: { empreendimento_id: any }) {
    try {
      const response = await api.get(`${url}/api/v1/admin/vendas/imovel/dashboard`, { params: queryParams });
      if (response.status === 200) {
        setDashGeral(response.data);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error('Erro!', String(mensagens));
    }
  };

  const categorias = [
    { label: 'Disponível', key: 'disponivel', color: '#4CAF50' },
    { label: 'Negociado', key: 'negociado', color: '#F44336' },
    { label: 'Vendido', key: 'vendido', color: '#fd7e14' },
    { label: 'Reservados', key: 'reservados', color: '#9C27B0' },
    { label: 'Reserva Simples', key: 'reserva_simples', color: '#2196F3' },
    { label: 'Reserva Fixa', key: 'reserva_fixa', color: '#FF9800' },
    { label: 'Reserva Diretoria', key: 'reserva_diretoria', color: '#795548' },
    { label: 'Reserva Técnica', key: 'reserva_tecnica', color: '#607D8B' },
  ];

  return (
    <div className={`${isVisible ? 'container-section-home-visible' : 'container-section-home'}`}>
      <Row>
        <Col md={8} >
          <Card
            className="mb-3"
            style={{
              height: '450px',
              marginTop: '20px',
              marginLeft: '20px',
              marginRight: '20px',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ marginTop: '15px' }}>
              <p style={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '10px' }}>Imóveis</p>
              <Row>
                {categorias.map((categoria) => (
                  <Col key={categoria.key} xs={6} sm={4} md={3} lg={3} style={{ textAlign: 'center' }}>
                    <CircleChart
                      label={categoria.label}
                      value={dashGeral?.data?.[categoria.key] || 0}
                      total={dashGeral?.data?.total}
                      color={categoria.color}
                      size={140}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card
            className="mb-3"
            style={{
              height: '450px',
              marginTop: '20px',
              marginRight: '20px',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              padding: '10px',
            }}
          >
            <DoughnutChartEmpreendimento
              disponivel={dashGeral?.data?.disponivel || 0}
              vendido={dashGeral?.data?.vendido || 0}
              reservados={dashGeral?.data?.reservados || 0}
              total={dashGeral?.data?.total || 0}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Locacao;