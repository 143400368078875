import './../../styles/global.css';
import paginaEmConstrucao from '../../../assets/images/pagina-em-construcao.png';
import { Col, Form, Row } from 'react-bootstrap';
import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AlertService } from '../../components/AlertService';
import { url } from '../../environments/environments-develop';
import api from '../../services/api';
import TokenService from '../../services/Auth/token.service';
import { useSidebarContext } from '../../contexts/SidebarContext';
import { useAppContext } from '../../contexts/AppContext';
import { RadioButton } from 'primereact/radiobutton';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
function Carteira() {
  const [progressConfirmados, setConfirmados] = useState(0);
  const [progressConfirmadosValor, setConfirmadosValor] = useState(0);
  const [progressConfirmadosPercentual, setConfirmadosPercentual] = useState(0);

  const [progressAReceber, setAReceber] = useState(0);
  const [progressAReceberValor, setAReceberValor] = useState(0);
  const [progressAReceberPercentual, setAReceberPercentual] = useState(0);

  const [progressInadimplentes, setInadimplentes] = useState(0);
  const [progressInadimplentesValor, setInadimplentesValor] = useState(0);
  const [progressInadimplentesPercentual, setInadimplentesPercentual] = useState(0);
  const [filtroQuery, setFiltroQuery] = useState(1);
  const [totalVendas, setTotalVendas] = useState(0);
  const { updateVisibility } = useSidebarContext();
  const { situacaoLicenca, setItemsBreadsCrumbs } = useAppContext();
  const circleSize = 110;
  const borderWidth = 4;
  const radius = (circleSize - borderWidth) / 2;
  const [totalInadimplentes, setTotalInadimplentes] = useState<any | null>(0);
  const [emissoesPeriodo, setEmissoesPeriodo] = useState<any | null>(0);
  const [qtdTotal, setQtdTotal] = useState<any | null>(0);
  const circumference = 2 * Math.PI * radius;
  const progressConfirmadosLength = (progressConfirmadosPercentual / 100) * circumference;    // Comprimento do progresso
  const progressAReceberLength = (progressAReceberPercentual / 100) * circumference;       // Comprimento do progresso
  const progressInadimplentesLength = (progressInadimplentesPercentual / 100) * circumference;  // Comprimento do progresso
  const [nodesConfirmacoes, setNodesConfirmacoes] = useState<any | null>(null);
  const [nodesInadimplentes, setNodesInadimplentes] = useState<any | null>(null);
  const [titles, setTitles] = useState<any | null>(null);
  const { isVisible } = useSidebarContext();
  const [isLoading, setLoading] = useState(false);
  const labelVigentes = "Mostra as parcelas em aberto e não pagas APENAS na visão Dia e Mes";
  useEffect(() => {
    if (situacaoLicenca === 2) {
      AlertService.infomessage(
        "Lembrete",
        "Identificamos que valor(es) referente a este serviço encontra-se em aberto. Para evitar suspensão automática, entre em contato com nosso financeiro."
      );
    } else if (situacaoLicenca === 3) {
      AlertService.error(
        "Aviso",
        "Este serviço encontra-se suspenso. Entre em contato com nosso financeiro."
      );
    }
    montarFiltro(1);
    updateVisibility(true);
    setItemsBreadsCrumbs([
      {
        label: 'Carteira',
        url: '/carteira',
      },
    ]);
  }, []);

  async function montarFiltro(filtro: any) {
    const queryParams = {
      filtro,
      empreendimento_id: TokenService.getEmpreendimentoId()?.id ?? null,
    };

    const queryString = new URLSearchParams(queryParams).toString();
    setLoading(true);
    await initRelatorioDashboard(queryString);
    await initDashGeral(queryString);
    await initConfirmados(queryString);
    await initInadimplentes(queryString);
    setLoading(false);
  }

  async function initRelatorioDashboard(queryString: any) {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/relatorio-dashboard?${queryString}`);
      setConfirmados(response.data.confirmados);
      setConfirmadosValor(response.data.valorConfirmados);
      setConfirmadosPercentual(response.data.percentConfirmados);
      setAReceber(response.data.abertos);
      setAReceberValor(response.data.valorAbertos);
      setAReceberPercentual(response.data.percentAbertos);
      setInadimplentes(response.data.inadimplentes);
      setInadimplentesValor(response.data.valorInadimplentes);
      setInadimplentesPercentual(response.data.percentInadimplentes);
    } catch (error: any) {
      AlertService.error("Erro!", error.response?.data?.message || "Erro desconhecido");
    }
  }
  async function initConfirmados(queryString: any) {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/relatorio-dashboard-confirmados?${queryString}`);
      if (response.status === 200) {
        const titles = response.data.headers.titles;
        setTitles(titles)
        const data = response.data.data;
        setNodesConfirmacoes(data);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error('Erro!', String(mensagens));
    }
  };
  async function initInadimplentes(queryString: any) {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/relatorio-dashboard-inadimplentes?${queryString}`);
      if (response.status === 200) {
        const titles = response.data.headers.titles;
        setTitles(titles)
        const data = response.data.data;
        setNodesInadimplentes(data);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error('Erro!', String(mensagens));
    }
  };
  async function initDashGeral(queryString: any) {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/relatorio-dashboard-geral?${queryString}`);
      if (response.status === 200) {
        setTotalVendas(response.data.total_vendas);
        setTotalInadimplentes(response.data.total_inadimplentes);
        setEmissoesPeriodo(response.data.emissoes_periodo);
        setQtdTotal(response.data.quantidade_total);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error('Erro!', String(mensagens));
    }
  };

  return (
    <div className="w-full h-full grid grid-col pb-20">
      <div className="w-full flex max-md:flex-col items-start pt-2 pr-5 mb-2">
        <Card className="w-full lg:w-2/3 rounded-lg p-4 mb-1 max-md:mb-6">
          <h2 className="text-xl font-semibold mb-4">Relatório do Dia</h2>
          <div className="flex flex-wrap gap-4">
            {["Dia", "Última Semana", "Últimos 30 Dias", "Mês"].map((label, index) => (
              <div key={index} className="flex items-center mb-4">
                <RadioButton
                  inputId={`filtro-${index}`}
                  name="filtro"
                  value={index + 1}
                  onChange={(e) => {
                    setFiltroQuery(e.value);
                    montarFiltro(e.value);
                  }}
                  checked={filtroQuery === index + 1}
                />
                <label htmlFor={`filtro-${index}`} className="ml-2">
                  {label}
                </label>
              </div>
            ))}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 mb-4">
            <Card className="flex flex-col items-center mb-2 shadow-none">
              <h3 id="info-tooltip" className="text-center text-lg font-medium mb-2">Confirmados</h3>
              <div className="relative w-28 h-28">
                <svg className="w-full h-full transform -rotate-90 mb-2">
                  <circle
                    cx={circleSize / 2} cy={circleSize / 2} r={radius}
                    fill="none"
                    stroke="#e5e7eb"
                    strokeWidth="4"
                  />
                  <circle
                    cx={circleSize / 2}
                    cy={circleSize / 2}
                    r={radius}
                    fill="none"
                    stroke="#4CAF50"
                    strokeWidth={borderWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={circumference - progressConfirmadosLength}
                  />
                </svg>
                <span className="absolute inset-0 flex items-center justify-center text-xl font-bold">
                  {progressConfirmados}
                </span>
              </div>
              <h6 className="text-center text-sm font-medium mb-2">{progressConfirmadosValor}</h6>
            </Card>
            <Card className="flex flex-col items-center mb-2 shadow-none">
              <div className="flex items-center justify-center">
                <h3 id="info-tooltip" className="text-center text-lg font-medium ">Vigentes</h3>
                <Tooltip target="#info-tooltip" content={labelVigentes} position="top" />
              </div>
              <div className="relative w-28 h-28">
                <svg className="w-full h-full transform -rotate-90 mb-2">
                  <circle
                    cx={circleSize / 2} cy={circleSize / 2} r={radius}
                    fill="none"
                    stroke="#e5e7eb"
                    strokeWidth="4"
                  />
                  <circle
                    cx={circleSize / 2}
                    cy={circleSize / 2}
                    r={radius}
                    fill="none"
                    stroke="#ffbb00"
                    strokeWidth={borderWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={circumference - progressAReceberLength}
                  />
                </svg>
                <span className="absolute inset-0 flex items-center justify-center text-xl font-bold">
                  {progressAReceber}
                </span>
              </div>
              <h6 className="text-center text-sm font-medium mb-2">{progressAReceberValor}</h6>
            </Card>
            <Card className="flex flex-col items-center mb-2 shadow-none">
              <h3 className="text-center text-lg font-medium mb-2">Inadimplentes</h3>
              <div className="relative w-28 h-28">
                <svg className="w-full h-full transform -rotate-90 mb-2">
                  <circle
                    cx={circleSize / 2} cy={circleSize / 2} r={radius}
                    fill="none"
                    stroke="#e5e7eb"
                    strokeWidth="4"
                  />
                  <circle
                    cx={circleSize / 2}
                    cy={circleSize / 2}
                    r={radius}
                    fill="none"
                    stroke="#ff0000"
                    strokeWidth={borderWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={circumference - progressInadimplentesLength}
                  />
                </svg>
                <span className="absolute inset-0 flex items-center justify-center text-xl font-bold">
                  {progressInadimplentes}
                </span>
              </div>
              <h6 className="text-center text-sm font-medium mb-4">{progressInadimplentesValor}</h6>
            </Card>
          </div>
        </Card>
        <Card className="w-2/4 max-md:w-full rounded-lg p-4 md:ml-3">
          <h2 className="text-xl font-semibold mb-4">Resumo Geral</h2>
          <div className="grid grid-cols-2 gap-2 mb-1">
            <div className="flex flex-col items-center mb-5">
              <h2 className="mb-3 ">
                {emissoesPeriodo}
              </h2>
              <label className="text-orange-700 mb-3">
                Emissões ocorridas por periodo
              </label>
            </div>
            <div className="flex flex-col items-center mb-5">
              <h2 className="mb-3">
                {totalInadimplentes}
              </h2>
              <label className="text-orange-700 mb-3">
                Cobranças Inadimplentes
              </label>
            </div>
            <div className="flex flex-col items-center mb-5">
              <h2 className="mb-3">
                {qtdTotal}
              </h2>
              <label className="text-orange-700 mb-3">
                Quantidade total de parcelas
              </label>
            </div>
            <div className="flex flex-col items-center mb-5">
              <h2 className="mb-3">
                {totalVendas}
              </h2>
              <label className="text-orange-700 mb-3">
                Total de vendas
              </label>
            </div>
          </div>
        </Card>
      </div>
      <div className="w-full flex max-md:flex-col items-start pr-5 pb-0 mb-0">
        <Card className="w-2/4 max-md:w-full rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-4">Ultimas Confirmações</h2>
          <div className="w-full grid grid-cols-1 gap-2 mb-6">
            <DataTable
              value={nodesConfirmacoes}
              tableStyle={{ width: '100%', display: 'revert-layer' }}
              emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}>
              <Column
                style={{ textAlign: 'left' }}
                key={'numero_documento'}
                field={'numero_documento'}
                headerClassName="sticky-header-dashboard lefted-header"
                header={<div className="lefted-content">N°.Documento</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'cliente_nome'}
                field={'cliente_nome'}
                headerClassName="sticky-header-dashboard lefted-header"
                header={<div className="lefted-content" >Cliente </div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'data_vencimento_format'}
                field={'data_vencimento_format'}
                headerClassName="sticky-header-dashboard lefted-header"
                header={<div className="lefted-content" >Dt.Vencimento </div>}
              />
              <Column
                style={{ textAlign: 'right' }}
                key={'valor_original_format'}
                field={'valor_original_format'}
                headerClassName="sticky-header-dashboard righted-header"
                header={<div className="righted-content" >Valor</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'unidade'}
                field={'unidade'}
                headerClassName="sticky-header-dashboard lefted-header"
                header={<div className="lefted-content" >Unidade</div>}
              />
            </DataTable>
          </div>
        </Card>
        <Card className="w-2/4 max-md:w-full rounded-lg p-4 md:ml-3">
          <h2 className="text-xl font-semibold mb-4">Ultimas Inadimplências</h2>
          <div className="w-full grid grid-cols-1 gap-2 mb-6">
            <DataTable
              value={nodesInadimplentes}
              tableStyle={{ width: '100%', display: 'revert-layer' }}
              emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}>
              <Column
                style={{ textAlign: 'left' }}
                key={'numero_documento'}
                field={'numero_documento'}
                headerClassName="sticky-header-dashboard lefted-header"
                header={<div className="lefted-content">N°.Documento</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'cliente_nome'}
                field={'cliente_nome'}
                headerClassName="sticky-header-dashboard lefted-header"
                header={<div className="lefted-content" >Cliente </div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'data_vencimento_format'}
                field={'data_vencimento_format'}
                headerClassName="sticky-header-dashboard lefted-header"
                header={<div className="lefted-content" >Dt.Vencimento </div>}
              />
              <Column
                style={{ textAlign: 'right' }}
                key={'valor_original_format'}
                field={'valor_original_format'}
                headerClassName="sticky-header-dashboard righted-header"
                header={<div className="righted-content" >Valor</div>}
              />
              <Column
                style={{ textAlign: 'left' }}
                key={'unidade'}
                field={'unidade'}
                headerClassName="sticky-header-dashboard lefted-header"
                header={<div className="lefted-content" >Unidade</div>}
              />
            </DataTable>
          </div>
        </Card>
      </div>
    </div >
  );
}
export default Carteira;