import React from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

interface EmitirContratoButtonProps {
    nodeId: string;
}

const EmitirContratoButton: React.FC<EmitirContratoButtonProps> = ({ nodeId }) => {
    const menu = React.useRef<Menu>(null);

    const items: any[] = [
        {
            label: "Emitir Contrato",
            icon: "pi pi-file",
            command: async () => {
                window.location.href = `/locacao/contratos/store?numero=${nodeId}`;
            },
        }
    ];

    return (
        <div className="relative">
            <Menu model={items} popup ref={menu} />
            <Button
                icon="pi pi-ellipsis-v"
                className="p-button-rounded p-button-text p-button-sm"
                onClick={(e) => menu.current?.toggle(e)}
            />
        </div>
    );
};

export default EmitirContratoButton;    