import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, NavLink } from "react-bootstrap";
import { useAppContext } from "../../../contexts/AppContext";
import { url } from "../../../environments/environments-develop";
import TokenService from "../../../services/Auth/token.service";
import { addLocale } from "primereact/api";
import FormularioPreenchimento from "./Formularios/FormularioPreenchimento";
import { useSidebarContext } from "../../../contexts/SidebarContext";
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';

function EditLocacaoContratos() {
    const { setUrl, setBody, setShowButtonSave, setItemsBreadsCrumbs, setTypeRequest, setValidationRequest } = useAppContext();
    const [formData, setFormData] = useState<any | null>({
        imoveis_caracteristicas: [],
        enderecos: [],
        empreendimento_id: TokenService.getEmpreendimentoId()?.id,
    });
    const [FilteredCorretor, setFilteredCorretor] = useState([]);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    const { isVisible, updateVisibility } = useSidebarContext();

    useEffect(() => {
        setItemsBreadsCrumbs([
            {
                label: 'Locação',
                url: '/locacao',
            },
            {
                label: 'Lista de Contratos',
                url: '/contratos',
            },
            {
                label: 'Editar'
            }
        ]);
        const storedData = localStorage.getItem('dadosEdicao');
        if (storedData) {
            const data = JSON.parse(storedData);
            setFormData(data);
            setFormData((data: any) => {
                return {
                    ...data,
                    ...JSON.parse(storedData),
                }
            });
            setTypeRequest('PUT');
            setUrl(`${url}/api/v1/admin/vendas/contrato-imovel-captado/${data.id}`);
        }
        setShowButtonSave(true);
    }, []);

    useEffect(() => {
        setValidationRequest(true);
        setBody(formData);
    }, [formData]);

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    return (
        <div className="body-content">
            <div className="form-group-create">
                <div className="contentSearch-header">
                    <NavLink onClick={toggleVisibility}>
                        <img
                            className="menu-context"
                            src={iconMenuTresPonto}
                        />
                    </NavLink>
                    <Col className='text-center' style={{ fontSize: '20px' }}>Edição do Contrato</Col>
                </div>
                <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
                <Container>
                    <FormularioPreenchimento
                        formData={formData}
                        setFormData={setFormData}
                        FilteredPessoa={FilteredPessoa}
                        FilteredCorretor={FilteredCorretor}
                        setFilteredPessoa={setFilteredPessoa}
                        setFilteredCorretor={setFilteredCorretor}
                    />
                </Container>
            </div>
        </div>
    );
}

export default EditLocacaoContratos;
