import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function VendaAvulsa(props: any) {
    const [formData, setFormData] = useState<any>({});

    const handleFormChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    }
    useEffect(() => {
        setFormData(props.formData);
    }, [props.formData]);
    return (
        <>
             <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o codipaga?</Form.Label>
                    <Form.Control
                        value={formData.codipaga || ''}
                        onChange={(e) => handleFormChange('codipaga', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codicoin?</Form.Label>
                    <Form.Control
                        value={formData.codicoin || ''}
                        onChange={(e) => handleFormChange('codicoin', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codibaix ?</Form.Label>
                    <Form.Control
                        value={formData.codibaix || ''}
                        onChange={(e) => handleFormChange('codibaix', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o dataemis?</Form.Label>
                    <Form.Control
                        value={formData.dataemis || ''}
                        onChange={(e) => handleFormChange('dataemis', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o datavenc?</Form.Label>
                    <Form.Control
                        value={formData.datavenc || ''}
                        onChange={(e) => handleFormChange('datavenc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datapaga?</Form.Label>
                    <Form.Control
                        value={formData.datapaga || ''}
                        onChange={(e) => handleFormChange('datapaga', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o percmult?</Form.Label>
                    <Form.Control
                        value={formData.percmult || ''}
                        onChange={(e) => handleFormChange('percmult', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o percjuro?</Form.Label>
                    <Form.Control
                        value={formData.percjuro || ''}
                        onChange={(e) => handleFormChange('percjuro', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o valodocu?</Form.Label>
                    <Form.Control
                        value={formData.valodocu || ''}
                        onChange={(e) => handleFormChange('valodocu', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valoacre?</Form.Label>
                    <Form.Control
                        value={formData.valoacre || ''}
                        onChange={(e) => handleFormChange('valoacre', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valodesc?</Form.Label>
                    <Form.Control
                        value={formData.valodesc || ''}
                        onChange={(e) => handleFormChange('valodesc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valopaga?</Form.Label>
                    <Form.Control
                        value={formData.valopaga || ''}
                        onChange={(e) => handleFormChange('valopaga', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o codicida?</Form.Label>
                    <Form.Control
                        value={formData.codicida || ''}
                        onChange={(e) => handleFormChange('codicida', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o numecaix?</Form.Label>
                    <Form.Control
                        value={formData.numecaix || ''}
                        onChange={(e) => handleFormChange('numecaix', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o logisenh?</Form.Label>
                    <Form.Control
                        value={formData.logisenh || ''}
                        onChange={(e) => handleFormChange('logisenh', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datarece?</Form.Label>
                    <Form.Control
                        value={formData.datarece || ''}
                        onChange={(e) => handleFormChange('datarece', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o tipopaga?</Form.Label>
                    <Form.Control
                        value={formData.tipopaga || ''}
                        onChange={(e) => handleFormChange('tipopaga', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o arqureto?</Form.Label>
                    <Form.Control
                        value={formData.arqureto || ''}
                        onChange={(e) => handleFormChange('arqureto', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codifopa?</Form.Label>
                    <Form.Control
                        value={formData.codifopa || ''}
                        onChange={(e) => handleFormChange('codifopa', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codiclie?</Form.Label>
                    <Form.Control
                        value={formData.codiclie || ''}
                        onChange={(e) => handleFormChange('codiclie', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o ordepaga?</Form.Label>
                    <Form.Control
                        value={formData.ordepaga || ''}
                        onChange={(e) => handleFormChange('ordepaga', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o statpaga?</Form.Label>
                    <Form.Control
                        value={formData.statpaga || ''}
                        onChange={(e) => handleFormChange('statpaga', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o codifunc?</Form.Label>
                    <Form.Control
                        value={formData.codifunc || ''}
                        onChange={(e) => handleFormChange('codifunc', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o datacada?</Form.Label>
                    <Form.Control
                        value={formData.datacada || ''}
                        onChange={(e) => handleFormChange('datacada', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o logicada?</Form.Label>
                    <Form.Control
                        value={formData.logicada || ''}
                        onChange={(e) => handleFormChange('logicada', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o tipocaix?</Form.Label>
                    <Form.Control
                        value={formData.tipocaix || ''}
                        onChange={(e) => handleFormChange('tipocaix', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o statfina?</Form.Label>
                    <Form.Control
                        value={formData.statfina || ''}
                        onChange={(e) => handleFormChange('statfina', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o valoindi?</Form.Label>
                    <Form.Control
                        value={formData.valoindi || ''}
                        onChange={(e) => handleFormChange('valoindi', e.target.value)} />
                </Col>
            </Row>
            <Row className="group-form">
                <Col>
                    <Form.Label>Em qual Coluna está o codiindi?</Form.Label>
                    <Form.Control
                        value={formData.codiindi || ''}
                        onChange={(e) => handleFormChange('codiindi', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o comprovanteDePagamento?</Form.Label>
                    <Form.Control
                        value={formData.comprovanteDePagamento || ''}
                        onChange={(e) => handleFormChange('comprovanteDePagamento', e.target.value)} />
                </Col>
                <Col>
                    <Form.Label>Em qual Coluna está o autorizacaoCartao?</Form.Label>
                    <Form.Control
                        value={formData.autorizacaoCartao || ''}
                        onChange={(e) => handleFormChange('autorizacaoCartao', e.target.value)} />
                </Col>
            </Row>
        </>
    );
}

export default VendaAvulsa;