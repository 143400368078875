import React from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { showToast } from "./ToastComponent";

type ShareButtonProps = {
    link: string; // Link a ser compartilhado
};

const ShareButton: React.FC<ShareButtonProps> = ({ link }) => {
    const menu = React.useRef<Menu>(null);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(link);
        showToast('success', "Link copiado para a área de transferência!");
    };

    const handleShareWhatsApp = () => {
        const message = encodeURIComponent(`Confira isso: ${link}`);
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        const whatsappURL = isMobile
            ? `whatsapp://send?text=${message}` // Abrir app no celular
            : `https://web.whatsapp.com/send?text=${message}`; // Abrir WhatsApp Web no desktop

        window.open(whatsappURL, "_blank");
    };

    const items = [
        {
            label: "Copiar Link",
            icon: "pi pi-copy",
            command: handleCopyLink,
        },
        {
            label: "WhatsApp",
            icon: "pi pi-whatsapp",
            command: handleShareWhatsApp,
        },
    ];

    return (
        <div className="relative">
            <Menu model={items} popup ref={menu} />
            <Button
                icon="pi pi-share-alt"
                className="p-button-rounded p-button-text p-button-sm"
                onClick={(e) => menu.current?.toggle(e)}
                aria-label="Compartilhar"
            />
        </div>
    );
};

export default ShareButton;
