import 'react-datepicker/dist/react-datepicker.css';
import { handleAddImage } from "../Service/ImovelService";
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';

interface CarouselImagemFormProps {
    images: any,
    imageTemplate: any,
    anexo: any,
    nextId: any,
    setImages: (value: any) => void,
    setNextId: (value: any) => void,
    setAnexos: (value: any) => void,
}
function CarouselImagemForm({
    images,
    imageTemplate,
    setImages,
    setNextId,
    setAnexos,
    nextId,
    anexo
}: CarouselImagemFormProps) {

    return (
        <div className="w-full flex justify-center items-center">
            {images.length === 0 ? (
                <div style={{ width: '65%' }} className="image-container">
                    <div style={{ height: '350px ' }} className="flex justify-center items-center bg-gray-200 rounded-lg">
                        <span className="text-gray-500">Sem imagem disponível</span>
                    </div>
                    <div className="overlay">
                        {/* Botões no meio */}
                        <Button
                            icon="pi pi-plus"
                            className="overlay-button add p-button-rounded p-button-success p-button-sm m-2"
                            onClick={() => document.getElementById("fileInput")?.click()}
                        />
                    </div>
                </div>
            ) : (
                <Carousel
                    value={images}
                    itemTemplate={imageTemplate}
                    numVisible={1}
                    numScroll={1}
                    className="mb-4"
                    style={{ width: '68%', height: '400px' }}
                    circular
                    showIndicators
                />
            )}
            <div className="flex flex-col gap-2 mt-2">
                <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleAddImage(e, setImages, setNextId, setAnexos, nextId, anexo)}
                    className="hidden"
                />
            </div>
        </div>
    );
}

export default CarouselImagemForm;
