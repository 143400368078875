import { Col, Form, Row } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from "react";
import 'react-autocomplete-input/dist/bundle.css';
import InformacaoBancariaForm from "./InformacaoBancariaForm";
import { InputNumber } from "primereact/inputnumber";
import ModalPessoa from "../Components/ModalPessoa";

function ContaFinanceiraForm(props: any) {
    const { nome, saldo, id_externo, tipo_conta, limite, emite_boleto, status, telefone_contato, descricao, seq_nosso_numero } = props.formBasico;

    const [showModalPessoa, setShowModalPessoa] = useState(false);

    const handleAddPessoa = () => {
        setShowModalPessoa(true);
    }

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    return (
        <Form.Group className="imovel-form">
            <Form.Group className="imovel-form">
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control value={nome} isInvalid={!nome} onChange={(e) => handleChange('nome', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Saldo</Form.Label>
                        <InputNumber
                            value={saldo}
                            onChange={(e) => handleChange('saldo', e.value)}
                            minFractionDigits={2}
                            maxFractionDigits={24}
                            style={{ width: '100%', height: '38px' }}
                            locale="pt-BR" />
                    </Col>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Tipo de Conta</Form.Label>
                        <Form.Select value={tipo_conta || ''} isInvalid={!tipo_conta} onChange={(e) => handleChange('tipo_conta', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Conta Corrente</option>
                            <option value={"2"}>Conta Poupança</option>
                            <option value={"3"}>Conta Caixa</option>
                            <option value={"4"}>Conta Cofre</option>
                        </Form.Select>
                    </Form.Group>
                    <Col>
                        <Form.Label>Limite</Form.Label>
                        <InputNumber
                            value={limite}
                            onChange={(e) => handleChange('limite', e.value)}
                            minFractionDigits={2}
                            maxFractionDigits={24}
                            style={{ width: '100%', height: '38px' }}
                            locale="pt-BR" />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={status || ''} isInvalid={!status} onChange={(e) => handleChange('status', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Inativo</option>
                            <option value={"2"}>Ativado</option>
                            <option value={"3"}>Desativado</option>
                        </Form.Select>
                    </Col>
                    <Col md="2">
                        <Form.Label>Telefone de Contato</Form.Label>
                        <InputNumber
                            value={telefone_contato ? telefone_contato : null}
                            onChange={(e) => handleChange("telefone_contato", e.value)}
                            useGrouping={false}
                            min={0}
                            max={999999999999}
                        />
                    </Col>
                    <Col md="1">
                        <Form.Label>Id Externo</Form.Label>
                        <InputNumber
                            value={id_externo ? id_externo : null}
                            onChange={(e) => handleChange("id_externo", e.value)}
                            useGrouping={false}
                            min={0}
                            max={999999999999}
                        />
                    </Col>
                    <Col md='4'>
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control value={descricao} onChange={(e) => handleChange('descricao', e.target.value)} />
                    </Col>
                    <Col md="1">
                        <Form.Label>Emite Boleto?</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={emite_boleto || false}
                            onChange={(e) => handleChange("emite_boleto", e.target.checked)}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                    {emite_boleto && (
                        <Col md={2}>
                            <Form.Label>Seq. Nosso Número</Form.Label>
                            <Form.Control
                                type="number"
                                value={seq_nosso_numero}
                                onChange={(e) => handleChange('seq_nosso_numero', e.target.value)}
                            />
                        </Col>
                    )}
                </Row>
            </Form.Group>

            {tipo_conta && tipo_conta !== '3' && tipo_conta !== '4' && (
                <>
                    <InformacaoBancariaForm
                        formBasico={props.formBasico.informacao_bancaria ? props.formBasico.informacao_bancaria : {}}
                        onInputChangeInformacaoBancaria={(field: string, value: any) => props.onInputChangeInformacaoBancaria(`${field}`, value)}
                        handleAddPessoa={() => handleAddPessoa()}
                        emiteBoleto={emite_boleto}
                    />
                    <ModalPessoa
                        formData={props.formBasico.informacao_bancaria ? props.formBasico.informacao_bancaria : {}}
                        showModal={showModalPessoa}
                        handleClose={() => setShowModalPessoa(false)}
                        onInputChange={(field: string, value: any) => props.onInputChangeInformacaoBancaria(`${field}`, value)}
                    />
                </>
            )}
        </Form.Group>
    );
}

export default ContaFinanceiraForm;
